import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ActionNoticeApps
 *
 * <!-- TODO: add a description here! -->
 */
function ActionNoticeApps(props) {
  const { className = '', messageContent, linksApp = [], children, ...other } = props;

  return (
    <div className={`${css['molecule__action-notice-apps-container']} ${className}`} {...other}>
      <span className={css['favorite-card-info']}>{messageContent}</span>
      <div className={css['favorite-card-app']}>
        {linksApp?.length > 0 &&
          linksApp?.map((link, index) => (
            <a key={index} href={link?.href} target="_blank">
              <img className={css[link?.className]} src={link?.imgSrc} alt={link?.alt} />
            </a>
          ))}
      </div>

      {children}
    </div>
  );
}

export default ActionNoticeApps;
