import { startOfMonth, endOfMonth, format } from 'date-fns';

const textShortCodesDictionary = {
  getStartOfTheMonth: (value) => {
    return value.replace('%START-OF-CURRENT-MONTH%', format(startOfMonth(new Date()), 'dd/MM/yyyy'));
  },
  getEndOfTheMonth: (value) => {
    return value.replace('%END-OF-CURRENT-MONTH%', format(endOfMonth(new Date()), 'dd/MM/yyyy'));
  }
};

export function applyRichTextShortcodes(richText) {

  if (typeof richText !== 'string') {return false;}

  Object.values(textShortCodesDictionary)
    .filter(func => typeof func === 'function')
    .forEach(func => {
      richText = func(richText);
    });

  return richText;

}