import AnchorLink from 'components/atoms/anchor-link';
import React from 'react';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

/**
 * Molecule BackToTop
 *
 * Back to top of the site button
 */
function BackToTop (props) {
  const t = i18n.useTranslations('components.back-to-top');

  const {
    className = "",
    spacing = 4,
    bgColor = null,
    linkLabel= t('back-to-top'),
    ...other
  } = props;

  return <>
    <Wrapper spacing={spacing} bgColor={bgColor} {...other}>
      <Section className={`${css["back-to-top-container"]} ${className}`} tag="div">
        <AnchorLink
          linkLabel={linkLabel}
          arrowPosition="up"
          data-uppercase
          removeUnderline
          link="#main"
        />
      </Section>
    </Wrapper>
  </>;
}

export default BackToTop;
