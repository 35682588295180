/* eslint-disable max-len */
import React from 'react';
import Wrapper from 'components/atoms/wrapper';

import css from './styles.module.scss';

/**
 * Atom WaveDivider
 *
 * Generic section divider
 */
function WaveDivider(props) {
  const { color = 'white', orientation = 'right' } = props;

  return (
    <Wrapper
      className={`${css['atom__wave-divider-container']}`}
      data-orientation={orientation}
      data-svg-bg-color={color}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="240" height="64" fill="none" className={`${css['wave']}`}>
        <path
          // eslint-disable-next-line max-len
          d="m240.45452,-0.68182c-115.55082,0 -106.26874,30.18826 -156.4418,51.22914c-21.17431,8.87982 -45.30793,13.90722 -70.91919,13.90722l-806.09353,0l0,-65.13636l1033.45452,0z"
        />
      </svg>
      <svg
        width="75"
        height="32"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Camada 1"
        className={`${css['wave--small']}`}
      >
        <g>
          <path
            stroke="null"
            id="svg_1"
            strokeWidth="0px"
            d="m80.75,0c-33.82,0 -31.1,14.90335 -45.79,25.28883c-6.2,4.38665 -13.26,6.86117 -20.76,6.86117l-235.94,0l0,-32.15l302.49,0l0,0z"
          />
        </g>
      </svg>
      <div className={`${css['fill']}`}></div>
    </Wrapper>
  );
}

export default WaveDivider;
