import FormSelect from 'components/atoms/form-select';
import Section from 'components/utils/section';
import React from 'react';
import i18n from 'utils/i18n';
import Button from 'components/atoms/button';
import { IconSearch } from 'components/icons';

// loading the sass style fot the component
import css from './styles.module.scss';
import CardsList from 'components/molecules/cards-list';

/**
 * Organism ManualsExplorer
 *
 * Filter, browse and download vehicles manuals.
 */
function ManualsExplorer (props) {
  const t = i18n.useTranslations('components.manuals-explorer');

  const {
    className = "",
    children,
    filters,
    locale,
    ...other
  } = props;

  const [selectedModel, setSelectedModel] = React.useState('');
  const [selectedYear, setSelectedYear] = React.useState('');
  const [selectedMonth, setSelectedMonth] = React.useState('');

  const [yearsList, setYearsList] = React.useState([]);
  const [monthsList, setMonthsList] = React.useState([]);

  const [cards, setCards] = React.useState(false);

  function onFiltersSubmit(event) {
    event.preventDefault();
    if (window && selectedModel && selectedYear && selectedMonth) {
      fetch(`/api/manuals?model=${filters[selectedModel].model}&year=${selectedYear}&month=${selectedMonth}&locale=${locale}`).then(
        async (result) => {
          let resultData = await result.json();

          if (resultData.manualsByFilters) {
            resultData = resultData.manualsByFilters;
            const _cards = [];
            for (let a_manual of resultData) {
              if (!a_manual.document) {
                continue;
              }
              _cards.push({
                title: a_manual.category.title,
                content: a_manual.model,
                additionalContent: a_manual.version,
                linkLabel: t('download'),
                link: a_manual.document.url,
                media: {
                  url: a_manual.category.icon.url,
                  alt: a_manual.category.icon.title,
                  type: a_manual.category.icon.mediaType.title
                },
                isDownload: true
              });
            }
            setCards(_cards);
            return;
          } else if (result.errors && result.errors.length) {
            throw new Error(result.errors);
          } else {
            throw new Error('Unknown result format');
          }
        }
      );
    }
  }

  function onChangeModel(event) {
    if (event.target.value != 'lexus') {
      const initialMonth = filters[event.target.value].initialMonth,
        finalMonth = filters[event.target.value].finalMonth;
      const firstYear = parseInt(initialMonth.substring(0, 4)),
        lastYear = parseInt(finalMonth.substring(0, 4));

      const _yearsList = [];
      for (let year = lastYear; year >= firstYear; year--) {
        _yearsList.push(year);
      }
      setYearsList(_yearsList);
    }

    setSelectedModel(event.target.value);
    setSelectedYear('');
    setSelectedMonth('');
  }

  function onChangeYear(event) {
    const initialMonth = filters[selectedModel].initialMonth,
      finalMonth = filters[selectedModel].finalMonth;
    const firstYear = parseInt(initialMonth.substring(0, 4)),
      lastYear = parseInt(finalMonth.substring(0, 4));
    const firstMonth = firstYear == event.target.value ? parseInt(initialMonth.substring(5)) : 1,
      lastMonth = lastYear == event.target.value ? parseInt(finalMonth.substring(5)) : 12;

    const _monthsList = [];
    for (let month = firstMonth; month <= lastMonth; month++) {
      _monthsList.push(month);
    }
    setMonthsList(_monthsList);

    setSelectedYear(event.target.value);
    setSelectedMonth('');
  }

  function onChangeMonth(event) {
    setSelectedMonth(event.target.value);
  }

  function getMonthName(month) {
    const date = new Date(2000, month-1, 15);
    const monthName = date.toLocaleString(locale, { month: 'long' });
    return monthName[0].toUpperCase() + monthName.substring(1);
  }

  return <div
    className={`${css["manuals-explorer"]} ${className}`}
    {...other}
  >

    <Section>
      <form onSubmit={onFiltersSubmit} className={css["filters-form"]}>

        <div className={css['fields-container']}>
          <FormSelect
            label={t('model-label')}
            placeholder={t('model-placeholder')}
            onChange={onChangeModel}
            className={css['fields']}
          >
            {
              filters && filters.map((item, index) => {
                return (
                  <option key={index} value={index}>{ item.model }</option>
                );
              })
            }
            <option value="lexus">{ t('lexus') }</option>
          </FormSelect>
          { selectedModel != 'lexus' &&
            <FormSelect
              label={t('year-label')}
              onChange={onChangeYear}
              disabled={!yearsList.length}
              value={selectedYear}
              className={css['fields']}
            >
              <option value='' disabled>{t('year-placeholder')}</option>
              {
                !!yearsList.length && yearsList.map((item, index) => {
                  return (
                    <option key={index} value={item}>{ item }</option>
                  );
                })
              }
            </FormSelect>
          }
          { selectedModel != 'lexus' &&
            <FormSelect
              label={t('month-label')}
              onChange={onChangeMonth}
              disabled={!monthsList.length}
              value={selectedMonth}
              className={css['fields']}
            >
              <option value='' disabled>{t('month-placeholder')}</option>
              {
                !!monthsList.length && monthsList.map((item, index) => {
                  return (
                    <option key={index} value={item}>{ getMonthName(item) }</option>
                  );
                })
              }
            </FormSelect>
          }

          { selectedModel == 'lexus' &&
            <Button
              className={`${css['fields']} ${css['button']}`}
              link="link"
              target="_blank"
              href="https://www.lexus.com.br/pt/servicing-and-support/manuais-do-veiculo.html"
            >
              {t('search-button')}
              <IconSearch className={css['icon-search']} />
            </Button>
          }

          { selectedModel == 'lexus' &&
            <div className={css['fields']} />
          }
        </div>

        { selectedModel != 'lexus' &&
          <div className={css['button-container']}>
            <Button
              className={css['button']}
              disabled={!selectedMonth}
              type="submit"
            >
              {t('search-button')}
              <IconSearch className={css['icon-search']} />
            </Button>
          </div>
        }
      </form>

      <CardsList
        cardsData={cards}
        className={css['cards-list']}
        removeCarousel
        isHorizontal
      />
    </Section>

    {children}
  </div>;
}

export default ManualsExplorer;
