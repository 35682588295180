/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';

import Section from 'components/utils/section';
import Title from 'components/atoms/title';

import Viwer360 from 'components/molecules/viwer-360';

import FormSelect from 'components/atoms/form-select';

import Media from 'components/molecules/media';

// loading the sass style fot the component
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';

/**
 * Molecule BuildYourCar
 *
 * Component build your car
 */
function BuildYourCar(props) {
  const t = i18n.useTranslations('components.model-form');

  const {
    className = '',
    children,
    carModelsList = null,
    setSelectedModel = null,
    setCurrentPath = null,
    pageModel = null,
    ...other
  } = props;

  const [selectedModelVersions, setSelectedModelVersions] = useState(undefined);
  const [selectedModelVersion, setSelectedModelVersion] = useState(undefined);

  const [vehicleColors, setVehicleColors] = useState(undefined);
  const [selectedVehicleColor, setSelectedVehicleColor] = useState(undefined);

  const [vehicleMedias, setVehicleMedias] = useState(undefined);

  //In the first render, verify if we already have a model from the parent component
  useEffect(() => {
    if (pageModel) {
      selectModel(undefined, pageModel);
    }
  }, []);

  //After changing models, load the first one as the default model if we do not find any version from the URL query (version)
  useEffect(() => {
    if (selectedModelVersions) {
      //See if the URL have a "version" parameter
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });

      const version = params.version || null;

      const verifyVersion = selectedModelVersions.find((model) => {
        return model?.name === version;
      });

      //If we have a value that´s not in the car models, load the first model from the array
      const currentVersion = verifyVersion || selectedModelVersions[0];

      setSelectedModelVersion(currentVersion);
    }
  }, [selectedModelVersions]);

  //When the user selects the model of the vehicle, we load it´s colors
  useEffect(() => {
    if (selectedModelVersion) {
      setVehicleColors(selectedModelVersion.modelYear.colorWithPrice);
    }
  }, [selectedModelVersion]);

     //When we have the colors and there is no color selected, set the first one as selected
 useEffect(() => {
  if (vehicleColors) {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    const version = params.version || null;

    const colorParams = decodeURIComponent(params.color) || null;

    const verifyColor = selectedModelVersion?.modelYear?.colorWithPrice.find((c) => {
      if(c?.color.name === colorParams && selectedModelVersion.name === version) {
        return c;
      }
    });

    const currentColor = verifyColor || selectedModelVersion?.modelYear?.colorWithPrice[0];
    setSelectedVehicleColor(currentColor);
  }
}, [vehicleColors]);

  //When the user selects the color of the vehicle, we pass to the parent it´s version
  useEffect(() => {
    if (selectedVehicleColor) {
      setSelectedModel({
        ...selectedModelVersion,
        selectedColor: selectedVehicleColor,
        price: selectedVehicleColor.price
      });

      setVehicleMedias({
        desktop: get360Images('desk'),
        mobile: get360Images('mob')
      });
    }
  }, [selectedVehicleColor]);

  function selectModel(evt, pageModel = undefined) {
    const selectedModelId = evt?.target?.value || pageModel?.id;

    const SelectedModelVersions = carModelsList.find((carModel) => carModel?.id === selectedModelId);
    const filteredVersions = SelectedModelVersions?.versions?.filter((version) => version?.status === true);
    const fullpath = evt?.currentTarget?.selectedOptions?.[0]?.dataset?.fullpath || pageModel?.page?.fullpath;

    setCurrentPath(fullpath);
    setSelectedModelVersions(filteredVersions);
  }

  //Change the version of the vehicle and load it´s colors again (we do this because some models have the same color id, thus making it dificult to verify it´s change)
  function updateModelVersion(evt) {
    setSelectedModelVersion(selectedModelVersions.find((carVersion) => carVersion.id === evt.target.value));
    setSelectedVehicleColor(undefined);
  }

  function updateVehicleColor(color) {
    if (color?.color?.id !== selectedVehicleColor?.color?.id) {
      setSelectedVehicleColor(color);
    }
  }

  function get360Images(type) {
    let medias = selectedModelVersion?.modelYear?.medias;

    medias = medias.find((media) => media.tag?.startsWith(`360:${type}:${selectedVehicleColor?.color?.code}`));

    return medias?.media?.url || null;
  }

  return (
    <>
      <Wrapper bgColor={'gray'}>
        <Section hasGrid tag="div" className={`${css['molecule__build-your-car-container']} ${className}`} {...other}>
          {pageModel ? (
            <Title level={1} variant={2} className={css['title']}>
              Monte seu <b>{pageModel?.name}</b> modelo {pageModel?.mainVersion?.modelYear?.year}
            </Title>
          ) : (
            <Title level={1} variant={2} className={css['title']}>
              Monte seu Toyota
            </Title>
          )}

          {vehicleMedias && (
            <div className={css['drag-container']}>
              {selectedModelVersion?.modelYear?.disabled360 ? (
                <Media key={vehicleMedias?.desktop} url={vehicleMedias?.desktop} type="image" />
              ) : (
                <Viwer360
                  key={vehicleMedias?.desktop}
                  spritesheet={vehicleMedias?.desktop}
                  spritesheetMob={vehicleMedias?.mobile}
                />
              )}
            </div>
          )}

          <div className={css['form']}>
            {carModelsList && !pageModel && (
              <FormSelect
                label="Modelo"
                placeholder={t('select-model')}
                onChange={selectModel}
                // value={pageModel?.name || null}
                className={css['form__select-field']}
              >
                {carModelsList?.map((model, index) => {
                  return (
                    <option key={index} data-fullpath={model?.page?.fullpath} value={model?.id}>
                      {model?.name}
                    </option>
                  );
                })}
              </FormSelect>
            )}

            {selectedModelVersions && (
              <FormSelect
                label="Versões"
                value={selectedModelVersion ? selectedModelVersion.id : undefined}
                onChange={updateModelVersion}
                className={css['form__select-field']}
              >
                {selectedModelVersions.length > 0 &&
                  selectedModelVersions.map((version, index) => {
                    return (
                      <option key={index} value={version.id}>
                        {version.name}
                      </option>
                    );
                  })}
              </FormSelect>
            )}

            {selectedModelVersion && vehicleColors?.length > 0 && (
              <>
                <div>
                  <span className={css['color-input-label']}>Escolha a cor</span>
                  <ul className={css['color-list']}>
                    {vehicleColors?.map((color) => {
                      return (
                        <li key={color?.color?.name} className={css['color-list__item']}>
                          <button
                            className={css['color-list__color']}
                            data-is-selected={selectedVehicleColor?.color?.id === color?.color?.id ? true : undefined}
                            style={color?.color?.rgb ? { backgroundColor: color?.color?.rgb } : undefined}
                            onClick={(_) => updateVehicleColor(color)}
                            title={color?.color?.name}
                          >
                            <span data-visuallyhidden>Selecione a cor {color?.color?.name}</span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}
          </div>
        </Section>
      </Wrapper>
    </>
  );
}

export default BuildYourCar;
