import React from 'react';
import i18n from 'utils/i18n';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import Title from 'components/atoms/title';
import Tab from 'components/atoms/tab';
import { slugfy } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';
import Media from 'components/molecules/media';
import Button from 'components/atoms/button';
import AnchorLink from 'components/atoms/anchor-link';
import { currency } from 'utils/functions';

/**
 * Organism ToyotaCyclePanel
 *
 * Toyota Cycle informations for all cars
 */
function ToyotaCyclePanel(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.toyota-cycle-panel');

  const { className = '', children, vehicles, ...other } = props;

  const [selectedVehicle, setSelectedVehicle] = React.useState(vehicles?.[0]);

  const initialVehicle = vehicles?.[0]?.versions.find((version) => version.id == vehicles?.[0]?.mainVersion?.id) || [];
  const [selectedMainVersion, setSelectedMainVersion] = React.useState(initialVehicle);

  function handleClick(vehicleId) {
    const _vehicle = vehicles.find((vehicle) => vehicle.id == vehicleId);
    setSelectedVehicle(_vehicle);
    setSelectedMainVersion(
      _vehicle.versions.find((version) => version.id == _vehicle.mainVersion?.id)
    );
  }

  return (
    <div className={`${css['organism__toyota-cycle-panel-container']} ${className}`} {...other}>
      <Section className={css['section']}>
        <Title className={css['title']}>{t('title')}</Title>
        <Wrapper className={css['subtitle']}>{t('subtitle')}</Wrapper>

        <Wrapper className={css['vehicle-tabs-panel']}>
          {vehicles?.map((vehicle, index) => {
            return (
              <Tab
                key={index}
                id={vehicle?.id}
                onClick={handleClick}
                selected={selectedVehicle?.id === vehicle?.id}
                className={css['vehicle-tab']}>
                {vehicle?.name}
              </Tab>
            );
          })}
        </Wrapper>

        <Wrapper className={css['vehicle-main-version']}>
          <Title className={css['vehicle-main-version-title']}>
            {t('main-version-title')}
            <strong>{` ${selectedVehicle?.name || ''} ${selectedMainVersion?.name || ''}`}</strong>
          </Title>

          <Wrapper className={css['vehicle-main-version-panel']}>
            <Media
              className={css['vehicle-main-version-logo']}
              {...selectedMainVersion?.modelYear?.colorWithPrice?.logo}></Media>

            <Wrapper className={css['vehicle-main-version-data']}>
              <Title className={css['vehicle-main-version-installments']}>
                {`${
                  selectedMainVersion?.modelYear?.colorWithPrice?.btbData?.installments || 'N'
                } ${t('installments')} `}
                <strong>
                  {!selectedMainVersion?.hidePrice &&
                    currency(
                      selectedMainVersion?.modelYear?.colorWithPrice?.btbData?.installment?.value
                    )}
                </strong>
              </Title>

              <ul className={css['vehicle-main-version-payment']}>
                <li>
                  {t('entrance')}
                  <strong>
                    {!selectedMainVersion?.hidePrice &&
                      currency(
                        selectedMainVersion?.modelYear?.colorWithPrice?.btbData?.downPayment
                      )}
                  </strong>
                </li>
                <li>
                  {t('residual')}
                  <strong>
                    {!selectedMainVersion?.hidePrice &&
                      currency(selectedMainVersion?.modelYear?.colorWithPrice?.btbData?.residual)}
                  </strong>
                  <small>{t('residual-used')}</small>
                </li>
              </ul>

              <Wrapper className={css['vehicle-main-version-actions']}>
                <Wrapper>
                  <Button
                    link={`/modelos/${slugfy(
                      `${selectedMainVersion?.vehicle?.name}`
                    )}/monte/?versao=${selectedMainVersion?.name}&state=simule`}>
                    {t('simulate')}
                  </Button>
                  <Wrapper className={css['vehicle-main-version-cash']}>
                    {t('cash')}
                    <strong>
                      {!selectedMainVersion?.hidePrice &&
                        currency(selectedMainVersion?.modelYear?.colorWithPrice?.btbData?.payment)}
                    </strong>
                    <small>{t('price-base')}</small>
                  </Wrapper>
                </Wrapper>

                <Wrapper className={css['vehicle-main-version-links']}>
                  <AnchorLink
                    size={'small'}
                    linkLabel={t('legal')}
                    className={css['vehicle-main-version-links-legal']}
                    link="#legal"
                  />
                  <AnchorLink
                    size={'small'}
                    linkLabel={t('repurchase-manual')}
                    className={css['vehicle-main-version-links-manual']}
                    link="/docs/manual-de-recompra.pdf"
                    isDownload={true}
                    target="_blank"
                  />
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Wrapper className={css['vehicle-versions']} data-grid data-grid-list="3">
          {selectedVehicle?.versions
            ?.filter((version) => version.id !== selectedMainVersion.id)
            ?.filter(version => version.status !== false)
            ?.map((version, index) => {
              return (
              <Wrapper key={index} className={css['vehicle-version-panel']}>
                <img
                  alt="logo-ciclo-toyota"
                  src="/images/logos/logo-ciclo-toyota.png"
                  className={css['vehicle-version-seal']}
                />

                <Media
                  {...version?.modelYear?.colorWithPrice?.logo}
                  className={css['vehicle-version-logo']}></Media>

                <Title className={css['vehicle-version-title']}>
                  {`${selectedVehicle?.name || ''} ${version?.name || ''}`}
                </Title>

                <Wrapper className={css['vehicle-version-data']}>
                  <Wrapper className={css['vehicle-version-prices']}>
                    {!version?.hidePrice &&
                      `${version?.modelYear?.colorWithPrice?.btbData?.installments || '--'} ${t('installments')}`
                    }
                    <strong>
                      {!version?.hidePrice &&
                        currency(version?.modelYear?.colorWithPrice?.btbData?.installment?.value)}
                    </strong>
                  </Wrapper>
                  <Wrapper className={css['vehicle-version-plus']}>+</Wrapper>
                  <ul className={css['vehicle-version-prices']}>
                    <li>
                      {t('entrance')}
                      <strong>
                        {!version?.hidePrice &&
                          currency(version?.modelYear?.colorWithPrice?.btbData?.downPayment)}
                      </strong>
                    </li>
                    <li>
                      {t('residual')}
                      <strong>
                        {!version?.hidePrice &&
                          currency(version?.modelYear?.colorWithPrice?.btbData?.residual)}
                      </strong>
                      <small>{t('residual-used')}</small>
                    </li>
                  </ul>
                </Wrapper>

                <Wrapper className={css['vehicle-version-cash']}>
                  {t('cash')}
                  <strong>{currency(version?.modelYear?.colorWithPrice?.btbData?.payment)}</strong>
                  <small>{t('price-base')}</small>
                  <Button
                    link={`/modelos/${slugfy(version?.vehicle?.name)}/monte/?versao=${
                      version?.name
                    }&state=simule`}>
                    {t('simulate')}
                  </Button>
                </Wrapper>

                <Wrapper className={css['vehicle-version-links']}>
                  <AnchorLink
                    size={'small'}
                    linkLabel={t('legal')}
                    className={css['vehicle-version-links-legal']}
                    link="#legal"
                  />
                  <AnchorLink
                    size={'small'}
                    linkLabel={t('repurchase-manual')}
                    className={css['vehicle-version-links-manual']}
                    link="/docs/manual-de-recompra.pdf"
                    isDownload={true}
                    target="_blank"
                  />
                </Wrapper>
              </Wrapper>
            );
          })}
        </Wrapper>

        {children}
      </Section>
    </div>
  );
}

export default ToyotaCyclePanel;
