import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';
import Input from '../../atoms/input';
import SubmitSearchButton from '../../atoms/submit-search-button';

/**
 * Molecule ActionBannerSearchForm
 *
 * <!-- TODO: add a description here! -->
 */
function ActionBannerSearchForm(props) {
  const { className = '', onChange, onClick } = props;

  return (
    <div className={`${css['molecule-action-banner-search-form-container']} ${className}`}>
      <label htmlFor="zip-code" data-visuallyhidden>Preencha o CEP da concessionária mais próxima</label>
      <Input id="zip-code" name="zip-code" className={css['input']} onChange={onChange} {...props} />
      <SubmitSearchButton onClick={onClick} />
    </div>
  );
}

export default ActionBannerSearchForm;
