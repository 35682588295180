import React, { useState } from 'react';
import FormSelect from 'components/atoms/form-select';
import Title from 'components/atoms/title';
import Media from '../media';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import { currency } from 'utils/functions';

/**
 * Molecule ServiceOffers
 *
 * <!-- TODO: add a description here! -->
 */
function ServiceOffers(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.service-offers');

  const { className = '', serviceItemCategories = [], locale = 'pt-BR', ...other } = props;

  const [serviceItemCategory, setServiceItemCategory] = useState({});

  const currencySymbol = locale === 'pt-BR' ? 'BRL' : locale === 'es-AR' ? 'ARS' : 'USD';

  return (
    <div className={`${css['molecule__service-offers-container']} ${className}`} {...other}>
      <div className={css['header']}>
        <form>
          <FormSelect
            id="car"
            className={css['header__select']}
            label={t('subtitle')}
            placeholder={t('placeholder-select')}
            onChange={(e) => setServiceItemCategory(serviceItemCategories?.[e?.target?.value])}
          >
            {serviceItemCategories?.map((item, index) => {
              return (
                <option key={item?.id} value={index}>
                  {item?.title}
                </option>
              );
            })}
          </FormSelect>
        </form>
      </div>
      <div className={css['wrapper']}>
        <div className={css['wrapper__content']}>
          {serviceItemCategory?.media?.url && (
            <Media
              url={serviceItemCategory?.media?.url}
              type="img"
              alt={serviceItemCategory?.media?.title}
              className={css['wrapper__image']}
            />
          )}
          <Title className={css['wrapper__title']} level={3} variant={3}>
            {serviceItemCategory?.subtitle}
          </Title>

          <ul className={css['content']}>
            {serviceItemCategory?.serviceItems?.length > 0 &&
              serviceItemCategory?.serviceItems?.map((item) => {
                return (
                  <li key={item?.id} className={css['card']}>
                    <strong className={css['card__title']}>{item?.title}</strong>
                    <p className={css['card__subtitle']}>{item?.subtitle}</p>
                    <p className={css['card__text']}>
                      {item?.price?.installment?.quantity} {t('item-text-one')}{' '}
                      {currency(item?.price?.installment?.value, locale, currencySymbol)}
                    </p>
                    <p className={css['card__text']}>
                      {t('item-text-two')} {currency(item?.price?.value, locale, currencySymbol)}
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className={css['footer']}>
          <div className={css['footer__highlight']}>{t('button-label')}</div>
        </div>
      </div>
    </div>
  );
}

export default ServiceOffers;
