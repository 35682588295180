/* eslint-disable no-misleading-character-class */
/* eslint-disable no-prototype-builtins */
export function fn(event) {
  // it does nothing
  event.stopPropagation();
  event.preventDefault();
  return false;
}

// use it like `await sleep(3000);`
export async function sleep(ms) {
  return await new Promise((resolve) => setTimeout(resolve, ms));
}

export function removeDiacritics(word, wordSeparator = ' ') {
  return word
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, wordSeparator);
}

export function camelize(str, upper = false) {
  // eslint-disable-next-line
  return str
    .replace(/(?:^\w|[A-Z\u0300-\u036f]|\b\w)/g, function (word, index) {
      return index === 0 && !upper ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export function slugfy(val) {
  return removeDiacritics(val, '-')
    .replace(/[^a-z0-9-]/gi, '')
    .replace(/-$/, '')
    .replace(/--/g, '-')
    .toLowerCase();
}

export function currency(value, lang = 'pt-BR', currency = 'BRL') {
  const originalLang = lang;
  if (lang === 'es-AR' || lang === 'es-VE') {
    lang = 'en';
  }
  if (currency === 'ARS') {
    currency = 'USD';
  }
  const formatedValue = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency
  }).format(value);

  let finalValue =
    Number(value) === 0
      ? String(formatedValue).replace(/0([.,])00/, '--$1--')
      : String(formatedValue).replace('NaN', '--,--');

  if (originalLang === 'es-AR' || (originalLang === 'es-VE' && finalValue !== '$--.--')) {
    finalValue = String(finalValue)?.replaceAll(',', '.').slice(0, -3);
  }

  return finalValue;
}

/**
 * Deals with the Google Maps API to treat the returned address object
 * and normalize it.
 *
 * @param {GMapsObject} gmapAddr The address object from GMaps
 * @returns Object
 */
export function parseGMapsAddress(gmapAddr) {
  const addr = {
    fullAddress: gmapAddr.formatted_address,
    geometry: gmapAddr.geometry
  };

  gmapAddr.address_components.forEach((comp) => {
    if (comp.types.includes('postal_code')) {
      addr.postal_code = comp.short_name;
      addr.cep = comp.short_name; // for retrocompatibility
      return;
    }
    if (comp.types.includes('route')) {
      addr.street = comp.short_name;
      addr.address = comp.short_name; // for retrocompatibility
      return;
    }
    if (comp.types.includes('sublocality_level_1')) {
      addr.neighborhood = comp.short_name;
      return;
    }
    if (comp.types.includes('administrative_area_level_2')) {
      addr.city = comp.short_name;
      return;
    }
    if (comp.types.includes('administrative_area_level_1')) {
      addr.uf = comp.short_name;
      addr.state = comp.long_name;
      return;
    }
    if (comp.types.includes('country')) {
      addr.countryId = comp.short_name;
      addr.country = comp.long_name;
      return;
    }
  });

  return addr;
}

let isIOS = -1;
export function iOS() {
  // isIOS will cache it
  if (isIOS === -1) {
    isIOS =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }
  return isIOS;
}

export function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth * ratio, height: srcHeight * ratio };
}

/**
 * Gera data e hora atuais.
 *
 * @returns {object} currentDate: string no formato "DD/MM/YYYY"
 *                   currentTime: string no formato "HH:MM:SS GMT-0300 (Brasilia Standard Time)"
 */
export function getDateAndTime() {
  const dateTime = new Date();
  const curDate = Intl.DateTimeFormat('pt-BR').format(dateTime);
  const curTime = dateTime.toTimeString();
  return {
    currentDate: curDate,
    currentTime: curTime
  };
}

/**
 * Converte, se necessário, data de ISO para BR
 *
 * @param {string} inputDate Data de entrada no formato YYYY-MM-DD
 * @returns Data de saída no formato DD/MM/YYYY
 */
export function dateIso2Br(inputDate) {
  let converted = String(inputDate).trim();
  if (converted.length > 0 && converted.indexOf('-') >= 0) {
    converted = converted.match(/\d+/g);
    const [
      year,
      month,
      day
    ] = converted;
    converted = `${day}/${month}/${year}`;
  }
  return converted;
}

/**
 * Converte, se necessário, data de BR para ISO
 *
 * @param {string} inputDate Data de entrada no formato DD/MM/YYYY
 * @returns Data de saída no formato YYYY-MM-DD
 */
export function dateBr2Iso(inputDate) {
  let converted = String(inputDate).trim();
  if (converted.length > 0 && converted.indexOf('/') >= 0) {
    converted = converted.match(/\d+/g);
    const [
      day,
      month,
      year
    ] = converted;
    converted = `${year}-${month}-${day}`;
  }
  return converted;
}

/**
 * Formats a date-time string into either date or time.
 *
 * @param {string} dateTimeStr - String containing the date and time in the format 'YYYY-MM-DD HH:mm:ss'.
 * @param {string} type - Desired format type: 'date' to return the date (DD/MM/YYYY) or 'hour' to return the time (HH:mm).
 * @returns {string|null} - The formatted date (DD/MM/YYYY) or time (HH:mm) based on the given type, or null if the type is invalid.
 */
export function extractDateOrTime(dateTimeStr, type) {
  const [date, time] = dateTimeStr.split(' '); // Split the string into date and time
  const formattedDate = date.split('-').reverse().join('/'); // Format the date as DD/MM/YYYY

  return type === 'date' ? formattedDate : type === 'hour' ? time.slice(0, 5) : null; // Return date or time, or null if type is invalid
}

/**
 * Cópia em profundidade de um objeto em outro, ignorando campos
 * indefinidos do segundo.
 *
 * @param {object} obj1 Primeiro objeto, que deve receber os valores
 * @param {object} obj2 Segundo objeto, que deve fornecer valores novos
 * para uma ou mais propriedades
 *
 * @returns O primeiro objeto atualizado
 */
export function deepCopy(obj1, obj2) {
  for (const field in obj1) {
    const field1Type = typeof obj1[field];
    const field2Type = typeof obj2[field];
    const sameType = field1Type === field2Type;

    if (field2Type === 'undefined') {
      continue;
    }

    // Se ambos são mesmo tipos e objeto, entra na recursão
    if (sameType && field2Type === 'undefined') {
      return deepCopy(obj1[field], obj2[field]);
    }

    obj1[field] = obj2[field];
  }

  return obj1;
}

export function popupCenterScreen(url, title, w, h, focus) {
  w = w || screen.availWidth;
  h = h || screen.availHeight;
  const top = (screen.height - h) / 4,
    left = (screen.width - w) / 2;
  const popup = window.open(
    url,
    title,
    // eslint-disable-next-line max-len
    `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,width=${w},height=${h},top=${top},left=${left}`
  );
  if (focus === true && window.focus) {
    popup.focus();
  }
  return popup;
}

export function debounce(fn, wait = 300) {
  let to = null;

  return (...args) => {
    clearTimeout(to);
    to = setTimeout((_) => fn(...args), wait);
  };
}

// Returns a function, that, when invoked, will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
export function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) {
    options = {};
  }
  var later = function () {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) {
      context = args = null;
    }
  };

  return function () {
    var now = Date.now();
    if (!previous && options.leading === false) {
      previous = now;
    }
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

export function cropString(str, limit, useEllipes = true) {
  if (str.length <= limit) {
    return str;
  }
  return str.substring(0, limit) + '…';
}

export function createScript(src, id) {
  return new Promise((resolve, reject) => {
    if (!document.querySelector('script#' + id)) {
      const script = document.createElement('script');
      script.src = src;
      script.onload = function () {
        resolve();
      };
      script.onError = function (event) {
        reject(event);
      };
      document.head.appendChild(script);
    } else {
      resolve();
    }
  });
}

export function getMonthName(data, lang = 'pt-BR', long = true) {
  return new Date(data).toLocaleString(lang, { month: long ? 'long' : 'short' });
}

export const downloadPDF = (pdfBase64) => {
  const urlFile = `data:application/pdf;base64,${pdfBase64}`;
  const link = document.createElement('a');
  const fileName = 'Arquivo.pdf';

  link.href = urlFile;
  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = (urlFile, filename) => {
  var link = document.createElement('a');
  link.href = urlFile;
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function getAge(dateString) {
  const today = new Date();
  const birthDate = new Date(dateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

// Author https://github.com/manishsaraan/email-validator
// eslint-disable-next-line max-len
const tester =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
export function validateEmail(email) {
  if (!email) {
    return false;
  }

  const emailParts = email.split('@');

  if (emailParts.length !== 2) {
    return false;
  }

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) {
    return false;
  } else if (address.length > 255) {
    return false;
  }

  const domainParts = address.split('.');
  if (
    domainParts.some(function (part) {
      return part.length > 63;
    })
  ) {
    return false;
  }

  if (!tester.test(email)) {
    return false;
  }

  return true;
}

export function isValidCPF(cpf) {
  if (typeof cpf !== 'string') {
    return false;
  }
  cpf = cpf.replace(/[\s.-]*/gim, '');
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) {
    resto = 0;
  }
  if (resto != parseInt(cpf.substring(9, 10))) {
    return false;
  }
  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) {
    resto = 0;
  }
  if (resto != parseInt(cpf.substring(10, 11))) {
    return false;
  }
  return true;
}

export function monthDiff(DataInicio) {
  const [currentYear, currentMonth] = new Date().toISOString().match(/\d+/g);
  const [startYear, startMonth] = DataInicio.match(/\d+/g); // "YYYY-MM-DD"
  const dateFrom = new Date(startYear, startMonth);
  const dateTo = new Date(currentYear, currentMonth);
  return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
}

export const openShare = (title, text, share) => {
  if (navigator) {
    require('share-api-polyfill');
    navigator.share(
      {
        title,
        text,
        url: share
      },
      {
        print: false,
        facebook: false,
        twitter: false,
        linkedin: false,
        pinterest: false,
        language: 'pt'
      }
    );
  }
};

export function getQueryVar(variable = null, url = '') {
  url = url ? new URL(url) : window?.location;
  var query = url.search?.substring(1);
  var vars = query.split('&');
  const params = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (variable && decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
    if (!variable) {
      params[pair[0]] = pair[1];
    }
  }
  return variable ? null : params;
}

export function getQueryVars(url = '') {
  return getQueryVar(null, url);
}

export function empty(value) {
  if (value === '' || value === undefined || value === null) {
    return true;
  }
  return false;
}

export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function secondToHrsMinSec(time) {
  var hours = Math.floor(time / 3600);
  var minutes = Math.floor((time % 3600) / 60);
  var seconds = time % 60;

  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;
  hours = hours < 10 ? '0' + hours : hours;

  return hours + ':' + minutes + ':' + seconds;
}

export function reorderAdress() {
  const fileName = '/docs/municipios.json';

  fetch(fileName).then(async (response) => {
    const data = await response.json();
    const states = {};
    const newData = {};

    data.municipios.map((data) => {
      if (!states.hasOwnProperty(data.provincia.nombre)) {
        states[data.provincia.nombre] = [];
      }

      states[data.provincia.nombre].push(data.nombre);
    });

    Object.keys(states)
      .sort()
      .forEach((key) => {
        newData[key] = states[key].sort();
      });

    console.log(newData);
  });
}

export const imageFilter = (images) => {
  if (!images || !Array.isArray(images) || images.length === 0) {
    return null;
  }
  const filteredImages = images.filter((data) => String(data?.tag) === 'display:regular');
  return filteredImages?.[0] || null;
};

export const urlHttpCheck = (url) => {
  let res = String(url);

  if (!url.startsWith('http')) {
    res = 'https://' + url;
  }

  return res;
};

export const checkAccessoryStatus = (status, status_available, unavailable) => {
  if (status === 'AVAILABLE') {
    return status_available;
  }
  return unavailable;
};

export const extractTimeFromDateBr = (dateString) => {
  const dateObj = new Date(dateString);
  const hours = dateObj.getUTCHours().toString().padStart(2, '0');
  const minutes = dateObj.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export function retry(fn, retries = 3, err = null) {
  if (!retries) {
    return Promise.reject(err);
  }

  return fn()
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return retry(fn, retries - 1, err);
    });
}

/**
 * Converts, if necessary, date from BR (Brazilian) to ISO
 * @param {string} date Input date in the format YYYY-MM-DDTHH:mm:ss.SSSZ
 * @returns Output date in the format YYYY-MM-DD
 */
export function transformDate(date) {
  // Creates a Date object from the provided date.
  const originalDateObj = new Date(date);
  // Extracts the year from the date in UTC format.
  const year = originalDateObj.getUTCFullYear();
  // Extracts the month from the date in UTC format (0-11) and adds 1 to make it conventional (1-12).
  const month = originalDateObj.getUTCMonth() + 1;
  // Extracts the day of the month from the date in UTC format.
  const day = originalDateObj.getUTCDate();
  // Formats these values into a string in the "YYYY-MM-DD" format.
  // The year, month, and day are converted to strings, and any single-digit values are padded with zero on the left (using padStart) to maintain a consistent format.
  const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  // Returns the formatted string as the result.
  return formattedDate;
}

export const getCurrencySymbol = (locale) => {
  switch (locale.toLowerCase()) {
    case 'pt-br':
      return 'BRL';
    case 'es-ar':
      return 'ARS';
    case 'en-us':
    default:
      return 'USD';
  }
};
