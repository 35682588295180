import React, { useState, useEffect, useReducer } from 'react';
import i18n from 'utils/i18n';

import Title from 'components/atoms/title';
import FormSelect from 'components/atoms/form-select';
import Pill from 'components/atoms/pill';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import { IconClose } from 'components/icons';

import { useForm } from 'react-hook-form';
import MapInformation from 'components/molecules/map-information';
import { updateURLParameter } from 'utils/forms';

// loading the sass style fot the component
import css from './styles.module.scss';

// import { fetchJson } from 'utils/http';

/**
 * Organism FindDealers
 *
 * <!-- TODO: add a description here! -->
 */
function FindDealers(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.find-dealers');

  const {
    className = '',
    dealers = [],
    listStates,
    googleKey,
    children,
    spacingTop = 6,
    spacingBottom = 6,
    locale,
    title,
    bgColor = 'gray',
    ...other
  } = props;

  const { register, setValue } = useForm({
    defaultValues: {
      state: 'placeholder',
      city: 'placeholder'
    }
  });

  const isTDB = locale?.toLowerCase() === 'pt-br';
  const isTDV = locale?.toLowerCase() === 'es-ve';

  const [selectedServicesList, setSelectedServicesList] = useState([]);

  const [servicesList, setServicesList] = useState([]);

  const [selectedState, setSelectedState] = useState('');

  const [cityFromUrl, setCityFromUrl] = useState('');

  const [markersByCity, setMarkersByCity] = useState([]);

  // * THIS CODE IS COMMENTED ON BECAUSE THE CEP AND GEOLOCATION SEARCHES ARE NOT READY YET
  // const [activeSearchCep, setActiveSearchCep] = useState(false);

  const [markers, setMarkers] = useState([]);

  const [mapZoom, setMapZoom] = useState(1);

  // const [locationFromBrasilApi, setLocationFromBrasilApi] = useState('');

  const [citiesFromStates, updateCitiesFromStates] = useReducer(
    (prev, next) => {
      const newEvent = { ...prev, ...next };

      // Ensure that the city is selected just if we have the cities list
      if (prev.citiesList.length > 0) {
        newEvent.city = next.city;
      }
      return newEvent;
    },
    { city: '', citiesList: [] }
  );

  useEffect((_) => {
    const url = new URL(location.href);
    const params = url.searchParams;

    const state = params.get('state')?.toUpperCase();
    const city = params.get('city')?.toUpperCase();

    if (state || city) {
      setStateAndCityByURLParams(state, city);
    } else {
      setMarkers(dealers);
    }
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(
    (_) => {
      setValue('city', cityFromUrl);
    },
    [cityFromUrl]
  );

  useEffect(() => {
    loadCitiesList();

    updateCitiesFromStates({ city: null });

    setServicesList([]);
    setSelectedServicesList([]);
    setValue('city', 'placeholder');
  }, [selectedState]);

  useEffect(() => {
    loadServicesList();
  }, [citiesFromStates.city]);

  useEffect(() => {
    if (cityFromUrl) {
      setValue('city', cityFromUrl);
      updateCitiesFromStates({ city: cityFromUrl });
    }
  }, [citiesFromStates.citiesList]);

  useEffect(() => {
    if (selectedServicesList.length === 0) {
      setMapZoom(7);
    }
  }, [markers]);

  useEffect(() => {
    let markersFilteredByService = [];
    if (selectedServicesList.length > 0) {
      markersByCity?.forEach((marker) => {
        marker.services.forEach((service) => {
          if (selectedServicesList.includes(service.title)) {
            markersFilteredByService.push(marker);
          }
        });
      });

      setMarkers(markersFilteredByService);
    }
  }, [selectedServicesList]);

  /**
   * Set the city and the state based on the url parameters.
   *
   */
  function setStateAndCityByURLParams(state, city) {
    if (state) {
      setValue('state', state);
      setSelectedState(state);

      if (city) {
        setCityFromUrl(city);
      }
    }
  }

  /**
   * Set up the cities list based on the current state..
   *
   */
  function loadCitiesList() {
    const dealersFromState = dealers?.filter(
      (dealer) => dealer?.address?.state.toUpperCase() === selectedState?.toUpperCase()
    );

    if (selectedState) {
      setMarkers(dealersFromState);
    }

    const citiesList = [...new Set(dealersFromState.map((dealer) => dealer.address.city.toUpperCase()))].sort();

    updateCitiesFromStates({ citiesList: citiesList });
  }

  /**
   * Set up the services list based on the current city and the current state..
   *
   */
  function loadServicesList() {
    const servicesList = [];
    const markersFilteredBycity = [];

    dealers?.forEach((dealer) => {
      if (
        dealer.address.state.toUpperCase() === selectedState &&
        dealer.address.city.toUpperCase() === citiesFromStates.city?.toUpperCase()
      ) {
        markersFilteredBycity.push(dealer);

        const services = dealer?.services;

        services.forEach((service) => {
          servicesList.push(service?.title);
        });
      }
    });

    setServicesList([...new Set(servicesList)].sort());

    if (citiesFromStates.city) {
      setMarkers(markersFilteredBycity);
      setMarkersByCity(markersFilteredBycity);
    }
  }

  /**
   * Deals with the services remove part.
   *
   */
  function removeServicesFilter(index) {
    if (selectedServicesList.length > 1) {
      setSelectedServicesList(
        selectedServicesList.filter((service) => {
          return service != index;
        })
      );
      loadServicesList();
    } else {
      clearServicesFilters();
    }
  }

  /**
   * Clear all the services from the filters..
   *
   */
  function clearServicesFilters() {
    setSelectedServicesList([]);
    setMarkers(markersByCity);
  }

  /**
   * Deals with the inputs change, this function changes from
   * state and city select to cep or actual location search.
   *
   */
  // function handleActiveCepSearch() {
  //   setActiveSearchCep(!activeSearchCep);
  //   clearServicesFilters();
  // }

  /**
   * Deals with the geolocation search based on the user cep through the
   * brasil api.
   *
   * @param {object} fields The form fields
   * @param {object} event The component event
   */
  // function onSubmit(fields, event) {
  //   event.preventDefault();

  //   const url = `https://brasilapi.com.br/api/cep/v2/${fields.cep}`;

  //   fetchJson(url)
  //     .then((result) => {
  //       setLocationFromBrasilApi({
  //         lat: result.location.coordinates.latitude,
  //         lng: result.location.coordinates.longitude
  //       });
  //     })
  //     .then((_) => {
  //       console.log(locationFromBrasilApi);
  //     });
  // }

  /**
   * Deals with the user current location.
   *
   */
  // function getPosition() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((r) => console.log(r));
  //   }
  // }

  /**
   * Deals with the state change.
   *
   */
  function onChangeState(event) {
    setSelectedState(event.target.value);
    updateURLParameter('state', event.target.value);
    updateURLParameter('city', '');
    setValue('city', 'placeholder');
  }

  /**
   * Deals with the city change.
   *
   */
  function onChangeCity(event) {
    updateCitiesFromStates({ city: event.target.value });
    updateURLParameter('city', event.target.value);
  }

  /**
   * Deals with the services change.
   *
   */
  function onChangeServices(event) {
    setSelectedServicesList([...selectedServicesList, event.target.value]);
  }

  return (
    <>
      <Wrapper bgColor={bgColor} spacingTop={spacingTop} spacingBottom={spacingBottom} {...other}>
        <Section>
          <div className={`${css['organism__find-dealers-container']} ${className}`}>
            <Title level={1} variant={2}>
              {title?.trim().length > 0 ? title : t('title_find_dealership')}
            </Title>
            <div className={css['filter-form']}>
              {/*

                * THIS CODE IS COMMENTED ON BECAUSE THE CEP AND GEOLOCATION SEARCHES ARE NOT READY YET

                {!activeSearchCep ? (
                <>
                  <FormSelect
                    displayLabel={false}
                    displayError={false}
                    id="selectState"
                    placeholder={t('placeholder_state')}
                    className={css['select-input']}
                    dataRegister={register('state')}
                    onChange={onChangeState}
                  >
                    {listStates?.length > 0 ? (
                      listStates?.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })
                    ) : (
                      <option>{t('placeholder_state')}</option>
                    )}
                  </FormSelect>
                  <FormSelect
                    displayLabel={false}
                    displayError={false}
                    id="selectCity"
                    placeholder={t('placeholder_city')}
                    className={css['select-input']}
                    dataRegister={register('city')}
                    onChange={onChangeCity}
                  >
                    {citiesFromStates.citiesList.length > 0 ? (
                      citiesFromStates.citiesList?.map((city, index) => {
                        return (
                          <option key={index} value={city}>
                            {city}
                          </option>
                        );
                      })
                    ) : (
                      <option>{t('placeholder_city')}</option>
                    )}
                  </FormSelect>
                </>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)} className={css['filter-cep']}>
                  <FormInput
                    className={`${css['form-input-form']}`}
                    id="cep"
                    label="CEP"
                    dataRegister={register('cep')}
                    type="text"
                    maxLength="9"
                    placeholder="00000-00"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = cepMask(value);
                    }}
                  />
                  {markers.length <= 0 && (
                    <span className={`${css['error-message']}`}>
                      Nenhuma concessionária foi encontrada para este cep. Digite novamente.
                    </span>
                  )}

                  <button type="submit" className={css['button-search-icon']}>
                    <ButtonSearchIcon />
                  </button>
                </form>
              )} */}

              <FormSelect
                displayLabel={false}
                displayError={false}
                id="selectState"
                placeholder={t('placeholder_state')}
                className={css['select-input']}
                dataRegister={register('state')}
                onChange={onChangeState}
              >
                {listStates?.length > 0 ? (
                  listStates?.map((state, index) => {
                    return (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    );
                  })
                ) : (
                  <option>{t('placeholder_state')}</option>
                )}
              </FormSelect>
              <FormSelect
                displayLabel={false}
                displayError={false}
                id="selectCity"
                placeholder={t('placeholder_city')}
                className={css['select-input']}
                dataRegister={register('city')}
                onChange={onChangeCity}
              >
                {citiesFromStates.citiesList.length > 0 ? (
                  citiesFromStates.citiesList?.map((city, index) => {
                    return (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    );
                  })
                ) : (
                  <option>{t('placeholder_city')}</option>
                )}
              </FormSelect>

              {(isTDB || isTDV) && (
                <FormSelect
                  displayLabel={false}
                  displayError={false}
                  id="selectService"
                  className={css['select-input']}
                  onChange={onChangeServices}
                >
                  <option key={-1} value={''}>
                    {t('select_a_service')}
                  </option>
                  {servicesList.length > 0 &&
                    servicesList?.map((option, key) => {
                      if (selectedServicesList.includes(option)) {
                        return null;
                      }
                      return (
                        <option key={key} value={option}>
                          {option}
                        </option>
                      );
                    })}
                </FormSelect>
              )}
            </div>

            {/*

              * THIS CODE IS COMMENTED ON BECAUSE THE CEP AND GEOLOCATION SEARCHES ARE NOT READY YET

              <div>
                {isTDB ? (
                  <button className={css['button-link-style']} onClick={() => handleActiveCepSearch()}>
                    {!activeSearchCep ? t('button_search_cep') : t('button_search_city_state')}
                  </button>
                ) : (
                  <button className={css['button-link-style']} onClick={() => getPosition()}>
                    Usar mi ubicación actual
                  </button>
                )}
              </div>
            */}

            <div className={selectedServicesList.length > 0 ? css['active-services'] : css['hidden']}>
              <span className={css['active-services__label']}>{t('service_title')}</span>
              <div className={css['pill-container']}>
                {selectedServicesList?.map((item, index) => {
                  return <Pill key={index} hasCloseIcon onClick={() => removeServicesFilter(item)} label={item} />;
                })}
              </div>
              <button onClick={clearServicesFilters} className={css['clear-filters-button']}>
                {t('link_clear_filter')}
                <IconClose className={css['clear-filters-button__icon']} />
              </button>
            </div>
          </div>
        </Section>
      </Wrapper>
      <MapInformation
        selectedCity={citiesFromStates.city}
        selectedState={selectedState}
        selectedZoom={mapZoom}
        markers={markers}
        enableScheduleService={isTDB || isTDV}
        locale={locale}
      />
    </>
  );
}

export default FindDealers;
