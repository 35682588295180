import React, { useState } from 'react';
import i18n from 'utils/i18n';

import Section from 'components/utils/section';
import SectionHeader from 'components/molecules/section-header';
import Wrapper from 'components/atoms/wrapper';
import Title from 'components/atoms/title';
import FormSelect from 'components/atoms/form-select';
import InterestForm from 'components/molecules/interest-form';
import TASAInterestForm from 'components/molecules/tasa-interest-form';

// loading the sass style fot the component
import css from './styles.module.scss';
import KintoInterestForm from 'components/molecules/kinto-interest-form';

/**
 * Organism InterestFormManager
 *
 * Interest form manager
 */
function InterestFormManager(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.interest-form-manager');

  const {
    className = '',
    models = [],
    dealers = [],
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    title,
    description,
    headerSpacing,
    attractingMedia,
    locale,
    comments = '',

    // settings of ContentBlock not need here, just to drop from 'other'
    allowedModels,

    ...other
  } = props;

  const [selectedContact, setSelectedContact] = useState('Concesionarios Oficiales');

  const [isStatusData, setIsStatusData] = useState('default');

  const contacts = [
    'Concesionarios Oficiales',
    'Toyota Argentina',
    'KINTO'
  ];

  const country = `${locale}`.slice(-2);

  function onChangeContact(e) {
    const selected = e.currentTarget.value;
    setSelectedContact(selected);
  }

  const checkFormStatus = (formStatusData) => {
    setIsStatusData(formStatusData);
  };

  return (
    <>
      <div className={`${css['organism__interest-form-manager-container']} ${className}`} {...other}>
        {isStatusData === 'default' && (
          <Wrapper spacing={headerSpacing}>
            <Section>
              <SectionHeader
                title={title || t('title_form')}
                description={{
                  value: description ? description : t('description_form'),
                  textColor: 'gray-4'
                }}
              />
            </Section>
          </Wrapper>
        )}

        {country === 'ar' && isStatusData === 'default' && (
          <Section tag="form">
            <div className={css['form-container__section']} tag="div" hasGrid={true}>
              <Title className={css['form-container__title']} variant={5}>
                {t('select_contact')}
              </Title>

              <FormSelect
                className={css['form-container__input']}
                id="contact"
                placeholder={t('placeholder_contact')}
                onChange={onChangeContact}
              >
                {contacts &&
                  contacts.length > 0 &&
                  contacts?.map((contact, index) => {
                    return (
                      <option key={index} value={contact}>
                        {contact}
                      </option>
                    );
                  })}
              </FormSelect>
            </div>
          </Section>
        )}

        {selectedContact == contacts[0] && (
          <InterestForm
            models={models}
            dealers={dealers}
            titleSuccess={titleSuccess}
            contentSuccess={contentSuccess}
            titleError={titleError}
            contentError={contentError}
            attractingMedia={attractingMedia}
            locale={locale}
            comments={comments}
            contacted={selectedContact}
            allowedModels={allowedModels}
            checkFormStatus={checkFormStatus}
            {...other}
          />
        )}
        {selectedContact == contacts[1] && (
          <TASAInterestForm
            locale={locale}
            dealers={dealers}
            comments={comments}
            titleSuccess={titleSuccess}
            contentSuccess={contentSuccess}
            titleError={titleError}
            contacted={selectedContact}
            contentError={contentError}
            checkFormStatus={checkFormStatus}
          />
        )}
        {selectedContact == contacts[2] && (
          <KintoInterestForm
            locale={locale}
            comments={comments}
            titleSuccess={titleSuccess}
            contentSuccess={contentSuccess}
            titleError={titleError}
            contacted={selectedContact}
            contentError={contentError}
            checkFormStatus={checkFormStatus}
          />
        )}
      </div>
    </>
  );
}

export default InterestFormManager;
