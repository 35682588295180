import Dealer from 'utils/repo/dealers';
import Recall from 'utils/repo/recall';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class RecallForm implements ContentBlock {
  label = 'Recall Form';

  component = 'RecallForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const productId = extra?.context?.productId;

    if (!productId) {
      throw new Error(`There is no productId defined.`);
    }

    return {
      recalls: (await dataFetcher(Recall.getAllRecalls(), { locale: extra?.context?.locale }))?.data?.recalls || [],
      dealers: (await dataFetcher(Dealer.getAllDealers(), { productId: productId }))?.data?.dealers || [],
      locale: extra?.context?.locale || 'pt-BR'
    };
  }
}

export { RecallForm };
