/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';

import FormSelect from 'components/atoms/form-select';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ModelForm
 *
 * <!-- TODO: add a description here! -->
 */
function ModelForm(props) {
  const t = i18n.useTranslations('components.model-form');

  const {
    className = '',
    carModelsList = null,
    setSelectedOptionId = null,
    onVersionSelect = () => undefined,
    locale,
    ...other
  } = props;

  const [selectedModelVersions, setSelectedModelVersions] = useState(undefined);
  const [selectedModelVersion, setSelectedModelVersion] = useState(undefined);
  const [selectedModelId, setSelectedModelId] = useState(undefined);
  const [currentSelectedVersionId, setCurrentSelectedVersionId] = useState('all');
  const isTdb = locale?.toLowerCase() === 'pt-br';

  // Updates the selected value in the parent component when choosing version filter options.
  useEffect(() => {
    onVersionSelect(currentSelectedVersionId);
  }, [currentSelectedVersionId, selectedModelVersions]);

  //After changing models, load the first one as the default model
  useEffect(() => {
    if (selectedModelVersions) {
      const modelVersion = selectedModelVersions[0];
      const data = {
        ...modelVersion
      };
      setSelectedModelVersion(data);
    }
  }, [selectedModelVersions]);

  useEffect(() => {
    if (selectedModelVersion) {
      if (selectedModelVersion.id === 'all') {
        setSelectedOptionId(selectedModelId);
      } else {
        setSelectedOptionId(selectedModelVersion?.id);
      }
    }
  }, [selectedModelVersion, selectedModelId]);

  function selectModel(evt) {
    const selectedModelId = evt.target.value;
    setCurrentSelectedVersionId('all');
    setSelectedModelId(selectedModelId);
    const SelectedModelVersions = carModelsList.find((carModel) => carModel.id === selectedModelId);
    const allVersions = [{ id: 'all', name: 'Todos' }, ...SelectedModelVersions.versions];
    setSelectedModelVersions(allVersions);
    setCurrentSelectedVersionId('all');
  }

  function updateModelVersion(evt) {
    const selectedVersionId = evt.target.value;
    if (selectedVersionId === 'all') {
      setCurrentSelectedVersionId('all');
      setSelectedModelVersion({
        id: 'all',
        name: 'Todos'
      });
    } else {
      const updatedModelVersion = selectedModelVersions.find((carVersion) => carVersion.id === selectedVersionId);
      const data = {
        ...updatedModelVersion,
      };
      setSelectedModelVersion(data);
      setCurrentSelectedVersionId(selectedVersionId);
    }
  }

  return (
    <div className={`${css['molecule__model-form-container']} ${className}`} {...other}>
      <div className={css['form-container']}>
        {carModelsList && (
          <FormSelect className={css['form__select-model']} placeholder={t('select-model')} onChange={selectModel}>
            {carModelsList?.map((model, index) => {
              return (
                <option key={index} value={model.id}>
                  {model.name}
                </option>
              );
            })}
          </FormSelect>
        )}
        {selectedModelVersions && isTdb && (
          <FormSelect
            className={css['form__select-version']}
            onChange={updateModelVersion}
            value={currentSelectedVersionId}
          >
            {selectedModelVersions.length > 0 &&
              selectedModelVersions.map((version, index) => {
                return (
                  <option key={index} value={version.id}>
                    {version.name}
                  </option>
                );
              })}
          </FormSelect>
        )}
      </div>
    </div>
  );
}

export default ModelForm;
