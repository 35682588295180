import React from 'react';

import { useReactToPrint } from 'react-to-print';

import Wrapper from 'components/atoms/wrapper';
import Button from 'components/atoms/button';

import Title from 'components/atoms/title';

import Disclaimer from 'components/molecules/disclaimer';

import Section from 'components/utils/section';

import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';


/**
 * Molecule OfferConditionsCard
 *
 * Simple card showing offer conditions
 */
function OfferConditionsCard(props) {
  const t = i18n.useTranslations('components.offer-conditions-card');

  const {
    className = "",
    conditions,
    showPrintButton = false,
    showHeader = false,
    ...other
  } = props;

  const conditionsContainer = React.useRef();

  const triggerPrinter = useReactToPrint({
    content: printContent,
    documentTitle: t('print-title')
  });

  const printContent = React.useCallback(() => {
    return conditionsContainer.current;
  }, [conditionsContainer.current]);

  return <Wrapper
    className={`${css["offer-conditions-card-container"]} ${className}`}
    // ref={conditionsContainer}
    {...other}
  >
    {showHeader &&
      <Section>
        <div data-grid={true}>
          <div className={css['offer-conditions-header']} data-grid-full-width="true">
            <Title variant={3}>Termos e condições</Title>
            {/* <Button onClick={triggerPrinter} color='light'>
              {t('print')}
            </Button> */}
          </div>
        </div>
      </Section>
    }
    <Disclaimer
      editorContent={conditions}
      spacingTop={0}
      spacingBottom={0}
      fullWidth={true}
    />
    <Section>
      {showPrintButton &&
        <div className={css['offer-conditions-card-footer']}>
          <Button onClick={triggerPrinter} color='light'>
            {t('print')}
          </Button>
        </div>
      }
    </Section>
  </Wrapper >;
}

export default OfferConditionsCard;
