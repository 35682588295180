import React from 'react';
// import i18n from 'utils/i18n';
import ItemAvailabilityList from 'components/molecules/item-availability-list';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ItemCompatibility
 *
 * Item compatibility
 */
function ItemCompatibility(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.item-compatibility');

  const { className = '', listAvailable, acessory, children, ...other } = props;

  return (
    <>
      <div className={`${css['molecule__item-compatibility-container']} ${className}`} {...other}>
        <div className={css['item-compatibility__item']}>
          <img
            alt={acessory?.model?.title || ''}
            src={acessory?.model?.url}
            className={css['item-compatibility__item-image']}
          />
          <span className={css['item-compatibility__item-text']}>{acessory?.model?.name}</span>
        </div>
        <ItemAvailabilityList listAvailable={listAvailable} />
      </div>
    </>
  );
}

export default ItemCompatibility;
