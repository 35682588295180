import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import {
  anchor,
  formTitle,
  formDescription,
  formTitleSuccess,
  formContentSuccess,
  formLinkSuccess,
  formLinkTitleSuccess,
  formTitleError,
  formContentError,
  spacingTopMeta,
  spacingBottomMeta
} from 'utils/meta';

import Dealers from 'utils/repo/dealers';

class DreamCarForm implements ContentBlock {
  label = 'Dream Car Form';

  component = 'DreamCarForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    {
      name: 'selectedContest',
      label: 'Selected Contest (Required)',
      help: 'Selected Contest (Required)',
      type: 'picker',
      src: 'Contests',
      control: 'input:text',
      required: true,
      defaultValue: ''
    },
    {
      name: 'titleExpiredEvent',
      label: 'Title for Expired Event',
      help: 'Title for Expired Event',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentExpiredEvent',
      label: 'Content for Expired Event',
      help: 'Content for Expired Event',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'linkButtonExpiredEvent',
      label: 'Link for Expired Event Button',
      help: 'Relative link to the button in the content displayed when an event is no longer active. This link redirects users to a chosen destination URL.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'labelButtonExpiredEvent',
      label: 'Label for Expired Event Button',
      help: 'Label relative to the button in the content displayed when an event is no longer active.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    formTitle,
    formDescription,
    formTitleSuccess,
    formContentSuccess,
    formLinkTitleSuccess,
    formLinkSuccess,
    formTitleError,
    formContentError,
    spacingTopMeta,
    spacingBottomMeta

  ];

  properties = [
    {
      name: 'title',
      label: 'Delivery title',
      help: 'The title of the delivery',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Delivery description',
      help: 'The description of the delivery',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    }
  ];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const settings = extra?.settings;
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;

    if (!extra?.context?.productId) {
      throw new Error(`There is no productId defined.`);
    }

    const dealersByLocation =
      (await dataFetcher(Dealers.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    return {
      locale: String(locale).toLowerCase(),
      dealersByLocation: dealersByLocation,
      selectedEvent: settings?.selectedContest || null,
      delivery: contents,
      title: settings?.title,
      description: settings?.description,
      linkButtonExpiredEvent: settings?.linkButtonExpiredEvent,
      labelButtonExpiredEvent: settings?.labelButtonExpiredEvent,
      titleExpiredEvent: settings?.titleExpiredEvent,
      contentExpiredEvent: settings?.contentExpiredEvent,
      titleSuccess: settings?.titleSuccess,
      contentSuccess: settings?.contentSuccess,
      linkTitleSuccess: settings?.linkTitleSuccess,
      linkSuccess: settings?.linkSuccess,
      titleError: settings?.titleError,
      contentError: settings?.contentError,
      spacingBottom: settings?.spacingBottom || 0,
      spacingTop: settings?.spacingTop || 0
    };
  }
}

export { DreamCarForm };
