import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom CloseButton
 *
 * Close button component
 */
function CloseButton(props) {
  const { className = '', children, color, ...other } = props;

  return (
    <button className={`${css['atom__close-button-container']} ${className}`} {...other}>
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" data-icon="1" {...props}>
        <path d="M2 14L14 2" stroke={color} strokeWidth="1" />
        <path d="M14 14L2 2" stroke={color} strokeWidth="1" />
      </svg>
    </button>
  );
}

export default CloseButton;
