/* eslint-disable no-unused-vars */
import { captchaToken } from 'utils/captcha/token';
import { phoneMask, cpfMask, isValidCPF  } from 'utils/validations';
import { removeSpecialCharacters } from 'utils/forms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import ContentDivider from 'components/atoms/content-divider';
import FormInput from 'components/atoms/form-input';
import FormRadioButton from 'components/atoms/form-radio-button';
import FormRadioGroup from 'components/atoms/form-radio-group';
import FormSelect from 'components/atoms/form-select';
import getConfig from 'next/config';
import React, { useState, useEffect, useCallback } from 'react';
import Script from 'next/script';
import Title from 'components/atoms/title';
import i18n from 'utils/i18n';
import Loader from 'components/atoms/loader';
import Wrapper from 'components/atoms/wrapper';
import FormCheckbox from 'components/atoms/form-checkbox';

import SuccessSubmitForm from 'components/molecules/success-submit-form';

import {
  getStatesFromDealers,
  getDealersFromState,
  getCitiesThatHaveDealersFromState,
  validateName,
  validatePhoneNumber
} from 'utils/forms';

// loading the sass style fot the component
import css from './styles.module.scss';
import { dateBr2Iso, empty, getDateAndTime } from 'utils/functions';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule OffersForm
 *
 * <!-- TODO: add a description here! -->
 */
function OffersForm(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.offers-form');

  const { className = '', offer = {}, dealers = [], attractingMedia, ...other } = props;

  const DEFAULT_ATTR_M = 'MTL2-Site_Ofertas';

  const states = getStatesFromDealers(dealers);

  const offerDescription = offer?.description ? offer.description.replace(/\n/g, '; ') : '';


  const [region, setRegion] = useState();
  const [state, setStateName] = useState();

  const [city, setCity] = useState();

  const [cities, setCities] = useState();

  const [cityDealers, setCityDealers] = useState(null);
  const [selectedDealerDn, setSelectedDealerDn] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const [showInvalidOfferMessage, setShowInvalidOfferMessage] = useState(false);

  const [isFormSubmited, setIsFormSubmited] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const offerDate = getDateAndTime();
  const formatOfferDate = dateBr2Iso(offerDate.currentDate).replace(/-/g, '/');

  const validationSchema = yup
    .object()
    .shape({
      firstName: yup
        .string()
        .trim()
        .required(t('message_error_required'))
        .min(3, t('message_error_min_char_name'))
        .test('test-invalid-fullName', t('message_error_invalid_name'), (firstName) => validateName(firstName)),
      lastName: yup
        .string()
        .trim()
        .required(t('message_error_required'))
        .min(3, t('message_error_min_char_last_name')),
      customerId: yup
        .string()
        .required(t('message_error_required'))
        .test('test-invalid-cpf', t('message_error_invalid_cpf'), (cpf) => isValidCPF(cpf)),
      email: yup
        .string()
        .trim()
        .required(t('message_error_required'))
        .email(t('message_error_invalid_email'))
        .max(50, t('message_error_max_char_email')),
      phone: yup
        .string()
        .required(t('message_error_required'))
        .test('test-invalid-phone', t('message_error_invalid_phone'), (phone) => validatePhoneNumber(phone))
        .min(14, t('message_error_min_char_phone')),
      client: yup.boolean()
        .required(t('message_error_required_option'))
        .nullable(),
      dealership: yup
        .string()
        .notOneOf(['placeholder'], t('message_error_required'))
        .required(t('message_error_required_dealership')),
      agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
    })
    .required();

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    city: 'placeholder',
    state: 'placeholder',
    dealership: 'placeholder'
  });

  const watchedFields = watch([
    'firstName',
    'lastName',
    'customerId',
    'email',
    'phone',
    'client',
    'state',
    'city',
    'dealership',
    'agreement'
  ]);

  const watchedState = watch('state');
  const watchedCity = watch('city');
  const watchedAgreement = watch('agreement');

  useEffect(() => {
    verifyRegionFromURL();
  }, []);

  useEffect(() => {
    if (!region) {
      return false;
    }

    setValue('state', region.state);
    setStateName(region.state);
  }, [region]);

  useEffect(() => {
    if (!state) {
      return false;
    }

    loadCities(state);

    //Reset dealers when the state changes
    setValue('dealership', 'placeholder');

    //Reset dealers when the state changes
    setCityDealers(null);
    setSelectedDealerDn(null);

    //Remove the invalid message
    setShowInvalidOfferMessage(false);
  }, [state]);

  useEffect(() => {
    if (!cities) {
      return false;
    }

    //If the url has city and state, we load them values into the form
    if (region?.city) {
      setCity(region.city);
      setValue('city', region.city);

      //Clear the regions after the first use
      setRegion(null);
    }
  }, [cities]);

  useEffect(() => {
    if (!city) {
      return false;
    }

    verifyOffersFromState(city);
  }, [city]);

  useEffect(() => {
    if (!cityDealers) {
      return false;
    }

    setValue('dealership', 'placeholder');
    setSelectedDealerDn(null);
  }, [cityDealers]);

  function onError(error) {
    console.log('Error: ', error);
  }

  function verifyOffersFromState(cityName) {
    fetch(`/api/valid-offers?state=${state}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const cityHasOffer = data.validOffers.find((validOffer) => validOffer.id === offer.id);

        if (cityHasOffer) {
          const filtered = dealers?.filter((dealer) => {
            return dealer?.address?.city.toUpperCase() == cityName.toUpperCase();
          });
          setCityDealers(filtered);
        } else {
          setShowInvalidOfferMessage(true);
        }
      })
      .catch((error) => {
        console.error(`could not find offers for region: ${state, city} with error ${error}`);
      });
  }

  function verifyRegionFromURL() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });

    if (!params.cidade && !params.estado) {
      return false;
    }

    setRegion({
      city: params.cidade,
      state: params.estado
    });
  }

  useEffect(() => {
    if (!region) {
      return false;
    }

    setValue('state', region.state);
    setStateName(region.state);
  }, [region]);

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  const resetForm = useCallback(() => {
    const result = {};
    reset(result);
  }, [reset]);

  function loadCities(state) {
    const dealersFromState = getDealersFromState(dealers, state);
    const citiesThatHaveDealers = getCitiesThatHaveDealersFromState(dealersFromState);


    setCities(citiesThatHaveDealers);
  }

  function onCityChange(event) {
    setValue('city', event.target.value);
    setCity(event.target.value);
  }

  function onStateChange(event) {
    setValue('state', event.target.value);
    setStateName(event.target.value);
    loadCities(event.target.value);
    setValue('city', 'placeholder');
  }

  function onChangeDealers(e) {
    const name = e.currentTarget.value;
    const dn = e.currentTarget?.selectedOptions?.[0]?.dataset?.dn || '';
    setValue('dealership', name);
    setSelectedDealerDn(dn);
  }

  function validateForm() {
    const isFormEmpty = watchedFields.some((value) => empty(value) || value === 'placeholder');
    (isFormEmpty || (watchedAgreement === false)) ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
  }

  const handleKeyDownName = (event) => {
    const key = event.key;
    const isSpecialCharacterOrNumber = /^[^a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s]+$/.test(key);

    if (isSpecialCharacterOrNumber) {
      event.preventDefault();
    }
  };

  function generateCommentsByCheckboxes() {
    let comments = '';
    comments = `${offerDescription}${comments}`;
    comments = getValues('client') === 'yes' ? `${comments}; ${t('label_client')}` : comments;
    return comments;
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsLoading(false);
      setIsFormSubmited(false);

      return false;
    }

    const vehicle = offer?.vehicleColor;

    const dataSource = {
      captchaPayload: captchaPayload,
      attractionMedia: getAttractingMedia(),
      externalColor: {
        name: vehicle.color?.name,
        code: vehicle?.color?.code
      },
      internalColor: {
        name: vehicle?.modelYear?.defaultInternalColorCode,
        code: vehicle?.modelYear?.defaultInternalColorCode
      },

      model: vehicle?.modelYear?.version?.vehicle?.name,
      version: vehicle?.modelYear?.version?.name,
      city: watchedCity,
      concessionaireOfInterest: data.dealership,
      dn: selectedDealerDn,
      email: data.email,
      katashikiCode: vehicle?.modelYear?.katashiki,
      firstName: data.firstName,
      lastName: data.lastName,
      customerId: removeSpecialCharacters(data.customerId),
      prodYear: vehicle?.modelYear?.year,
      state: watchedState,
      telephone: data.phone,
      comments: generateCommentsByCheckboxes() || '',
      locale: 'pt-br',
      isToyotaCustomer: data.client,
      type: '01 – VEÍCULO DE INTERESSE',
      salesforce: true,
      lastUpdateDate: formatOfferDate,
      requestDate: formatOfferDate,
      price: offer?.cashPayment || offer?.afterPrice,
      quantity: 1,
      offer: {
        offerDate: formatOfferDate,
        campaign: offer?.category?.name ? offer?.category?.name : '',
        campaignDetails: offer?.description || '',
        classification: offer?.paymentType || '',
        origin: 'Site de Ofertas',
        model: vehicle?.modelYear?.version?.vehicle?.name || '',
        vehicleValue: offer?.cashPayment || offer?.afterPrice,
        numberOfShares: offer?.installments || '',
        initialFee: offer?.entrancePayment || '',
        valueOfShares: offer?.installmentsValue || 0,
        residual: offer?.residual || ''
      }
    };

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dataSource)
    };

    const url = '/api/lead';

    fetch(url, opts)
      .then((res) => {
        setIsLoading(false);
        setIsSubmitting(false);
        setIsFormSubmited(res.status === 200);
        if (res.status === 200) {
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setIsSubmitting(false);
        setIsFormSubmited(false);
      });
  };

  function getAttractingMedia() {
    if (attractingMedia) {
      return attractingMedia;
    }

    const params = new URL(document.location).searchParams;

    return params.has('attr_m') ? params.get('attr_m') : DEFAULT_ATTR_M;
  }

  function updateFormStatus() {
    setIsFormSubmited(null);
  }

  return (
    <div className={`${css['molecule__offers-form-container']} ${className}`} {...other}>
      <Script
        async
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      {isLoading ? (
        <Wrapper spacingTop={6} spacingBottom={5} data-center-elements>
          <Loader />
        </Wrapper>
      ) : (
        <>
          {isFormSubmited !== null && (
            <SuccessSubmitForm
              onBackForm={updateFormStatus}
              titleSuccess={isFormSubmited ? t('form_submit_title_success') : t('form_submit_title_error')}
              contentSuccess={isFormSubmited ? t('form_submit_content_success') : t('form_submit_content_error')}
              contentBackForm={t('form_submit_button_redo')}
              contentBackHome={t('form_submit_button_back_home')}
              spacingTop={0}
              spacingBottom={0}
            />
          )}

          {isFormSubmited === null && (
            <form onSubmit={handleSubmit(onSubmit, onError)} className={css['offers-form']}>
              <Title level="2" variant={2} className={css['form-title']}>
                {t('form_title')}
              </Title>

              <FormInput
                id="firstName"
                label="Nome*"
                dataRegister={register('firstName')}
                onKeyDown={handleKeyDownName}
                dataErrors={errors['firstName']}
                className={css['form-input']}
                maxLength="50"
                type="text"
                placeholder="Nome"
              />
              <FormInput
                id="lastName"
                label="Sobrenome*"
                dataRegister={register('lastName')}
                onKeyDown={handleKeyDownName}
                dataErrors={errors['lastName']}
                className={css['form-input']}
                maxLength="50"
                type="text"
                placeholder="Sobrenome"
              />

              <FormInput
                id="customerId"
                label="CPF*"
                dataRegister={register('customerId')}
                dataErrors={errors['customerId']}
                placeholder="000.000.000-00"
                className={css['form-input']}
                maxLength="14"
                type="tel"
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = cpfMask(value);
                }}
              />

              <FormInput
                id="email"
                label="E-mail*"
                dataRegister={register('email')}
                className={css['form-input']}
                dataErrors={errors['email']}
                type="text"
                maxLength="50"
                placeholder="exemplo@mail.com"
              />

              <FormInput
                id="phone"
                label="Telefone*"
                dataRegister={register('phone')}
                className={css['form-input']}
                dataErrors={errors['phone']}
                maxLength="15"
                type="tel" // This presents only a numerical keyboard on mobile versions;
                placeholder="(00) 00000-0000"
                masking={(event) => {
                  // TODO: procurar versão useForm para mascara
                  const value = event.target.value;
                  event.target.value = phoneMask(value);
                }}
              />

              <FormRadioGroup
                className={css['radio-group-label']}
                label="Já é cliente Toyota?*"
                name="client"
                dataRegister={register('client')}
                dataErrors={errors['client']}
              >
                <FormRadioButton id="radio-yes" value={true}>
                  {' '}
                  Sim{' '}
                </FormRadioButton>
                <FormRadioButton id="radio-no" value={false}>
                  {' '}
                  Não{' '}
                </FormRadioButton>
              </FormRadioGroup>

              {states?.length > 0 && (
                <FormSelect
                  id="state"
                  dataRegister={register('state')}
                  label="Estado*"
                  placeholder="Selecione o estado"
                  className={css['form-input']}
                  onChange={onStateChange}
                >
                  {states?.map((state, index) => {
                    return (
                      <option value={state} key={index}>
                        {state}
                      </option>
                    );
                  })}
                </FormSelect>
              )}

              <FormSelect
                id="city"
                dataRegister={register('city')}
                label="Cidade*"
                onChange={onCityChange}
                className={css['form-input']}
                placeholder="Selecione a cidade"
              >
                {cities?.length > 0 &&
                  cities?.map((city, index) => {
                    return (
                      <option data-name={city} key={index}>
                        {city}
                      </option>
                    );
                  })}
              </FormSelect>

              {showInvalidOfferMessage ? (
                <div className={css['no-offer-container']}>
                  <span>{t('form_invalid_offer_title')}</span>
                  <AnchorLink
                    color="primary"
                    linkLabel={t('form_invalid_offer_link')}
                    link={`/ofertas/?estado=${watchedState}&cidade=${watchedCity}`}
                  />
                </div>
              ) : (
                cityDealers?.length > 0 && (
                  <FormSelect
                    id="dealership"
                    dataRegister={register('dealership')}
                    label="Concessionária*"
                    placeholder="Selecione a concessionária"
                    onChange={onChangeDealers}
                    dataErrors={errors['dealership']}
                    className={css['form-input']}
                  >
                    {cityDealers?.map((dealer, index) => {
                      return (
                        <option key={index} value={dealer?.name} data-dn={dealer?.dn}>
                          {dealer?.name}
                        </option>
                      );
                    })}
                  </FormSelect>
                )
              )}

              <div className={css['form-container__section']}>
                <ContentDivider />

                <FormCheckbox
                  id="agreement"
                  dataRegister={register('agreement')}
                  dataErrors={errors['agreement']}
                  className={css['form-input-agreement']}
                >
                  {t('form_agreement_label')} &nbsp;
                  <AnchorLink
                    link="/politica-de-privacidade"
                    hasArrow={false}
                    color="gray-4"
                    linkLabel={t('form_agreement_link_label')}
                  />
                </FormCheckbox>
              </div>

              <Button disabled={Object.keys(errors).length > 0 || isFormIncomplete || isSubmitting} type="submit">
                Enviar
              </Button>
            </form>
          )}
        </>
      )}
    </div>
  );
}

export default OffersForm;
