import Prices from 'utils/repo/btb';
// import RUN_CLIENT from './__mock.json';
// const RUN_CLIENT = true;

/**
 * BTB Library
 *
 * const btb = new BTB({
 *    fipe: "002154-12",            // REQUIRED : fipe number
 *    price: 150000,                // REQUIRED : total price
 *    year: 2022,                   // REQUIRED, default current year (format: sometimes it is factoryYear+modelYear, sometimes its YYYY)
 *
 *    down: "30%",                  // default `30%`
 *    downValue: 32000,             // optional, calculated automatically
 *    desiredInstallment: 3000,     // only when you want to define the downValue
 *    accessories: [
 *      {
 *        partNumber: 123,
 *        name: "",                 // optional
 *        price: 123
 *      }
 *    ]
 *    ensurance: false,             // optional, default false
 *    state: "",                    // optional, default `DF`
 *  });
 *
 *  await btb.load();               // will fetch data from the API
 *
 *  btb.setInstallments(24);        // specify how many installments the user wants to see
 *  console.log(
 *   'VALUES FOR 24',
 *    btb.getSinglePayment(),       // get the "a vista" price
 *    btb.getDownPayment(),         // get the first payment ("entrada")
 *    btb.getInstallments(),        // get the price for the installments (preço da parcela)
 *    btb.getResidualPayment(),     // get the residual amount
 *    btb.toObject()                // get all parts as an object
 *  );
 *
 *  btb.addAccessory(89090);       // adds an accessory based on the partNumber
 *  console.log(
 *    'WITH ACCESSORIES',
 *    btb.toObject()
 *  );
 *  console.log(
 *    'PERC %% ',
 *    btb.percFromPrice(50000)     // takes an ammount of money and returns the % related to the total price
 *  );
 */

const DEFAULT_NUM_INSTALLMENTS = 24;
const DEFAULT_STATE = 'DF';
const DEFAULT_DOWN_PERC = 20;
const DEFAULT_RESIDUAL = 20;
const TRY_LIMIT = 4;

class BTB {
  #data = {};
  #btbData = {};
  #numberOfInstallments = DEFAULT_NUM_INSTALLMENTS;
  #addedAccessories = {};
  #downPerc = 0;
  #loadTries = 0;

  constructor(innitialData = {}) {
    this.setData(innitialData);
    return this;
  }

  /**
   * Allow clone of instance
   *
   * @returns Create a new instance with same data
   */
  clone() {
    return new BTB(this.#data);
  }

  /**
   * This public method will receive a value (money) and return the percentage that
   * money represents for the total.
   *
   * @param {Number} value The value to be calculated from total
   * @returns Percentage of total
   */
  percFromPrice(value) {
    return (value / this.#data.valorBem) * 100;
  }

  /**
   * Sets the data to be used and sent to the BTB server/api.
   *
   * @param {Object} data The innitial data
   * @returns BTB
   */
  setData(data = {}) {
    const downPerc = parseInt(data.down || DEFAULT_DOWN_PERC, 10);
    const total = parseFloat(data.price || this.#data.valorBem);
    const minimumDown = total * (DEFAULT_DOWN_PERC / 100);
    const down = data.downValue
      ? Math.max(data.downValue, minimumDown)
      : total * (downPerc / 100);

    // aplied percentage
    this.#downPerc = down / total * 100;

    function getCodeFromFipe (code) {
      // "002154-1"
      // "TMMVVV-D"
      // where:
      // T = tipo (carro, moto, caminhão...)
      // M = Marca (02 para Toyota)
      // V = Modelo/Versão do veículo
      // D = Dígito (ignorado pelo BTB)
      if (!code) {
        return "";
      }
      if (code?.includes('-')) {
        return code
          .replace(/-\d+$/, '')
          .substring(0, 6)
          .padStart(6, '0');
      }
      // if no - is found, we have to remove the last digit
      return code
        .slice(0, -1)
        .substring(0, 6)
        .padStart(6, '0');
    }

    const residual = data.price * (DEFAULT_RESIDUAL / 100);
    const tmpData = {
      // cnpjOrigemNegocio: "90194801439359",
      anoModelo: data.year || this.#getDefaultYear(), // ano fabricação + ano modelo
      modeloId: getCodeFromFipe(data.fipe),
      parcelas: data.installments || null,
      seguroAuto: data.ensurance ? 1 : 0,
      spfRemovido: true,
      svpRemovido: true,
      taxaSubsidio: null,
      ufEmplacamento: data.state || DEFAULT_STATE,
      valorBem: total,
      valorEntrada: down,
      valorSubsidio: null,
      itensFinanciaveis: (data.accessories || []).map((acc) => {
        return {
          codigo: acc.partNumber || acc.id,
          descricao: acc.name || acc.description || acc.title || acc.partNumber,
          valor: acc.price
        };
      })
    };

    if (!data.desiredInstallment) {
      tmpData['valorParcelaResidual'] = residual;
    }

    if (data.desiredInstallment) {
      tmpData['valorParcelaDesejada'] = parseFloat(data.desiredInstallment);
    }

    this.#data = tmpData;
    return this;
  }

  /**
   * This sad function will return a default year in the weird, weird format expected
   * by the BTB api.
   *
   * @returns String
   */
  #getDefaultYear() {
    const d = new Date();
    const y = d.getFullYear().toString().substring(2);
    return y + y;
  }

  /**
    * Will return a promise that resolves after retrieving BTB data based in
    * the local data.
    *
    * @returns BTB
    */
  async load(fetcher = null) {
    await this.#fetchData(fetcher);
  }

  async #fetchData(fetcher = null) {
    // consumed by portal: server side
    if (fetcher !== null) {

      if (this.#data['valorParcelaDesejada']) {
        return await fetcher(Prices.parcelaDesejada(), this.#data)
          .then(response => response.data)
          .then(data => this.#btbData = data.parcelaDesejada)
          .catch(err => {
            if (this.#loadTries <= TRY_LIMIT) {
              this.#loadTries++;
              return this.#fetchData();
            }
            console.error('Falha ao pegar dados no BTB::parcelaResidual', err);
            return {
              errors: err
            };
          });
      }

      if (this.#data['valorParcelaResidual']) {
        return await fetcher(Prices.parcelaResidual(), this.#data)
          .then(response => response.data)
          .then(data => this.#btbData = data.parcelaResidual)
          .catch(err => {
            if (this.#loadTries <= TRY_LIMIT) {
              this.#loadTries++;
              return this.#fetchData();
            }
            console.error('Falha ao pegar dados no BTB::parcelaResidual', err);
            return {
              errors: err
            };
          });
      }
    }

    // consumed by portal: client side
    if (typeof window == 'object') {
      return await fetch('/api/simulation', {
        method: 'POST',
        body: JSON.stringify(this.#data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((data) => this.#btbData = data)
        .catch((err) => {
          if (this.#loadTries <= TRY_LIMIT) {
            this.#loadTries++;
            return this.#fetchData();
          }
          console.error('Falha ao pegar dados no BTB', err);
          return {
            errors: err
          };
        });
    }
  }

  /**
   * This method sets the number of installments the user wants to use.
   * Other methods will rely on this information to get the correct data.
   * Default is specified by DEFAULT_NUM_INSTALLMENTS.
   *
   * @param {Number} num The number of installments
   * @returns BTB
   */
  setInstallments(num) {
    this.#numberOfInstallments = parseInt(num, 10);
    return this;
  }

  /**
   * Method to retrieve the current total price.
   *
   * @returns Number
   */
  getSinglePayment() {
    return this.getTotalPrice();
  }

  /**
   * Internal method to get the price Object for the given number of
   * installments at the moment.
   *
   * @returns Object
   */
  #findPrice() {
    if (this.#btbData?.prazos?.length === 0) {
      this.#numberOfInstallments = this.#btbData?.prazos[0].parcelas;
      return this.#btbData?.prazos[0];
    }
    return this.#btbData?.prazos?.find((prazo) => {
      return prazo.parcelas === parseInt(this.#numberOfInstallments, 10);
    });
  }

  #findAllPrice() {
    return this.#btbData.prazos;
  }

  /**
   * Will parse the accessory info from BTB into a local, more modern approach.
   *
   * @param {Accessory} data The accessory object
   * @returns ParsedAccessoryObject
   */
  #parseAccessories(data) {
    return data?.map((data) => {
      return {
        id: data.codigo, // : 89090
        // partNumber: data.codigo, // 89090,
        price: data.valor, // 849,
        description: data.descricao, //"Caixa organizadora de porta-malas com três divisórias",
        name: data.descricao, //"Caixa organizadora de porta-malas com três divisórias",
        monthlyPrice: data['valor-parcela'] // 34.92,
      };
    });
  }

  /**
   * This method is used to get an object containing structured information about
   * the current payment method.
   *
   * @param {PriceObject} prazo The price object with the current info
   * @returns Object
   */
  #getInstallmentsData(prazo) {
    const data = {
      value: 0,
      totalTaxes: 0,
      accessories: 0,
      total: 0,
      taxes: 0,
    };

    prazo = prazo || this.#findPrice();

    if (!prazo) {
      return data;
    }

    const totalPrice = this.#data.valorBem;
    const value = prazo['valor-parcela'];
    const taxes = {
      iof: prazo['valor-iof'],
      month: prazo['valor-taxa-mes'],
      anualCet: prazo['valor-cet-anual'],
      anualTax: prazo['valor-taxa-anual']
    };

    function percToValue(total, perc) {
      return (total * perc) / 100;
    }

    const totalTaxes =
      taxes.iof +
      percToValue(totalPrice, taxes.month) +
      percToValue(totalPrice, taxes.anualCet) +
      percToValue(totalPrice, taxes.anualTax);

    const accessories = this.getAccessoriesMonthTotal();
    const total = value + accessories;

    return {
      value,
      totalTaxes,
      accessories,
      total,
      taxes
    };
  }

  /**
   * Returns the price/value for the installments (parcelas).
   *
   * @returns Number
   */
  getInstallments() {
    const installmentData = this.#getInstallmentsData();
    return installmentData.total;
  }

  /**
   * Will retrieve the price/value for the first payment (entrada).
   *
   * @returns Number
   */
  getDownPayment() {
    return this.#data.valorEntrada || null;
  }

  /**
   * Gets the price/value for the last installment, the residual payment.
   *
   * @returns Number
   */
  getResidualPayment() {
    const value = this.#findPrice();

    if (!value || !value['valor-parcela-residual']) {return null;}

    return value['valor-parcela-residual'];
  }

  getDesiredInstallment() {
    const value = this.#findPrice();

    if (!value || !value['valor-parcela-desejada']) {return null;}

    return value['valor-parcela-desejada'];
  }

  /**
   * Gets the total financed. Not used or shown anywhere in the moment.
   *
   * @returns Number
   */
  getTotalFinanced() {
    const value = this.#findPrice();

    if (!value || !value['valor-total-financiado']) {return null;}

    return value['valor-total-financiado'];
  }

  /**
   * A method that will get and parse all accessories for the current
   * state of the payment method.
   *
   * @param {PriceObject} value Optional object with the price
   * @returns Object
   */
  getAccessories(value) {
    value = value || this.#findPrice();

    if (!value || !value['itens-financiaveis']) {return [];}

    return this.#parseAccessories(value['itens-financiaveis']);
  }

  /**
   * Will return an structured object including all the relevant data
   * related to the payment so far.
   *
   * @returns Object
   */
  toObject() {
    const value = this.#findPrice();

    if (!value) {return null;}

    return {
      downPayment: this.#data.valorEntrada,
      installments: this.#numberOfInstallments,
      installment: this.#getInstallmentsData(value), // value['valor-parcela'],
      fullInstallments: this.#findAllPrice(),
      residual: value['valor-parcela-residual'],
      downPerc: this.#downPerc,
      totalWithTaxes: value?.['valor-total-prazo'],
      totalFinanced: value?.['valor-total-financiado'],
      total: this.getTotalPrice(),
      payment: this.#data.valorBem,
      accessoriesAvailable: this.getAccessories(value),
      addedAccessories:     this.#addedAccessories,
      simulationCode:       this.#btbData?.["codigo-simulacao"],
      dataBase:             this.#btbData?.["data-base"],
      valueReached:         this.#btbData?.["valor-atingido"],
      constractLog:         this.#btbData?.["valor-registro-contrato"],
      serviceBasket:        this.#btbData?.["valor-cesta-servico"],
      registrationFee:      this.#btbData?.["valor-tarifa-cadastro"],
    };
  }

  /**
   * Gets the total sum of the car + selected accessories.
   *
   * @returns Number
   */
  getTotalPrice() {
    return parseFloat(this.#data.valorBem) + this.getAccessoriesTotal();
  }

  /**
   * Sums the total price for accessories for each month/installment.
   * @returns Number
   */
  getAccessoriesMonthTotal() {
    return Object.values(this.#addedAccessories)
      .reduce((total = 0, current) => {
        return total + parseFloat(current?.monthlyPrice || 0);
      }, 0);
  }

  /**
   * This will summ the total price for all selected accessories.
   * This value is the one added to the final price of the car.
   *
   * @returns Number
   */
  getAccessoriesTotal() {
    return Object.values(this.#addedAccessories)
      .reduce((total = 0, current) => {
        return total + parseFloat(current?.price || 0);
      }, 0);
  }

  /**
   * Marks an accessory as selected.
   * This adds this accessory's price to the total ammount.
   *
   * @param {Number} partNumber The accessory's part number identifier
   */
  addAccessory(name) {
    const accessories = this.getAccessories();

    console.log('acessorios que tem na lib: ', this.#addedAccessories);
    console.log('acessorio que eu quero que pegue: ', name);

    this.#addedAccessories[name] = accessories.find((acc) => acc.name === name);

    console.log('meus acessorios: ', this.#addedAccessories[name]);
  }

  /**
   * Will remove the accessory from the list of selected accessories.
   *
   * @param {Number} partNumber The accessory's part number identifier
   */
  removeAccessory(name) {
    delete this.#addedAccessories[name];
  }

  /**
   * Will remove all accessories stored
   *
   */
  clearAccessories() {
    this.#addedAccessories = {};
  }
}

export default BTB;

const constants = {
  DEFAULT_NUM_INSTALLMENTS,
  DEFAULT_STATE,
  DEFAULT_DOWN_PERC,
  DEFAULT_RESIDUAL,
  TRY_LIMIT,
};

export {
  constants
};
