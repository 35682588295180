import React from 'react';

/**
 * Atom MissingBlock
 *
 * A simple atom to replace missing components/blocks
 */
function MissingBlock(props) {
  const { missingComponent = '', ...other } = props;

  return (
    <span data-missing-component={missingComponent} data-visuallyhidden {...other}>
      Could not find block component {missingComponent}
    </span>
  );
}

export default MissingBlock;
