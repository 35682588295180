import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom IconArrow
 *
 * Arrow that rotate to right, left, top and down
 */
function IconArrow(props) {
  const { className = '', arrowPosition = '', size = '', ...other } = props;

  return (
    <>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-arrow-position={arrowPosition}
        data-size={size}
        className={`${css['atom__icon-arrow-container']} ${className}`}
        {...other}>
        <path d="M7.667 11.333 1.667 6l6-5.333" stroke="currentColor" />
      </svg>
    </>
  );
}

export default IconArrow;
