import React from 'react';
import Media from '../../molecules/media';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom ThumbHover
 *
 * <!-- TODO: add a description here! -->
 */
function ThumbHover (props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.thumb-hover');

  const {
    className = "",
    image,
    children,
    ...other
  } = props;

  return <div
    className={`${css["atom__thumb-hover-container"]} ${className}`}
    {...other}
  >
    { image?.[1]?.url ?
      <>
        <Media
          url={image?.[0]?.url}
          alt={image?.[0]?.alt}
          type={image?.[0]?.type}
          className={css['car-image-base']}
        />
        <Media
          url={image?.[1]?.url}
          alt={image?.[1]?.alt}
          type={image?.[1]?.type}
          className={css['car-image-hover']}
        />
      </>
      :
      <Media
        url={image?.[0]?.url}
        alt={image?.[0]?.alt}
        type={image?.[0]?.type}
        className={css['hover-grow']}
      />
    }
    {children}
  </div>;
}

export default ThumbHover;
