import React from 'react';

import css from './styles.module.scss';
import { ButtonSearchIcon } from '../../icons';

/**
 * Atom SubmitSearchButton
 *
 * <!-- TODO: add a description here! -->
 */
function SubmitSearchButton(props) {
  const { className = '', type = 'submit', ...others } = props;

  return (
    <button className={`${css['atom-submit-search-button-container']} ${className}`} type={type} {...others}>
      <ButtonSearchIcon className={css['icon']} />
    </button>
  );
}

export default SubmitSearchButton;
