import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

import Dealers from 'utils/repo/dealers';
import Vehicle from 'utils/repo/vehicle';

class ArmoredForm implements ContentBlock {
  label = 'Armored Form';

  component = 'ArmoredForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Title form',
      help: 'The title of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'description',
      label: 'Description',
      help: 'Is the description of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'attractingMedia',
      label: 'Attracting Media',
      help: 'Indentify the form',
      type: 'String',
      control: 'input:text',
      defaultValue: 'site-montadora'
    },
    {
      name: 'allowedModels',
      label: 'Allowed Models',
      help: 'List of allowed models to display, comma separated. Empty to allow all.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleSuccess',
      label: 'Title success',
      help: 'The title used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentSuccess',
      label: 'Content success',
      help: 'Descriptive text used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleError',
      label: 'Title error',
      help: 'The title used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentError',
      label: 'Content error',
      help: 'Descriptive text used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'headerSpacingTop',
      label: 'Header spacing at the Top',
      help: 'It is the spacing at the top between the header with title and description of the form',
      type: 'Number',
      control: 'input:number',
      defaultValue: '0'
    },
    {
      name: 'headerSpacingBotton',
      label: 'Header spacing at the bottom',
      help: 'It is the spacing at the bottom between the header with title and description of the form',
      type: 'Number',
      control: 'input:number',
      defaultValue: '0'
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;

    const productId = extra?.context?.productId;

    if (!extra?.context?.productId) {
      throw new Error(`There is no productId defined.`);
    }
    const vehiclesByModels = (await dataFetcher(Vehicle.getAllVehicles(), { locale: locale }))?.data?.vehicles || [];

    const dealersByLocation =
      (await dataFetcher(Dealers.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    const allowedModels = extra?.settings?.allowedModels || '';
    let modelSet = [];
    if (allowedModels.length > 0) {
      modelSet = allowedModels.split(',').map((model) => String(model).trim().toLocaleLowerCase());
    }

    const models = [];
    for (const model of vehiclesByModels) {
      if (modelSet.length > 0 && !modelSet.includes(`${model.name}`.trim().toLocaleLowerCase())) {
        continue;
      }

      models.push({
        name: model.name || '',
        armored: model?.mainVersion?.armoured || null,
        vehicleExternalId: model?.vehicleExternalId || '',
        version: model?.mainVersion?.name || '',
        prodYear: model?.mainVersion?.modelYear?.year || '',
        katashiki: model?.mainVersion?.modelYear?.katashiki || '',
        suffixCode: model?.mainVersion?.modelYear?.suffixCode || '',
        internalColor: {
          name: model?.mainVersion?.modelYear?.defaultInternalColorCode || '',
          code: model?.mainVersion?.modelYear?.defaultInternalColorCode || ''
        },
        externalColor: {
          name: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.name || '',
          code: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.code || ''
        }
      });
    }

    return {
      locale: String(locale).toLowerCase(),
      models: models,
      dealers: dealersByLocation,
      title: extra?.settings?.title,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      description: extra?.settings?.description,
      headerSpacingTop: extra?.settings?.headerSpacingTop || '0',
      headerSpacingBotton: extra?.settings?.headerSpacingBotton || '0',
      attractingMedia: extra?.settings?.attractingMedia || ''
    };
  }
}

export { ArmoredForm };
