import Wrapper from 'components/atoms/wrapper';
import CardsList from 'components/molecules/cards-list';
import SectionHeader from 'components/molecules/section-header';
import Section from 'components/utils/section';
import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SectionCard
 *
 * A section that will render cards.
 */
function SectionCard(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.section-card');

  const {
    className = '',
    title = null,
    description = null,
    callToAction = null,
    spacingTop = 0,
    spacingBottom = 0,
    cardsData,
    buttonColor,
    settings = {},
    autoPlay,
    muted,
    loop,
    controls,
    ...other
  } = props;

  const { bgColor = 'default', centerContent = false } = settings;

  return (
    <Wrapper
      className={`${css['organism__section-card-container']} ${className}`}
      bgColor={bgColor}
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
      data-center-content={centerContent}
      {...other}
    >
      <Section>
        {settings.showTitle && title && (
          <SectionHeader
            title={title}
            description={{
              value: description
            }}
            level={2}
            variant="2"
            linkData={
              callToAction
                ? {
                    title: callToAction.label,
                    link: callToAction.target,
                    color: buttonColor || 'ghost-2'
                  }
                : null
            }
          />
        )}
        {cardsData?.length > 0 && (
          <CardsList
            cardsData={cardsData}
            autoPlay={autoPlay}
            muted={muted}
            loop={loop}
            controls={controls}
            {...settings}
          />
        )}
      </Section>
    </Wrapper>
  );
}

export default SectionCard;
