import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class RegistrationForm implements ContentBlock {
  label = 'Registration Form';

  component = 'RegistrationForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    return {};
  }
}

export { RegistrationForm };
