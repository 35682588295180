import React from 'react';
//import i18n from 'utils/i18n';
import Section from 'components/utils/section';
import Title from 'components/atoms/title';
import Button from 'components/atoms/button';
import IconArrow from 'components/atoms/icon-arrow';
import Media from 'components/molecules/media';

// loading the sass style fot the component
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';

/**
 * Molecule MediaBannerAction
 *
 * <!-- TODO: add a description here! -->
 */
function MediaBannerAction(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.media-banner-action');

  const {
    className = '',
    title,
    textContent,
    actionButton,
    actionLink,
    media,
    stamp = false,
    highImage,
    children,
    ...other
  } = props;

  return (
    <Wrapper spacing={5}>
      <Section
        className={`${css['molecule__media-banner-action-container']} ${className}`}
        hasGrid
        {...other}>
        <div className={css['content-wrapper']}>
          <div className={css['content-container']}>
            {stamp &&
              <Media className={css['desk-only']} url={stamp?.url} alt={stamp?.alt} type={stamp?.type ? stamp?.type : 'img'}/>
            }
            <Title variant={2} level={2}>
              {title}
            </Title>
            {textContent &&
              <p className={css['content-container__content']}>{textContent}</p>
            }
            {children}
          </div>
          {actionButton &&
            <div className={css['action-container']} data-hide="mobile">
              <Button
                link={actionButton?.link}
                className={css['action-container__button']}
                size={actionButton?.size ? actionButton?.size : 'large'}
              >
                {actionButton?.label}
                <IconArrow size="medium" arrowPosition="right" />
              </Button>
            </div>
          }
        </div>
        {media &&
          <div className={css['media-container']}>
            <Media url={media?.url} alt={media?.alt} type={media?.type ? media?.type : 'img'}/>
          </div>
        }
        {actionButton &&
          <div className={css['action-container']} data-hide="desktop">
            <Button
              link={actionButton?.link}
              className={css['action-container__button']}
              size={actionButton?.size ? actionButton?.size : 'large'}>
              {actionButton?.label}
              <IconArrow size="medium" arrowPosition="right" />
            </Button>
          </div>
        }
      </Section>
    </Wrapper>
  );
}

export default MediaBannerAction;
