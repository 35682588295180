import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import LoaderOverlay from 'components/molecules/loader-overlay';
import Tooltip from 'components/atoms/tooltip';
import { IconCheck } from 'components/icons';
import { useMediaQuery } from 'react-responsive';
import i18n from 'utils/i18n';
import { useServiceAppointment, ACTIONS } from 'providers/service-appointment';
import { getVehiclesForCustomer } from 'utils/functions/appointment';
import { updatePageParam } from 'utils/forms';
import { useRouter } from 'next/router';

import css from './styles.module.scss';

/**
 * Molecule ServiceAppointmentVehicleStep
 *
 * <!-- TODO: add a description here! -->
 */
function ServiceAppointmentVehicleStep(props) {
  const t = i18n.useTranslations('components.service-appointment-vehicle-step');
  const router = useRouter();
  const { state, dispatch } = useServiceAppointment();
  const { className = '', onNextStep, ...other } = props;

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const listVehicles = state?.customerVehicles;

  const [selectedVehicleId, setSelectedVehicleId] = useState(state.customerVehicleId);
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isServicesStepDisabled, setIsServicesStepDisabled] = useState(true);
  const [loading, setLoading] = useState(true);
  const vehicleRef = useRef(null);

  const fetchAllMyVehicles = async () => {
    setLoading(true);
    try {
      const vehicles = await getVehiclesForCustomer();

      if (Array.isArray(vehicles) && vehicles.length > 0) {
        dispatch({ type: ACTIONS.SET_CUSTOMER_VEHICLES, payload: vehicles });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllMyVehicles();
  }, []);

  useEffect(() => {
    if (listVehicles.length === 1) {
      setSelectedVehicleId(listVehicles[0].id);
      dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_INFO, payload: listVehicles[0] });
    }
  }, [listVehicles]);

  useLayoutEffect(() => {
    setIsServicesStepDisabled(state?.listSteps?.find((step) => step?.type === 'SERVICES')?.disabled ?? true);
  }, [state.listSteps]);

  const handleSelectVehicle = (vehicle) => {
    if (listVehicles.length > 1) {
      if (vehicle?.id === selectedVehicleId) {
        setSelectedVehicleId(null);
      } else {
        setSelectedVehicleId(vehicle?.id);
      }
    }
    dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_NAME, payload: vehicle?.name });
    setIsShowTooltip(false);
  };

  const handleCommonConfirmation = (vehicle) => {
    dispatch({ type: 'RESET_STATE' });
    dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_INFO, payload: vehicle });
    dispatch({ type: ACTIONS.SET_CUSTOMER_VEHICLE_ID, payload: vehicle?.id });
    onNextStep();
  };

  const handleConfirmation = (vehicleId) => {
    const selectedVehicle = listVehicles?.find((vehicle) => vehicle.id === vehicleId);

    if (selectedVehicle && selectedVehicle.appointments.length > 0 && !isShowTooltip) {
      setIsShowTooltip(true);

      setTimeout(() => {
        if (vehicleRef.current) {
          vehicleRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 100);
      return;
    }

    if (selectedVehicle && (!selectedVehicle.appointments.length || selectedVehicle.appointments.length === 0)) {
      handleCommonConfirmation(selectedVehicle);
    }
  };

  function getImageUrl(vehicle, isMobile) {
    return (
      (isMobile && vehicle?.images?.small) || vehicle?.images?.large || '/images/my-vehicle/image-unavailable-tasa.png'
    );
  }

  const buildVehicleInfoData = (vehicle) => {
    return [
      {
        label: t('vehicle_patent'),
        value: vehicle?.patent
      },
      {
        label: t('vehicle_chassis'),
        value: vehicle?.chassi
      },
      {
        label: t('vehicle_year'),
        value: vehicle?.year
      }
    ];
  };

  return (
    <div className={`${css['molecule__service-appointment-vehicle-step-container']} ${className}`} {...other}>
      {loading && <LoaderOverlay content={t('content_loading')} />}
      {!loading && (
        <div className={css['container-details-vehicles']}>
          <div className={css['container-details-vehicles_card']}>
            {listVehicles.map((vehicle, index) => {
              const selectedImage = getImageUrl(vehicle, isMobile);
              const vehicleInfoData = buildVehicleInfoData(vehicle, t);
              const isVehicleAppointment = vehicle?.appointments && vehicle?.appointments?.length > 0;
              return (
                <div
                  ref={selectedVehicleId === vehicle.id ? vehicleRef : null}
                  className={`${css['container-details-vehicles_card-vehicle']} ${
                    selectedVehicleId === vehicle.id ? css['selected'] : ''
                  }`}
                  key={index}
                >
                  <div className={css['card-image-content']}>
                    {isVehicleAppointment && (
                      <Tooltip
                        className={css['card-tooltip']}
                        isvisible={isShowTooltip && vehicle.id === selectedVehicleId}
                      >
                        <div className={css['card-tooltip-content']}>
                          <span className={css['card-tooltip-content-title']}>{t('tooltip_title')}</span>
                          <span>{vehicle.id === selectedVehicleId ? t('tooltip_info') : t('tooltip_info_alert')}</span>
                          {selectedVehicleId && selectedVehicleId && vehicle.id === selectedVehicleId && (
                            <button
                              onClick={() => handleCommonConfirmation(vehicle)}
                              disabled={!selectedVehicleId}
                              className={css['card-tooltip-content-button']}
                            >
                              {t('tooltip_label_button')}
                            </button>
                          )}
                          {vehicle.id !== selectedVehicleId && <span>{t('tooltip_alert')}</span>}
                        </div>
                      </Tooltip>
                    )}
                    <img
                      className={css['image-vehicle']}
                      src={selectedImage}
                      title={vehicle?.name || ''}
                      alt={vehicle?.name || ''}
                    />
                    <div className={css['info-vehicle']}>
                      <p className={css['info-vehicle-title']}>{vehicle.name}</p>

                      {vehicleInfoData.map(
                        (info, index) =>
                          info.value && (
                            <div className={css['info-vehicle-content']} key={index}>
                              <span className={css['info-vehicle-content_label']}>{info.label}: </span>
                              <span className={css['info-vehicle-content_value']}>{info.value}</span>
                            </div>
                          )
                      )}
                    </div>
                  </div>

                  <Button
                    color={selectedVehicleId === vehicle?.id ? 'primary' : 'ghost-black'}
                    onClick={() => handleSelectVehicle(vehicle)}
                    className={css['card-button']}
                  >
                    {selectedVehicleId ? (
                      <>
                        {t('label_selected_vehicle')}
                        <IconCheck className={css['button-icon']} />
                      </>
                    ) : (
                      t('label_select_vehicle')
                    )}
                  </Button>
                </div>
              );
            })}
          </div>
          <div className={css['dealers-step-container_confirm']}>
            <AnchorLink
              className={css['confirm_anchor']}
              linkLabel={t('label_anchor_confirm')}
              onClick={() => updatePageParam('section', 'vehicles', router)}
              hasArrow={false}
            />
            <Button
              className={css['confirm_button']}
              onClick={() => handleConfirmation(selectedVehicleId)}
              disabled={
                !selectedVehicleId || (selectedVehicleId === state?.customerVehicleId && !isServicesStepDisabled)
              }
            >
              {t('label_button_confirm')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceAppointmentVehicleStep;
