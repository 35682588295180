import React from 'react';
// import i18n from 'utils/i18n';
// import Media from '../media';
import Button from 'components/atoms/button';
import ThumbHover from 'components/atoms/thumb-hover';
import AnchorLink from 'components/atoms/anchor-link';
import { currency } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';

function TabCar(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.tab-car');

  const {
    className = '',
    carlabel,
    button,
    buttonVariant = 'light',
    link,
    image,
    children,
    locale,
    price,
    variant = null,
    ...other
  } = props;

  const country = String(locale).toLowerCase().substring(3);
  const currencySymbol = country === 'br' ? 'BRL' : 'USD';

  return (
    <div className={`${css['molecule__tab-car-container']} ${className}`} data-variant={variant} {...other}>
      <a href={button?.url} aria-hidden="true" title={carlabel ? `Ver modelo ${carlabel}` : undefined}>
        <ThumbHover image={image} className={css['car-container']} />
      </a>
      {carlabel && <span className={css['car-label']}>{carlabel}</span>}
      {price && <span className={css['price-label']}>{currency(price, locale, currencySymbol)}</span>}
      <div className={css['action-container']}>
        <Button className={css['explore-button']} color={buttonVariant} link={button?.url}>
          {button?.label} <span data-visuallyhidden>{carlabel}</span>
        </Button>
        {link?.url && <AnchorLink className={css['mos-link']} size="small" linkLabel={link?.label} link={link?.url} />}
      </div>
      {children}
    </div>
  );
}

export default TabCar;
