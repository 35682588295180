import AnchorLink from 'components/atoms/anchor-link';
// import { GenericIcon } from 'components/icons';
import ThumbHover from 'components/atoms/thumb-hover';
import Title from 'components/atoms/title';
import React from 'react';

import CarPaymentBanner from 'components/molecules/car-payment-banner';

import { useCarBuilder } from 'providers/car-builder';

import { currency } from 'utils/functions';

// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule CarContactInfo
 *
 * <!-- TODO: add a description here! -->
 */
function CarContactInfo (props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.car-contact-info');

  const {
    className = "",
    carModel,
    updatePageState = null,
    pageState = null
  } = props;

  const {
    data: carData,
  } = useCarBuilder();

  const totalValue = currency(carData?.data?.totalValue) || currency(carModel?.selectedColor?.price) || null;

  //There´s no display image for every car color for the thumb hover. Cauan made a helper function to ammend repetition, use the thing.
  function getCarImages() {

    let mediasURL = [];

    carModel?.modelYear?.medias.filter(media => media.tag?.startsWith('display'))
      .map(media => mediasURL.push(media?.media));

    return mediasURL;

  }

  return <div
    className={`${css["car-contact-info-container"]} ${className}`}
  >
    <div className={css['header-container']}>
      <Title level={2} variant={2}>Solicite um contato da concessionária</Title>
    </div>

    <div className={css['car-image-container']}>
      <ThumbHover image={getCarImages()}/>
    </div>

    <div className={css['subtitle-container']}>
      <Title level={3} variant={3} >{carModel?.vehicle?.name} {carModel?.name}</Title>
      {pageState === 'eu-quero-ciclo' &&
        <Title level={4} variant={3} className={css['is-thinner']}>Pelo Ciclo Toyota</Title>
      }
    </div>

    {pageState === 'eu-quero-ciclo' &&
      <div className={css['car-payment-banner-container']}>
        <CarPaymentBanner
          hasExtraInfo
          isContactForm
          model={carModel}
        />
      </div>
    }

    <div className={css['payment-info-container']}>
      <span className={css['payment-info-container__label']}>Com frete incluso, a partir de</span>
      <span className={css['payment-info-container__value']} data-testid="car-price">{totalValue}</span>
      <span className={css['payment-info-container__extra-info']}>Preço base: Brasília¹</span>
    </div>

    <ul className={css['features-list']}>
      {/* <li className={css['features-list__item']}>
        <div className={css['item-title-container']}>
          <div className={css['icon-container']}>
            <GenericIcon/>
          </div>
          <span className={css['title']}>
            Motor
          </span>
        </div>
        <span className={css['item-info']}>
          2.0L Dynamic Force
        </span>
      </li> */}

      {/* <li className={css['features-list__item']}>
        <div className={css['item-title-container']}>
          <div className={css['icon-container']}>
            <GenericIcon/>
          </div>
          <span className={css['title']}>
            Transmissão
          </span>
        </div>
        <span className={css['item-info']}>
          Direct Shift CVT
        </span>
      </li> */}

      <li className={css['features-list__item']}>
        <div className={css['item-title-container']}>
          <div style={{backgroundColor: carModel?.selectedColor?.color?.rgb}} className={css['color-container']}/>
          <span className={css['title']}>
            Cor
          </span>
        </div>
        <span className={css['item-info']}>
          {carModel?.selectedColor?.color?.name}
        </span>
      </li>
    </ul>

    <div className={css['link-container']}>
      <AnchorLink
        className={css['anchor-link']}
        linkLabel="Configurar novamente"
        arrowPosition="left"
        invertArrow
        onClick={(_) => {
          if (updatePageState) {
            updatePageState('');
          }}}
      />
    </div>
  </div>;
}

export default CarContactInfo;
