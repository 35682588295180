import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class BreadcrumbList implements ContentBlock {
  label = 'Breadcrumb List';

  component = 'BreadcrumbList';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  properties = [];

  async adapter(contents: any[], extra: any): Promise<object> {
    const locale = extra?.context?.locale;
    const linkLabel =
      extra?.context?.i18nMessages?.main_navigation?.previous_page || locale === 'pt-BR'
        ? 'Voltar a página anterior'
        : 'Volver a la página anterior';
    const link = extra?.context?.params?.breadcrumb?.at?.(-2) || '/';
    const breadcrumbList = extra?.context?.params?.breadcrumb;

    return {
      linkLabel: linkLabel,
      link: link,
      breadcrumbList: breadcrumbList
    };
  }
}

export { BreadcrumbList };
