import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import Dealers from 'utils/repo/dealers';
import { anchor } from 'utils/meta';

class RecallBanner implements ContentBlock {
  label = 'Dealer search';

  component = 'ActionBannerWithForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Dealer search component title',
      help: 'Dealer search component title',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'bgColor',
      label: 'The color background at the section wrapper ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'default',
        'black'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;

    if (!extra?.context?.productId) {
      throw new Error(`There is no productId defined.`);
    }

    const dealersByLocation =
      (await dataFetcher(Dealers.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    return {
      locale: String(locale).toLowerCase(),
      dealers: dealersByLocation,
      title: extra?.settings?.title,
      spacingTop: extra?.settings?.spacingTop,
      spacingBottom: extra?.settings?.spacingBottom,
      bgColor: extra?.settings?.bgColor
    };
  }
}

export { RecallBanner };
