import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockProperty } from 'components/content-blocks/ContentBlockProperty';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class SimpleBanner implements ContentBlock {
  properties: ContentBlockProperty[] = [
    {
      name: 'title',
      label: 'Title for banner',
      help: 'Main text to be displayed on banner',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Description of banner',
      help: 'Text to be displayed below the title',
      type: 'string',
      control: 'textarea',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Banner media',
      help: 'Set the banner media',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'iconId',
      label: 'Logo image',
      help: 'Set the logo image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    }
  ];

  label = 'Simple Banner';

  component = 'SimpleBanner';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(content: any): Promise<object> {
    const { title, description, media, icon } = content[0];

    return {
      title,
      description,
      media,
      logo: icon?.url
    };
  }
}

export { SimpleBanner };
