import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import ActionBannerSearchForm from 'components/molecules/action-banner-search-form';

/**
 * Molecule RecallBannerForm
 *
 * The recall banner search form
 */
function RecallBannerForm(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.recall-banner-form');

  const {
    className = '',
    bgImage = '/images/bgRecall.png',
    highlightImage = '/images/hlRecall.png',
    title = 'Recall',
    description = `Consulte sua placa e confira se o seu veículo está envolvido em alguma campanha`,
    variant = true,
    placeholder = 'Busque sua placa ou chassi',
    spacing = 8,
    ...other
  } = props;

  const [searchStr, setSearchStr] = React.useState(null);

  function onSubmit(evt) {
    evt.preventDefault();
    if (typeof window !== 'undefined') {
      window.location = '/meu-toyota/servicos/recall?searchStr=' + searchStr;
    }
  }

  function onChange(evt) {
    setSearchStr(evt.target.value);
  }

  return (
    <>
      <Wrapper spacing={spacing} data-remove-top-spacing="true" {...other}>
        <Section
          className={`${css['molecule-recall-banner-form-container']} ${className}`}
          style={{ backgroundImage: `url('${bgImage}')` }}
          hasGrid={true}
        >
          <picture>
            <source srcSet={highlightImage} media="(min-width: 768px)" />
            <img
              alt=""
              data-variant={variant}
              className={css['banner-highlight-image']}
              src={`data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`}
            />
          </picture>

          <form className={css['banner-form-wrapper']} data-variant={variant} onSubmit={onSubmit}>
            <h2 className={css['banner-title']}>{title}</h2>
            {description && <span className={css['banner-description']}>{description}</span>}
            <ActionBannerSearchForm
              className={css['banner-input-wrapper']}
              onChange={onChange}
              placeholder={placeholder}
            />
          </form>
        </Section>
      </Wrapper>
    </>
  );
}

export default RecallBannerForm;
