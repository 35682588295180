import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor, spacingTopMeta, spacingBottomMeta } from 'utils/meta';
import News from 'utils/repo/news';
import getConfig from 'next/config';

class NewsBanner implements ContentBlock {
  label = 'News Banner';

  component = 'NewsBanner';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    spacingTopMeta,
    spacingBottomMeta,
    {
      name: 'selectedNews',
      label: 'Selected News (Required)',
      help: 'Selected News (Required)',
      type: 'picker',
      src: 'News',
      control: 'input:text',
      required: true,
      defaultValue: ''
    }
  ];

  properties = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const { serverRuntimeConfig } = getConfig();
    const productId = serverRuntimeConfig.PRODUCT_ID;

    const newsIds = extra?.settings?.selectedNews;

    if (!Array.isArray(newsIds) || newsIds.length === 0) {
      return {
        newsData: []
      };
    }

    const query = News.newsByIds();

    const response = await dataFetcher(query, { newsIds, productId });

    const newsData = response.data?.newsByIds || [];

    const orderedNewsData = newsData.sort((a: any, b: any) => {
      return newsIds.indexOf(a.id) - newsIds.indexOf(b.id);
    });

    return {
      newsData: orderedNewsData
    };
  }
}

export { NewsBanner };
