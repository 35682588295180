import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class BackToTop implements ContentBlock {
  label = 'Back to top';

  component = 'BackToTop';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    {
      name: 'bgColor',
      label: 'Cor de fundo',
      help: 'Cor de fundo para a sessão',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'black'
      ]
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {};
  }
}

export { BackToTop };
