import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import Media from 'components/molecules/media';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Disclaimer
 *
 * A text with or without an image
 */
function Disclaimer(props) {
  const {
    className = '',
    children,
    photoList = false,
    editorContent = false,
    spacingTop = '6',
    spacingBottom = '6',
    bgColor = 'default',
    fullWidth = false,
    ...other
  } = props;

  return (
    <Wrapper
      bgColor={bgColor}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      data-full-width={fullWidth}
      {...other}
    >
      <Section hasGrid>
        <div className={`${css['molecule__disclaimer-container']} ${className}`} {...other}>
          {photoList && (
            <div className={css['images-container']}>
              {photoList.map((photo, key) => (
                <Media key={key} url={photo?.url} alt={photo?.alt} />
              ))}
            </div>
          )}

          {editorContent && (
            <div className={css['content-container']}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{editorContent}</ReactMarkdown>
            </div>
          )}
        </div>
      </Section>
    </Wrapper>
  );
}

export default Disclaimer;
