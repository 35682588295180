import { captchaToken } from 'utils/captcha/token';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from 'components/atoms/button';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import FormTextarea from 'components/atoms/form-text-area';
import getConfig from 'next/config';
import React, { useCallback, useState } from 'react';
import Script from 'next/script';
import Section from 'components/utils/section';
import SectionHeader from 'components/molecules/section-header';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Wrapper from 'components/atoms/wrapper';

import { phoneNumber, phoneMask, cpfMask, isValidCPF } from 'utils/validations';

import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule LgpdForm
 *
 * <!-- TODO: add a description here! -->
 */
function LgpdForm(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.lgpd-form');

  const {
    className = '',
    title = 'Formulário LGPD',
    locale,
    description,
    headerSpacing,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    ...other
  } = props;

  const validationSchema = yup
    .object()
    .shape({
      name: yup
        .string()
        .required('O campo nome não pode ficar vazio.')
        .min(4, 'O nome deve conter no mínimo 4 caractéres.'),
      surname: yup
        .string()
        .required('O campo sobrenome não pode ficar vazio.')
        .min(4, 'O sobrenome deve conter no mínimo 4 caractéres.'),
      cpf: yup
        .string()
        .required('O campo cpf não pode ficar vazio.')
        .test('test-invalid-cpf', 'Este não é um cpf válido.', (cpf) => isValidCPF(cpf))
        .min(14, 'Este não é um cpf completo.'),
      email: yup.string().required('O campo email não pode ficar vazio.').email('Este não é um email válido.'),
      telephone: yup
        .string()
        .required('O campo telefone não pode ficar vazio.')
        .matches(phoneNumber, 'Este não é um telefone válido.')
        .min(14, 'Este não é um telefone completo.'),
      subject: yup.string().notOneOf(['placeholder'], 'Selecione uma opção.').required('Selecione uma opção'),
      requests: yup.string().notOneOf(['placeholder'], 'Selecione uma opção.').required('Selecione uma opção'),
      message: yup
        .string()
        .min(5, 'Esta mensagem precisa ter mais do que 5 caracteres')
        .required('O campo mensagem não pode ficar vazio.')
    })
    .required();

  const {
    register,
    reset,
    handleSubmit,
    formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isDisable, setIsDisable] = useState(false);

  const resetForm = useCallback(() => {
    const result = { name: '', surname: '', cpf: '', email: '', telephone: '', subject: '', requests: '', message: '' };
    reset(result);
  }, [reset]);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  const onSubmit = async (data) => {
    setIsDisable(true);
    data.locale = locale;
    data.type = 'LGPD';

    const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');

      return false;
    }

    data.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    const url = '/api/contact';
    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsDisable(false);
          resetForm(reset);
          setIsStatusComponent('success');
          return;
        }
        setIsDisable(false);
        setIsStatusComponent('error');
      })
      .catch((err) => {
        setIsDisable(false);
        console.log(err);
      });
  };

  function onError(error) {
    console.log('Error: ', error);
  }

  return (
    <div className={`${css['molecule__lgpd-form-container']} ${className}`} {...other}>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      {isStatusComponent === 'success' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleSuccess}
          contentSuccess={contentSuccess}
          contentBackForm="Enviar outro formulário"
          contentBackHome="Ir a página inicial"
        />
      )}
      {isStatusComponent === 'error' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleError}
          contentSuccess={contentError}
          contentBackForm="Enviar outro formulário"
          contentBackHome="Ir a página inicial"
        />
      )}
      {isStatusComponent === 'default' && (
        <>
          <Wrapper spacing={headerSpacing}>
            <Section>
              <SectionHeader
                title={title}
                description={{
                  value: description ? description : 'Todos os campos com (*) são obrigatórios.',
                  textColor: 'gray-4'
                }}
              />
            </Section>
          </Wrapper>
          <Section>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Section className={css['form-container__section']} tag="div">
                <FormInput
                  id="name"
                  label="Nome *"
                  dataRegister={register('name')}
                  dataErrors={errors['name']}
                  type="text"
                  placeholder="Nome completo"
                />

                <FormInput
                  id="surname"
                  label="Sobrenome *"
                  dataRegister={register('surname')}
                  dataErrors={errors['surname']}
                  type="text"
                  placeholder="Sobrenome"
                />

                <FormInput
                  className={`${css['group__input-two']}`}
                  id="cpf"
                  label="CPF"
                  dataRegister={register('cpf')}
                  dataErrors={errors['cpf']}
                  type="text"
                  maxLength="14"
                  placeholder="000.000.000-00"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = cpfMask(value);
                  }}
                />

                <FormInput
                  id="email"
                  label="Email *"
                  dataRegister={register('email')}
                  dataErrors={errors['email']}
                  type="text"
                  placeholder="E-mail"
                />

                <FormInput
                  id="telephone"
                  label="Telefone *"
                  dataRegister={register('telephone')}
                  dataErrors={errors['telephone']}
                  type="tel" // This presents only a numerical keyboard on mobile versions;
                  maxLength="15"
                  placeholder="(00) 00000.0000"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = phoneMask(value);
                  }}
                />
                <p>Tipo de titular dos dados *</p>

                <FormSelect
                  id="subject"
                  placeholder="Selecione"
                  label="Identifique sua relação com a Toyota"
                  dataRegister={register('subject')}
                  dataErrors={errors['subject']}
                >
                  <option value="Colaboradores">Colaboradores</option>
                  <option value="Fornecedor">Fornecedor</option>
                  <option value="Clientes, consumidores finais e usuários dos produtos e/ou serviços">
                    Clientes, consumidores finais e usuários dos produtos e/ou serviços
                  </option>
                  <option value="Membros da Diretoria, Acionistas ou Sócios">
                    Membros da Diretoria, Acionistas ou Sócios
                  </option>
                  <option value="Parentes associados ao titular">Parentes associados ao titular</option>
                  <option value="Conselheiros, consultores e outros profissionais e especialistas">
                    Conselheiros, consultores e outros profissionais e especialistas
                  </option>
                  <option value="Outros">Outros/comentários:</option>
                </FormSelect>

                <FormSelect
                  id="requests"
                  placeholder="Selecione"
                  label="Tipo de solicitação *"
                  dataRegister={register('requests')}
                  dataErrors={errors['requests']}
                >
                  <option value="Acesso aos dados">Acesso aos dados</option>
                  <option value="Correção dos dados">Correção dos dados</option>
                  <option value="Anonimização/Bloqueio/Eliminação">Anonimização/Bloqueio/Eliminação</option>
                  <option value="Portabilidade">Portabilidade</option>
                  <option value="Revogação de consentimento">Revogação de consentimento</option>
                  <option value="Cancelamento de informe publicitário">Cancelamento de informe publicitário</option>
                  <option value="Outros">Outros</option>
                </FormSelect>

                <div data-grid-full-width>
                  <FormTextarea
                    id="message"
                    placeholder="Escreva sua mensagem"
                    label="Mensagem *"
                    dataRegister={register('message')}
                    dataErrors={errors['message']}
                  />
                </div>

                <div data-grid-full-width className={css['form-submit-container']}>
                  <Button type="submit" disabled={!formState.isValid || isDisable}>
                    Enviar
                  </Button>
                </div>
              </Section>
            </form>
          </Section>
        </>
      )}
    </div>
  );
}

export default LgpdForm;
