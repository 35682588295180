import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import {
  anchor,
  formAttractingMedia,
  formContentError,
  formContentSuccess,
  formDescription,
  formHeaderSpacing,
  formTitle,
  formTitleError,
  formTitleSuccess
} from 'utils/meta';

class EmailDreamCarForm implements ContentBlock {
  label = 'E-mail DreamCar Form';

  component = 'EmailDreamCarForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'selectedContest',
      label: 'Selected Contest (Required)',
      help: 'Selected Contest (Required)',
      type: 'picker',
      src: 'Contests',
      control: 'input:text',
      required: true,
      defaultValue: ''
    },
    formTitle,
    formDescription,
    formAttractingMedia,
    formTitleSuccess,
    formContentSuccess,
    formTitleError,
    formContentError,
    formHeaderSpacing,
    {
      name: 'registrationLink',
      label: 'Link to New Registration',
      help: 'The link utilized in the success response modal of the submitted form. It will be used to redirect the user to initiate a new registration.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'registrationLinkLabel',
      label: 'Link Title for Success',
      help: 'The title of the link used in the success response modal of the submitted form. This text will appear as the label of the link.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const settings = extra?.settings;
    const locale = extra?.context?.locale;

    return {
      contestId: settings?.selectedContest || null,
      registrationLink: settings?.registrationLink,
      registrationLinkLabel: settings?.registrationLinkLabel,
      locale: String(locale).toLowerCase(),
      title: settings?.title,
      titleSuccess: settings?.titleSuccess,
      contentSuccess: settings?.contentSuccess,
      titleError: settings?.titleError,
      contentError: settings?.contentError,
      description: settings?.description,
      headerSpacing: settings?.headerSpacing || '0',
      attractingMedia: settings?.attractingMedia || ''
    };
  }
}

export { EmailDreamCarForm };
