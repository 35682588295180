import React from 'react';
//import i18n from 'utils/i18n';
import AcessoryCard from '../acessory-card';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule AccesoryCardList
 *
 * <!-- TODO: add a description here! -->
 */
function AccesoryCardList(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.accesory-card-list');

  const { className = '', acessoryListData, children, locale = 'pt-BR', ...other } = props;

  return (
    <ul className={`${css['accesory-card-list']} ${className}`} {...other}>
      {acessoryListData?.length > 0 &&
        acessoryListData?.map((card, key) => {
          return (
            <li className={css['accesory-card-list__item']} key={key}>
              <AcessoryCard
                title={card?.name}
                price={card?.price?.value}
                media={card?.media}
                link={card?.page?.fullpath}
                category={card?.category}
                partNumber={card?.partNumber}
                locale={locale}
                status={card?.status}
              />
            </li>
          );
        })}
    </ul>
  );
}

export default AccesoryCardList;
