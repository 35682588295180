import React, { useState, useEffect, useCallback } from 'react';
import i18n from 'utils/i18n';

/* eslint-disable react-hooks/exhaustive-deps */
import { empty } from 'utils/functions';
import { formReasons as contactFormReasons } from 'utils/repo/salesForce';
import { captchaToken } from 'utils/captcha/token';
import { phoneMask } from 'utils/validations';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import ContentDivider from 'components/atoms/content-divider';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import getConfig from 'next/config';
import Script from 'next/script';
import Section from 'components/utils/section';
import SectionHeader from 'components/molecules/section-header';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';

const { publicRuntimeConfig } = getConfig();

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ArmoredForm
 *
 * <!-- TODO: add a description here! -->
 */
function ArmoredForm(props) {
  //const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.armored-form');

  const {
    className = '',
    models = [],
    dealers = [],
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    title,
    description,
    headerSpacingTop,
    headerSpacingBottom,
    attractingMedia,
    locale,

    // settings of ContentBlock not need here, just to drop from 'other'
    allowedModels,

    ...other
  } = props;

  const country = `${locale}`.slice(-2);
  const contactReasons = country === 'br' ? {} : contactFormReasons;

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [selectedModels, setSelectedModels] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDealer, setSelectedDealer] = useState('');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);
  const [listModels, setListModels] = useState([]);

  const validationSchemaConfig = {
    name: yup.string().required(t('message_error_required_name')).min(4, t('message_error_min_char_name')),
    email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
    phone: yup.string().required(t('message_error_required_phone')).min(14, t('message_error_min_char_phone')),
    state: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_state'))
      .required(t('message_error_required_state')),
    city: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_city'))
      .required(t('message_error_required_city')),
    dealership: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership'))
      .required(t('message_error_required_dealership')),
    agreement: yup.boolean().oneOf([true], t('message_error_required_agreement')),
    drive: yup.boolean()
  };

  if (country === 'ar') {
    validationSchemaConfig['consultReason'] = yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_reason'))
      .required(t('message_error_required_reason'));
  }

  if (country === 'br' && listModels.length > 0) {
    validationSchemaConfig['model'] = yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_model'))
      .required(t('message_error_required_model'));
  }

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    reset,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      client: false,
      agreement: false,
      drive: false,
      finance: false
    }
  });

  const [dataFields, setDataFields] = useState({
    name: '',
    email: '',
    phone: '',
    state: '',
    city: '',
    dealer: { name: '', dn: '' },
    client: false,
    agreement: false,
    drive: false,
    finance: false
  });

  const [data, setData] = useState({
    externalColor: {
      name: '',
      code: ''
    },
    internalColor: {
      name: '',
      code: ''
    },
    attractingMedia: getAttractingMedia(),
    model: '',
    version: '',
    city: '',
    comments: '',
    concessionaireOfInterest: '',
    dn: '',
    email: '',
    katashikiCode: '',
    consultReason: '',
    name: '',
    prodYear: '',
    state: '',
    suffixCode: '',
    telephone: '',
    locale: locale
  });

  const resetForm = useCallback(() => {
    const resultData = setData({});
    const resultDataFields = setDataFields({});
    reset(resultData);
    reset(resultDataFields);
  }, [reset]);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function getAttractingMedia() {
    if (typeof window === 'undefined') {
      return attractingMedia || '';
    }

    let params = new URL(document.location).searchParams;
    let currentAttracting = attractingMedia || '';
    if (params.has('attr_m')) {
      currentAttracting = params.get('attr_m');
    }

    return currentAttracting;
  }

  function validateForm() {
    setDataFields({
      name: getValues('name'),
      email: getValues('email'),
      phone: getValues('phone'),
      state: selectedState,
      city: selectedCity,
      dealer: selectedDealer,
      agreement: getValues('agreement'),
      drive: getValues('drive'),
      client: getValues('client'),
      finance: getValues('finance')
    });
    const isFormEmpty = Object.values(dataFields).some((x) => empty(x));

    !isFormEmpty ? setIsDisable(false) : setIsDisable(true);
    isFormEmpty ? setIsFormIncomplete(true) : setIsFormIncomplete(false);

    setData({
      locale: locale,
      consultReason: getValues('consultReason') || '',
      contacted: 'Concesionarios oficiales',
      attractingMedia: getAttractingMedia(),
      externalColor: {
        name: selectedModels?.externalColor?.name,
        code: selectedModels?.externalColor?.code
      },
      internalColor: {
        name: selectedModels?.internalColor?.name,
        code: selectedModels?.internalColor?.code
      },
      model: getValues('model') || '',
      version: selectedModels?.version,
      city: dataFields?.city,
      concessionaireOfInterest: selectedDealer?.name,
      dn: selectedDealer?.dn,
      email: dataFields?.email,
      katashikiCode: selectedModels?.katashiki,
      name: dataFields?.name,
      prodYear: selectedModels?.prodYear,
      state: dataFields?.state,
      suffixCode: selectedModels?.suffixCode,
      telephone: dataFields?.phone
    });
  }

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state.address.state))].sort());
  }

  function loadModels() {
    const list = [];

    models.map((model) => {
      if (model?.armored === true) {
        list.push(model?.name);
      }
    });
    setListModels(list.sort());
  }

  useEffect(() => {
    loadModels();
  }, []);

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setSelectedState(selected);

    if (selected) {
      loadCity(selected);
    }
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setSelectedCity(selected);
    loadDealers(selected);
  }

  function onChangeModel(e) {
    const selected = e.currentTarget.value;
    if (!selected) {
      setSelectedModels(null);
      return;
    }

    const model = models.find((model) => model.name === selected);
    setSelectedModels(model);
  }

  function onChangeDealers(e) {
    const name = e.currentTarget.value;
    const dn = e.currentTarget?.selectedOptions?.[0]?.dataset?.dn || '';
    setSelectedDealer({ name: name, dn: dn });
  }

  const onBlurFields = React.useCallback(
    (event) => {
      setDataFields({
        ...dataFields,
        [event.currentTarget.id]: event.currentTarget.value
      });
    },
    [dataFields]
  );

  function loadCity(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() == selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();

    setListCities(resultCities);
    setSelectedCity(resultCities[0]);
    loadDealers(selectedState);
  }

  function loadDealers(selectedCity) {
    if (selectedCity) {
      const filtered = dealers?.filter((dealer) => {
        return dealer?.address?.city.toUpperCase() == selectedCity.toUpperCase();
      });
      setListDealers(filtered);
      setSelectedDealer({
        name: filtered?.[0]?.name,
        dn: filtered?.[0]?.dn
      });
    }
  }

  const onSubmit = async () => {
    setIsDisable(true);

    const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');
      setIsFormIncomplete(true);
      setIsDisable(true);

      return false;
    }

    data.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    const url = '/api/lead';
    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsDisable(false);
          resetForm(reset);
          setIsStatusComponent('success');
          return;
        }
        setIsDisable(false);
        setIsStatusComponent('error');
      })
      .catch((err) => {
        setIsDisable(false);
        setIsStatusComponent('error');
        console.log(err);
      });
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <div className={`${css['molecule__armored-form-container']} ${className}`} {...other}>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      {isStatusComponent === 'success' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
          contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
          contentBackForm={t('interest_content_back_form')}
          contentBackHome={t('interest_content_back_home')}
        />
      )}
      {isStatusComponent === 'error' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleError ? titleError : t('error_title')}
          contentSuccess={contentError ? contentError : t('error_content')}
          contentBackForm={t('interest_content_back_form')}
          contentBackHome={t('interest_content_back_home')}
        />
      )}
      {isStatusComponent === 'default' && (
        <>
          <Wrapper spacingTop={headerSpacingTop} spacingBottom={headerSpacingBottom}>
            <Section>
              <SectionHeader
                title={title ? title : t('title_form')}
                description={{
                  value: description ? description : t('description_form'),
                  textColor: 'gray-4'
                }}
              />
            </Section>
          </Wrapper>

          <form onSubmit={handleSubmit(onSubmit, onError)}>
            {country === 'ar' && (
              <>
                <Section className={css['form-container__section']} tag="div" hasGrid={true}>
                  <Title className={css['form-container__title']} variant={5}>
                    {t('select_consult_reason')}
                  </Title>
                  <FormSelect
                    className={css['form-container__input']}
                    id="consultReason"
                    placeholder={t('placeholder_consult_reason')}
                    onBlur={onBlurFields && validateForm}
                    dataRegister={register('consultReason')}
                    displayLabel={false}
                    dataErrors={errors['consultReason']}
                  >
                    {Object.keys(contactReasons).map((v, i) => {
                      return (
                        <option key={`contact-reason-${i}`} value={contactReasons[v]}>
                          {v}
                        </option>
                      );
                    })}
                  </FormSelect>
                </Section>
              </>
            )}

            <Section className={css['form-container__section']} tag="div" hasGrid={true}>
              {listModels.length > 0 && (
                <>
                  <Title className={css['form-container__title']} variant={5}>
                    {t('select_model_interest')}
                  </Title>
                  <FormSelect
                    className={css['form-container__input']}
                    id="model"
                    placeholder={t('placeholder_model_interest')}
                    onBlur={onChangeModel && validateForm}
                    onClick={onChangeModel}
                    onFocus={loadModels}
                    dataRegister={register('model')}
                    displayLabel={false}
                    dataErrors={errors['model']}
                  >
                    {listModels &&
                      listModels?.map((model, index) => {
                        return (
                          <option key={index} value={model}>
                            {model}
                          </option>
                        );
                      })}
                  </FormSelect>
                </>
              )}
            </Section>
            <Section className={css['form-container__section']} tag="div" hasGrid={true}>
              <Title className={css['form-container__title']} variant={5}>
                {t('title_section_personal_data')}
              </Title>
              <div className={css['form-container__list-input']}>
                <FormInput
                  className={css['form-container__input']}
                  id="name"
                  label={t('label_user_name_field')}
                  placeholder={t('placeholder_user_name_field')}
                  onBlur={onBlurFields && validateForm}
                  dataRegister={register('name')}
                  dataErrors={errors['name']}
                  type="text"
                />
                <FormInput
                  className={css['form-container__input']}
                  id="email"
                  label="Email"
                  placeholder={locale === 'pt-br' ? 'Exemplo@mail.com' : 'Ejemplo@mail.com'}
                  onBlur={onBlurFields && validateForm}
                  dataRegister={register('email')}
                  dataErrors={errors['email']}
                  type="email"
                />

                <FormInput
                  className={css['form-container__input']}
                  id="phone"
                  label={t('label_phone_field')}
                  placeholder={locale === 'pt-br' ? '(00) 00000.0000' : 'Ej: 1165276575'}
                  onBlur={onBlurFields && validateForm}
                  dataRegister={register('phone')}
                  dataErrors={errors['phone']}
                  type="tel"
                  maxLength={locale === 'pt-br' ? '15' : '17'}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = locale === 'pt-br' ? phoneMask(value) : value;
                  }}
                />
              </div>
            </Section>

            <Section className={css['form-container__section']} tag="div" hasGrid={true}>
              <Title className={css['form-container__title']} variant={5}>
                {t('title_section_dealership')}
              </Title>
              <div className={css['form-container__list-input']}>
                <FormSelect
                  className={css['form-container__input']}
                  id="state"
                  label={t('label_state')}
                  placeholder={t('placeholder_state')}
                  onFocus={loadStates}
                  onClick={onChangeState}
                  onBlur={validateForm}
                  dataRegister={register('state')}
                  dataErrors={errors['state']}
                >
                  {listStates.length > 0 &&
                    listStates?.map((state, index) => {
                      return (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      );
                    })}
                </FormSelect>
                <FormSelect
                  className={css['form-container__input']}
                  id="city"
                  label={t('label_city')}
                  placeholder={t('placeholder_city')}
                  onClick={onChangeCity}
                  onBlur={validateForm}
                  dataRegister={register('city')}
                  dataErrors={errors['city']}
                >
                  {selectedState &&
                    listCities.length > 0 &&
                    listCities?.map((city, index) => {
                      return (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      );
                    })}
                </FormSelect>
                <FormSelect
                  className={css['form-container__input']}
                  id="dealership"
                  label={t('label_dealership')}
                  placeholder={t('placeholder_dealership')}
                  onClick={onChangeDealers}
                  onBlur={onChangeDealers && validateForm}
                  dataRegister={register('dealership')}
                  dataErrors={errors['dealership']}
                >
                  {listDealers.length > 0 &&
                    listDealers?.map((dealer, index) => {
                      return (
                        <option key={index} value={dealer.name} data-dn={dealer?.dn}>
                          {dealer.name.toUpperCase()}
                        </option>
                      );
                    })}
                </FormSelect>
              </div>
            </Section>

            <Section className={css['form-container__section']} tag="div">
              {country === 'br' && (
                <>
                  <Title className={css['form-container__title']} variant={5}>
                    Selecione quando necessário
                  </Title>
                  <div className={css['form-container__checkbox']}>
                    <FormCheckbox
                      className={css['form-container__checkbox-client']}
                      id="client"
                      label="Já sou cliente Toyota"
                      dataRegister={register('client')}
                      dataErrors={errors['client']}
                      disabled={isDisable}
                    />
                    <FormCheckbox
                      className={css['form-container__checkbox-finance']}
                      id="finance"
                      label="Quero financiar"
                      dataRegister={register('finance')}
                      onClick={validateForm}
                      onBlur={validateForm}
                      dataErrors={errors['finance']}
                      disabled={isDisable}
                    />

                    <FormCheckbox
                      className={css['form-container__checkbox-test']}
                      id="drive"
                      label="Quero agendar um teste drive"
                      dataRegister={register('drive')}
                      onClick={validateForm}
                      onBlur={validateForm}
                      placeholder=""
                      dataErrors={errors['drive']}
                      disabled={isDisable}
                    />
                  </div>
                </>
              )}

              <ContentDivider />
              <FormCheckbox
                className={css['form-container__checkbox-agreement']}
                id="agreement"
                dataRegister={register('agreement')}
                onClick={validateForm}
                onBlur={validateForm}
                dataErrors={errors['agreement']}
                disabled={isDisable}
              >
                {t('label_agreement')}&nbsp;
                <AnchorLink
                  link={locale === 'pt-br' ? '/politica-de-privacidade' : '/terminos-y-condiciones'}
                  hasArrow={false}
                  linkLabel={t('link_label_agreement')}
                />
              </FormCheckbox>
            </Section>

            <Section className={css['form-container__section']} tag="div">
              <Button onClick={validateForm} disabled={isFormIncomplete || isDisable} type="submit">
                {t('button_submit_form')}
              </Button>
            </Section>
          </form>
        </>
      )}
    </div>
  );
}

export default ArmoredForm;
