import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom FormRadioGroup
 *
 * <!-- TODO: add a description here! -->
 */
function FormRadioGroup(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.form-radio-group');

  const {
    className = '',
    id,
    name,
    disabled,
    label,
    dataRegister,
    dataErrors,
    vertical = false,
    displayLabel = true,
    displayError = true,
    spacingGap = 'extraLarge',
    children,
    ...other
  } = props;

  function updatedChildren(children) {
    return React.Children.map(children, (child, i) => {
      let childDisabled = child?.props?.disabled ? true : disabled;
      return React.cloneElement(child, {
        ...child?.props,
        name: name,
        dataRegister: dataRegister,
        key: i,
        disabled: childDisabled
      });
    });
  }

  return (
    <div className={`${css['atom__form-radio-group-container']} ${css['ff-container']} ${className}`}>
      <label
        htmlFor={id}
        className={`${css['ff-label']} ${disabled ? css['ff-disabled'] : ''} ${displayLabel ? '' : css['absent']}`}
        disabled={disabled ? true : undefined}
      >
        {label}
      </label>
      <div
        data-spacing-gap={spacingGap}
        className={`${css['ff-radio-container']} ${vertical ? css['ff-radio-column'] : ''}`}
      >
        {updatedChildren(children)}
      </div>
      {displayError && dataErrors?.type && (
        <span
          className={
            css[displayError && dataErrors?.message?.length > 40 ? 'ff-error-message-long' : 'ff-error-message']
          }
        >
          {dataErrors?.message}
        </span>
      )}
    </div>
  );
}

export default FormRadioGroup;
