import React, { useEffect, useState } from 'react';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import OfferDetails from 'components/molecules/offer-details';
import FormSelect from 'components/atoms/form-select';
import i18n from 'utils/i18n';
import Loader from 'components/atoms/loader';

import { getCitiesFromState } from 'utils/forms';

import { useForm } from 'react-hook-form';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism OffersPanel
 *
 * Shows offers filtered by vehicle
 */
function OffersPanel(props) {
  const t = i18n?.useTranslations('components.offers-panel');
  const {
    className = "",
    spacingTop = 6,
    spacingBottom = 6,
    ...other
  } = props;

  const { register, setValue } = useForm({
    state: 'national',
    city: 'national',
    category: 'all'
  });

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedState, setSelectedState] = useState('national');
  const [selectedCity, setSelectedCity] = useState('national');
  const [states, setStates] = useState(null);
  const [cities, setCities] = useState(null);
  const [offers, setOffers] = useState(null);
  const [filteredOffers, setFilteredOffers] = useState(null);

  const [offerCategories, setOfferCategories] = useState(null);

  useEffect(() => {
    setSelectedState('national');
    setValue('state', 'national');
    const getStates = async () => {
         const data = await fetchStatesAndCities();
         setStates(data);
    };
   getStates();
   loadOffers();
  }, []);

  useEffect(() => {
    const getData = async () =>{
      const data = await loadOffers();
      const isThereNationalOrStateOffers = data.some(offer => (offer.national == true) ||
        (offer.campaign.states.some(state => state.name === selectedState) && offer.campaign.cities.length === 0));
      if (isThereNationalOrStateOffers) {
        const allCitiesFromState = getCitiesFromState(states, selectedState);
        setCities(allCitiesFromState);
      } else {
        const allCitiesWithOffers = [];
        data.map(offer => {
          if(offer.campaign.cities && offer.campaign.cities.length > 0){
            offer.campaign.cities.map(city => allCitiesWithOffers .push(city.name));
          }
        });
        const filteredCities = allCitiesWithOffers.filter((value, index) => allCitiesWithOffers .indexOf(value) === index);
        setCities(filteredCities);
      }
    };
    if (!selectedState) {
      return null;
    }
    if (selectedState === 'national') {
      loadOffers();
      setCities([]);
      return null;
    } else {
      getData();
    }
  }, [selectedState]);

  useEffect(() => {

    if (!selectedCity) {return false;}

    const region = {
      state: selectedState,
      city: selectedCity
    };

    //Save the new region for the offer panel links
    setSelectedRegion(region);

    loadOffers();

  }, [selectedCity]);

  useEffect(() => {

    if (!offers) {return null;}

    buildFilters();
    setValue('category', 'all');
    filterOffers();

  }, [offers]);

  async function loadOffers() {
    setIsLoading(true);
    const offersData = await fetchOffers();
    setOffers(offersData);
    setIsLoading(false);
    return offersData;
  }
  async function fetchOffers() {
    const offers = fetch(selectedState !== 'national' ?
    (selectedCity !== 'national' ? `/api/valid-offers?state=${selectedState}&city=${selectedCity}&national=${true}` : `/api/valid-offers?state=${selectedState}&national=${true}`)
    : `/api/valid-offers`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        return data.validOffers;
      })
      .catch(error => {
        console.error(`could not find offers for region: ${selectedState} with error ${error}`);
      });

    return offers;

  }

  async function fetchStatesAndCities() {
    const statesAndCities = fetch('/api/states')
      .then(response => {
        return response.json();
      })
      .then(data => {
        return data.states;
      })
      .catch(error => {
        console.error(`Error ${error}`);
      });

    return statesAndCities;

  }

  function buildFilters() {

    //Offers can be saved without any category. We need to remove then from the category select.
    const validCategories = offers.filter(offer => offer.category !== null);

    setOfferCategories([...new Set(validCategories.map(offer => offer.category?.name))]);

  }

  function onStateChange(event) {

    setSelectedState(event.target.value);
    setSelectedCity('national');
    setValue('city', 'national');

  }

  function onCityChange(event) {

    setSelectedCity(event.target.value);

  }

  function onCategoryChange(event) {

    const category = event.target.value;

    filterOffers(category);

  }

  function filterOffers(category = null) {

    if (!category) {
      setFilteredOffers(offers);
      return false;
    }

    const offersFromCategory = offers.filter(offer => offer.category?.name === category);

    setFilteredOffers(offersFromCategory.length > 0 ? offersFromCategory : offers);

  }

  return <Wrapper
    className={`${css["organism__offers-panel-container"]} ${className}`}
    data-spacing-top={spacingTop}
    data-spacing-bottom={spacingBottom}
    {...other}
  >
    <Section tag="div">
      {states &&
        <div className={css['selectors-container']}>
          <FormSelect
            id='state'
            dataRegister={register('state')}
            label={t('label-selector-state')}
            onChange={onStateChange}
          >
            <option value='national'>{t('selector-state-national')}</option>
            {states.map((state, index) => {
              return (
                <option
                  value={state.name}
                  key={index}
                >
                  {state.name}
                </option>
              );
            })}
          </FormSelect>
          <FormSelect
            id='city'
            dataRegister={register('city')}
            label={t('label-selector-city')}
            onChange={onCityChange}
          >
            <option value='national'>{t('selector-city-national')}</option>
            {cities?.map((city, index) => {
              return (
                <option
                  value={city}
                  key={index}
                >
                  {city}
                </option>
              );
            })}

          </FormSelect>
          {offerCategories &&
            <FormSelect
              className={css['offers-region-form__select']}
              displayLabel={true}
              label={t('label-selector-segment')}
              onChange={onCategoryChange}
              dataRegister={register('category')}
            >
              <option value='all'>{t('selector-all')}</option>
              {offerCategories.map((category, index) => {
                return (
                  <option
                    value={category}
                    key={index}
                  >
                    {category}
                  </option>
                );
              })}
            </FormSelect>
        }
        </div>
      }

      {isLoading ?
        <Wrapper spacingTop={4} data-center-elements>
          <Loader />
        </Wrapper>
        :
        filteredOffers && filteredOffers.length > 0 ?
          <ul className={css['car-payment-list']} data-grid data-grid-list="3">
            {
              filteredOffers?.map((offer, index) => {
                return (
                  <OfferDetails
                    offer={offer}
                    key={index}
                    region={selectedRegion}
                  />
                );
              })
            }
          </ul>
          :
          <>
              <div className={css['offer-placeholder-container']} data-center-elements>
                <p className={css['offer-placeholder-container__message']}>{t('message-no-offers')}</p>
              </div>
          </>
      }
    </Section>
  </Wrapper>;
}

export default OffersPanel;
