export default {
    "Buenos Aires": [
        "25 de Mayo",
        "9 de Julio",
        "Adolfo Alsina",
        "Adolfo Gonzales Chaves",
        "Alberti",
        "Almirante Brown",
        "Arrecifes",
        "Avellaneda",
        "Ayacucho",
        "Azul",
        "Bahía Blanca",
        "Balcarce",
        "Baradero",
        "Benito Juárez",
        "Berazategui",
        "Berisso",
        "Bolívar",
        "Bragado",
        "Brandsen",
        "Campana",
        "Capitán Sarmiento",
        "Carlos Casares",
        "Carlos Tejedor",
        "Carmen de Areco",
        "Castelli",
        "Cañuelas",
        "Chacabuco",
        "Chascomús",
        "Chivilcoy",
        "Colón",
        "Coronel Dorrego",
        "Coronel Pringles",
        "Coronel Suárez",
        "Coronel de Marina Leonardo Rosales",
        "Daireaux",
        "Dolores",
        "Ensenada",
        "Escobar",
        "Esteban Echeverría",
        "Exaltación de la Cruz",
        "Ezeiza",
        "Florencio Varela",
        "Florentino Ameghino",
        "General Alvarado",
        "General Alvear",
        "General Arenales",
        "General Belgrano",
        "General Guido",
        "General Juan Madariaga",
        "General Lavalle",
        "General Paz",
        "General Pinto",
        "General Pueyrredón",
        "General Rodríguez",
        "General San Martín",
        "General Viamonte",
        "General Villegas",
        "General la Madrid",
        "General las Heras",
        "Guaminí",
        "Hipólito Yrigoyen",
        "Hurlingham",
        "Ituzaingó",
        "José C. Paz",
        "Junín",
        "La Costa",
        "La Matanza",
        "La Plata",
        "Lanús",
        "Laprida",
        "Las Flores",
        "Leandro N. Alem",
        "Lezama",
        "Lincoln",
        "Lobería",
        "Lobos",
        "Lomas de Zamora",
        "Luján",
        "Magdalena",
        "Maipú",
        "Malvinas Argentinas",
        "Mar Chiquita",
        "Marcos Paz",
        "Mercedes",
        "Merlo",
        "Monte",
        "Monte Hermoso",
        "Moreno",
        "Morón",
        "Navarro",
        "Necochea",
        "Olavarría",
        "Patagones",
        "Pehuajó",
        "Pellegrini",
        "Pergamino",
        "Pila",
        "Pilar",
        "Pinamar",
        "Presidente Perón",
        "Punta Indio",
        "Puán",
        "Quilmes",
        "Ramallo",
        "Rauch",
        "Rivadavia",
        "Rojas",
        "Roque Pérez",
        "Saavedra",
        "Saladillo",
        "Salliqueló",
        "Salto",
        "San Andrés de Giles",
        "San Antonio de Areco",
        "San Cayetano",
        "San Fernando",
        "San Isidro",
        "San Miguel",
        "San Nicolás",
        "San Pedro",
        "San Vicente",
        "Suipacha",
        "Tandil",
        "Tapalqué",
        "Tigre",
        "Tordillo",
        "Tornquist",
        "Trenque Lauquen",
        "Tres Arroyos",
        "Tres Lomas",
        "Tres de Febrero",
        "Vicente López",
        "Villa Gesell",
        "Villarino",
        "Zárate"
    ],
    "Catamarca": [
        "Aconquija",
        "Ancasti",
        "Andalgalá",
        "Antofagasta de la Sierra",
        "Belén",
        "Capayán",
        "Corral Quemado",
        "El Alto",
        "El Rodeo",
        "Fiambalá",
        "Fray Mamerto Esquiú",
        "Hualfín",
        "Huillapima",
        "Icaño",
        "La Puerta",
        "Las Juntas",
        "Londres",
        "Los Altos",
        "Los Varela",
        "Mutquín",
        "Paclín",
        "Pomán",
        "Pozo de Piedra",
        "Puerta de Corral Quemado",
        "Puerta de San José",
        "Recreo",
        "San Fernando",
        "San Fernando del Valle de Catamarca",
        "San José",
        "Santa María",
        "Santa Rosa",
        "Saujil",
        "Tapso",
        "Tinogasta",
        "Valle Viejo",
        "Villa Vil"
    ],
    "Chaco": [
        "Avia Terai",
        "Barranqueras",
        "Basail",
        "Campo Largo",
        "Capitán Solari",
        "Charadai",
        "Charata",
        "Chorotis",
        "Ciervo Petiso",
        "Colonia Benitez",
        "Colonia Popular",
        "Colonias Elisa",
        "Colonias Unidas",
        "Concepción del Bermejo",
        "Coronel Du Graty",
        "Corzuela",
        "Cote Lai",
        "El Sauzalito",
        "Enrique Urien",
        "Fontana",
        "Fuerte Esperanza",
        "Gancedo",
        "General Capdevila",
        "General Pinedo",
        "General Vedia",
        "Gral. San Martengeneral San Martín",
        "Hermoso Campo",
        "Isla del Cerrito",
        "Juan José Castelli",
        "La Clotilde",
        "La Eduvigis",
        "La Escondida",
        "La Leonesa",
        "La Tigra",
        "La Verde",
        "Laguna Blanca",
        "Laguna Limpia",
        "Lapachito",
        "Las Breñas",
        "Las Garcitas",
        "Las Palmas",
        "Los Frentones",
        "Machagai",
        "Makallé",
        "Margarita Belén",
        "Miraflores",
        "Misión Nueva Pompeya",
        "Napenay",
        "Pampa Almirón",
        "Pampa del Indio",
        "Pampa del Infierno",
        "Presidencia Roca",
        "Presidencia Roque Sáenz Peña",
        "Presidencia de la Plaza",
        "Puerto Bermejo",
        "Puerto Eva Perón",
        "Puerto Tirol",
        "Puerto Vilelas",
        "Quitilipi",
        "Resistencia",
        "Samuhú",
        "San Bernardo",
        "Santa Sylvina",
        "Taco Pozo",
        "Tres Isletas",
        "Villa Berthet",
        "Villa Río Bermejito",
        "Villa Ángela"
    ],
    "Chubut": [
        "28 de Julio",
        "Aldea Beleiro",
        "Aldea Epulef",
        "Alto Río Senguer",
        "Buen Pasto",
        "Camarones",
        "Carrenleufú",
        "Cerro Centinela",
        "Cholila",
        "Colan Conhué",
        "Comodoro Rivadavia",
        "Corcovado",
        "Cushamen",
        "Dique Florentino Ameghino",
        "Doctor Ricardo Rojas",
        "Dolavon",
        "Dr. Atilio Oscar Viglione",
        "El Hoyo",
        "El Maitén",
        "Epuyén",
        "Esquel",
        "Facundo",
        "Gaiman",
        "Gan Gan",
        "Gastre",
        "Gobernador Costa",
        "Gualjaina",
        "José de San Martín",
        "Lago Blanco",
        "Lago Puelo",
        "Lagunita Salada",
        "Las Plumas",
        "Los Altares",
        "Paso de Indios",
        "Paso del Sapo",
        "Puerto Madryn",
        "Puerto Pirámides",
        "Rada Tilly",
        "Rawson",
        "Río Mayo",
        "Río Pico",
        "Sarmiento",
        "Tecka",
        "Telsen",
        "Trelew",
        "Trevelín"
    ],
    "Corrientes": [
        "9 de Julio",
        "Alvear",
        "Bella Vista",
        "Berón de Astrada",
        "Bonpland",
        "Carolina",
        "Caá Catí",
        "Chavarría",
        "Colonia Carlos Pellegrini",
        "Colonia Libertad",
        "Colonia Liebig'S",
        "Colonia Pando",
        "Colonia Santa Rosa",
        "Concepción",
        "Corrientes",
        "Cruz de los Milagros",
        "Curuzú Cuatiá",
        "Empedrado",
        "Esquina",
        "Estación Torrent",
        "Felipe Yofré",
        "Garruchos",
        "Gobernador Ing. Valentín Virasoro",
        "Gobernador Martínez",
        "Goya",
        "Guaviraví",
        "Herlitzka",
        "Itatí",
        "Ituzaingó",
        "Itá Ibaté",
        "Jose Rafael Gomez",
        "Juan Pujol",
        "La Cruz",
        "Lavalle",
        "Lomas de Vallejos",
        "Loreto",
        "Mariano I. Loza",
        "Mburucuyá",
        "Mercedes",
        "Mocoretá",
        "Monte Caseros",
        "Palmar Grande",
        "Parada Pucheta",
        "Paso de la Patria",
        "Paso de los Libres",
        "Pedro R. Fernández",
        "Perugorría",
        "Pueblo Libertador",
        "Ramada Paso",
        "Riachuelo",
        "Saladas",
        "San Antonio",
        "San Carlos",
        "San Cosme",
        "San Isidro",
        "San Lorenzo",
        "San Luis del Palmar",
        "San Miguel",
        "San Roque",
        "Santa Ana",
        "Santa Lucía",
        "Santo Tomé",
        "Sauce",
        "Tabay",
        "Tapebicuá",
        "Tatacuá",
        "Villa Olivari",
        "Yapeyú",
        "Yataytí Calle"
    ],
    "Córdoba": [
        "Achiras",
        "Adelia María",
        "Agua de Oro",
        "Alcira",
        "Aldea Santa María",
        "Alejandro Roca",
        "Alejo Ledesma",
        "Alicia",
        "Almafuerte",
        "Alpa Corral",
        "Alta Gracia",
        "Alto Alegre",
        "Alto de los Quebrachos",
        "Altos de Chipión",
        "Amboy",
        "Ambul",
        "Ana Zumarán",
        "Anisacate",
        "Arias",
        "Arroyito",
        "Arroyo Cabral",
        "Arroyo los Patos",
        "Assunta",
        "Atahona",
        "Ausonia",
        "Avellaneda",
        "Ballesteros",
        "Ballesteros Sud",
        "Balnearia",
        "Bañado de Soto",
        "Bell Ville",
        "Bengolea",
        "Benjamín Gould",
        "Berrotarán",
        "Bialet Massé",
        "Bouwer",
        "Brinkmann",
        "Buchardo",
        "Bulnes",
        "Cabalango",
        "Calchín",
        "Calchín Oeste",
        "Calmayo",
        "Camilo Aldao",
        "Caminiaga",
        "Canals",
        "Candelaria Sud",
        "Capilla de los Remedios",
        "Capilla del Carmen",
        "Capilla del Monte",
        "Capilla del Sitón",
        "Capitán General B. O'Higgins",
        "Carnerillo",
        "Carrilobo",
        "Casa Grande",
        "Cavanagh",
        "Cañada de Luque",
        "Cañada de Machado",
        "Cañada de Río Pinto",
        "Cañada del Sauce",
        "Cerro Colorado",
        "Chaján",
        "Chalacea",
        "Chancaní",
        "Charbonier",
        "Charras",
        "Chazón",
        "Chañar Viejo",
        "Chilibroste",
        "Chucul",
        "Churqui Cañada",
        "Chuña",
        "Chuña Huasi",
        "Cintra",
        "Ciénaga del Coro",
        "Colazo",
        "Colonia Almada",
        "Colonia Anita",
        "Colonia Barge",
        "Colonia Bismarck",
        "Colonia Bremen",
        "Colonia Caroya",
        "Colonia Italiana",
        "Colonia Iturraspe",
        "Colonia Marina",
        "Colonia Prosperidad",
        "Colonia San Bartolomé",
        "Colonia San Pedro",
        "Colonia Tirolesa",
        "Colonia Valtelina",
        "Colonia Vicente Agüero",
        "Colonia Videla",
        "Colonia Vignaud",
        "Colonia las Cuatro Esquinas",
        "Colonia las Pichanas",
        "Comechingones",
        "Conlara",
        "Copacabana",
        "Coronel Baigorria",
        "Coronel Moldes",
        "Corral de Bustos",
        "Corralito",
        "Cosquín",
        "Costasacate",
        "Cruz Alta",
        "Cruz de Caña",
        "Cruz del Eje",
        "Cuesta Blanca",
        "Córdoba",
        "Dalmacio Vélez",
        "Del Campillo",
        "Despeñaderos",
        "Devoto",
        "Deán Funes",
        "Diego de Rojas",
        "Dique Chico",
        "El Arañado",
        "El Brete",
        "El Chacho",
        "El Crispín",
        "El Fortín",
        "El Manzano",
        "El Rastreador",
        "El Rodeo",
        "El Tío",
        "Elena",
        "Embalse",
        "Esquina",
        "Estación General Paz",
        "Estación Juárez Celman",
        "Estancia Vieja",
        "Estancia de Guadalupe",
        "Etruria",
        "Eufrasio Loza",
        "Falda del Carmen",
        "Freyre",
        "General Baldissera",
        "General Cabrera",
        "General Deheza",
        "General Fotheringham",
        "General Levalle",
        "General Roca",
        "Guanaco Muerto",
        "Guasapampa",
        "Guatimozín",
        "Gütemberg",
        "Hernando",
        "Huanchilla",
        "Huerta Grande",
        "Huinca Renancó",
        "Idiazabal",
        "Impira",
        "Inriville",
        "Isla Verde",
        "Italó",
        "James Craik",
        "Jesús María",
        "Jovita",
        "Justiniano Posse",
        "Kilómetro 658",
        "La Batea",
        "La Calera",
        "La Carlota",
        "La Carolina \"El Potosi\"",
        "La Cautiva",
        "La Cesira",
        "La Cruz",
        "La Cumbre",
        "La Cumbrecita",
        "La Falda",
        "La Francia",
        "La Granja",
        "La Higuera",
        "La Laguna",
        "La Paisanita",
        "La Palestina",
        "La Pampa",
        "La Paquita",
        "La Para",
        "La Paz",
        "La Playa",
        "La Playosa",
        "La Población",
        "La Posta",
        "La Puerta",
        "La Quinta",
        "La Rancherita",
        "La Rinconada",
        "La Serranita",
        "La Tordilla",
        "Laborde",
        "Laboulaye",
        "Laguna Larga",
        "Las Acequias",
        "Las Albahacas",
        "Las Arrias",
        "Las Bajadas",
        "Las Caleras",
        "Las Calles",
        "Las Cañadas",
        "Las Gramillas",
        "Las Higueras",
        "Las Isletillas",
        "Las Junturas",
        "Las Palmas",
        "Las Perdices",
        "Las Peñas",
        "Las Peñas Sud",
        "Las Playas",
        "Las Rabonas",
        "Las Saladas",
        "Las Tapias",
        "Las Varas",
        "Las Varillas",
        "Las Vertientes",
        "Leguizamón",
        "Leones",
        "Los Cedros",
        "Los Cerrillos",
        "Los Chañaritos",
        "Los Chañaritos (C.D. Eje)",
        "Los Cisnes",
        "Los Cocos",
        "Los Cóndores",
        "Los Hornillos",
        "Los Hoyos",
        "Los Mistoles",
        "Los Molinos",
        "Los Pozos",
        "Los Reartes",
        "Los Surgentes",
        "Los Talares",
        "Los Zorros",
        "Lozada",
        "Luca",
        "Lucio V. Mansilla",
        "Luque",
        "Lutti",
        "Luyaba",
        "Malagueño",
        "Malena",
        "Malvinas Argentinas",
        "Manfredi",
        "Maquinista Gallini",
        "Marcos Juárez",
        "Marull",
        "Matorrales",
        "Mattaldi",
        "Mayu Sumaj",
        "Media Naranja",
        "Melo",
        "Mendiolaza",
        "Mi Granja",
        "Mina Clavero",
        "Miramar",
        "Monte Buey",
        "Monte Cristo",
        "Monte Leña",
        "Monte Maíz",
        "Monte Ralo",
        "Monte de los Gauchos",
        "Morrison",
        "Morteros",
        "Nicolás Bruzzone",
        "Noetinger",
        "Nono",
        "Obispo Trejo",
        "Olaeta",
        "Oliva",
        "Olivares de San Nicolás",
        "Onagoity",
        "Oncativo",
        "Ordóñez",
        "Pacheco de Melo",
        "Pampayasta Norte",
        "Pampayasta Sud",
        "Panaholma",
        "Pascanas",
        "Pasco",
        "Paso Viejo",
        "Paso del Durazno",
        "Pilar",
        "Pincen",
        "Piquillín",
        "Plaza Luxardo",
        "Plaza de Mercedes",
        "Porteña",
        "Potrero de Garay",
        "Pozo Nuevo",
        "Pozo del Molle",
        "Pueblo Italiano",
        "Puesto de Castro",
        "Punta del Agua",
        "Quebracho Herrado",
        "Quilino",
        "Rafael García",
        "Ranqueles",
        "Rayo Cortado",
        "Reducción",
        "Rincón",
        "Rosales",
        "Rosario del Saladillo",
        "Río Bamba",
        "Río Ceballos",
        "Río Cuarto",
        "Río Primero",
        "Río Segundo",
        "Río Tercero",
        "Río de los Sauces",
        "Sacanta",
        "Sagrada Familia",
        "Saira",
        "Saladillo",
        "Saldán",
        "Salsacate",
        "Salsipuedes",
        "Sampacho",
        "San Agustín",
        "San Antonio de Arredondo",
        "San Antonio de Litín",
        "San Basilio",
        "San Carlos Minas",
        "San Clemente",
        "San Esteban",
        "San Francisco",
        "San Francisco del Chañar",
        "San Gerónimo",
        "San Ignacio",
        "San Javier y Yacanto",
        "San Joaquín",
        "San José",
        "San José de la Dormida",
        "San José de las Salinas",
        "San Lorenzo",
        "San Marcos Sierras",
        "San Marcos Sud",
        "San Pedro",
        "San Pedro Norte",
        "San Roque",
        "San Vicente",
        "Santa Catalina",
        "Santa Elena",
        "Santa Eufemia",
        "Santa María de Punilla",
        "Santa Rosa de Calamuchita",
        "Santa Rosa de Río Primero",
        "Santiago Temple",
        "Sarmiento",
        "Saturnino María Laspiur",
        "Sauce Arriba",
        "Sebastián Elcano",
        "Seeber",
        "Segunda Usina",
        "Serrano",
        "Serrezuela",
        "Silvio Pellico",
        "Simbolar",
        "Sinsacate",
        "Suco",
        "Tala Cañada",
        "Tala Huasi",
        "Talaini",
        "Tancacha",
        "Tanti",
        "Ticino",
        "Tinoco",
        "Toledo",
        "Toro Pujio",
        "Tosno",
        "Tosquita",
        "Tránsito",
        "Tuclame",
        "Tío Pujio",
        "Ucacha",
        "Unquillo",
        "Valle Hermoso",
        "Valle de Anisacate",
        "Viamonte",
        "Vicuña Mackenna",
        "Villa Allende",
        "Villa Amancay",
        "Villa Ascasubi",
        "Villa Candelaria Norte",
        "Villa Carlos Paz",
        "Villa Cerro Azul",
        "Villa Ciudad Parque los Reartes",
        "Villa Ciudad de América",
        "Villa Concepción del Tío",
        "Villa Cura Brochero",
        "Villa Dolores",
        "Villa Elisa",
        "Villa Fontana",
        "Villa General Belgrano",
        "Villa Giardino",
        "Villa Gutiérrez",
        "Villa Huidobro",
        "Villa María",
        "Villa Nueva",
        "Villa Parque Santa Ana",
        "Villa Parque Siquiman",
        "Villa Quillinzo",
        "Villa Rossi",
        "Villa Rumipal",
        "Villa Río Icho Cruz",
        "Villa San Esteban",
        "Villa San Isidro",
        "Villa Santa Cruz del Lago",
        "Villa Sarmiento (G.R.)",
        "Villa Sarmiento (S. A.)",
        "Villa Tulumba",
        "Villa Valeria",
        "Villa Yacanto",
        "Villa de María",
        "Villa de Pocho",
        "Villa de Soto",
        "Villa de las Rosas",
        "Villa del Dique",
        "Villa del Prado",
        "Villa del Rosario",
        "Villa del Totoral",
        "Villa el Chacay",
        "Villa la Bolsa",
        "Villa los Aromos",
        "Villa los Patos",
        "Washington",
        "Wenceslao Escalante"
    ],
    "Formosa": [
        "Buena Vista",
        "Clorinda",
        "Colonia Pastoril",
        "Comandante Fontana",
        "El Colorado",
        "Espinillo",
        "Estanislao del Campo",
        "Formosa",
        "Fortín Lugones",
        "General Belgrano",
        "General Güemes",
        "General Lucio Victorio Mansilla",
        "General Mosconi",
        "Gran Guardia",
        "Herradura",
        "Ibarreta",
        "Ingeniero Guillermo N. Juárez",
        "Laguna Blanca",
        "Laguna Naik Neck",
        "Laguna Yema",
        "Las Lomitas",
        "Los Chiriguanos",
        "Mayor Vicente E. Villafañe",
        "Misión San Francisco de Laishí",
        "Misión Taacaglé",
        "Palo Santo",
        "Pirané",
        "Portón Negro",
        "Pozo del Tigre",
        "Riacho He-He",
        "San Hilario",
        "San Martín Dos",
        "Siete Palmas",
        "Subteniente Perín",
        "Tres Lagunas",
        "Villa Dos Trece",
        "Villa Escolar"
    ],
    "Jujuy": [
        "Abdón Castro Tolay",
        "Abra Pampa",
        "Abralaite",
        "Aguas Calientes",
        "Arrayanal",
        "Barrios",
        "Caimancito",
        "Calilegua",
        "Cangrejillos",
        "Caspalá",
        "Catua",
        "Cieneguillas",
        "Coranzuli",
        "Cusi Cusi",
        "El Aguilar",
        "El Carmen",
        "El Cóndor",
        "El Fuerte",
        "El Piquete",
        "El Talar",
        "Fraile Pintado",
        "Hipólito Yrigoyen",
        "Huacalera",
        "Humahuaca",
        "La Esperanza",
        "La Mendieta",
        "La Quiaca",
        "Libertador General San Martín",
        "Maimará",
        "Mina Pirquitas",
        "Monterrico",
        "Palma Sola",
        "Palpalá",
        "Pampa Blanca",
        "Pampichuela",
        "Perico",
        "Puesto Viejo",
        "Puesto del Marqués",
        "Pumahuasi",
        "Purmamarca",
        "Rinconada",
        "Rodeíto",
        "Rosario de Río Grande",
        "San Antonio",
        "San Francisco",
        "San Pedro de Jujuy",
        "San Salvador de Jujuy",
        "Santa Ana",
        "Santa Catalina",
        "Santa Clara",
        "Susques",
        "Tilcara",
        "Tres Cruces",
        "Tumbaya",
        "Valle Grande",
        "Vinalito",
        "Volcán",
        "Yala",
        "Yavi",
        "Yuto"
    ],
    "La Pampa": [
        "Abramo",
        "Adolfo Van Praet",
        "Agustoni",
        "Algarrobo del Águila",
        "Alpachiri",
        "Alta Italia",
        "Anguil",
        "Arata",
        "Ataliva Roca",
        "Bernardo Larroude",
        "Bernasconi",
        "Caleufú",
        "Carro Quemado",
        "Casa de Piedra",
        "Catriló",
        "Ceballos",
        "Chacharramendi",
        "Colonia Barón",
        "Colonia Santa María",
        "Conhelo",
        "Coronel Hilario Lagos",
        "Cuchillo Có",
        "Doblas",
        "Dorila",
        "Eduardo Castex",
        "Embajador Martini",
        "Falucho",
        "General Acha",
        "General Campos",
        "General Pico",
        "General San Martín",
        "Gobernador Duval",
        "Guatraché",
        "Ingeniero Luiggi",
        "Intendente Alvear",
        "Jacinto Arauz",
        "La Adela",
        "La Humada",
        "La Maruja",
        "La Reforma",
        "Limay Mahuida",
        "Lonquimay",
        "Loventué",
        "Luan Toro",
        "Macachín",
        "Maisonnave",
        "Mauricio Mayer",
        "Metileo",
        "Miguel Cané",
        "Miguel Riglos",
        "Monte Nievas",
        "Parera",
        "Perú",
        "Pichi Huinca",
        "Puelches",
        "Puelén",
        "Quehué",
        "Quemú Quemú",
        "Quetrequén",
        "Rancul",
        "Realicó",
        "Relmo",
        "Rolón",
        "Rucanelo",
        "Santa Isabel",
        "Santa Rosa",
        "Santa Teresa",
        "Sarah",
        "Speluzzi",
        "Telén",
        "Toay",
        "Tomás Manuel Anchorena",
        "Trenel",
        "Unanue",
        "Uriburu",
        "Veinticinco de Mayo",
        "Victorica",
        "Villa Mirasol",
        "Vértiz",
        "Winifreda"
    ],
    "La Rioja": [
        "Arauco",
        "Capital",
        "Castro Barros",
        "Chamical",
        "Chilecito",
        "Coronel Felipe Varela",
        "Famatina",
        "General Belgrano",
        "General Juan Facundo Quiroga",
        "General Lamadrid",
        "General Ocampo",
        "General San Martín",
        "General Ángel Vicente Peñaloza",
        "Independencia",
        "Rosario Vera Peñaloza",
        "San Blas de los Sauces",
        "Sanagasta",
        "Vinchina"
    ],
    "Mendoza": [
        "Capital",
        "General Alvear",
        "Godoy Cruz",
        "Guaymallén",
        "Junín",
        "La Paz",
        "Las Heras",
        "Lavalle",
        "Luján de Cuyo",
        "Maipú",
        "Malargüe",
        "Rivadavia",
        "San Carlos",
        "San Martín",
        "San Rafael",
        "Santa Rosa",
        "Tunuyán",
        "Tupungato"
    ],
    "Misiones": [
        "25 de Mayo",
        "9 de Julio",
        "Alba Posse",
        "Almafuerte",
        "Ameghino",
        "Apóstoles",
        "Aristóbulo del Valle",
        "Arroyo del Medio",
        "Azara",
        "Bernardo de Irigoyen",
        "Bonpland",
        "Caa Yarí",
        "Campo Grande",
        "Campo Ramón",
        "Campo Viera",
        "Candelaria",
        "Capioví",
        "Caraguatay",
        "Cerro Azul",
        "Cerro Corá",
        "Colonia Alberdi",
        "Colonia Aurora",
        "Colonia Delicia",
        "Colonia Polana",
        "Colonia Victoria",
        "Colonia Wanda",
        "Comandante A. Guacurary",
        "Concepción de la Sierra",
        "Corpus",
        "Dos Arroyos",
        "Dos de Mayo",
        "El Alcázar",
        "El Soberbio",
        "Eldorado",
        "Fachinal",
        "Garuhapé",
        "Garupá",
        "General J. J. Urquiza",
        "Gobernador López",
        "Gobernador Roca",
        "Gral. Alvear",
        "Guaraní",
        "Hipólito Irigoyen",
        "Iguazú",
        "Itacaruaré",
        "Jardín América",
        "Leandro N. Alem",
        "Leoni",
        "Libertad",
        "Loreto",
        "Los Helechos",
        "Mojón Grande",
        "Montecarlo",
        "Mártires",
        "Oberá",
        "Olegario V. Andrade",
        "Panambí",
        "Posadas",
        "Profundidad",
        "Puerto Esperanza",
        "Puerto Piray",
        "Puerto Rico",
        "Ruiz de Montoya",
        "San Antonio",
        "San Ignacio",
        "San Javier",
        "San José",
        "San Martín",
        "San Pedro",
        "San Vicente",
        "Santa Ana",
        "Santa María",
        "Santiago de Liniers",
        "Santo Pipo",
        "Tres Capones"
    ],
    "Neuquén": [
        "Aguada San Roque",
        "Aluminé",
        "Andacollo",
        "Añelo",
        "Bajada del Agrio",
        "Barrancas",
        "Buta Ranquil",
        "Caviahue-Copahue",
        "Centenario",
        "Chorriaca",
        "Chos Malal",
        "Covunco Abajo",
        "Coyuco-Cochico",
        "Cutral Có",
        "El Cholar",
        "El Huecú",
        "El Sauce",
        "Guañacos",
        "Huinganco",
        "Junín de los Andes",
        "Las Coloradas",
        "Las Lajas",
        "Las Ovejas",
        "Loncopué",
        "Los Catutos",
        "Los Chihuidos",
        "Los Miches",
        "Manzano Amargo",
        "Mariano Moreno",
        "Neuquén",
        "Octavio Pico",
        "Paso Aguerre",
        "Picún Leufú",
        "Piedra del Águila",
        "Pilo Lil",
        "Plaza Huincul",
        "Plottier",
        "Quili Malal",
        "Ramón Castro",
        "Rincón de los Sauces",
        "San Martín de los Andes",
        "San Patricio del Chañar",
        "Santo Tomás",
        "Sauzal Bonito",
        "Senillosa",
        "Taquimilán",
        "Tricao Malal",
        "Varvarco - Invernada Vieja",
        "Villa Curi Leuvú",
        "Villa Pehuenia",
        "Villa Traful",
        "Villa del Nahueve",
        "Villa del Puente Picún Leufú",
        "Villa el Chocón",
        "Villa la Angostura",
        "Vista Alegre",
        "Zapala"
    ],
    "Río Negro": [
        "Aguada Cecilio",
        "Aguada Guzman",
        "Aguada de Guerra",
        "Allen",
        "Arroyo Ventana",
        "Arroyo los Berros",
        "Campo Grande",
        "Catriel",
        "Cervantes",
        "Chichinales",
        "Chimpay",
        "Chipauquil",
        "Choele Choel",
        "Cinco Saltos",
        "Cipolletti",
        "Clemente Onelli",
        "Colan Conhué",
        "Comallo",
        "Comico",
        "Cona Niyeu",
        "Contralmirante Cordero",
        "Coronel Belisle",
        "Darwin",
        "Dina Huapi",
        "El Bolson",
        "El Cain",
        "El Cuy",
        "El Manso",
        "Gral. Conesa",
        "Gral. Enrique Godoy",
        "Gral. Fdez. Oro",
        "Gral. Roca",
        "Guardia Mitre",
        "Ing. Jacobacci",
        "Ingeniero Huergo",
        "Laguna Blanca",
        "Lamarque",
        "Los Menucos",
        "Luis Beltran",
        "Mainque",
        "Mamuel Choique",
        "Maquinchao",
        "Mencuen",
        "Ministro Ramos Mexia",
        "Nahuel Niyeu",
        "Naupa Huen",
        "Ojos de Agua",
        "Paso Flores",
        "Peñas Blancas",
        "Pilcaniyeu",
        "Pilquiniyeu",
        "Pilquiniyeu del Limay",
        "Pomona",
        "Prahuaniyeu",
        "Rincon de Treneta",
        "Rio Chico",
        "Rìo Colorado",
        "San Antonio Oeste",
        "San Carlos de Bariloche",
        "San Javier",
        "Sierra Colorada",
        "Sierra Grande",
        "Sierra Paileman",
        "Valcheta",
        "Valle Azul",
        "Viedma",
        "Villa Llanquin",
        "Villa Regina",
        "Yaminué",
        "Ñorquinco"
    ],
    "Salta": [
        "Aguaray",
        "Angastaco",
        "Animaná",
        "Apolinario Saravia",
        "Cachi",
        "Cafayate",
        "Campo Quijano",
        "Campo Santo",
        "Cerrillos",
        "Chicoana",
        "Colonia Santa Rosa",
        "Coronel Moldes",
        "El Bordo",
        "El Carril",
        "El Galpón",
        "El Jardín",
        "El Potrero",
        "El Quebrachal",
        "El Tala",
        "Embarcación",
        "General Ballivián",
        "General Güemes",
        "General Mosconi",
        "General Pizarro",
        "Guachipas",
        "Hipólito Yrigoyen",
        "Iruya",
        "Isla de Cañas",
        "Joaquín V. González",
        "La Caldera",
        "La Candelaria",
        "La Merced",
        "La Poma",
        "La Viña",
        "Las Lajitas",
        "Los Toldos",
        "Metán",
        "Molinos",
        "Nazareno",
        "Payogasta",
        "Pichanal",
        "Profesor Salvador Mazza",
        "Rivadavia Banda Norte",
        "Rivadavia Banda Sur",
        "Rosario de Lerma",
        "Rosario de la Frontera",
        "Río Piedras",
        "Salta",
        "San Antonio de los Cobres",
        "San Carlos",
        "San Lorenzo",
        "San Ramón de la Nueva Orán",
        "Santa Victoria Este",
        "Santa Victoria Oeste",
        "Seclantás",
        "Tartagal",
        "Tolar Grande",
        "Urundel",
        "Vaqueros"
    ],
    "San Juan": [
        "25 de Mayo",
        "9 de Julio",
        "Albardón",
        "Angaco",
        "Calingasta",
        "Caucete",
        "Chimbas",
        "Iglesia",
        "Jáchal",
        "Pocito",
        "Rawson",
        "Rivadavia",
        "San Juan",
        "San Martín",
        "Santa Lucía",
        "Sarmiento",
        "Ullum",
        "Valle Fértil",
        "Zonda"
    ],
    "San Luis": [
        "Alto Pelado",
        "Alto Pencoso",
        "Anchorena",
        "Arizona",
        "Bagual",
        "Balde",
        "Batavia",
        "Beazley",
        "Buena Esperanza",
        "Candelaria",
        "Carolina",
        "Carpinteria",
        "Concaran",
        "Cortaderas",
        "Desaguadero",
        "El Trapiche",
        "El Volcan",
        "Estancia Grande",
        "Fortin el Patria",
        "Fortuna",
        "Fraga",
        "Juan Jorba",
        "Juan Llerena",
        "Juana Koslay",
        "Justo Daract",
        "La Calera",
        "La Punilla",
        "La Punta",
        "La Toma",
        "Lafinur",
        "Las Aguadas",
        "Las Chacras",
        "Las Lagunas",
        "Las Vertientes",
        "Lavaisse",
        "Leandro N. Alem",
        "Los Molles",
        "Lujan",
        "Merlo",
        "Naschel",
        "Navia",
        "Nogoli",
        "Nueva Galia",
        "P. de los Funes",
        "Papagayos",
        "Paso Grande",
        "Quines",
        "Ranqueles",
        "Renca",
        "Saladillo",
        "San Francisco",
        "San Jeronimo",
        "San Jose del Morro",
        "San Luis",
        "San Martin",
        "San Pablo",
        "Sta Rosa del Conlara",
        "Talita",
        "Tilisarao",
        "Union",
        "V, Gral, Roca",
        "V, de Praga",
        "V, de la Quebrada",
        "Villa Larca",
        "Villa Mercedes",
        "Villa del Carmen",
        "Zanjitas"
    ],
    "Santa Fe": [
        "Aarón Castellanos",
        "Acebal",
        "Aguará Grande",
        "Albarellos",
        "Alcorta",
        "Aldao",
        "Alejandra",
        "Alvear",
        "Ambrosetti",
        "Amenábar",
        "Angeloni",
        "Angélica",
        "Arequito",
        "Arminda",
        "Armstrong",
        "Arocena",
        "Arroyo Aguiar",
        "Arroyo Ceibal",
        "Arroyo Leyes",
        "Arroyo Seco",
        "Arrufo",
        "Arteaga",
        "Ataliva",
        "Aurelia",
        "Avellaneda",
        "Barrancas",
        "Bauer y Sigel",
        "Bella Italia",
        "Berabevú",
        "Berna",
        "Bernardo de Irigoyen",
        "Bigand",
        "Bombal",
        "Bouquet",
        "Bustinza",
        "Cabal",
        "Cacique Ariacaiquín",
        "Cafferata",
        "Calchaquí",
        "Campo Andino",
        "Campo Piaggio",
        "Candioti",
        "Capitán Bermúdez",
        "Capivara",
        "Carcarañá",
        "Carlos Pellegrini",
        "Carmen",
        "Carmen del Sauce",
        "Carreras",
        "Carrizales",
        "Casalegno",
        "Casas",
        "Casilda",
        "Castelar",
        "Castellanos",
        "Cavour",
        "Cayastacito",
        "Cayastá",
        "Cañada Ombú",
        "Cañada Rica",
        "Cañada Rosquín",
        "Cañada de Gómez",
        "Cañada del Ucle",
        "Centeno",
        "Cepeda",
        "Ceres",
        "Chabas",
        "Chapuy",
        "Chañar Ladeado",
        "Chovet",
        "Christophersen",
        "Clason",
        "Colonia Aldao",
        "Colonia Ana",
        "Colonia Belgrano",
        "Colonia Bicha",
        "Colonia Bigand",
        "Colonia Bossi",
        "Colonia Cello",
        "Colonia Dolores",
        "Colonia Durán",
        "Colonia Iturraspe",
        "Colonia Margarita",
        "Colonia Mascias",
        "Colonia Raquel",
        "Colonia Rosa",
        "Colonia San José",
        "Colonia la Clara",
        "Constanza",
        "Coronda",
        "Coronel Arnold",
        "Coronel Bogado",
        "Coronel Domínguez",
        "Coronel Fraga",
        "Correa",
        "Crispi",
        "Cululú",
        "Curupaity",
        "Desvío Arijón",
        "Diego de Alvear",
        "Dos Rosas y la Legua",
        "Díaz",
        "Egusquiza",
        "El Arazá",
        "El Rabón",
        "El Sombrerito",
        "El Trébol",
        "Elisa",
        "Elortondo",
        "Emilia",
        "Empalme San Carlos",
        "Empalme Villa Constitución",
        "Esmeralda",
        "Esperanza",
        "Estación Clucellas",
        "Esteban Rams",
        "Esther",
        "Eusebia y Carolina",
        "Eustolia",
        "Felicia",
        "Fidela",
        "Fighiera",
        "Firmat",
        "Florencia",
        "Fortín Olmos",
        "Franck",
        "Fray Luis Beltrán",
        "Frontera",
        "Fuentes",
        "Funes",
        "Gaboto",
        "Galisteo",
        "Garabato",
        "Garibaldi",
        "Gato Colorado",
        "General Gelly",
        "General Lagos",
        "Gessler",
        "Gobernador Crespo",
        "Godoy",
        "Golondrina",
        "Granadero Baigorria",
        "Gregoria Pérez de Denis",
        "Grutly",
        "Guadalupe Norte",
        "Gálvez",
        "Gödeken",
        "Helvecia",
        "Hersilia",
        "Hipatía",
        "Huanqueros",
        "Hugentobler",
        "Hughes",
        "Humberto Primo",
        "Humboldt",
        "Ibarlucea",
        "Ingeniero Chanourdie",
        "Intiyaco",
        "Ituzaingó",
        "Jacinto L. Arauz",
        "Josefina",
        "Juan Bernabé Molina",
        "Juan de Garay",
        "Juncal",
        "La Brava",
        "La Cabral",
        "La Camila",
        "La Chispa",
        "La Criolla",
        "La Gallareta",
        "La Lucila",
        "La Pelada",
        "La Penca y Caraguatá",
        "La Rubia",
        "La Sarita",
        "La Vanguardia",
        "Labordeboy",
        "Laguna Paiva",
        "Landeta",
        "Lanteri",
        "Larrechea",
        "Las Avispas",
        "Las Bandurrias",
        "Las Garzas",
        "Las Palmeras",
        "Las Parejas",
        "Las Petacas",
        "Las Rosas",
        "Las Toscas",
        "Las Tunas",
        "Lazzarino",
        "Lehmann",
        "Llambi Campbell",
        "Logroño",
        "Loma Alta",
        "Los Amores",
        "Los Cardos",
        "Los Laureles",
        "Los Molinos",
        "Los Quirquinchos",
        "Lucio V. López",
        "Luis Palacios",
        "López",
        "M9Ximo Paz",
        "Maciel",
        "Maggiolo",
        "Malabrigo",
        "Marcelino Escalada",
        "Margarita",
        "María Juana",
        "María Luisa",
        "María Susana",
        "María Teresa",
        "Matilde",
        "Maua",
        "Melincué",
        "Miguel Torres",
        "Moisés Ville",
        "Monigotes",
        "Monje",
        "Monte Oscuridad",
        "Monte Vera",
        "Montefiore",
        "Montes de Oca",
        "Murphy",
        "Naré",
        "Nelson",
        "Nicanor Molinas",
        "Nuevo Torino",
        "Oliveros",
        "Palacios",
        "Pavón",
        "Pavón Arriba",
        "Pedro Gómez Cello",
        "Peyrano",
        "Piamonte",
        "Pilar",
        "Piñero",
        "Plaza Clucellas",
        "Portugalete",
        "Pozo Borrado",
        "Presidente Roca",
        "Progreso",
        "Providencia",
        "Pueblo Andino",
        "Pueblo Esther",
        "Pueblo Irigoyen",
        "Pueblo Marini",
        "Pueblo Muñoz",
        "Pujato",
        "Pujato Norte",
        "Pérez",
        "Rafaela",
        "Ramayón",
        "Ramona",
        "Reconquista",
        "Recreo",
        "Ricardone",
        "Rivadavia",
        "Roldán",
        "Romang",
        "Rueda",
        "Rufino",
        "Sa Pereyra",
        "Saguier",
        "Saladero Mariano Cabal",
        "Salto Grande",
        "San Agustín",
        "San Antonio",
        "San Antonio de Obligado",
        "San Bernardo",
        "San Bernardo",
        "San Carlos Centro",
        "San Carlos Norte",
        "San Carlos Sud",
        "San Cristóbal",
        "San Eduardo",
        "San Eugenio",
        "San Fabián",
        "San Francisco de Santa Fe",
        "San Genaro",
        "San Gregorio",
        "San Guillermo",
        "San Javier",
        "San Jerónimo Norte",
        "San Jerónimo Sud",
        "San Jerónimo del Sauce",
        "San Jorge",
        "San José de la Esquina",
        "San José del Rincón",
        "San Justo",
        "San Lorenzo",
        "San Mariano",
        "San Mart",
        "San Mart",
        "San Vicente",
        "Sancti Spiritu",
        "Sanford",
        "Santa Clara de Buena Vista",
        "Santa Clara de Saguier",
        "Santa Fe",
        "Santa Isabel",
        "Santa Margarita",
        "Santa María Centro",
        "Santa María Norte",
        "Santa Rosa de Calchines",
        "Santa Teresa",
        "Santo Domingo",
        "Santo Tomé",
        "Santurce",
        "Sargento Cabral",
        "Sarmiento",
        "Sastre",
        "Sauce Viejo",
        "Serodino",
        "Silva",
        "Soldini",
        "Soledad",
        "Soutomayor",
        "Suardi",
        "Sunchales",
        "Susana",
        "Tacuarendí",
        "Tacural",
        "Tacurales",
        "Tartagal",
        "Teodelina",
        "Theobald",
        "Timbúes",
        "Toba",
        "Tortugas",
        "Tostado",
        "Totoras",
        "Traill",
        "Uranga",
        "Venado Tuerto",
        "Vera",
        "Vera y Pintado",
        "Videla",
        "Vila",
        "Villa Amelia",
        "Villa Ana",
        "Villa Cañás",
        "Villa Constitución",
        "Villa Eloísa",
        "Villa Gobernador Gálvez",
        "Villa Guillermina",
        "Villa Minetti",
        "Villa Mugueta",
        "Villa San José",
        "Villa Saralegui",
        "Villa Trinidad",
        "Villada",
        "Virginia",
        "Wheelwright",
        "Zavalla",
        "Zenón Pereyra",
        "Álvarez",
        "Ñanducita"
    ],
    "Tierra del Fuego, Antártida e Islas del Atlántico Sur": [
        "Río Grande",
        "Tolhuin",
        "Ushuaia"
    ],
    "Tucumán": [
        "7 de Abril",
        "Acheral",
        "Agua Dulce y la Soledad",
        "Alpachiri y el Molino",
        "Alto Verde y los Guchea",
        "Amaicha del Valle",
        "Amberes",
        "Ancajuli",
        "Arcadia",
        "Atahona",
        "Benjamin Araoz y el Tajamar",
        "Buena Vista",
        "Capitan Caceres",
        "Cevil Redondo",
        "Choromoro",
        "Ciudacita",
        "Colalao del Valle",
        "Colombres",
        "Comuna Villa Belgrano",
        "Delfin Gallo",
        "El Bracho y el Cevilar",
        "El Cadillal",
        "El Cercado",
        "El Chañar",
        "El Manantial",
        "El Mojon",
        "El Mollar",
        "El Naranjito",
        "El Naranjo y el Sunchal",
        "El Polear",
        "El Puestito",
        "El Sacrificio",
        "El Timbo",
        "Escaba",
        "Esquina y Mancopa",
        "Estacion Araoz y Tacanas",
        "Garmendia",
        "Gastona y Belicha",
        "Gdor. Piedrabuena",
        "Huasa Pampa",
        "La Esperanza",
        "La Florida y Luisiana",
        "La Ramada y la Cruz",
        "Lamadrid",
        "Las Cejas",
        "Las Talas",
        "Leon Rouges y Santa Rosa",
        "Los Bulacios y los Villagras",
        "Los Gomez",
        "Los Nogales",
        "Los Pereyras",
        "Los Perez",
        "Los Puestos",
        "Los Ralos",
        "Los Sarmiento y la Tipa",
        "Los Sosa",
        "Manuel Garcia Fernandez",
        "Manuela Pedraza",
        "Medina",
        "Monte Bello",
        "Monteagudo",
        "Municipalidad de Aguilares",
        "Municipalidad de Alderetes",
        "Municipalidad de Banda del Rio Sali",
        "Municipalidad de Bella Vista",
        "Municipalidad de Burruyacu",
        "Municipalidad de Concepcion",
        "Municipalidad de Famailla",
        "Municipalidad de Graneros",
        "Municipalidad de Juan Bautista Alberdi",
        "Municipalidad de Lules",
        "Municipalidad de Monteros",
        "Municipalidad de San Miguel De Tucuman",
        "Municipalidad de Simoca",
        "Municipalidad de Tafi Viejo",
        "Municipalidad de Tafi del Valle",
        "Municipalidad de Trancas",
        "Municipalidad de Yerba Buena",
        "Municipalidad de la Cocha",
        "Municipalidad de las Talitas",
        "Pampa Mayo",
        "Quilmes y los Sueldos",
        "Raco",
        "Ranchillos y San Miguel",
        "Rio Chico y Nueva Trinidad",
        "Rio Colorado",
        "Rio Seco",
        "Rumipunco",
        "San Andres",
        "San Felipe y Santa Barbara",
        "San Ignacio",
        "San Javier",
        "San Jose de la Cocha",
        "San Pablo y Villa Nougues",
        "San Pedro de Colalao",
        "San Pedro y San Antonio",
        "Santa Ana",
        "Santa Cruz y la Tuna",
        "Santa Lucia",
        "Santa Rosa de Leales y L. Blanca",
        "Sargento Moya",
        "Soldado Maldonado",
        "Taco Ralo",
        "Tapia",
        "Teniente Berdina",
        "Trinidad",
        "Villa Padre Monti",
        "Villa Quinteros",
        "Villa de Chicligasta",
        "Villa de Leales",
        "Yanima",
        "Yerba Buena"
    ]
}