import Wrapper from 'components/atoms/wrapper';
import FaqList from 'components/molecules/faq-list';
import SectionHeader from 'components/molecules/section-header';
import Section from 'components/utils/section';
import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism DealersList
 *
 * <!-- TODO: add a description here! -->
 */
function DealersList(props) {
  const t = i18n.useTranslations('components.dealers-list');

  const { className, dealers = [], locale = 'pt-BR', ...other } = props;

  const [faqData, setFaqData] = useState([]);
  const [objData, setObjData] = useState({});
  const [listStates, setListStates] = useState([]);

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state?.address?.state))].sort());
  }

  function loadStatesObject() {
    const statesObject = {};
    listStates?.forEach((state) => {
      statesObject[state] = {
        title: state,
        content: []
      };
    });
    setObjData(statesObject);
  }

  useEffect(() => {
    loadStates();
  }, []);

  useEffect(() => {
    loadStatesObject();
  }, [listStates]);

  useEffect(() => {
    dealers?.forEach((dealer) => {
      const state = objData[dealer?.address?.state];

      if (!state || !dealer?.name) {
        return;
      }

      const socialMedias = [];

      dealer?.dealerProducts?.forEach((product) => {
        if (product.attributes && product.attributes.socialMedia) {
          product.attributes.socialMedia.forEach((social) => {
            socialMedias.push(social);
          });
        }
      });

      state.content.push({
        title: dealer.name,
        street: dealer?.address?.street,
        city: dealer?.address?.city,
        state: dealer?.address?.state,
        contactEmail: dealer.contactEmail,
        site: dealer.site,
        whatsapp: dealer.whatsapp,
        phone: dealer.phone,
        buttonLabel: t('button-label'),
        mapLink: `${t('map-link')}?state=${dealer?.address?.state}&city=${dealer?.address?.city}`,
        cuit: dealer.idDealerTasa,
        companyName: dealer.companyName,
        zipcode: dealer?.address?.zipcode,
        uf: dealer?.address?.uf,
        socialMedias
      });

      state.content.sort((a, b) => a.title.localeCompare(b.title));
    });

    const data = Object.keys(objData)?.map((key) => objData[key]);

    setFaqData({ items: data });
  }, [objData]);

  return (
    <Wrapper
      className={`${css['organism__dealers-list-container']} ${className}`}
      bgColor="gray"
      spacing="7"
      {...other}
    >
      <Section>
        <SectionHeader
          title={t('header-title')}
          linkData={{
            link: t('map-link'),
            title: t('button-title'),
            color: 'ghost-2'
          }}
        />
      </Section>

      <Section>
        <FaqList faqContent={faqData} locale={locale} invertArrow />
      </Section>
    </Wrapper>
  );
}

export default DealersList;
