import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

import Carousel from 'components/molecules/carousel';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';


/**
 * Molecule ContentSlider
 *
 * <!-- TODO: add a description here! -->
 */
function ContentSlider (props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.content-slider');

  const {
    className = "",
    contentList,
    spacingTop = "6",
    spacingBottom = "6",
    title = null,
    bgColor = "default",
    ...other
  } = props;

  let settings = {
    speed: 500,
    dots: true,
    infinite: false,
    padding: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 600, //$bp-phone
        settings: {
          arrows: false,
        }
      }
    ]
  };

  return (
    <>
      <Wrapper
        bgColor={bgColor}
        spacingTop={spacingTop}
        spacingBottom={spacingBottom}
      >
        <Section>
          <div
            className={`${css["content-slider-container"]} ${className}`}
            {...other}
          >
            {title &&
              <Title level={2} variant={2} className={css['content-title']}>{title}</Title>
            }
            <Carousel settings={settings} className={css['carousel-container']}>
              {
                contentList?.map((item, index) => {
                  return (
                    <div className={css['content-container']} key={index}>
                      <div className={css['content-container__info']}>
                        <div className={css['image-container']}>
                          <img className={css['image-container__image']} src={item.image} alt={item.title}/>
                        </div>
                        <div className={css['info-container']}>
                          <strong className={css['info-container__lede']}>{item.lede}</strong>
                          <span className={css['info-container__text']}>{item.text}</span>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </Carousel>
          </div>
        </Section>
      </Wrapper>
    </>
  );
}

export default ContentSlider;
