import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class MissingBlock implements ContentBlock {
  label = 'Missing Block';

  component = 'MissingBlock';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  missingComponent = '';

  settings = [
    anchor
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    return {
      missingComponent: this.missingComponent
    };
  }
}

export { MissingBlock };
