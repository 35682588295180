import React from 'react';
import getConfig from 'next/config';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

import CategoryTabs from 'components/molecules/category-tabs';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism TabsListButtons
 *
 * <!-- TODO: add a description here! -->
 */
function TabsListButtons(props) {

  const {
    bgColor = null,
    spacingTop = 4,
    spacingBottom = 4,
    buttonsList,
    pagePath,
    buttonsListItens = [],
    listAlignment = 'center',
    ...other
  } = props;

  const { publicRuntimeConfig } = getConfig();
  const pageURL = pagePath === '/' ? `${publicRuntimeConfig?.SERVER_ADDR}` : `${publicRuntimeConfig?.SERVER_ADDR}${pagePath}`;

  if (buttonsList) {

    buttonsList.forEach(button => {
      buttonsListItens.push({
        label: button.linkLabel,
        link: button.link,
        id: button.link
      });
    });

  }

  return <>
    <Wrapper
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
      data-bg-color={bgColor}
      data-list-alignment={listAlignment}
      {...other}
    >
      <Section>
        <CategoryTabs
          tabs={buttonsListItens}
          selected={pageURL || undefined}
          className={css['tabs-list-buttons']}
          removeDesktopCarousel={true}
        />
      </Section>
    </Wrapper>
  </>;
}

export default TabsListButtons;
