import Button from 'components/atoms/button';
import FormSelect from 'components/atoms/form-select';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import AnchorLink from 'components/atoms/anchor-link';
import FormInput from 'components/atoms/form-input';
import Loader from 'components/atoms/loader';
import Modal from 'components/molecules/modal';
import RecallTasaModal from '../recall-tasa-modal';
import RecallContactModal from '../recall-contact-modal';
import ServiceRecall from 'components/molecules/service-recall';
import SimpleModal from 'components/molecules/simple-modal';
import i18n from 'utils/i18n';

import css from './styles.module.scss';

/**
 * Organism RecallForm
 *
 * <!-- TODO: add a description here! -->
 */
function RecallForm(props) {
  const t = i18n?.useTranslations('components.recall_form');

  const { className = '', recalls = [], locale, dealers = [], ...other } = props;
  const router = useRouter();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const [tasaModalIsOpen, setTasaModalIsOpen] = useState(false);
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false);
  const [modelFiltered, setModelFiltered] = useState(null);
  const [yearFiltered, setYearFiltered] = useState(null);
  const [modelsFiltered, setModelsFiltered] = useState([]);
  const [yearsFiltered, setYearsFiltered] = useState([]);
  const [cardVariation, setCardVariation] = useState(false);
  const [vehicle, setVehicle] = useState(false);
  const [vehicleStatus, setVehicleStatus] = useState(false);
  const [recallMessage, setRecallMessage] = useState(null);
  const [showChassisInfoModal, setShowChassisInfoModal] = useState(false);
  const [isContactFormEnabled, setIsContactFormEnabled] = useState(false);

  const [recallCards, setRecallCards] = useState([]);

  const [numberOfCards, setNumberOfCards] = useState(10);

  const [searchStr, setSearchStr] = React.useState('');

  const [loading, setLoading] = useState(false);

  const searchForm = React.useRef();

  const formattedLocale = locale?.toUpperCase();

  const isTDB = formattedLocale === 'PT-BR';
  const isTASA = formattedLocale === 'ES-AR';
  const isTDV = formattedLocale === 'ES-VE';

  useEffect(() => {
    const modelsArr = [];

    if (typeof window !== 'undefined') {
      let regexp = new RegExp('[?&]searchStr=([^&#]*)', 'i');
      let _searchStr = regexp.exec(window.location);
      if (_searchStr) {
        setSearchStr(_searchStr[1]);
      }
    }

    setRecallCards(loadRecallCards());
    recalls?.map((res) => {
      modelsArr.push(res?.model);
    });

    const newModelsArr = modelsArr.filter((actual, i) => modelsArr.indexOf(actual) === i);

    setModelsFiltered(newModelsArr);
  }, []);

  useEffect(() => {
    if (!searchStr) {
      return;
    }
    dispatchRecallFunction(searchStr, formattedLocale);
  }, [searchStr]);

  useEffect(() => {
    if (modelFiltered || yearFiltered) {
      const newArr = [];
      recalls?.map((item) => {
        if (modelFiltered && yearFiltered) {
          item?.model === modelFiltered && item?.modelYear === yearFiltered && newArr.push(item);
        } else {
          if (!modelFiltered && yearFiltered) {
            item?.modelYear === yearFiltered && newArr.push(item);
          } else {
            if (modelFiltered && !yearFiltered) {
              item?.model === modelFiltered && newArr.push(item);
            }
          }
        }
      });
      setRecallCards(newArr);
    }
  }, [modelFiltered, yearFiltered]);

  async function handleSearch(param) {
    setLoading(true);
    const res = await fetch(`/api/recall/detran?chassiOrBoard=${param}`);
    const resData = await res.json();
    setRecallCards(resData.data.recallByChassiOrBoardViaDetran);
    setLoading(false);
  }

  function handleRecallStatus(status) {
    const recallMessages = {
      true: t('recall_campaign_text'),
      false: t('no_recall_campaign_text')
    };

    setRecallMessage(recallMessages[status]);
    setIsContactFormEnabled(status === true);
  }

  function redirectToContactForm(chassiParam) {
    router.push(
      {
        pathname: t('contact_form_url'),
        query: { chassi: chassiParam }
      },
      t('contact_form_url')
    );
  }

  async function fetchRecallData(param) {
    if (!param) {
      console.error('Chassis number not provided.');
      return;
    }
    setLoading(true);

    try {
      const response = await fetch(`/api/recall?chassiOrPatent=${param}&locale=${locale}`);

      if (!response.ok) {
        throw new Error(`Request error: ${response.statusText}`);
      }

      const { data } = await response.json();

      if (!data || !data.recallVehiclesByChassiOrPatent) {
        throw new Error('Invalid response format: Missing recallVehiclesByChassiOrPatent');
      }

      const { recallVehiclesByChassiOrPatent } = data || {};
      const { vehicle, status } = recallVehiclesByChassiOrPatent || {};

      if (status === undefined) {
        throw new Error('Status is missing from the recall data.');
      }

      if (vehicle === undefined) {
        throw new Error('Vehicle is missing from the recall data.');
      }

      setVehicle(vehicle);

      if (isTDV) {
        handleRecallStatus(status);
      }

      if (isTASA) {
        setVehicleStatus(status);
        setTasaModalIsOpen(true);
      }
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    } finally {
      setLoading(false);
    }
  }

  function loadRecallCards() {
    setNumberOfCards(numberOfCards + 10);
    return recalls?.filter((item, index) => index < numberOfCards);
  }

  function handleModelSelected(modelName) {
    setModelFiltered(modelName);

    const years = recalls?.filter((recall) => recall?.model === modelName);
    const yearsNames = [...new Set(years?.map((year) => year?.modelYear))].filter((year) => year !== '').sort();

    yearFiltered?.length !== 0 && setYearFiltered(yearsNames?.[0]);

    setYearsFiltered(yearsNames);
  }

  async function dispatchRecallFunction(vehicleIdentifier, locale) {
    if (!vehicleIdentifier || !locale) {
      console.error('Missing vehicleIdentifier or locale.');
      return;
    }

    switch (locale) {
      case 'PT-BR':
        setCardVariation(true);
        await handleSearch(vehicleIdentifier);
        break;
      default:
        await fetchRecallData(vehicleIdentifier);
        break;
    }
  }

  async function submitRecallForm(formData) {
    try {
      if (!formData?.carId?.length) {
        throw new Error('The entered vehicle identifier is required.');
      }
      await dispatchRecallFunction(formData.carId, formattedLocale);
    } catch (error) {
      console.error('Error processing form submission:', error);
    }
  }

  function onError(error) {
    console.log('Error: ', error);
  }

  return (
    <div className={`${css['recall-form-container']} ${className}`} {...other}>
      <form
        ref={searchForm}
        onSubmit={handleSubmit(submitRecallForm, onError)}
        className={css[isTDB ? 'forms-container' : 'forms-container-grid']}
        data-grid
      >
        <div className={css[isTDB ? 'select-container' : 'select-container-grid']}>
          <FormSelect
            data-testid="model-select"
            displayLabel={true}
            displayError={false}
            className={css['selected-model']}
            id="model"
            label={t('select_model_label')}
            placeholder={t('select_model_placeholder')}
            dataRegister={register('model')}
            onFocus={() => setCardVariation(false)}
            onChange={(e) => handleModelSelected(e?.target?.value)}
          >
            {modelsFiltered?.map((model, index) => {
              return (
                <option key={index} value={model}>
                  {model}
                </option>
              );
            })}
          </FormSelect>
        </div>
        {isTDB && (
          <div className={css[isTDB ? 'select-container' : 'select-container-grid']}>
            <FormSelect
              data-testid="year-select"
              className={css['selected-year']}
              displayLabel={false}
              displayError={false}
              id="year"
              placeholder={t('select_year_placeholder')}
              dataRegister={register('year')}
              onChange={(e) => setYearFiltered(e?.target?.value)}
            >
              {yearsFiltered?.map((year, index) => {
                return (
                  <option data-testid="year-option" key={index} value={year}>
                    {year}
                  </option>
                );
              })}
            </FormSelect>
          </div>
        )}

        <span className={css[isTDB ? 'form-text' : 'form-text-grid']}>{t('form_text')}</span>

        <div className={css[isTDB ? 'input-container' : 'input-container-grid']}>
          <FormInput
            displayLabel={true}
            displayError={false}
            className={css['remove-margin']}
            id="carId"
            label={t('input_label')}
            data-testid="recall-form-search-by-plate-or-chassi-input"
            dataRegister={register('carId')}
            dataErrors={errors['carId']}
            maxLength="19"
            defaultValue={searchStr}
          />

          {isTDB && (
            <AnchorLink
              data-testid="active-simple-modal"
              className={css['button-doc-active']}
              hasArrow={false}
              linkLabel={t('anchor_link_label')}
              onClick={() => setShowChassisInfoModal(true)}
            >
              {t('anchor_link_label')}
            </AnchorLink>
          )}

          {(isTASA || isTDV) && (
            <button
              data-testid="active-modal"
              className={css['modal-activate-button']}
              onClick={() => setShowChassisInfoModal(true)}
            >
              {t('anchor_link_label')}
            </button>
          )}
        </div>

        <div className={css[isTDB ? 'form-button-container' : 'form-button-container-grid']}>
          <Button data-testid="recall-form-search-button">{t('form_button')}</Button>
        </div>
      </form>

      {recallMessage && (
        <div className={css['recall-message-container']}>
          <p className={css['content-message-container']}>{recallMessage}</p>
          {isContactFormEnabled && (
            <Button
              className={css['button-redirect-contact-form']}
              onClick={() => redirectToContactForm(vehicle?.chassi)}
            >
              {t('label_redirect_contact_form')}
            </Button>
          )}
        </div>
      )}

      <div className={css['content-container']}>
        <span className={css['section-info']}>
          {recallCards?.length > 0 ? t('cards_section_title') : <span data-testid="no-recalls-message">t('no_cards_section_title')</span>}
        </span>

        {loading ? (
          <div data-testid="loader" className={css['loader-container']}>
            <Loader />
          </div>
        ) : (
          <ul data-testid="recall-list-cards" className={css['service-recall-list']}>
            {recallCards?.map((item, index) => {
              return (
                <li key={index} className={css['service-recall-list__item']} data-testid="recall-card">
                  <ServiceRecall
                    cardVariation={cardVariation}
                    locale={locale}
                    data={item}
                    setContactModalIsOpen={setContactModalIsOpen}
                  />
                </li>
              );
            })}
          </ul>
        )}

        {recalls?.length !== recallCards?.length && !modelFiltered && !yearFiltered && !cardVariation && (
          <div className={css['list-button-container']}>
            <Button onClick={() => setRecallCards(loadRecallCards())} color="light" type="button">
              {t('load_more')}
            </Button>
          </div>
        )}
      </div>
      {isTDB && contactModalIsOpen && (
        <RecallContactModal
          data-testid="recall-contact-modal"
          contactModalIsOpen={contactModalIsOpen}
          setContactModalIsOpen={setContactModalIsOpen}
          dealers={dealers}
        />
      )}

      {isTASA && tasaModalIsOpen && (
        <RecallTasaModal
          data-testid="recall-tasa-modal"
          tasaModalIsOpen={tasaModalIsOpen}
          setTasaModalIsOpen={setTasaModalIsOpen}
          vehicle={vehicle}
          vehicleStatus={vehicleStatus}
          dealers={dealers}
        />
      )}

      {showChassisInfoModal && isTDB && (
        <SimpleModal
          data-testid="simple-modal-doc"
          isOpen={showChassisInfoModal}
          onClose={() => setShowChassisInfoModal(false)}
          className={css['simple-modal']}
        >
          <img src="/images/chassiBR.png" alt="chassi" />
        </SimpleModal>
      )}

      {showChassisInfoModal && (isTASA || isTDV) && (
        <Modal
          data-testid="modal-doc"
          modalIsOpen={showChassisInfoModal}
          onClose={() => setShowChassisInfoModal(false)}
          className={css['tasa-chassi-modal']}
        >
          <img src={isTASA ? '/images/chassiAR.png' : isTDV ? '/images/chassiVE.png' : ''} alt="chassi" />
        </Modal>
      )}
    </div>
  );
}

export default RecallForm;
