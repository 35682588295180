import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import {
  anchor,
  bgColorMeta,
  kindOfContent,
  spacingBottomMeta,
  spacingTopMeta,
  autoPlay,
  muted,
  loop,
  controls
} from 'utils/meta';

class MediaSection implements ContentBlock {
  label = 'Media';

  component = 'MediaSection';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'containerSize',
      label: 'Container size',
      help: 'The width of the container',
      type: 'String',
      control: 'select',
      options: [
        'full-width',
        'full-screen',
        'one-third'
      ],
      defaultValue: 'full-width'
    },
    {
      name: 'centralizeItem',
      label: 'Centralize the media',
      help: 'The media will be centralized',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    {
      name: 'isFullContentSize',
      label: 'Set the media to occupy the entire width',
      help: 'The media will have 100% of the available width',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    autoPlay,
    muted,
    loop,
    controls,
    bgColorMeta,
    spacingTopMeta,
    spacingBottomMeta,
    kindOfContent

  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'hidden title attr',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Media',
      help: 'Select the media',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];

    return {
      bgColor: extra?.settings?.bgColor,
      centralizeItem: extra?.settings?.centralizeItem,
      isFullContentSize: extra?.settings?.isFullContentSize,
      data: {
        type: content?.media?.mediaType?.title,
        url: content?.media?.url,
        alt: content?.media?.description
      }
    };
  }
}

export { MediaSection };
