import React from 'react';
import i18n from 'utils/i18n';
import css from './styles.module.scss';
import Button from 'components/atoms/button';

function DeleteConfirmationModal(props) {
  const t = i18n.useTranslations('components.delete-confirmation-modal');
  const { className = '', vehicle, title, content, onCancel, onConfirmDelete, children, ...other } = props;

  return (
    <div className={`${css['molecule__delete-confirmation-modal-container']} ${className}`} {...other}>
      <div className={css['container-info']}>
        <span className={css['container-info-title']}>{title}</span>
        <span className={css['container-info-content']}>{content}</span>
      </div>

      <div className={css['container-buttons']}>
        <Button onClick={onCancel} color="ghost-2">
          {t('cancel-button')}
        </Button>
        <Button onClick={() => onConfirmDelete(vehicle?.id)}>{t('confirm-button')}</Button>
      </div>
      {children}
    </div>
  );
}

export default DeleteConfirmationModal;
