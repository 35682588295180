import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class EventsForm implements ContentBlock {
  label = 'Events Form';

  component = 'EventsForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'selectedEvent',
      label: 'Selected Event (Required)',
      help: 'Selected Event (Required)',
      type: 'picker',
      src: 'Events',
      control: 'input:text',
      required: true,
      defaultValue: ''
    },
    {
      name: 'title',
      label: 'Title form',
      help: 'The title of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'description',
      label: 'Description',
      help: 'Is the description of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'attractingMedia',
      label: 'Attracting Media',
      help: 'Indentify the form',
      type: 'String',
      control: 'input:text',
      defaultValue: 'SITE MONTADORA'
    },
    {
      name: 'titleSuccess',
      label: 'Title success',
      help: 'The title used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentSuccess',
      label: 'Content success',
      help: 'Descriptive text used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleError',
      label: 'Title error',
      help: 'The title used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentError',
      label: 'Content error',
      help: 'Descriptive text used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;

    return {
      locale: String(locale).toLowerCase(),
      selectedEvent: extra?.settings?.selectedEvent || null,
      title: extra?.settings?.title,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      attractingMedia: extra?.settings?.attractingMedia || '',
      spacingBottom: extra?.settings?.spacingBottom,
      spacingTop: extra?.settings?.spacingTop
    };
  }
}

export { EventsForm };
