import React, { useEffect, useState } from 'react';
// import i18n from 'utils/i18n';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Script from 'next/script';

import Modal from 'components/molecules/modal';
import Title from 'components/atoms/title';
import Button from 'components/atoms/button';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import { getCitiesFromDealers, getStatesFromDealers, getDealersByCity } from 'utils/forms';

import { models } from './mockData';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Organism RecallTasaModal
 *
 * <!-- TODO: add a description here! -->
 */
function RecallTasaModal(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.recall-tasa-modal');

  const {
    className = '',
    tasaModalIsOpen = false,
    setTasaModalIsOpen = () => undefined,
    vehicle = {},
    dealers = [],
    vehicleStatus = false
  } = props;

  const validationSchema = yup
    .object()
    .shape({
      name: yup.string().required('Ingrese su nombre.'),
      email: yup.string().required('Ingrese una dirección de e-mail.').email('El email ingresado no es válido.'),
      plaque: yup.string().required(''),
      state: yup
        .string()
        .notOneOf(['placeholder', undefined], 'Seleccioná un estado.')
        .required('Selecciona una provincia.'),
      city: yup
        .string()
        .notOneOf(['placeholder', undefined], 'Seleccioná una ciudad.')
        .required('Seleccione una ciudad.'),
      dealership: yup
        .string()
        .notOneOf(['placeholder', undefined], 'Seleccioná un concesionario.')
        .required('Seleccione un concesionario.')
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [isForm, setIsForm] = useState(false);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState('');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function loadStates() {
    const states = getStatesFromDealers(dealers);
    setListStates(states);
  }

  function loadDealers(selectedCity) {
    const filteredDealers = getDealersByCity(dealers, selectedCity);
    setListDealers(filteredDealers);
  }

  function loadCities(selectedState) {
    const cities = getCitiesFromDealers(dealers, selectedState);
    setListCities(cities);
  }

  useEffect(() => {
    loadDealers(selectedCity);
  }, [selectedCity]);

  useEffect(() => {
    loadCities(selectedState);
  }, [selectedState]);

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('city', 'placeholder');
    setSelectedState(selected);
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setSelectedCity(selected);
    setValue('dealership', 'placeholder');
  }

  function getSelectedDealerData(data) {
    if (!data?.dealership) {
      return null;
    }

    return dealers?.find((v) => v.name === data.dealership);
  }

  const onSubmit = async (formData) => {
    formData.locale = 'es-AR';
    formData.type = 'RECALL';
    const dealer = getSelectedDealerData(formData);
    if (dealer) {
      formData.dealershipEmail = dealer.afterSalesEmail;
    }

    const url = '/api/contact';

    const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');

      return false;
    }

    formData.captchaPayload = captchaPayload;

    if (formData.model === 'placeholder') {
      formData.model = '';
    }

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    };

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          return setIsStatusComponent('success');
        }
        return setIsStatusComponent('error');
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      <Modal
        modalIsOpen={tasaModalIsOpen}
        onClose={() => {
          setTasaModalIsOpen(false);
          setIsForm(false);
        }}
        className={`${css['recall-tasa-modal-container']} ${className}`}
        title={isForm && 'Llamado a Revisión Técnica'}
        textContent={
          isForm &&
          `Para solicitar un turno, completá este formulario. Ante cualquier duda, por favor, comunicate al Centro de Atención al Cliente, 0800-888-8696, de lunes a viernes, de 9 a 17 H.`
        }
      >
        {!isForm ? (
          <>
            {!vehicleStatus ? (
              <div className={css['modal-text-wrapper']}>
                <span className={css['modal-text']}>Llamado a revisión</span>
                <Title variant={2} level={2} className={css['modal-highlighted-text']}>
                  Tu vehículo no se encuentra alcanzado por ninguna de las campañas especiales de servicio vigente
                </Title>
                <span className={css['modal-text']}>
                  Ante cualquier duda sugerimos comunicarse con nuestro Centro de Atención al Cliente al: 0800-888-8696
                </span>
                <span>(Lunes a Viernes de 9:00 a 13:00 hs. y de 14:00 a 17:00 hs.).</span>
              </div>
            ) : (
              <div className={css['modal-text-wrapper']}>
                <span className={css['modal-text']}>Llamado a revisión</span>
                <Title variant={2} level={2} className={css['modal-highlighted-text']}>
                  Tu vehículo cuenta con una campaña especial de servicio pendiente de revisión
                </Title>
                <span className={css['modal-text']}>
                  Es necesario que te comuniques con el concesionario oficial de tu zona y coordines una visita, la
                  revisión es gratuita. Ante cualquier duda comunícate con nuestro Centro de Atención al cliente:
                </span>
                <span className={css['modal-text']}>0800-888-8696</span>
                <Button type="button" color="dark" onClick={() => setIsForm(true)}>
                  Contactarme
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            {isStatusComponent === 'success' && (
              <SuccessSubmitForm
                onBackForm={onBackForm}
                titleSuccess={'¡Formulario enviado con éxito!'}
                contentSuccess={'Se le responderá lo antes posible.'}
                contentBackForm={'Enviar otro formulario'}
                contentBackHome={'Ir a la página principal'}
              />
            )}

            {isStatusComponent === 'error' && (
              <SuccessSubmitForm
                onBackForm={onBackForm}
                titleSuccess={'¡Error al enviar el formulario!'}
                contentSuccess={'Tenta novamente.'}
                contentBackForm={'Enviar otro formulario'}
                contentBackHome={'Ir a la página principal'}
              />
            )}
            {isStatusComponent === 'default' && (
              <form onSubmit={handleSubmit(onSubmit, onError)} className={css['modal-form-container']}>
                <ul className={css['modal-form-column-container']}>
                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={true}
                      className={css['remove-margin']}
                      id="name"
                      label="Nombre y Apellido*"
                      dataRegister={register('name')}
                      dataErrors={errors['name']}
                    />
                  </li>

                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={false}
                      className={css['remove-margin']}
                      id="phone"
                      label="Teléfono"
                      placeholder="Ej: 1165276575"
                      dataRegister={register('phone')}
                      dataErrors={errors['phone']}
                      maxLength="19"
                    />
                  </li>

                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={false}
                      className={css['remove-margin']}
                      id="secondPhone"
                      label="Teléfono alternativo"
                      dataRegister={register('secondPhone')}
                      dataErrors={errors['secondPhone']}
                      maxLength="19"
                    />
                  </li>

                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={true}
                      className={css['remove-margin']}
                      id="email"
                      label="Email*"
                      dataRegister={register('email')}
                      dataErrors={errors['email']}
                    />
                  </li>

                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={false}
                      className={css['remove-margin']}
                      id="address"
                      label="Dirección completa"
                      dataRegister={register('address')}
                      dataErrors={errors['address']}
                    />
                  </li>
                </ul>

                <ul className={css['modal-form-column-container']}>
                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={false}
                      className={css['remove-margin']}
                      id="plaque"
                      label="Dominio"
                      placeholder={vehicle?.patent}
                      dataRegister={register('plaque')}
                      dataErrors={errors['plaque']}
                      value={vehicle?.patent}
                      maxLength="19"
                    />
                  </li>

                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={false}
                      className={css['remove-margin']}
                      id="frame"
                      label="Chasis"
                      placeholder={vehicle?.chassi}
                      value={vehicle?.chassi}
                      dataRegister={register('frame')}
                      dataErrors={errors['frame']}
                      maxLength="19"
                    />
                  </li>

                  <li>
                    <FormSelect
                      displayLabel={true}
                      displayError={false}
                      id="model"
                      placeholder="Modelo"
                      label="Modelo"
                      dataRegister={register('model')}
                      className={css['remove-margin']}
                    >
                      {models?.length > 0 &&
                        models?.map((model, index) => {
                          return (
                            <option key={index} value={model?.name ? model?.name : ''}>
                              {model?.name}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </li>

                  <li>
                    <FormInput
                      displayLabel={true}
                      displayError={false}
                      className={css['remove-margin']}
                      id="year"
                      label="Año"
                      dataRegister={register('year')}
                      dataErrors={errors['year']}
                      maxLength="4"
                      minLength="2"
                    />
                  </li>

                  <li>
                    <FormSelect
                      displayLabel={true}
                      displayError={true}
                      onFocus={loadStates}
                      onChange={onChangeState}
                      id="state"
                      placeholder="Provincia"
                      label="Provincia*"
                      dataRegister={register('state')}
                      dataErrors={errors['state']}
                      className={css['remove-margin']}
                    >
                      {listStates?.length > 0 &&
                        listStates?.map((state, index) => {
                          return (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </li>

                  <li>
                    <FormSelect
                      displayLabel={true}
                      displayError={true}
                      onChange={onChangeCity}
                      id="city"
                      placeholder="Ciudad"
                      label="Ciudad*"
                      dataRegister={register('city')}
                      dataErrors={errors['city']}
                      className={css['remove-margin']}
                    >
                      {selectedState &&
                        listCities?.length > 0 &&
                        listCities?.map((city, index) => {
                          return (
                            <option key={index} value={city}>
                              {city}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </li>

                  <li>
                    <FormSelect
                      displayLabel={true}
                      displayError={true}
                      dataRegister={register('dealership')}
                      dataErrors={errors['dealership']}
                      id="dealership"
                      placeholder="Concesionario"
                      label="Concesionario*"
                      className={css['remove-margin']}
                    >
                      {listDealers?.length > 0 &&
                        listDealers?.map((dealer, index) => {
                          return (
                            <option key={index} value={dealer?.name}>
                              {dealer?.name}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </li>
                  <Button type="submit" color="dark">
                    Enviar
                  </Button>
                </ul>
              </form>
            )}
          </>
        )}
      </Modal>
    </>
  );
}

export default RecallTasaModal;
