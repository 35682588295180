import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import Vehicle from 'utils/repo/vehicle';
import Dealers from 'utils/repo/dealers';
import {
  anchor,
  formAttractingMedia,
  formContentError,
  formContentSuccess,
  formDescription,
  formTitle,
  formTitleError,
  formTitleSuccess,
  spacingBottomMeta,
  spacingTopMeta
} from 'utils/meta';

class SpecialSalesForm implements ContentBlock {
  label = 'Special Sales Form';

  component = 'SpecialSalesForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    formTitle,
    formDescription,
    formAttractingMedia,
    {
      name: 'disabilitiesPeopleAllowedModels',
      label: 'Allowed Models (People with Disabilities)',
      help: 'List of allowed models to display, comma separated. Empty to allow all.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'diplomatsAllowedModels',
      label: 'Allowed Models (Diplomats)',
      help: 'List of allowed models to display, comma separated. Empty to allow all.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },

    {
      name: 'fleetsAllowedModels',
      label: 'Allowed Models (Fleets)',
      help: 'List of allowed models to display, comma separated. Empty to allow all.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    formTitleSuccess,
    formContentSuccess,
    formTitleError,
    formContentError,
    spacingTopMeta,
    spacingBottomMeta

  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;

    const dealersByLocation =
      (await dataFetcher(await Dealers?.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    const vehiclesByModels =
      (await dataFetcher(await Vehicle.getAllVehicles(), { locale: locale }))?.data?.vehicles || [];

    const disabilitiesPeopleAllowedModels = extra?.settings?.disabilitiesPeopleAllowedModels || '';
    const diplomatsAllowedModels = extra?.settings?.diplomatsAllowedModels || '';
    const fleetsAllowedModels = extra?.settings?.fleetsAllowedModels || '';

    let disabilitiesPeopleModelSet = [];
    let diplomatsModelSet = [];
    let fleetsModelSet = [];

    if (disabilitiesPeopleAllowedModels?.length > 0) {
      disabilitiesPeopleModelSet = disabilitiesPeopleAllowedModels
        .split(',')
        .map((model) => String(model).trim().toLocaleLowerCase());
    }

    if (diplomatsAllowedModels?.length > 0) {
      diplomatsModelSet = diplomatsAllowedModels.split(',').map((model) => String(model).trim().toLocaleLowerCase());
    }

    if (fleetsAllowedModels?.length > 0) {
      fleetsModelSet = fleetsAllowedModels.split(',').map((model) => String(model).trim().toLocaleLowerCase());
    }

    const models = [];

    for (const model of vehiclesByModels) {
      models.push({
        name: model.name || '',
        vehicleExternalId: model?.vehicleExternalId || '',
        version: model?.mainVersion?.name || '',
        prodYear: model?.mainVersion?.modelYear?.year || '',
        katashiki: model?.mainVersion?.modelYear?.katashiki || '',
        suffixCode: model?.mainVersion?.modelYear?.suffixCode || '',
        internalColor: {
          name: model?.mainVersion?.modelYear?.defaultInternalColorCode || '',
          code: model?.mainVersion?.modelYear?.defaultInternalColorCode || ''
        },
        externalColor: {
          name: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.name || '',
          code: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.code || ''
        }
      });
    }

    let disabilitiesPeopleModels = models;
    let diplomatsModels = models;
    let fleetsModels = models;

    if (disabilitiesPeopleModelSet?.length > 0) {
      disabilitiesPeopleModels = disabilitiesPeopleModels.filter((model) =>
        disabilitiesPeopleModelSet.includes(`${model.name}`.trim().toLocaleLowerCase())
      );
    }

    if (diplomatsModelSet?.length > 0) {
      diplomatsModels = diplomatsModels.filter((model) =>
        diplomatsModelSet.includes(`${model.name}`.trim().toLocaleLowerCase())
      );
    }

    if (fleetsModelSet?.length > 0) {
      fleetsModels = fleetsModels.filter((model) =>
        fleetsModelSet.includes(`${model.name}`.trim().toLocaleLowerCase())
      );
    }

    return {
      locale: String(locale).toLowerCase(),
      dealers: dealersByLocation,
      disabilitiesPeopleModels: disabilitiesPeopleModels,
      diplomatsModels: diplomatsModels,
      fleetsModels: fleetsModels,
      title: extra?.settings?.title,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      spacingTop: extra?.settings?.spacingTop || '0',
      spacingBottom: extra?.settings?.spacingBottom || '0',
      attractingMedia: extra?.settings?.attractingMedia || ''
    };
  }
}

export { SpecialSalesForm };
