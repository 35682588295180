import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

import BannerListItem from 'components/molecules/banner-list-item';

/**
 * Molecule BannerList
 *
 * A list of full width banners
 */
function BannerList(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.banner-list');

  const {
    className = '',
    children,
    banners = null,
    type = null,
    colorButton = 'light',
    autoPlay,
    muted,
    loop,
    controls,
    ...other
  } = props;

  return (
    <>
      <ul className={`${css['banner-list']} ${className}`} {...other}>
        {banners &&
          banners.map((banner, index) => {
            return (
              <BannerListItem
                bannerData={banner}
                key={index}
                type={type}
                colorButton={colorButton}
                autoPlay={autoPlay}
                muted={muted}
                loop={loop}
                controls={controls}
              />
            );
          })}
      </ul>
    </>
  );
}

export default BannerList;
