import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class Search implements ContentBlock {
  label = 'Search';

  component = 'Search';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'bgColor',
      label: 'Cor de fundo',
      help: 'Cor de fundo para a sessão',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'black'
      ]
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Search title with no result',
      help: 'Title for unrelated search result',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Search text no result',
      help: 'Text for unrelated search result',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    }
  ];

  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];
    const locale = extra?.context?.locale;
    const titleNoResults = content?.title || null;
    const textNoResults = content?.description || null;

    const spacingTop = extra?.settings?.spacingTop;
    const spacingBottom = extra?.settings?.spacingBottom;
    const bgColor = extra?.settings?.bgColor;

    return {
      locale: String(locale).toLowerCase(),
      textNoResults: textNoResults,
      titleNoResults: titleNoResults,
      spacingTop: spacingTop,
      spacingBottom: spacingBottom,
      bgColor: bgColor
    };
  }
}

export { Search };
