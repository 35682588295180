import { secondToHrsMinSec } from "utils/functions";

export const generateCertificate = (data) => {
  var a = window.open('', '', 'height=500, width=500');
  a.document.write(`
  <html>
	<head>
		<title>Certificado de Recall - Toyota</title>
		<style>
			* { padding:0px; margin:0px 0px 30px 0px; line-height:1.2em; font-family:Arial, Open Sans, Helvetica, sans-serif; }
			body { padding:60px; }
			h1 { font-size:18px; }
			p { font-size:14px; }
			img { width:150px; }
    </style>
    </head>
    <body>
      <img src="/images/toyota_black_logo.svg" />
      <h1>CERTIFICADO DE ATENDIMENTO A CAMPANHA DE CHAMAMENTO PREVENTIVA</h1>
      <p>A TOYOTA DO BRASIL LTDA., em cumprimento ao Art. 7º da Portaria Nº 618, de 01 de julho de 2019 do Ministério de Estado da Justiça e Segurança Público e ao Art. 4º da Portaria Conjunta Nº 3, de 01 de julho de 2019 do Ministério de Estado da Infraestrutura e do Ministério de Estado da Justiça e Segurança Pública, declara que o veículo abaixo realizou a Campanha de Chamamento Preventiva, conforme descrição:</p>
      <p>
        <strong>Chassi n°:</strong> ${data?.chassi}.<br>
        <strong>Modelo:</strong> ${data?.model}.
      </p>
      <p>
        <strong>Campanha:</strong> ${data?.name}.<br>
        <strong>Nº:</strong> ${data?.denatranCode}.<br>
        <strong>Descrição do Reparo:</strong> ${data?.description}.
      </p>
      <p>
        <p>
          <strong>Data:</strong> ${data?.newRepairDate}.<br>
          <strong>Duração:</strong> ${secondToHrsMinSec(data?.duration)}.
        </p>
        <strong>Distribuidor Toyota:</strong> ${data?.dealership?.name}.<br>
        <strong>Endereço:</strong> ${data?.dealership?.address}.<br>
        <strong>Cidade:</strong> ${data?.dealership?.city}. <strong>UF:</strong> ${data?.dealership?.uf}.
      </p>
    </body>
    </html>
    `);
  a.document.close();
  a.print();
};