import React from 'react';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule IframeSection
 *
 * <!-- TODO: add a description here! -->
 */
function IframeSection (props) {

  const {
    className = '',
    title = '',
    url = '',
    children,
    ...other
  } = props;

  return <div
    className={`${css["molecule__iframe-section-container"]} ${className}`}
    {...other}
  >
    <Wrapper spacing="2">
      <Section>
        <iframe className={css["iframe"]} title={title} src={url}></iframe>
      </Section>
    </Wrapper>

    {children}
  </div>;
}

export default IframeSection;
