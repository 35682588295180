import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

class ContentDivider implements ContentBlock {
  label = 'Line Break';

  component = 'ContentDivider';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    {
      name: 'size',
      label: 'Line size',
      help: 'Horizontal line break',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'tiny',
        'small'
      ]
    },
    {
      name: 'fullWidth',
      label: 'The line will be a full width content?',
      help: '',
      type: 'boolean',
      control: 'toggle',
      defaultValue: true
    },
    {
      name: 'bgColor',
      label: 'Wrapper backgroud color',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'black'
      ]
    },
    {
      name: 'spacing',
      label: 'Spacing',
      help: 'Horizontal line padding spacing',
      type: 'String',
      control: 'select',
      options: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {};
  }
}

export { ContentDivider };
