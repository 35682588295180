import React from 'react';
// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule FieldsetHeader
 *
 * <!-- TODO: add a description here! -->
 */
function FieldsetHeader(props) {
  const { className = '', variant, legend, description, ...other } = props;

  return (
    <div data-variant={variant} className={`${css['molecule__fieldset-header-container']} ${className}`} {...other}>
      <legend className={css['legend']}>{legend}</legend>
      {description && <p className={css['description']}>{description}</p>}
    </div>
  );
}

export default FieldsetHeader;
