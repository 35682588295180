/* eslint-disable */
import React from 'react';
import i18n from 'utils/i18n';

import Pill from 'components/atoms/pill';
import Media from 'components/molecules/media';
import Title from 'components/atoms/title';

import { currency, checkAccessoryStatus } from 'utils/functions';

import { useCarBuilder } from 'providers/car-builder';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule AcessoryCard
 *
 * Acessory Card
 */
function AcessoryCard(props) {
  const {
    className = '',
    title = null,
    price = null,
    media = null,
    link = null,
    category = null,
    partNumber = null,
    installmentValue = null,
    status,
    locale = 'pt-BR'
  } = props;

  const { data: carData, setData: setCarData } = useCarBuilder();

  const Tag = link && Object.keys(carData)?.length === 0 ? 'a' : 'div';
  const t = i18n.useTranslations('components.acessory-card');

  //We will use this code when a solution to the "BuildACarForm", to append accessories to it´s data, is implemented
  // const [isActive, setIsactive] = useState(false);

  // function toggleState() {

  //   const selectedAccessories = carData?.data?.selectedAccessories || {};

  //   if (selectedAccessories[title]) {
  //     delete selectedAccessories[title];
  //   } else {
  //     selectedAccessories[title] = true
  //   }

  //   setCarData("data", {
  //     ...carData?.data,
  //     selectedAccessories: {...selectedAccessories}
  //   });

  //   setIsactive(!isActive);

  // }

  const currencySymbol = locale === 'pt-BR' ? 'BRL' : locale === 'es-AR' ? 'ARS' : 'USD';

  return (
    <>
      <Tag
        className={`${css['molecule__acessory-card-container']} ${className}`}
        href={link ? link : undefined}
        data-is-link={link ? true : undefined}
      >
        {media && media?.url && <Media url={media?.url} alt={media?.title} className={css['image']} centralizeItem />}

        <div className={css['content-container']}>
          <div className={css['content-container_content']}>
            <div className={css['pills']}>
              <Pill label={category?.name} size="small" isButton={false} />
            </div>

            <Title level="3" variant="5" isUppercase>
              {title}
            </Title>

            {installmentValue && (
              <>
                <div>
                  {carData && installmentValue && <p className={css['info-list__item-title']}>Acréscimo na parcela</p>}
                  <p className={css['info-list__item-description']}>
                    {checkAccessoryStatus(
                      status,
                      currency(installmentValue, locale, currencySymbol),
                      t('accessory-status-unavailable')
                    )}
                  </p>
                </div>
              </>
            )}

            <div>
              {carData && installmentValue && <p className={css['info-list__item-title']}>À vista</p>}
              <p className={css['info-list__item-description']}>
                {checkAccessoryStatus(
                  status,
                  currency(price, locale, currencySymbol),
                  t('accessory-status-unavailable')
                )}
              </p>
            </div>

            {partNumber && <p className={css['extra-info']}>{partNumber}</p>}
          </div>

          <p className={css['notice']}>{t('instalation-message')}</p>
        </div>
      </Tag>
    </>
  );
}

export default AcessoryCard;
