import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom FormTextArea
 *
 * <!-- TODO: add a description here! -->
 */
function FormTextArea(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.form-text-area');

  const [textSize, setTextSize] = useState(0);
  const [textMaxSize, setTextMaxSize] = useState(0);

  const {
    className = '',
    id,
    disabled,
    label,
    masking,
    rows,
    dataRegister,
    dataErrors,
    displayLabel = true,
    displayError = true,
    displaySize = false,
    children,
    ...other
  } = props;

  useEffect(() => {
    const elemento = document.getElementById(id);
    if (elemento) {
      setTextMaxSize(elemento.maxLength);
    }
  }, [id]);

  return (
    <div className={`${css['atom__form-text-area-container']} ${css['ff-container']} ${className}`}>
      <label
        htmlFor={id}
        className={`${css['ff-label']} ${disabled ? css['ff-disabled'] : ''} ${displayLabel ? '' : css['absent']}`}
        disabled={disabled ? true : undefined}
      >
        {label}
      </label>
      <textarea
        id={id}
        className={`${displayError ? css['ff-box'] : css['ff-box-clean']} ${css['field-textarea']} ${
          dataErrors?.type ? css['ff-box-error'] : ''
        } ${displaySize ? css['big-area'] : {}}`}
        {...dataRegister}
        {...other}
        rows={rows ? rows : '3'}
        disabled={disabled ? true : undefined}
        onChange={(text) => {
          masking && masking(text);
          setTextSize(text.target.value.length);
          dataRegister.onChange(text);
        }}
      ></textarea>

      {displaySize && (
        <span className={css['text-size']}>
          {textSize} / {textMaxSize} {t('span_text-size')}
        </span>
      )}

      {displayError && dataErrors?.type && (
        <span className={displaySize ? css['error-message'] : css['ff-error-message']}> {dataErrors.message} </span>
      )}
    </div>
  );
}

export default FormTextArea;
