import React from 'react';
import i18n from 'utils/i18n';

import AnchorLink from 'components/atoms/anchor-link';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ProgressStepper
 *
 * <!-- TODO: add a description here! -->
 */
function ProgressStepper(props) {
  const t = i18n.useTranslations('components.progress-stepper');

  const { className = '', listSteps = [], currentStep, onNext, onPrevious, ...other } = props;

  const hasSteps = listSteps.length > 0;

  const sortedItems = listSteps?.sort((a, b) => a.order - b.order);

  const nextItem = sortedItems[currentStep + 1];

  const hasPrevious = currentStep > 0;

  const hasNext = currentStep < listSteps?.length - 1;

  const nextItemDisabled = listSteps[currentStep + 1]?.disabled;

  const calculateProgress = (currentStep, totalSteps) => {
    return totalSteps === 0 ? 0 : ((currentStep + 1) / totalSteps) * 100;
  };

  const progress = calculateProgress(currentStep, listSteps?.length);

  return hasSteps ? (
    <div className={`${css['molecule__progress-stepper-container']} ${className}`} {...other}>
      <div className={css['details-container']}>
        <h3 className={css['details-container__title']}>{listSteps[currentStep]?.name}</h3>
        <div className={css['details-info']}>
          <div className={css['info-content']}>
            <p className={css['info-content__description']}>{listSteps[currentStep]?.description}</p>
            {nextItem && (
              <span className={css['info-content__description-next-step']}>
                {t('next_step_prefix')}
                {nextItem.name}
              </span>
            )}
          </div>

          <div className={css['progress-wrapper']}>
            <div className={css['progress-bar']} style={{ width: `${progress}%` }}></div>
          </div>
        </div>
      </div>
      <div className={css['buttons-container']}>
        <div>
          {hasPrevious && (
            <AnchorLink
              className={css['button-previous']}
              linkLabel={t('previous')}
              invertArrow={true}
              arrowPosition="left"
              onClick={onPrevious}
            />
          )}
        </div>
        <div>
          {hasNext && (
            <AnchorLink
              className={css['button-next']}
              linkLabel={t('next')}
              onClick={onNext}
              disabled={nextItemDisabled}
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  );
}

export default ProgressStepper;
