import React from 'react';
import DownloadDocumentItem from 'components/atoms/download-document-item';
import Title from 'components/atoms/title';
//import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

/**
 * Molecule DownloadDocList
 *
 * <!-- TODO: add a description here! -->
 */
function DownloadDocList(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.download-doc-list');

  const { className = '', downloadDocList = [], showTitle = false, title = null, ...other } = props;

  return (
    <>
      <Wrapper bgColor="gray-6" spacing={5} {...other}>
        <Section>
          <div className={`${css['molecule__download-doc-list-container']} ${className}`} {...other}>
            {title && showTitle && (
              <Title className={css['title']} level={3} variant={3}>
                {title}
              </Title>
            )}

            <ul className={css['doc-list']}>
              {React.Children.toArray(
                downloadDocList?.map((doc, index) => {
                  return (
                    <li className={css['doc-list__item']} key={index}>
                      <DownloadDocumentItem
                        className={css['doc-list__doc']}
                        title={doc?.title}
                        text={doc?.text}
                        value={doc?.value}
                        linkUrl={doc?.linkUrl}
                        color={doc?.color}
                        showTitle={showTitle}
                      />
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        </Section>
      </Wrapper>
    </>
  );
}

export default DownloadDocList;
