import React from 'react';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import Title from 'components/atoms/title';
import TabCar from 'components/molecules/tab-car';
import vehicleUtils from 'utils/repo/vehicle';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism CarsTabWithCategory
 *
 */
function CarsTabWithCategory(props) {
  const {
    className = '',
    vehiclesByCategory = [],
    locale,
    exploreLabel = 'components.action_banner.explore',
    buildYourLabel = 'components.action_banner.build_your',
    children
  } = props;

  return (
    <div className={`${css['organism__cars-tab-with-category-container']} ${className}`}>
      {vehiclesByCategory?.map((vehicleCategory, index) => {
        return (
          vehicleCategory?.vehicles?.length > 0 && (
            <Wrapper key={index} spacing="4" bgColor="gray">
              <Section className={css['list-section']}>
                <Title className={css['list__title']} level={2} variant={3} data-font-regular>
                  {vehicleCategory?.categoryName}
                </Title>
                <ul className={css['list']}>
                  {vehicleCategory?.vehicles?.map((car, key) => {
                    if (!car?.mainVersion?.name) {
                      return '';
                    }

                    const medias = vehicleUtils.getMediaByType(car, 'main', 'display');
                    const mediaRegular = medias.find((media) => media?.tag?.includes(':regular'));
                    const mediaHover = medias.find((media) => media?.tag?.includes(':hover'));

                    return (
                      <li className={css['list__item']} key={key}>
                        <Title level={3} variant={2}>
                          {car?.name}
                        </Title>
                        <TabCar
                          className={css['list__tabcar']}
                          image={[
                            {
                              url: mediaRegular?.media?.url,
                              alt: mediaRegular?.media?.title,
                              type:
                                mediaRegular?.media?.mediaType?.title === 'image'
                                  ? 'img'
                                  : mediaRegular?.media?.mediaType?.title
                            },
                            {
                              url: mediaHover?.media?.url,
                              alt: mediaHover?.media?.title,
                              type:
                                mediaHover?.media?.mediaType?.title === 'image'
                                  ? 'img'
                                  : mediaHover?.media?.mediaType?.title
                            }
                          ]}
                          price= {locale === 'es-VE' ? car?.price : null}
                          button={{ label: exploreLabel, url: `${car?.page?.fullpath}` }}
                          link={
                            !car?.removeFromBuildCar
                              ? {
                                  label: buildYourLabel,
                                  url:
                                    locale === 'es-VE' ? `${car?.page?.fullpath}/monte` : car.vehicleExternalUrl || null
                                }
                              : null
                          }
                          carlabel={null}
                          variant="car-list"
                          buttonVariant="ghost-2"
                          locale = {locale}
                        />
                      </li>
                    );
                  })}
                </ul>
              </Section>
            </Wrapper>
          )
        );
      })}

      {children}
    </div>
  );
}

export default CarsTabWithCategory;
