import React, { useCallback, useEffect, useState } from 'react';

import i18n from 'utils/i18n';
import { captchaToken } from 'utils/captcha/token';
import { cpfMask, isValidCPF, phoneMask } from 'utils/validations';
import { isBirthdayBeforeToday, isNumberMask, isTextMask, validatePhoneNumber } from 'utils/forms';
import { dateIso2Br, extractTimeFromDateBr } from 'utils/functions';

import getConfig from 'next/config';
import Script from 'next/script';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import FieldsetHeader from 'components/molecules/fieldset-header';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormRadioGroup from 'components/atoms/form-radio-group';
import FormRadioButton from 'components/atoms/form-radio-button';
import FormSelect from 'components/atoms/form-select';
import Loader from 'components/atoms/loader';
import Section from 'components/utils/section';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import ThumbHover from 'components/atoms/thumb-hover';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';

import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

function EventsForm(props) {
  const t = i18n.useTranslations('components.events-form');

  const {
    className = '',
    locale,
    selectedEvent,
    title,
    description,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    attractingMedia,
    spacingTop = 0,
    spacingBottom = 0,
    children,
    ...other
  } = props;

  const [events, setEvents] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [availableVacanciesDisable, setAvailableVacanciesDisable] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isFormSubmitedEnabled, setIsFormSubmitedEnabled] = useState(false);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isStatusEvents, setIsStatusEvents] = useState('');
  const [messageError, setMessageError] = useState('');
  const [listCities, setListCities] = useState([]);
  const [listStates, setListStates] = useState([]);
  const [listTestDrives, setListTestDrives] = useState([]);
  const [listLocationsEvents, setListLocationsEvents] = useState([]);
  const [selectedTestDriveEventId, setSelectedTestDriveEventId] = useState('');
  const isListTestDrivesEmpty = listTestDrives.length === 0;

  const fetchData = async () => {
    try {
      const response = await fetch(`/api/events/allEvents?locale=${locale}&editionId=${selectedEvent}`);
      if (!response.ok) {
        throw new Error('Erro ao buscar eventos');
      }
      const data = await response.json();

      const eventsEditionsByData = data?.availableEventEditions;

      setEvents(eventsEditionsByData);
    } catch (error) {
      console.error('Erro ao buscar eventos:', error);

      setTimeout(() => {
        fetchData();
      }, 5000);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!Array.isArray(events) || events.length === 0) {
      setIsStatusEvents('notAvailable');
    } else {
      setIsStatusEvents('available');
    }
  }, [events]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth <= 600);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const validationSchemaConfig = {
    firstName: yup
      .string()
      .required(t('message_error_required_first_name'))
      .min(3, t('message_error_min_char_first_name')),
    lastName: yup
      .string()
      .required(t('message_error_required_last_name'))
      .min(3, t('message_error_min_char_last_name')),
    email: yup
      .string()
      .required(t('message_error_required_email'))
      .email(t('message_error_invalid_email'))
      .max(50, t('message_error_max_char_email')),
    phone: yup
      .string()
      .required(t('message_error_required_phone'))
      .test('test-invalid-phone', t('message_error_invalid_phone'), (phone) => validatePhoneNumber(phone))
      .min(14, t('message_error_min_char_phone')),
    cpf: yup
      .string()
      .required(t('message_error_required_cpf'))
      .test('test-invalid-cpf', t('message_error_invalid_cpf'), (cpf) => isValidCPF(cpf)),
    birthDate: yup
      .string()
      .required(t('message_error_required_date'))
      .test('test-invalid-date', t('message_error_invalid_date'), (date) => isBirthdayBeforeToday(date)),
    state: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_state'))
      .required(t('message_error_required_state')),
    city: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_city'))
      .required(t('message_error_required_city')),
    eventEditionId: yup.string().required(t('message_error_required_event_edition_id')).nullable(),
    goWithCompany: yup.string().required(t('message_error_required_go_with_company')).nullable(),
    companyQuantity: yup.string().when('goWithCompany', {
      is: 'yes',
      then: yup
        .string()
        .required(t('message_error_required_company_quantity'))
        .min(1, t('message_error_required_company_quantity'))
        .test('max-length', t('message_error_max_char_company_quantity'), (value) => value <= 4)
        .test('no-zero', t('message_error_no_zero_company_quantity'), (value) => value !== '0' || value === ''),
      otherwise: yup.string()
    }),
    wantATestDrive: yup.string().when('listTestDrives', {
      is: (listTestDrives) => listTestDrives && listTestDrives.length > 0,
      then: yup.string().required(t('message_error_required_test_drive')),
      otherwise: yup.string().nullable()
    }),
    vehicleEventId: yup.string().when('wantATestDrive', {
      is: (wantATestDrive) => wantATestDrive === 'yes',
      then: yup.string().test({
        name: 'isMobileValidation',
        test: function (value) {
          if (this.resolve(isMobile) && value === 'placeholder') {
            return this.createError({
              message: t('message_error_required_vehicle_event_id'),
              path: 'vehicleEventId'
            });
          }
          return true;
        }
      }),
      otherwise: yup.string().nullable()
    }),
    testDriveOffsetId: yup.string().when('wantATestDrive', {
      is: 'yes',
      then: yup.string().required(isMobile ? t('message_error_required_test_drive_offset_id') : null),
      otherwise: yup.string().when('wantATestDrive', {
        is: 'no',
        then: yup.string().nullable(),
        otherwise: yup.string().nullable()
      })
    }),
    agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
    register,
    formState,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  });

  const watchState = watch('state');
  const watchCity = watch('city');
  const watchCityAvailable = watch('city') !== 'placeholder';
  const watchAgreement = watch('agreement');
  const watchEventEditionId = watch('eventEditionId');
  const watchGoWithCompanyAvailable = watch('goWithCompany') === 'yes' ? true : false;
  const watchTestDrive = watch('wantATestDrive');
  const watchVehicleEventId = watch('vehicleEventId');
  const testDriveListAvailable = listTestDrives?.length > 0;
  const watchTestDriveAvailable = watch('wantATestDrive') === 'yes';
  const watchTestDriveOffsetIdSelected = watch('testDriveOffsetId');
  const watchTestDriveSelectedAvailable =
    watchTestDriveOffsetIdSelected !== null || watchTestDriveOffsetIdSelected !== '';

  useEffect(() => {
    if (!watchTestDriveAvailable) {
      setValue('testDriveOffsetId', '');
    }
  }, [watchTestDrive]);

  useEffect(() => {
    loadStates();
  }, [events]);

  useEffect(() => {
    loadCities(watchState);
  }, [watchState]);

  useEffect(() => {
    if (watchCityAvailable) {
      loadEventsLocales();
    }
  }, [watchCityAvailable, watchCity]);

  useEffect(() => {
    loadTestDrives();
    if (isListTestDrivesEmpty) {
      setValue('wantATestDrive', '');
      clearErrors('wantATestDrive');
    }
  }, [watchEventEditionId]);

  useEffect(() => {
    if (isListTestDrivesEmpty) {
      clearErrors('wantATestDrive');
    }
  }, [listTestDrives]);

  useEffect(() => {
    setIsDisable(!watchAgreement);
  }, [watchAgreement]);

  useEffect(() => {
    if (watchTestDrive === 'no') {
      setIsFormSubmitedEnabled(true);
    } else {
      setIsFormSubmitedEnabled(false);
    }
  }, [watchTestDrive]);

  useEffect(() => {
    if (!isListTestDrivesEmpty) {
      if (!watchTestDriveOffsetIdSelected) {
        setError('testDriveOffsetId', {
          type: 'manual',
          message: isMobile ? t('message_error_required_test_drive_offset_id') : null
        });
      }
    } else {
      setError('wantATestDrive', null);
      setError('testDriveOffsetId', null);
    }
  }, [isListTestDrivesEmpty]);

  const resetForm = useCallback(() => {
    const formValues = getValues();
    Object.keys(formValues).forEach((field) => {
      const initialValue = field === 'state' || field === 'city' ? 'placeholder' : '';
      setValue(field, initialValue);
    });
    setValue('agreement', false);
    fetchData();
    reset();
  }, [
    reset,
    setValue,
    getValues
  ]);

  function loadStates() {
    setListStates([...new Set(events?.map((event) => event?.eventLocal?.address?.state))].sort());
  }

  function loadCities(selectedState) {
    const filtered = events?.filter((state) => {
      return state?.eventLocal?.address?.state.toUpperCase() == selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered?.map((event) => event?.eventLocal?.address?.city.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  const loadEventsLocales = () => {
    const filteredLocations = events
      .filter(
        (event) =>
          event?.eventLocal?.address?.city.toUpperCase() === watchCity?.toUpperCase() &&
          event?.eventLocal?.address?.state.toUpperCase() === watchState.toUpperCase()
      )
      .map((event) => event);

    setListLocationsEvents(filteredLocations);
  };

  function loadTestDrives() {
    const filteredTestDriveEvents = listLocationsEvents?.filter(
      (event) => event?.id === watchEventEditionId && event?.testDrives?.length > 0 && event?.hasTestDrive === true
    );
    const resultListTestDrives = [...new Set(filteredTestDriveEvents.map((event) => event?.testDrives))].sort();
    setListTestDrives(resultListTestDrives?.[0] || []);
  }

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setValue('city', 'placeholder');
    setValue('state', selected);
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setValue('eventEditionId', null);
    setValue('city', selected);
  }

  function onChangeVehicleModelVersion(e) {
    const selected = e.currentTarget.value;
    setValue('vehicleEventId', selected);
    setMessageError('');
  }

  function onChangeEventEditionId(e) {
    const selected = e.currentTarget.value;
    setValue('eventEditionId', selected);
    setValue('vehicleEventId', 'placeholder');
  }

  const handleRadioClick = (item) => {
    if (item?.availableVacancies === 0) {
      setSelectedTestDriveEventId(null);
    } else {
      setSelectedTestDriveEventId(item.id);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  function getAttractingMedia() {
    if (typeof window === 'undefined') {
      return attractingMedia || '';
    }

    let params = new URL(document.location).searchParams;
    let currentAttracting = attractingMedia || '';
    if (params.has('attr_m')) {
      currentAttracting = params.get('attr_m');
    }

    return currentAttracting;
  }

  function isValidImageUrl(url) {
    return /^https?:\/\//.test(url);
  }

  function constructTestDriveData(listTestDrives, selectedTestDriveEventId) {
    return listTestDrives?.map((event) => {
      const allOffsetsUnavailable = event?.offsets?.every((offset) => offset?.availableVacancies === 0);
      const selectedOffset = event?.offsets?.find((offset) => offset?.id === selectedTestDriveEventId);
      const selectedVehicle = selectedOffset
        ? listTestDrives?.find((item) => item?.offsets?.includes(selectedOffset))
        : null;

      const selectedVehicleAvailable =
        selectedVehicle?.vehicleModelYear?.version?.name === event?.vehicleModelYear?.version?.name;

      const filteredRegularImages = event?.vehicleModelYear?.medias?.filter(
        (image) => image?.media?.mediaType?.title === 'image' && image?.tag === 'display:regular'
      );

      const filteredHoverImages = event?.vehicleModelYear?.medias?.filter(
        (image) => image?.media?.mediaType?.title === 'image' && image?.tag === 'display:hover'
      );

      const createImageObject = (image) => ({
        tag: image?.tag || '',
        url: isValidImageUrl(image?.media?.url)
          ? image?.media?.url
          : '/images/' +
            (image?.tag === 'display:regular'
              ? 'image-car-not-available-text.webp'
              : 'image-car-not-available-text.webp'),
        alt: image?.media?.title,
        type: image?.media?.mediaType?.title
      });

      const combinedImageList = [];
      const listImageHover = [];
      const listImageRegular = [];

      if (filteredRegularImages.length > 0) {
        combinedImageList.push(createImageObject(filteredRegularImages[0]));
      }

      if (filteredHoverImages.length > 0) {
        const roverImage = filteredHoverImages[0];
        combinedImageList.push(createImageObject(roverImage));
        listImageHover.push(createImageObject(roverImage));
        listImageRegular.push(createImageObject(filteredRegularImages[0]));
      }

      return {
        allOffsetsUnavailable,
        selectedVehicleAvailable,
        combinedImageList,
        listImageHover,
        listImageRegular
      };
    });
  }

  function isYes(value) {
    return value === 'yes';
  }

  function convertToNumber(str) {
    if (str === '') {
      return 0;
    }
    const number = parseFloat(str);
    return isNaN(number) ? 0 : number;
  }

  function createUpdatedData(data, locale) {
    return {
      attractingMedia: getAttractingMedia(),
      birthDate: data?.birthDate,
      captchaPayload: data?.captchaPayload,
      companyQuantity: convertToNumber(data?.companyQuantity),
      doc: isNumberMask(data?.cpf),
      email: data?.email,
      eventEditionId: data?.eventEditionId || '',
      firstName: data?.firstName,
      goWithCompany: isYes(data?.goWithCompany),
      lastName: data?.lastName,
      phone: isNumberMask(data?.phone),
      testDriveOffsetId: data?.testDriveOffsetId,
      wantATestDrive: isYes(data?.wantATestDrive),
      locale: locale,
      formType: 'EVENT_FORM'
    };
  }

  async function fetchTestDrives(eventEditionId) {
    try {
      const response = await fetch(`/api/events/eventEditions?eventEditionId=${eventEditionId}`);
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      throw new Error('Error fetching test drives');
    }
  }

  async function validateAndPrepareForm(data) {
    const updateData = createUpdatedData(data, locale);

    const selectedEvent = events.find((event) => event.id === data?.eventEditionId);
    const emptyTestDrive = testDrives?.length === 0;

    if (!selectedEvent?.hasTestDrive || emptyTestDrive) {
      await submitFormToAPI(updateData);
      return;
    }

    const responseData = await fetchTestDrives(data?.eventEditionId);

    const testDrives = responseData?.testDrives;
    const selectedOffsetId = data?.testDriveOffsetId;

    const selectedTestDrive = testDrives?.find((testDrive) =>
      testDrive.offsets.some((offset) => offset.id === selectedOffsetId)
    );

    const selectedOffset = selectedTestDrive?.offsets.find((offset) => offset.id === selectedOffsetId);
    const isOffsetAvailable = selectedOffset?.availableVacancies > 0;

    if (isOffsetAvailable) {
      await submitFormToAPI(updateData);
      return;
    }

    if (!isOffsetAvailable) {
      setIsFormSubmitedEnabled(false);
      setValue('testDriveOffsetId', '');
      setError('testDriveOffsetId', {
        type: 'manual',
        message: ''
      });
      setAvailableVacanciesDisable(true);
      setSelectedTestDriveEventId(null);
      setMessageError('O horário escolhido não está mais disponível. Escolha outro horário disponível.');
      return;
    }
  }

  async function submitFormToAPI(data) {
    const url = '/api/lead';
    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    try {
      const response = await fetch(url, opts);
      const isFormSubmittedSuccessfully = response.status === 200;
      if (isFormSubmittedSuccessfully) {
        setIsDisable(false);
        resetForm();
        setIsStatusComponent('success');
        return;
      }
      setIsDisable(false);
      setIsStatusComponent('error');
      console.error(t('error_title'), response.statusText + ' ' + response.status);
    } catch (error) {
      setIsDisable(false);
      setIsStatusComponent('error');
      console.log(error);
    }
  }

  const onSubmit = async (data, event) => {
    setIsDisable(true);
    event.preventDefault();

    const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');
      setIsDisable(true);

      return false;
    }

    data.captchaPayload = captchaPayload;

    const updateData = createUpdatedData(data, locale);

    if (!isFormSubmitedEnabled && listTestDrives?.length > 0) {
      await validateAndPrepareForm(data);
    }

    if (isFormSubmitedEnabled || listTestDrives?.length === 0) {
      await submitFormToAPI(updateData);
      return;
    }
  };

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('loading');
    window.location.reload();
    fetchData();
    setTimeout(() => {
      setIsStatusComponent('default');
    }, 5000);
  }

  return isStatusEvents === 'notAvailable' ? (
    <div />
  ) : (
    <Wrapper
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      className={`${css['molecule__events-form-container']} ${className}`}
      {...other}
    >
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>
      <Section tag="form" onSubmit={handleSubmit(onSubmit, onError)} className={css['form-container']}>
        {isStatusComponent === 'loading' && (
          <Wrapper spacingTop={6} spacingBottom={5} data-center-elements>
            <Loader />
          </Wrapper>
        )}
        {isStatusComponent === 'success' && (
          <SuccessSubmitForm
            className={css['result_submit']}
            onBackForm={onBackForm}
            titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
            contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
            contentBackForm={t('content_back_form')}
            contentBackHome={t('content_back_home')}
          />
        )}
        {isStatusComponent === 'error' && (
          <SuccessSubmitForm
            className={css['result_submit']}
            onBackForm={onBackForm}
            titleSuccess={titleError ? titleError : t('error_title')}
            contentSuccess={contentError ? contentError : t('error_content')}
            contentBackForm={t('content_back_form')}
            contentBackHome={t('content_back_home')}
          />
        )}

        {isStatusComponent === 'default' && (
          <>
            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader
                className={css['form-container_fieldset_header']}
                variant="1"
                legend={title ? title : t('title_form_personal_information')}
                description={description ? description : t('description_formpersonal_information')}
              />
              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  className={css['form-container_fieldset_horizontal_field']}
                  id="firstName"
                  label={t('label_first_name')}
                  placeholder={t('placeholder_first_name')}
                  dataErrors={errors['firstName']}
                  type="text"
                  dataRegister={register('firstName')}
                  isLongMessage={true}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />
                <FormInput
                  className={css['form-container_fieldset_horizontal_field']}
                  id="lastName"
                  label={t('label_last_name')}
                  placeholder={t('placeholder_last_name')}
                  dataRegister={register('lastName')}
                  dataErrors={errors['lastName']}
                  isLongMessage={true}
                  type="text"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />
                <FormInput
                  className={css['form-container_fieldset_horizontal_field']}
                  id="email"
                  label={t('label_email')}
                  dataErrors={errors['email']}
                  type="text"
                  placeholder={t('placeholder_email')}
                  dataRegister={register('email')}
                  displayLabel={true}
                  isLongMessage={true}
                />
              </div>
              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  className={css['form-container_fieldset_horizontal_field']}
                  id="phone"
                  label={t('label_phone')}
                  placeholder={t('placeholder_phone')}
                  dataErrors={errors['phone']}
                  type="tel"
                  maxLength="15"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = phoneMask(value);
                  }}
                  dataRegister={register('phone')}
                  displayLabel={true}
                  isLongMessage={true}
                />

                <FormInput
                  className={css['form-container_fieldset_horizontal_field']}
                  id="cpf"
                  label={t('label_cpf')}
                  placeholder={t('placeholder_cpf')}
                  dataRegister={register('cpf')}
                  dataErrors={errors['cpf']}
                  isLongMessage={true}
                  type="text"
                  maxLength="14"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = cpfMask(value);
                  }}
                />

                <FormInput
                  className={css['form-container_fieldset_horizontal_field']}
                  id="birthDate"
                  type="date"
                  label={t('label_birth_date')}
                  placeholder={t('placeholder_birth_date')}
                  dataRegister={register('birthDate')}
                  dataErrors={errors['birthDate']}
                  isLongMessage={true}
                />
              </div>
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader
                className={css['form-container_fieldset_header']}
                legend={t('title_form_event_editing')}
                description={t('description_form_event_editing')}
              />
              <div className={css['form-container_fieldset_horizontal']}>
                <FormSelect
                  className={css['form-container_fieldset_horizontal_field_select']}
                  id="state"
                  label={t('label_state')}
                  placeholder={t('placeholder_state')}
                  dataErrors={errors['state']}
                  dataRegister={register('state')}
                  onChange={onChangeState}
                >
                  {listStates.length > 0 &&
                    listStates?.map((state, index) => {
                      return (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      );
                    })}
                </FormSelect>

                <FormSelect
                  className={css['form-container_fieldset_horizontal_field_select']}
                  id="city"
                  label={t('label_city')}
                  placeholder={t('placeholder_city')}
                  dataErrors={errors['city']}
                  dataRegister={register('city')}
                  onChange={onChangeCity}
                >
                  {watchState &&
                    listCities.length > 0 &&
                    listCities?.map((city, index) => {
                      return (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      );
                    })}
                </FormSelect>
              </div>
              {watchState && watchCityAvailable && (
                <FormRadioGroup
                  name="eventEditionId"
                  dataRegister={register('eventEditionId')}
                  dataErrors={errors['eventEditionId']}
                  className={css['form-container_radio_group_events']}
                >
                  {listLocationsEvents.map((event, index) => {
                    return (
                      <FormRadioButton
                        key={index}
                        id={event?.id}
                        value={event?.id}
                        onChange={onChangeEventEditionId}
                        variant="type-1"
                        className={css['form-container_radio_group_events_locale']}
                      >
                        <div>
                          <span>{event?.eventLocal?.name}</span>
                          <span>{dateIso2Br(event?.startAt)}</span>
                        </div>
                      </FormRadioButton>
                    );
                  })}
                </FormRadioGroup>
              )}
              {watchEventEditionId && (
                <FormRadioGroup
                  label={t('label_go_with_company')}
                  name="goWithCompany"
                  dataRegister={register('goWithCompany')}
                  dataErrors={errors['goWithCompany']}
                  className={css['form-container_group_go_with_company']}
                >
                  <FormRadioButton id="yes_companions" label={t('label_go_with_company_yes')} value="yes" />
                  <FormRadioButton id="no_companions" label={t('label_go_with_company_no')} value="no" />
                </FormRadioGroup>
              )}
              {watchGoWithCompanyAvailable && (
                <div className={css['form-container_fieldset_horizontal']}>
                  <FormInput
                    className={css['form-container_fieldset_horizontal_field']}
                    id="companyQuantity"
                    label={t('label_company_quantity')}
                    placeholder={t('placeholder_company_quantity')}
                    dataErrors={errors['companyQuantity']}
                    type="text"
                    dataRegister={register('companyQuantity')}
                    isLongMessage={true}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isNumberMask(value);
                    }}
                  />
                </div>
              )}
            </fieldset>
            {testDriveListAvailable && (
              <fieldset className={css['form-container_fieldset']}>
                <FieldsetHeader legend={t('title_form_test_drive')} description={t('description_form_test_drive')} />
                <div className={css['form-container_fieldset_horizontal']}>
                  <FormRadioGroup
                    name="wantATestDrive"
                    dataRegister={register('wantATestDrive')}
                    dataErrors={errors['wantATestDrive']}
                    className={css['form-container_group_test_drive_want']}
                  >
                    <FormRadioButton id="yes" label={t('label_test_drive_yes')} value="yes" />
                    <FormRadioButton id="no" label={t('label_test_drive_no')} value="no" />
                  </FormRadioGroup>
                </div>

                {isMobile && testDriveListAvailable && watchTestDriveAvailable && (
                  <div>
                    <FormSelect
                      className={css['form-container_fieldset_horizontal_field_select']}
                      id="vehicleEventId"
                      label={t('label_vehicle_event_id')}
                      placeholder={t('placeholder_vehicle_event_id')}
                      dataErrors={errors['vehicleEventId']}
                      dataRegister={register('vehicleEventId')}
                      onChange={onChangeVehicleModelVersion}
                    >
                      {listTestDrives?.map((testDrive, index) => {
                        const vehicleName = testDrive.vehicleModelYear.version.vehicle.name;
                        const vehicleVersion = testDrive.vehicleModelYear.version.name;
                        const optionValue = testDrive.id;
                        return (
                          <option key={index} value={optionValue}>
                            {`${vehicleName} / ${vehicleVersion}`}
                          </option>
                        );
                      })}
                    </FormSelect>
                  </div>
                )}

                {testDriveListAvailable && watchTestDriveAvailable && (
                  <div className={css['form-container_group_test_drive']}>
                    {listTestDrives?.map((event, index) => {
                      if (!isMobile || event.id === watchVehicleEventId) {
                        const {
                          allOffsetsUnavailable,
                          selectedVehicleAvailable,
                          combinedImageList,
                          listImageHover,
                          listImageRegular
                        } = constructTestDriveData([event], selectedTestDriveEventId)[0];

                        return (
                          <div key={index}>
                            {!isMobile && (
                              <ThumbHover
                                className={css['form-container_group_test_drive_thumbHouver']}
                                image={
                                  selectedVehicleAvailable && watchTestDriveSelectedAvailable
                                    ? listImageHover
                                    : combinedImageList
                                }
                              />
                            )}
                            {isMobile && (
                              <ThumbHover
                                className={css['form-container_group_test_drive_thumbHouver']}
                                image={selectedVehicleAvailable ? listImageHover : listImageRegular}
                              />
                            )}
                            <div className={css['form-container_group_test_drive_content']}>
                              <Title
                                color={
                                  selectedVehicleAvailable && watchTestDriveSelectedAvailable ? 'primary' : undefined
                                }
                                style={{
                                  fontWeight:
                                    selectedVehicleAvailable && watchTestDriveSelectedAvailable ? 'bold' : 'normal'
                                }}
                                className={css['form-container_group_test_drive_content_title']}
                              >
                                {event?.vehicleModelYear?.version?.name}
                                {allOffsetsUnavailable && (
                                  <span className={css['form-container_group_test_drive_content_not-available']}>
                                    {' '}
                                    {t('all_off_sets_unavailable')}
                                  </span>
                                )}
                              </Title>
                              <span
                                className={
                                  css[
                                    selectedVehicleAvailable && watchTestDriveSelectedAvailable
                                      ? 'form-container_group_test_drive_content_subtitle--selected'
                                      : 'form-container_group_test_drive_content_subtitle'
                                  ]
                                }
                              >
                                {event?.vehicleModelYear?.version?.vehicle?.name}
                              </span>
                            </div>
                            <FormRadioGroup
                              name="testDriveOffsetId"
                              dataRegister={register('testDriveOffsetId')}
                              dataErrors={errors['testDriveOffsetId']}
                              className={css['form-container_group_test_drive_time']}
                            >
                              {event?.offsets?.map((item, index) => (
                                <FormRadioButton
                                  key={index}
                                  id={item?.id}
                                  value={item?.id}
                                  variant="type-2"
                                  disabled={
                                    item?.availableVacancies === 0 || availableVacanciesDisable || allOffsetsUnavailable
                                  }
                                  className={
                                    css[
                                      `form-container_group_test_drive_radio_button${
                                        (item?.availableVacancies === 0 && selectedTestDriveEventId !== item?.id) ||
                                        availableVacanciesDisable
                                          ? '_disabled'
                                          : ''
                                      }`
                                    ]
                                  }
                                  onClick={() => handleRadioClick(item)}
                                >
                                  <p className={css['form-container_group_test_drive_info_time']}>
                                    {extractTimeFromDateBr(item?.startAt)} às {extractTimeFromDateBr(item?.finishAt)}
                                  </p>
                                </FormRadioButton>
                              ))}
                            </FormRadioGroup>
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                )}
                {messageError && <span className={css['form-container_message_error']}>{messageError}</span>}
              </fieldset>
            )}

            <fieldset className={css['form-container_fieldset']}>
              <FormCheckbox
                id="agreement"
                dataErrors={errors['agreement']}
                className={css['form-container_fieldset_agreement']}
                dataRegister={register('agreement')}
              >
                {t('label_agreement')}&nbsp;
                <AnchorLink link={t('link_label_agreement')} hasArrow={false} linkLabel={t('link_label_agreement')} />
              </FormCheckbox>
              <Button
                className={css['form-container_fieldset_button_submit']}
                type="submit"
                disabled={!formState.isValid || isDisable}
              >
                {t('button_submit_form')}
              </Button>
            </fieldset>
          </>
        )}
      </Section>
    </Wrapper>
  );
}

export default EventsForm;
