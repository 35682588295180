import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import { extractNumbers, validNumber } from 'utils/validations';
import { empty, slugfy } from 'utils/functions';
import { isTextMask } from 'utils/forms';
import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Script from 'next/script';

import AnchorLink from 'components/atoms/anchor-link';
import Title from 'components/atoms/title';
import Button from 'components/atoms/button';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';
import SectionHeader from 'components/molecules/section-header';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import Loader from 'components/atoms/loader';
import SuccessSubmitForm from 'components/molecules/success-submit-form';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Organism SpecialSalesForm
 *
 * special sales form
 */
function SpecialSalesForm(props) {
  const t = i18n.useTranslations('components.special-sales-form');
  const {
    className = '',
    dealers = [],
    disabilitiesPeopleModels = [],
    diplomatsModels = [],
    fleetsModels = [],
    title,
    description,
    spacingTop,
    spacingBottom,
    attractingMedia,
    locale,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    ...other
  } = props;

  // This list will be managed by AMOS
  const industryOptions = [
    { value: '', label: '--Ninguno--' },
    { value: 'Agricola', label: 'Agrícola' },
    { value: 'Textil', label: 'Textil' },
    { value: 'Bancario', label: 'Bancario' },
    { value: 'Biotecnologia', label: 'Biotecnología' },
    { value: 'Quimico', label: 'Químico' },
    { value: 'Comunicaciones', label: 'Comunicaciones' },
    { value: 'Construccion', label: 'Construcción' },
    { value: 'Consultoria', label: 'Consultoría' },
    { value: 'Educativo', label: 'Educativo' },
    { value: 'Electronico', label: 'Electrónico' },
    { value: 'Energetico', label: 'Energético' },
    { value: 'Ingenieria', label: 'Ingeniería' },
    { value: 'Entretenimiento', label: 'Entretenimiento' },
    { value: 'Medioambiental', label: 'Medioambiental' },
    { value: 'Financiero', label: 'Financiero' },
    { value: 'Alimentos y Bebidas', label: 'Alimentos y Bebidas' },
    { value: 'Gubernamental', label: 'Gubernamental' },
    { value: 'Salud', label: 'Salud' },
    { value: 'Hotelero', label: 'Hotelero' },
    { value: 'Seguros', label: 'Seguros' },
    { value: 'Maquinaria', label: 'Maquinaria' },
    { value: 'Manufacturero', label: 'Manufacturero' },
    { value: 'Medios de comunicacion', label: 'Medios de comunicación' },
    { value: 'Sin fines de lucro', label: 'Sin fines de lucro' },
    { value: 'Otro', label: 'Otro' },
    { value: 'Recreativo', label: 'Recreativo' },
    { value: 'Minorista', label: 'Minorista' },
    { value: 'Envios', label: 'Envíos' },
    { value: 'Tecnologia', label: 'Tecnología' },
    { value: 'Telecomunicaciones', label: 'Telecomunicaciones' },
    { value: 'Transporte', label: 'Transporte' },
    { value: 'Servicios publicos', label: 'Servicios públicos' }
  ];

  const desiredContactMethodOptions = [
    { value: '', label: '--Ninguno--' },
    { value: 'Email', label: 'Email' },
    { value: 'Celular', label: 'Celular' },
    { value: 'Telefono', label: 'Teléfono' },
    { value: 'Whatsapp', label: 'Whatsapp' }
  ];

  const customerTypeOptions = [
    { value: 'Flota', label: 'Flotas' },
    { value: 'Discapacidad', label: 'Personas con Discapacidad' },
    { value: 'Diplomático', label: 'Diplomáticos' }
  ];

  const country = `${locale}`.slice(-2);
  const queryUrlTypeReason = getQueryParam('type');

  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isDisable, setIsDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listStates, setListStates] = useState([]);
  const [dealershipCities, setDealershipCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const validationSchemaConfig = {
    clientType: yup.string(),
    firstName: yup
      .string()
      .required(t('message_error_required_first_name'))
      .min(1, t('message_error_min_char_first_name'))
      .max(40, t('message_error_max_char_first_name')),
    lastName: yup
      .string()
      .required(t('message_error_required_last_name'))
      .min(1, t('message_error_min_char_last_name'))
      .max(80, t('message_error_max_char_last_name')),
    email: yup
      .string()
      .required(t('message_error_required_email'))
      .email(t('message_error_invalid_email'))
      .max(80, t('message_error_max_char_email')),
    telephone: yup
      .string()
      .min(8, t('message_error_min_char_telephone'))
      .max(12, t('message_error_max_char_telephone'))
      .matches(/^[1-9]\d{7,11}$/, t('message_error_invalid_telephone_prefix'))
      .transform((valuePhone) => (valuePhone === '' ? undefined : valuePhone))
      .nullable()
      .notRequired(),
    mobile: yup
      .string()
      .required(t('message_error_required_mobile'))
      .min(8, t('message_error_min_char_mobile'))
      .max(12, t('message_error_max_char_mobile'))
      .matches(/^(?!0|15)\d+$/, t('message_error_invalid_mobile_prefix'))
      .test('test-invalid-repeated-digits', t('message_error_invalid_mobile_repeated_digits'), function (phoneNumber) {
        return !/(\d)\1{4,}/.test(phoneNumber);
      })
      .nullable(),
    contactMethod: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_contact_method'))
      .required(t('message_error_required_contact_method'))
      .nullable(),
    state: yup
      .string()
      .required(t('message_error_required_state'))
      .min(1, t('message_error_min_char_state'))
      .max(80, t('message_error_max_char_state')),
    city: yup
      .string()
      .required(t('message_error_required_city'))
      .min(1, t('message_error_min_char_city'))
      .max(80, t('message_error_max_char_city')),
    zip: yup
      .string()
      .matches(/^\d{4}$/, t('message_error_invalid_zip'))
      .transform((valueZip) => (valueZip === '' ? undefined : valueZip))
      .nullable()
      .notRequired(),
    country: yup
      .string()
      .min(1, t('message_error_min_char_country'))
      .max(80, t('message_error_max_char_country'))
      .transform((valueCountry) => (valueCountry === '' ? undefined : valueCountry))
      .nullable()
      .notRequired(),
    currentVehicle: yup
      .string()
      .min(1, t('message_error_min_char_current_vehicle'))
      .max(255, t('message_error_max_char_current_vehicle'))
      .transform((valueVehicle) => (valueVehicle === '' ? undefined : valueVehicle))
      .nullable()
      .notRequired(),
    dealershipState: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership_state'))
      .required(t('message_error_required_dealership_state')),
    dealershipCity: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership_city'))
      .required(t('message_error_required_dealership_city')),
    dealership: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership'))
      .required(t('message_error_required_dealership')),
    carModel: yup
      .string()
      .transform((valueModel) => (valueModel === '' ? undefined : valueModel))
      .nullable()
      .notRequired(),
    agreement: yup.boolean().oneOf([true], t('message_error_required_agreement')),
    company: yup
      .string()
      .max(255, t('message_error_max_char_company'))
      .transform((valueCompany) => (valueCompany === '' ? undefined : valueCompany))
      .nullable()
      .notRequired(),
    industry: yup.string().when('clientType', {
      is: (clientType) => clientType === 'Flota',
      then: yup.string().nullable().notOneOf(['placeholder'], t('message_error_required_industry')),
      otherwise: yup.string().nullable()
    }),
    unityQuantities: yup
      .string()
      .max(18, t('message_error_max_char_unity-quantity'))
      .transform((valueQtds) => (valueQtds === '' ? undefined : valueQtds))
      .nullable()
      .notRequired(),
    fleetsDetails: yup
      .string()
      .max(255, t('message_error_max_char_fleets_details'))
      .transform((valueDetails) => (valueDetails === '' ? undefined : valueDetails))
      .nullable()
      .notRequired(),
    fleetsSize: yup
      .string()
      .max(255, t('message_error_max_char_fleets_size'))
      .transform((valueSize) => (valueSize === '' ? undefined : valueSize))
      .nullable()
      .notRequired()
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    trigger,
    formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      clientType: queryUrlTypeReason || 'Flota',
      industry: 'placeholder'
    }
  });

  const watchDealershipState = watch('dealershipState');
  const watchDealershipCity = watch('dealershipCity');
  const watchClientType = watch('clientType');
  const watchCityAvailable = watch('dealershipCity') !== 'placeholder';

  const watchedFields = watch([
    'clientType',
    'firstName',
    'lastName',
    'contactMethod',
    'email',
    'mobile',
    'state',
    'city',
    'dealership',
    'dealershipCity',
    'dealershipState'
  ]);

  function validateForm() {
    const isFormEmpty = watchedFields.some((value) => empty(value) || value === 'placeholder');

    const isIndustryEmpty = watch('industry') === 'placeholder';

    if (watchClientType === 'Flota') {
      setIsDisable(isIndustryEmpty || isFormEmpty);
      return;
    }
    setIsDisable(isFormEmpty);
    return;
  }

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state.address.state))].sort());
  }

  function loadDealershipCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() == selectedState?.toUpperCase();
    });

    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();

    setDealershipCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = dealers?.filter((dealer) => {
      return dealer?.address?.city?.toUpperCase() == selectedCity?.toUpperCase();
    });
    setListDealers(filtered);
  }

  function onChangeDealershipState(e) {
    const selected = e.currentTarget.value;
    setValue('dealershipCity', 'placeholder');
    setValue('dealership', 'placeholder');
    setValue('dealershipState', selected);
  }

  function onChangeDealershipCity(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('dealershipCity', selected);
  }

  function onChangeClientType(e) {
    const selected = e.currentTarget.value;
    setValue('clientType', selected);
    updateURL(selected);
  }

  function getQueryParam(parameterName) {
    if (typeof window === 'undefined') {
      return null;
    }

    const params = new URL(document.location).searchParams;
    return params.get(parameterName);
  }

  function getParamUrlSelectedReasonType() {
    const queryUrlTypeReason = getQueryParam('type');

    if (!queryUrlTypeReason) {
      setValue('clientType', watchClientType);
      updateURL(slugfy(watchClientType));
      return;
    }

    const selectedType = customerTypeOptions.find(
      (element) => slugfy(element?.value).toLowerCase() === slugfy(queryUrlTypeReason).toLowerCase()
    );

    const typeValueToSet = selectedType?.value || watchClientType;

    if (typeValueToSet) {
      setValue('clientType', typeValueToSet);
      updateURL(slugfy(typeValueToSet));
      return;
    }
  }

  function getDealerSelect(selectedDealerName) {
    const selectedDealer = listDealers?.find(
      (dealer) => dealer?.name.toUpperCase() === selectedDealerName.toUpperCase()
    );

    return selectedDealer;
  }

  function getAttractingMedia() {
    let params = new URL(document.location).searchParams;

    if (typeof window === 'undefined' || !params.has('attr_m')) {
      return attractingMedia || '';
    }

    return params.get('attr_m');
  }

  function getVehicleValue(model) {
    if (country === 'ar') {
      return model?.vehicleExternalId;
    }
  }

  function getLabelByValueCustomerType(selectedValue, options) {
    const selectedOption = options?.find((option) => option?.value === selectedValue);
    return selectedOption ? selectedOption.label : '';
  }

  useEffect(() => {
    loadStates();
  }, []);

  useEffect(() => {
    loadDealershipCities(watchDealershipState);
  }, [watchDealershipState]);

  useEffect(() => {
    if (watchCityAvailable) {
      loadDealers(watchDealershipCity);
    }
  }, [watchDealershipCity]);

  useEffect(() => {
    getParamUrlSelectedReasonType();
  }, [watchClientType]);

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  const resetForm = useCallback(() => {
    const formValues = getValues();
    Object.keys(formValues).forEach((field) => {
      const initialValue = field === 'state' || field === 'city' ? 'placeholder' : '';
      setValue(field, initialValue);
    });
    reset();
  }, [
    reset,
    setValue,
    getValues
  ]);

  function updateURL(selected) {
    const formattedValue = slugfy(selected);
    const newURL = `${window.location.pathname}?type=${formattedValue}`;
    window.history.replaceState(null, '', newURL);
  }

  function createUpdatedData(data) {
    const selectedContact = data?.clientType;

    const selectedDealer = getDealerSelect(data?.dealership);
    const selectedDealerDn = selectedDealer?.dn;

    if (selectedContact === 'Flota') {
      delete data.currentVehicle;
    } else {
      delete data.company;
      delete data.industry;
      delete data.unityQuantities;
      delete data.fleetsDetails;
      delete data.fleetsSize;
      delete data.industry;
    }

    data.dn = selectedDealerDn;
    data.attractingMedia = getAttractingMedia();

    if ('mobile' in data) {
      data.mobile = '+549' + data.mobile;
    }

    delete data.agreement;
    delete data.dealershipState;
    delete data.dealershipCity;
    delete data.dealership;

    data = { ...data, attractingMedia: getAttractingMedia() };

    return data;
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  const sendRequest = async (url, data) => {
    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    return await fetch(url, opts);
  };

  const handleResponse = (success) => {
    if (success) {
      setIsStatusComponent('success');
      resetForm(reset);
      return;
    }
    setIsStatusComponent('error');
    return;
  };
  const onSubmit = async (data) => {
    setIsStatusComponent('loading');
    setIsSubmitting(true);
    try {
      Object.keys(data)?.map((field) => {
        if (data[field] == 'placeholder' || data[field] == null) {
          data[field] = '';
        }
      });

      const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');

      if (!captchaPayload) {
        console.debug('Falhou verificação do recaptcha');
        handleResponse(false);
      }

      data.captchaPayload = captchaPayload;

      const updateData = createUpdatedData(data);

      const url = '/api/lead/lead-special-sales';

      const response = await sendRequest(url, updateData);

      handleResponse(response.ok && response.status === 200);
    } catch (error) {
      console.error('Erro durante o envio do formulário:', error);
      handleResponse(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onError = (errors, e) => console.log(errors, e);

  return (
    <Section className={`${css['organism__special-sales-form-container']} ${className}`} {...other}>
      <Wrapper spacingTop={spacingTop} spacingBottom={spacingBottom}>
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
        ></Script>
        {isStatusComponent === 'success' && (
          <SuccessSubmitForm
            onBackForm={onBackForm}
            titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
            contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
            contentBackForm={t('interest_content_back_form')}
            contentBackHome={t('interest_content_back_home')}
          />
        )}
        {isStatusComponent === 'error' && (
          <SuccessSubmitForm
            onBackForm={onBackForm}
            titleSuccess={titleError ? titleError : t('error_title')}
            contentSuccess={contentError ? contentError : t('error_content')}
            contentBackForm={t('interest_content_back_form')}
            contentBackHome={t('interest_content_back_home')}
          />
        )}

        {isStatusComponent === 'loading' && (
          <div className={css['container-loader']}>
            <Loader />
          </div>
        )}
        {isStatusComponent === 'default' && (
          <form className={css['form-container']} onSubmit={handleSubmit(onSubmit, onError)}>
            <SectionHeader
              className={css['form-container__header']}
              title={
                (title ? title : t('title_form')) + ' ' + getLabelByValueCustomerType(watchClientType).toLowerCase()
              }
              description={{
                value: description ? description : t('description_form'),
                textColor: 'gray-4'
              }}
            />
            <div className={css['form-container__section']}>
              <Title className={css['form-container__title']} variant={5}>
                {t('select_clientType')}
              </Title>

              <FormSelect
                id="clientType"
                className={css['form-container__input']}
                placeholder={t('placeholder_clientType')}
                dataRegister={register('clientType')}
                dataErrors={errors['clientType']}
                displayError={true}
                onChange={onChangeClientType}
              >
                {customerTypeOptions?.map((customer, index) => (
                  <option key={index} value={customer?.value}>
                    {customer?.label}
                  </option>
                ))}
              </FormSelect>
            </div>

            <div className={css['form-container__section']}>
              <Title className={css['form-container__title']} variant={5}>
                {t('title_section_personal_information')}
              </Title>

              <div className={css['form-container__list-input']}>
                <FormInput
                  id="firstName"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_firstName')}
                  placeholder={t('placeholder_firstName')}
                  dataRegister={register('firstName')}
                  dataErrors={errors['firstName']}
                  maxLength="40"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  id="lastName"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_lastName')}
                  placeholder={t('placeholder_lastName')}
                  dataRegister={register('lastName')}
                  dataErrors={errors['lastName']}
                  maxLength="80"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  id="email"
                  type="email"
                  className={css['form-container__input']}
                  label={t('label_email')}
                  placeholder={t('placeholder_email')}
                  dataRegister={register('email')}
                  dataErrors={errors['email']}
                  maxLength="80"
                />

                <FormInput
                  id="telephone"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_telephone')}
                  placeholder={t('placeholder_telephone')}
                  dataRegister={register('telephone')}
                  dataErrors={errors['telephone']}
                  minLength="8"
                  maxLength="12"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = validNumber(value);
                  }}
                />

                <FormInput
                  id="mobile"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_mobile')}
                  placeholder={t('placeholder_mobile')}
                  dataRegister={register('mobile')}
                  dataErrors={errors['mobile']}
                  minLength="8"
                  maxLength="12"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = validNumber(value);
                  }}
                />

                <FormSelect
                  id="contactMethod"
                  className={css['form-container__input']}
                  placeholder={t('placeholder_contactMethod')}
                  onChange={(e) => {
                    setValue('contactMethod', e.currentTarget.value);
                  }}
                  label={t('label_contactMethod')}
                  dataRegister={register('contactMethod')}
                  dataErrors={errors['contactMethod']}
                >
                  {desiredContactMethodOptions?.map((option, index) => (
                    <option key={index} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </FormSelect>

                <FormInput
                  id="state"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_state')}
                  placeholder={t('placeholder_state')}
                  dataRegister={register('state')}
                  dataErrors={errors['state']}
                  maxLength="80"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  id="city"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_city')}
                  placeholder={t('placeholder_city')}
                  dataRegister={register('city')}
                  dataErrors={errors['city']}
                  maxLength="80"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  id="zip"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_zip')}
                  placeholder={t('placeholder_zip')}
                  dataRegister={register('zip')}
                  dataErrors={errors['zip']}
                  maxLength="4"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = validNumber(value);
                  }}
                />

                <FormInput
                  id="country"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_country')}
                  placeholder={t('placeholder_country')}
                  dataRegister={register('country')}
                  dataErrors={errors['country']}
                  minLength="1"
                  maxLength="80"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                {(watchClientType === 'Diplomático' || watchClientType === 'Discapacidad') && (
                  <FormInput
                    id="currentVehicle"
                    type="text"
                    className={css['form-container__input']}
                    label={t('label_current_vehicle')}
                    placeholder={t('placeholder_current_vehicle')}
                    dataRegister={register('currentVehicle')}
                    dataErrors={errors['currentVehicle']}
                    maxLength="255"
                  />
                )}
              </div>
            </div>

            {watchClientType === 'Flota' && (
              <div className={css['form-container__section']}>
                <Title className={css['form-container__title']} variant={5}>
                  {t('title_company_information')}
                </Title>

                <div className={css['form-container__list-input']}>
                  <FormInput
                    id="company"
                    type="text"
                    className={css['form-container__input']}
                    label={t('label_company')}
                    placeholder={t('placeholder_company')}
                    dataRegister={register('company')}
                    dataErrors={errors['company']}
                    maxLength="255"
                  />

                  <FormSelect
                    id="industry"
                    className={css['form-container__input']}
                    placeholder={t('placeholder_industry')}
                    onChange={(e) => {
                      setValue('industry', e.currentTarget.value);
                      trigger('commentary');
                    }}
                    label={t('label_industry')}
                    dataRegister={register('industry')}
                    dataErrors={errors['industry']}
                  >
                    {industryOptions?.map((industry, index) => (
                      <option key={index} value={industry?.value}>
                        {industry?.label}
                      </option>
                    ))}
                  </FormSelect>

                  <FormInput
                    id="unityQuantities"
                    type="text"
                    className={css['form-container__input']}
                    label={t('label_unity_quantities')}
                    placeholder={t('placeholder_unity_quantities')}
                    dataRegister={register('unityQuantities')}
                    dataErrors={errors['unityQuantities']}
                    maxLength={18}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = extractNumbers(value);
                    }}
                  />

                  <FormInput
                    id="fleetsDetails"
                    type="text"
                    className={css['form-container__input']}
                    label={t('label_fleets_details')}
                    placeholder={t('placeholder_fleets_details')}
                    dataRegister={register('fleetsDetails')}
                    dataErrors={errors['fleetsDetails']}
                    maxLength={255}
                  />

                  <FormInput
                    id="fleetsSize"
                    type="text"
                    className={css['form-container__input']}
                    label={t('label_fleets_size')}
                    placeholder={t('placeholder_fleets_size')}
                    dataRegister={register('fleetsSize')}
                    dataErrors={errors['fleetsSize']}
                    maxLength={255}
                  />
                </div>
              </div>
            )}

            <div className={css['form-container__section']}>
              <Title className={css['form-container__title']} variant={5}>
                {t('title_search_a_dealership')}
              </Title>

              <div className={css['form-container__list-input']}>
                <FormSelect
                  id="dealershipState"
                  className={css['form-container__input']}
                  label={t('label_dealershipState')}
                  placeholder={t('placeholder_dealershipState')}
                  dataRegister={register('dealershipState')}
                  dataErrors={errors['dealershipState']}
                  onChange={onChangeDealershipState}
                >
                  {listStates?.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </FormSelect>

                <FormSelect
                  id="dealershipCity"
                  className={css['form-container__input']}
                  label={t('label_dealershipCity')}
                  placeholder={t('placeholder_dealershipCity')}
                  onChange={onChangeDealershipCity}
                  dataRegister={register('dealershipCity')}
                  dataErrors={errors['dealershipCity']}
                >
                  {watchDealershipState &&
                    dealershipCities?.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                </FormSelect>

                <FormSelect
                  id="dealership"
                  className={css['form-container__input']}
                  label={t('label_dealership')}
                  placeholder={t('placeholder_dealership')}
                  onChange={(e) => {
                    setValue('dealership', e.currentTarget.value);
                  }}
                  dataRegister={register('dealership')}
                  dataErrors={errors['dealership']}
                >
                  {watchDealershipCity &&
                    listDealers?.map((dealer, index) => (
                      <option key={index} value={dealer.name}>
                        {dealer.name.toUpperCase()}
                      </option>
                    ))}
                </FormSelect>
              </div>
            </div>

            <div className={css['form-container__section']}>
              <Title className={css['form-container__title']} variant={5}>
                {t('title_choose_model')}
              </Title>
              <div className={css['form-container__list-input']}>
                <FormSelect
                  id="carModel"
                  className={css['form-container__input']}
                  label={t('label_model_interest')}
                  placeholder={t('placeholder_model_interest')}
                  onChange={(e) => {
                    setValue('carModel', e.currentTarget.value);
                  }}
                  dataRegister={register('carModel')}
                  dataErrors={errors['carModel']}
                >
                  {watchClientType === 'Diplomático' &&
                    diplomatsModels &&
                    diplomatsModels?.map((model, index) => (
                      <option key={index} value={getVehicleValue(model)}>
                        {model.name}
                      </option>
                    ))}

                  {watchClientType == 'Discapacidad' &&
                    disabilitiesPeopleModels &&
                    disabilitiesPeopleModels?.map((model, index) => (
                      <option key={index} value={getVehicleValue(model)}>
                        {model.name}
                      </option>
                    ))}

                  {watchClientType === 'Flota' &&
                    fleetsModels &&
                    fleetsModels?.map((model, index) => (
                      <option key={index} value={getVehicleValue(model)}>
                        {model.name}
                      </option>
                    ))}
                </FormSelect>
              </div>

              <FormCheckbox
                className={css['form-container__checkbox-agreement']}
                id="agreement"
                dataRegister={register('agreement')}
                dataErrors={errors['agreement']}
                disabled={isDisable}
              >
                {t('legal_text')}&nbsp;
                <AnchorLink
                  link={t('link_legal_text')}
                  isExternal={true}
                  hasArrow={false}
                  linkLabel={t('label_link_legal_text')}
                />
              </FormCheckbox>
            </div>

            <div className={css['form-container__section']}>
              <Button type="submit" disabled={!formState.isValid || isSubmitting}>
                {t('button_submit_form')}
              </Button>
            </div>
          </form>
        )}
      </Wrapper>
    </Section>
  );
}

export default SpecialSalesForm;
