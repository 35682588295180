import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockProperty } from 'components/content-blocks/ContentBlockProperty';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

class WaveDivider implements ContentBlock {
  properties: ContentBlockProperty[] = [];

  label = 'Wave Divider';

  component = 'WaveDivider';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    {
      name: 'color',
      label: 'Wave color',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'white',
        'black',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'gray-7',
        'blue-1',
        'blue-2'
      ]
    },
    {
      name: 'orientation',
      label: 'Wave orientation',
      help: '',
      type: 'String',
      control: 'select',
      options: ['left', 'right']
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(content: any, extras: any): Promise<object> {
    const { color, orientation } = extras;

    return {
      color,
      orientation
    };
  }
}

export { WaveDivider };
