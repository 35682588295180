import React, { useState, useEffect } from 'react';
import i18n from 'utils/i18n';
import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Script from 'next/script';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Title from 'components/atoms/title';
import FormInput from 'components/atoms/form-input';
import FormTextArea from 'components/atoms/form-text-area';
import Button from 'components/atoms/button';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Section from 'components/utils/section';
import AnchorLink from 'components/atoms/anchor-link';
import ContentDivider from 'components/atoms/content-divider';

// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule KintoInterestForm
 *
 * Kinto interest form
 */

function KintoInterestForm(props) {
  const t = i18n.useTranslations('components.kinto-interest-form');

  const {
    className = '',
    children,
    locale,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    checkFormStatus = () => {},
    ...other
  } = props;

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('message_error_required_name')).min(4, t('message_error_min_char_name')),
    email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
    phone: yup.string(),
    commentary: yup.string().required(t('message_error_required_commentary'))
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [isStatusComponent, setIsStatusComponent] = useState('default');

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  const onSubmit = async (data) => {
    data = { ...data, locale, type: 'KINTO' };

    const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');

      return false;
    }

    data.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    const url = '/api/contact';

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsStatusComponent('success');
          checkFormStatus('success');
          return;
        }
        setIsStatusComponent('error');
        checkFormStatus('error');
      })
      .catch((err) => {
        setIsStatusComponent('error');
        checkFormStatus('error');
        console.log(err);
      });
  };

  useEffect(() => {
    checkFormStatus(isStatusComponent);
  }, [isStatusComponent]);

  return (
    <Section>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      {isStatusComponent === 'success' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
          contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
          contentBackForm={t('interest_content_back_form')}
          contentBackHome={t('interest_content_back_home')}
        />
      )}
      {isStatusComponent === 'error' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleError ? titleError : t('error_title')}
          contentSuccess={contentError ? contentError : t('error_content')}
          contentBackForm={t('interest_content_back_form')}
          contentBackHome={t('interest_content_back_home')}
        />
      )}
      {isStatusComponent === 'default' && (
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          className={`${css['molecule__kinto-interest-form-container']} ${className}`}
          {...other}
        >
          <div className={css['form-container__section']}>
            <Title className={css['form-container__title']} variant={5}>
              {t('title_section_personal_data')}
            </Title>

            <div className={css['form-container__list-input']}>
              <FormInput
                id="name"
                type="text"
                className={css['form-container__input']}
                label={t('label_name')}
                placeholder={t('placeholder_name')}
                dataRegister={register('name')}
                dataErrors={errors['name']}
              />

              <FormInput
                id="email"
                type="email"
                className={css['form-container__input']}
                label={t('label_email')}
                placeholder={t('placeholder_email')}
                dataRegister={register('email')}
                dataErrors={errors['email']}
              />

              <FormInput
                id="phone"
                type="tel"
                className={css['form-container__input']}
                label={t('label_phone')}
                placeholder={t('placeholder_phone')}
                dataRegister={register('phone')}
                maxLength={locale === 'pt-br' ? '15' : '17'}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = value;
                }}
              />
            </div>
          </div>

          <div className={css['form-container__section']}>
            <FormTextArea
              id="commentary"
              rows="3"
              className={css['form-container__input_commentary']}
              label={t('label_commentary')}
              placeholder={t('placeholder_commentary')}
              dataRegister={register('commentary')}
              dataErrors={errors['commentary']}
            />
          </div>

          <div className={css['form-container__section']}>
            <ContentDivider />

            <div className={css['disclaimer']}>
              {t('label_agreement_one')} &nbsp;
              <AnchorLink
                className={css['link']}
                link={locale === 'pt-br' ? '/politica-de-privacidade' : '/terminos-y-condiciones'}
                hasArrow={false}
                linkLabel={t('link_label_agreement')}
              />
              &nbsp; {t('label_agreement_two')}
            </div>
          </div>

          <div className={css['form-container__section']}>
            <Button type="submit">{t('button_submit_form')}</Button>
          </div>
        </form>
      )}
    </Section>
  );
}

export default KintoInterestForm;
