import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import BannerList from 'components/molecules/banner-list';
import SectionHeader from 'components/molecules/section-header';

/**
 * Organism BannerListSection
 *
 * A section that contains a list of banners
 */
function BannerListSection(props) {
  const {
    className = '',
    spacingTop = 0,
    spacingBottom = 0,
    bgColor = null,
    showTitle = null,
    sectionTitle = null,
    banners = null,
    layoutType = null,
    autoPlay,
    muted,
    loop,
    controls,
    ...other
  } = props;

  let colorButton;

  switch (bgColor) {
    case 'gray-7':
      colorButton = 'ghost-3';
      break;
    case 'blue-1':
      colorButton = 'ghost-4';
      break;
    case 'blue-2':
      colorButton = 'ghost-5';
      break;

    default:
      colorButton = 'light';
  }

  return (
    <Wrapper
      className={`${css['organism__section-card-container']} ${className}`}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      bgColor={bgColor}
      {...other}
    >
      <Section>
        {showTitle && sectionTitle && <SectionHeader title={sectionTitle} />}
        {banners && (
          <BannerList
            banners={banners}
            type={layoutType}
            colorButton={colorButton}
            autoPlay={autoPlay}
            muted={muted}
            loop={loop}
            controls={controls}
          />
        )}
      </Section>
    </Wrapper>
  );
}

export default BannerListSection;
