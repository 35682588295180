import React from 'react';
import ReactMarkdown from 'react-markdown';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

import { applyRichTextShortcodes } from 'utils/richText';

import rehypeRaw from "rehype-raw";

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism RichTextSection
 *
 * <!-- TODO: add a description here! -->
 */
function RichTextSection(props) {
  const {
    textContent = null,
    richText = applyRichTextShortcodes(textContent),
    spacingTop = 0,
    spacingBottom = 0,
    bgColor = 'default',
    ...other
  } = props;

  return (
    <>
      <Wrapper bgColor={bgColor} spacingTop={spacingTop} spacingBottom={spacingBottom} {...other}>
        <Section className={`${css['organism__rich-text-section-container']}`}>
          {richText && <ReactMarkdown rehypePlugins={[rehypeRaw]}>{richText}</ReactMarkdown>}
        </Section>
      </Wrapper>
    </>
  );
}

export default RichTextSection;
