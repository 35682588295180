import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';

import getConfig from 'next/config';
import Script from 'next/script';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useRouter } from 'next/router';

import FieldsetHeader from 'components/molecules/fieldset-header';
import FormInput from 'components/atoms/form-input';
import FormRadioGroup from 'components/atoms/form-radio-group';
import FormRadioButton from 'components/atoms/form-radio-button';
import Loader from 'components/atoms/loader';
import Section from 'components/utils/section';
import SuccessSubmitForm from 'components/molecules/success-submit-form';

import Wrapper from 'components/atoms/wrapper';
import {
  getCitiesThatHaveDealersFromState,
  getDealersFromState,
  getStatesFromDealers,
  isNumberMask,
  isTextMask
} from 'utils/forms';
import { cepMask, cpfMask, isValidBrazilPhoneNumber, isValidCPF, isValidRangeAge, phoneMask } from 'utils/validations';
import RichTextSection from 'components/organisms/rich-text-section';
import FormCheckbox from 'components/atoms/form-checkbox';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import SubmitSearchButton from 'components/atoms/submit-search-button';
import FormSelect from 'components/atoms/form-select';
import FormTextArea from 'components/atoms/form-text-area';
import { ufToState } from 'public/docs/pt-br/state';

// loading the sass style fot the component
import css from './styles.module.scss';
import { format, parseISO } from 'date-fns';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule DreamCarForm
 *
 * Dream Car form
 */
function DreamCarForm(props) {
  const t = i18n.useTranslations('components.dream-car-form');

  const router = useRouter();

  const {
    className = '',
    locale,
    dealersByLocation = [],
    selectedEvent,
    delivery = [],
    title,
    description,
    titleSuccess,
    contentSuccess,
    linkTitleSuccess,
    linkSuccess,
    titleError,
    contentError,
    titleExpiredEvent,
    contentExpiredEvent,
    linkButtonExpiredEvent,
    labelButtonExpiredEvent,
    headerSpacing,
    spacingBottom,
    spacingTop,
    ...other
  } = props;

  const [isStatusComponent, setIsStatusComponent] = useState('loading');
  const [contestDataAvailable, setContestDataAvailable] = useState(null);
  const [deliveryIndex, setDeliveryIndex] = useState(0);
  const dealerState = getStatesFromDealers(dealersByLocation);
  const [listDealersCities, setListDealersCities] = useState([]);
  const [listDealersNeighborhoods, setListDealersNeighborhoods] = useState([]);
  const [dealerAdress, setDealerAdress] = useState('');

  const addressFields = [
    'addressStreet',
    'addressNeighborhood',
    'addressCity',
    'addressState'
  ];

  const validationSchema = yup
    .object()
    .shape({
      deliveryLocation: yup.string().required(t('message_error_required_delivery_location')),
      dealerState: yup.string().when('deliveryLocation', {
        is: (deliveryLocation) => deliveryLocation.toLowerCase() == 'concessionária',
        then: yup.string().notOneOf(['placeholder'], t('message_error_required_dealer_state')),
        otherwise: yup.string()
      }),
      dealerCity: yup.string().when('deliveryLocation', {
        is: (deliveryLocation) => deliveryLocation.toLowerCase() == 'concessionária',
        then: yup.string().notOneOf(['placeholder'], t('message_error_required_dealer_city')),
        otherwise: yup.string()
      }),
      dealerNeighborhood: yup.string().when('deliveryLocation', {
        is: (deliveryLocation) => deliveryLocation.toLowerCase() == 'concessionária',
        then: yup.string().notOneOf(['placeholder'], t('message_error_required_dealer_neighborhood')),
        otherwise: yup.string()
      }),
      aboutWorkTitle: yup
        .string()
        .required(t('message_error_required_work_title'))
        .min(3, t('message_error_min_max_char_work_title'))
        .max(255, t('message_error_min_max_char_work_title')),
      aboutWorkDescription: yup
        .string()
        .required(t('message_error_required_work_description'))
        .min(3, t('message_error_min_max_char_work_description'))
        .max(500, t('message_error_min_max_char_work_description')),
      firstName: yup
        .string()
        .required(t('message_error_required_first_name'))
        .min(3, t('message_error_min_max_char_first_name'))
        .max(50, t('message_error_min_max_char_first_name')),
      lastName: yup
        .string()
        .required(t('message_error_required_last_name'))
        .min(3, t('message_error_min_max_char_last_name'))
        .max(50, t('message_error_min_max_char_last_name')),
      phone: yup
        .string()
        .required(t('message_error_required_phone'))
        .test('test_id_phone', t('message_error_invalid_phone'), (number) => isValidBrazilPhoneNumber(number)),
      email: yup
        .string()
        .required(t('message_error_required_email'))
        .email(t('message_error_invalid_email'))
        .max(50, t('message_error_invalid_email')),
      birthDate: yup
        .string()
        .required(t('message_error_required_birth_date'))
        .test('test-id-birth-date', t('message_error_invalid_birth_date'), (date) => isValidRangeAge(date, 0, 15)),
      addressCep: yup.string().required(t('message_error_required_address_cep')),
      addressStreet: yup
        .string()
        .required(t('message_error_required_address_street'))
        .min(3, t('message_error_min_max_char_address_street'))
        .max(50, t('message_error_min_max_char_address_street')),
      addressNumber: yup
        .string()
        .required(t('message_error_required_address_number'))
        .min(1, t('message_error_min_max_char_address_number'))
        .max(20, t('message_error_min_max_char_address_number')),
      addressNeighborhood: yup
        .string()
        .required(t('message_error_required_address_neighborhood'))
        .min(3, t('message_error_min_max_char_address_neighborhood'))
        .max(50, t('message_error_min_max_char_address_neighborhood')),
      addressCity: yup
        .string()
        .required(t('message_error_required_address_city'))
        .min(3, t('message_error_min_max_char_address_city'))
        .max(50, t('message_error_min_max_char_address_city')),
      addressState: yup
        .string()
        .required(t('message_error_required_address_state'))
        .min(3, t('message_error_min_max_char_address_state'))
        .max(50, t('message_error_min_max_char_address_state')),
      schoolName: yup
        .string()
        .required(t('message_error_required_school_name'))
        .min(3, t('message_error_min_max_char_school_name'))
        .max(50, t('message_error_min_max_char_school_name')),
      schoolType: yup.string().required(t('message_error_required_school_type')).nullable(),
      responsibleRg: yup
        .string()
        .required(t('message_error_required_responsible_rg'))
        .min(5, t('message_error_invalid_responsible_rg'))
        .max(13, t('message_error_invalid_responsible_rg')),
      responsibleCpf: yup
        .string()
        .required(t('message_error_required_responsible_cpf'))
        .min(14, t('message_error_invalid_responsible_cpf'))
        .max(14, t('message_error_invalid_responsible_cpf'))
        .test('test_id_responsible_cpf', t('message_error_invalid_responsible_cpf'), (cpf) => isValidCPF(cpf)),
      responsibleName: yup
        .string()
        .required(t('message_error_required_responsible_name'))
        .min(3, t('message_error_min_max_char_responsible_name'))
        .max(50, t('message_error_min_max_char_responsible_name')),
      responsibleSurname: yup
        .string()
        .required(t('message_error_required_responsible_surname'))
        .min(3, t('message_error_min_max_char_responsible_surname'))
        .max(50, t('message_error_min_max_char_responsible_surname')),
      agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
    })
    .required();

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      dealerState: 'placeholder',
      dealerCity: 'placeholder'
    }
  });

  const addressCep = watch('addressCep');
  const watchCity = watch('dealerCity');
  const watchState = watch('dealerState');

  const fetchDataContest = async () => {
    try {
      const contestId = selectedEvent;
      const response = await fetch(`/api/subscription/contestId?contestId=${contestId}`);
      if (!response.ok) {
        throw new Error('Error fetching contest data');
      }
      const contestData = await response.json();
      setContestDataAvailable(contestData);
    } catch (error) {
      console.error('Error fetching contest data:', error);

      setTimeout(() => {
        fetchDataContest();
      }, 3000);
    }
  };

  function checkContestExpired(contestData) {
    const registrationFinishAt = new Date(contestData?.contest?.registrationFinishAt);
    const currentDate = new Date();
    return currentDate > registrationFinishAt;
  }

  useEffect(() => {
    fetchDataContest();
  }, []);

  useEffect(() => {
    if (contestDataAvailable) {
      const isExpired = checkContestExpired(contestDataAvailable);
      setIsStatusComponent(isExpired ? 'expired' : 'default');
    }
  }, [contestDataAvailable]);

  function loadCities(state) {
    const dealersFromState = getDealersFromState(dealersByLocation, state);

    const citiesThatHaveDealers = getCitiesThatHaveDealersFromState(dealersFromState);

    setListDealersCities(citiesThatHaveDealers);
  }

  function loadNeighborhoods(selectedState, selectedCity) {
    const filteredDealers = dealersByLocation.filter(
      (dealer) =>
        dealer.address.state.toUpperCase() === selectedState.toUpperCase() &&
        dealer.address.city.toUpperCase() === selectedCity.toUpperCase()
    );

    const neighborhoodNames = filteredDealers.map((dealer) => dealer.address.neighborhood);
    const uniqueNeighborhoodNames = [...new Set(neighborhoodNames)];
    setListDealersNeighborhoods(uniqueNeighborhoodNames);
  }

  function onStateChange(e) {
    const selected = e.currentTarget.value;
    setValue('dealerCity', 'placeholder');
    setValue('dealerNeighborhood', 'placeholder');
    setValue('dealerState', selected);
  }

  function onCityChange(e) {
    const selected = e.currentTarget.value;
    setValue('dealerNeighborhood', 'placeholder');
    setValue('dealerCity', selected);
  }

  function onNeighborhoodChange(e) {
    const selectedNeighborhood = e.currentTarget.value;
    setValue('dealerNeighborhood', selectedNeighborhood);

    const aboutDealer = dealersByLocation.find((element) => element.address.neighborhood == selectedNeighborhood);
    const dealerAdress = `
    \n\n\n${t('dealer_address_name')}: ${aboutDealer.name}
    \n${aboutDealer.address.street}, ${aboutDealer.address.number} - ${aboutDealer.address.neighborhood} - ${
      aboutDealer.address.city
    } ${aboutDealer.address.uf} - ${t('dealer_address_zipcode')}: ${aboutDealer.address.zipcode} `;

    setDealerAdress(dealerAdress);
  }

  useEffect(() => {
    if (!watchState) {
      return false;
    }
    loadCities(watchState);
  }, [watchState]);

  useEffect(() => {
    if (!watchState || !watchCity) {
      return false;
    }

    loadNeighborhoods(watchState, watchCity);
  }, [watchCity]);

  useEffect(() => {
    if (addressCep === '') {
      clearAddressFields();
    }
  }, [addressCep]);

  const updateAddressFields = (data) => {
    addressFields.forEach((field) => {
      const dataKey = field.replace('address', '').toLowerCase();
      const value = data[dataKey];
      if (field === 'addressState') {
        const stateName = ufToState[value];
        setValue(field, stateName || value);
      } else {
        setValue(field, value);
      }
    });
  };

  const fetchCepData = async (cep) => {
    try {
      const response = await fetch(`https://brasilapi.com.br/api/cep/v2/${cep}`);

      if (!response.ok) {
        const responseV1 = await fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`);
        const dataV1 = await responseV1.json();

        if (!responseV1.ok) {
          throw new Error('Error fetching contest data');
        }

        if (!dataV1.errors) {
          setError('addressCep', null);
          return updateAddressFields(dataV1);
        }
        addressFields.forEach((field) => {
          setValue(field, '');
        });
        setError('addressCep', {
          type: 'manual',
          message: t('message_error_invalid_address_cep')
        });
      } else {
        const data = await response.json();

        if (!data.errors) {
          setError('addressCep', null);
          return updateAddressFields(data);
        }
        addressFields.forEach((field) => {
          setValue(field, '');
        });
        setError('addressCep', {
          type: 'manual',
          message: t('message_error_invalid_address_cep')
        });
      }
    } catch (error) {
      addressFields.forEach((field) => {
        setValue(field, '');
      });
      setError('addressCep', {
        type: 'manual',
        message: t('message_error_unavailable_service')
      });
    }
  };

  const handleSearchAddress = () => {
    if (addressCep) {
      const currentCepFormated = addressCep.replace(/[-]/g, '');
      fetchCepData(currentCepFormated);
    }
  };

  const clearAddressFields = () => {
    addressFields.forEach((fieldName) => {
      setValue(fieldName, '');
    });
  };

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function onError(error) {
    console.log('Error: ', error);
  }

  function getStateAbbreviation(stateName) {
    for (const uf in ufToState) {
      if (ufToState[uf] === stateName) {
        return uf;
      }
    }
    return null;
  }

  function translatedDeliveryLocal(deliveryLocal) {
    const local = {
      concessionária: 'DEALERSHIP',
      correios: 'MAIL',
      'e-mail': 'ELETRONIC_SUBMISSION'
    };

    return local[deliveryLocal?.toLowerCase()];
  }

  function createUpdatedData(data) {
    const addressStateUf = getStateAbbreviation(data?.addressState);
    const translatedLocal = translatedDeliveryLocal(data.deliveryLocation);
    const responsibleCpfUnmasked = data.responsibleCpf.replace(/\D/g, '');

    return {
      artWorkDescription: data.aboutWorkDescription,
      artWorkTitle: data.aboutWorkTitle,
      contestId: selectedEvent,
      deliveryLocal: translatedLocal,
      participant: {
        birthdate: format(parseISO(data.birthDate), 'MM/dd/yyyy'),
        email: data.email,
        gender: data.gender == null ? undefined : data.gender,
        name: data.firstName,
        phone: data.phone,
        researchAnswers: [
          {
            question: t('label_brand'),
            answers: [data.brand || 'Uninformed']
          },
          {
            question: t('label_search_know'),
            answers: [
              {
                text: t('label_search_school'),
                check: data.search_school
              },
              {
                text: t('label_search_friends'),
                check: data.search_friends
              },
              {
                text: t('label_search_dealers'),
                check: data.search_dealers
              },
              {
                text: t('label_search_site'),
                check: data.search_site
              },
              {
                text: t('label_search_news'),
                check: data.search_news
              },
              {
                text: t('label_search_posters'),
                check: data.search_posters
              },
              {
                text: t('label_search_ad'),
                check: data.search_ad
              },
              {
                text: t('label_search_others'),
                check: data.search_others
              }
            ]
          }
        ],
        responsibleDoc: responsibleCpfUnmasked,
        responsibleRg: data.responsibleRg,
        responsibleName: data.responsibleName,
        responsibleOccupation: data.responsibleOccupation,
        schoolName: data.schoolName,
        schoolType: data.schoolType,
        surname: data.lastName,
        address: {
          state: data.addressState,
          city: data.addressCity,
          street: data.addressStreet,
          number: data.addressNumber,
          zipcode: data.addressCep,
          complement: data.addressComplement || '',
          uf: addressStateUf
        }
      }
    };
  }

  async function onSubmit(data) {
    const updateData = createUpdatedData(data);

    const url = '/api/subscription';
    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updateData)
    };
    try {
      const response = await fetch(url, opts);

      const isFormSubmittedSuccessfully = response.status === 200;
      if (isFormSubmittedSuccessfully) {
        reset();
        setDealerAdress('');
        setIsStatusComponent('success');
        return;
      }
      setIsStatusComponent('error');
      console.error(t('error_title'), response.statusText + ' ' + response.status);
    } catch (error) {
      setIsStatusComponent('error');
      console.log(error);
    }
  }

  function onBackLink() {
    if (linkButtonExpiredEvent) {
      router.push(linkButtonExpiredEvent);
    }
  }

  return (
    <Wrapper
      className={`${css['molecule__dream-car-form-container']} ${className}`}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      {...other}
    >
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      <Section tag="form" onSubmit={handleSubmit(onSubmit, onError)} className={css['form-container']}>
        {isStatusComponent === 'loading' && (
          <Wrapper spacingTop={spacingTop} spacingBottom={spacingBottom} data-center-elements>
            <Loader />
          </Wrapper>
        )}
        {isStatusComponent === 'expired' && (
          <SuccessSubmitForm
            titleSuccess={titleExpiredEvent || t('title_expired_event')}
            contentSuccess={contentExpiredEvent || t('content_expired_event')}
            contentBackHome={t('content_back_home')}
            contentBackForm={labelButtonExpiredEvent}
            onBackForm={onBackLink}
          />
        )}
        {isStatusComponent === 'success' && (
          <SuccessSubmitForm
            titleSuccess={titleSuccess || t('success_title')}
            contentSuccess={contentSuccess || t('success_content')}
            contentBackForm={t('content-success_back_form')}
            contentBackHome={t('content_back_home')}
            extraLabelLink={linkTitleSuccess}
            extraLink={linkSuccess}
            onBackForm={onBackForm}
          />
        )}
        {isStatusComponent === 'error' && (
          <SuccessSubmitForm
            titleSuccess={titleError || t('error_title')}
            contentSuccess={contentError || t('error_content')}
            contentBackHome={t('content_back_home')}
            contentBackForm={t('content-error_back_form')}
            onBackForm={onBackForm}
          />
        )}

        {isStatusComponent === 'default' && (
          <>
            <fieldset className={`${css['space']} ${css['form-container_fieldset']}`}>
              <FieldsetHeader
                variant={1}
                legend={title || t('form_title')}
                description={description || t('form_description')}
              />
            </fieldset>

            <fieldset className={`${css['form-container_fieldset']} ${css['space']}`}>
              <FieldsetHeader variant={3} legend={t('form_title_delivery_location')} className={css['space']} />

              <FormRadioGroup
                id="deliveryLocation"
                name="deliveryLocation"
                label={t('label_delivery-location')}
                dataRegister={register('deliveryLocation')}
                dataErrors={errors['deliveryLocation']}
                className={css['form-container_fieldset_radio']}
              >
                {delivery?.map((delivery, index) => (
                  <FormRadioButton
                    key={`${index}_${delivery.title}`}
                    id={`${index}_${delivery.title}`}
                    label={delivery.title}
                    value={delivery.title}
                    checked={index == deliveryIndex}
                    onClick={() => {
                      setDeliveryIndex(index);
                    }}
                  />
                ))}
              </FormRadioGroup>

              {deliveryIndex == 0 && (
                <div className={css['form-container_fieldset_horizontal']}>
                  <FormSelect
                    id="dealerState"
                    name="dealerState"
                    label={t('label_dealer_state')}
                    placeholder={t('placeholder_dealer_state')}
                    dataRegister={register('dealerState')}
                    dataErrors={errors['dealerState']}
                    onChange={onStateChange}
                  >
                    {dealerState?.length > 0 &&
                      dealerState?.map((state, index) => {
                        return (
                          <option value={state} key={index}>
                            {state}
                          </option>
                        );
                      })}
                  </FormSelect>

                  <FormSelect
                    id="dealerCity"
                    name="dealerCity"
                    label={t('label_dealer_city')}
                    placeholder={t('placeholder_dealer_city')}
                    dataRegister={register('dealerCity')}
                    dataErrors={errors['dealerCity']}
                    onChange={onCityChange}
                  >
                    {watchState &&
                      listDealersCities?.length > 0 &&
                      listDealersCities?.map((city, index) => {
                        return (
                          <option data-name={city} key={index}>
                            {city}
                          </option>
                        );
                      })}
                  </FormSelect>

                  <FormSelect
                    id="dealerNeighborhood"
                    name="dealerNeighborhood"
                    label={t('label_dealer_neighborhood')}
                    placeholder={t('placeholder_dealer_neighborhood')}
                    dataRegister={register('dealerNeighborhood')}
                    dataErrors={errors['dealerNeighborhood']}
                    onChange={onNeighborhoodChange}
                  >
                    {watchState &&
                      watchCity &&
                      listDealersNeighborhoods?.length > 0 &&
                      listDealersNeighborhoods?.map((neighborhood, index) => (
                        <option key={index} value={neighborhood}>
                          {neighborhood}
                        </option>
                      ))}
                  </FormSelect>
                </div>
              )}

              <RichTextSection
                textContent={delivery[deliveryIndex]?.description + (deliveryIndex == 0 ? dealerAdress : '')}
                bgColor="gray"
              />
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader variant={3} legend={t('form_title_about-work')} className={css['space']} />

              <FormInput
                type="text"
                minLength="3"
                maxLength="255"
                id="aboutWorkTitle"
                name="aboutWorkTitle"
                label={t('label_about-work_title')}
                placeholder={t('placeholder_about-work_title')}
                dataRegister={register('aboutWorkTitle')}
                dataErrors={errors['aboutWorkTitle']}
              />

              <FormTextArea
                rows="4"
                displaySize
                minLength="3"
                maxLength="500"
                id="aboutWorkDescription"
                name="aboutWorkDescription"
                label={t('label__about-work_description')}
                placeholder={t('placeholder__about-work_description')}
                dataRegister={register('aboutWorkDescription')}
                dataErrors={errors['aboutWorkDescription']}
              />
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader
                variant={3}
                legend={t('form_title_participant')}
                description={t('form_description_participant')}
                className={css['space']}
              />

              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="firstName"
                  name="firstName"
                  label={t('label_first_name')}
                  placeholder={t('placeholder_first_name')}
                  dataRegister={register('firstName')}
                  dataErrors={errors['firstName']}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="lastName"
                  name="lastName"
                  label={t('label_last_name')}
                  placeholder={t('placeholder_last_name')}
                  dataRegister={register('lastName')}
                  dataErrors={errors['lastName']}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  type="tel"
                  maxLength="15"
                  id="phone"
                  name="phone"
                  label={t('label_phone')}
                  placeholder={t('placeholder_phone')}
                  dataRegister={register('phone')}
                  dataErrors={errors['phone']}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = phoneMask(value);
                  }}
                />
              </div>

              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  type="email"
                  maxLength="50"
                  id="email"
                  name="email"
                  label={t('label_email')}
                  placeholder={t('placeholder_email')}
                  dataRegister={register('email')}
                  dataErrors={errors['email']}
                />

                <FormInput
                  type="date"
                  maxLength="10"
                  id="birthDate"
                  name="birthDate"
                  label={t('label_birth_date')}
                  placeholder={t('placeholder_birth_date')}
                  dataRegister={register('birthDate')}
                  dataErrors={errors['birthDate']}
                />

                <FormRadioGroup
                  id="gender"
                  name="gender"
                  label={t('label_gender')}
                  dataRegister={register('gender')}
                  className={` ${css['form-container_fieldset_radio']} ${css['center-radio']}`}
                >
                  <FormRadioButton id="gender_masculine" label={t('label_gender_masculine')} value="MALE" />
                  <FormRadioButton id="gender_feminine" label={t('label_gender_feminine')} value="FEMALE" />
                  <FormRadioButton id="gender_others" label={t('label_gender_others')} value="OTHER" />
                </FormRadioGroup>
              </div>
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader variant={3} legend={t('form_title_address')} className={css['space']} />

              <div className={css['form-container_fieldset_search-container']}>
                <FormInput
                  type="text"
                  maxLength="9"
                  id="addressCep"
                  name="addressCep"
                  label={t('label_address_cep')}
                  placeholder={t('placeholder_address_cep')}
                  dataRegister={register('addressCep')}
                  dataErrors={errors['addressCep']}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = cepMask(value);
                  }}
                />

                <SubmitSearchButton
                  type="button"
                  onClick={() => handleSearchAddress()}
                  className={css['form-container_fieldset_search-container_button']}
                />
              </div>

              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="addressStreet"
                  name="addressStreet"
                  label={t('label_address_street')}
                  placeholder={t('placeholder_address_street')}
                  dataRegister={register('addressStreet')}
                  dataErrors={errors['addressStreet']}
                />

                <FormInput
                  type="text"
                  minLength="1"
                  maxLength="20"
                  id="addressNumber"
                  name="addressNumber"
                  label={t('label_address_number')}
                  placeholder={t('placeholder_address_number')}
                  dataRegister={register('addressNumber')}
                  dataErrors={errors['addressNumber']}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isNumberMask(value);
                  }}
                />

                <FormInput
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="addressComplement"
                  name="addressComplement"
                  label={t('label_address_complement')}
                  placeholder={t('placeholder_address_complement')}
                  dataRegister={register('addressComplement')}
                />
              </div>

              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="addressNeighborhood"
                  name="addressNeighborhood"
                  label={t('label_address_neighborhood')}
                  placeholder={t('placeholder_address_neighborhood')}
                  dataRegister={register('addressNeighborhood')}
                  dataErrors={errors['addressNeighborhood']}
                />

                <FormInput
                  className={css['disabled-field']}
                  disabled={true}
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="addressCity"
                  name="addressCity"
                  label={t('label_address_city')}
                  placeholder={t('placeholder_address_city')}
                  dataRegister={register('addressCity')}
                  dataErrors={errors['addressCity']}
                />

                <FormInput
                  className={css['disabled-field']}
                  disabled={true}
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="addressState"
                  name="addressState"
                  label={t('label_address_state')}
                  placeholder={t('placeholder_address_state')}
                  dataRegister={register('addressState')}
                  dataErrors={errors['addressState']}
                />
              </div>
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader variant={3} legend={t('form_title_school')} className={css['space']} />

              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  type="text"
                  minLength="3"
                  maxLength="50"
                  id="schoolName"
                  name="schoolName"
                  label={t('label_school_name')}
                  placeholder={t('placeholder_school_name')}
                  dataRegister={register('schoolName')}
                  dataErrors={errors['schoolName']}
                />

                <FormRadioGroup
                  id="schoolType"
                  name="schoolType"
                  label={t('label_school_type')}
                  dataRegister={register('schoolType')}
                  dataErrors={errors['schoolType']}
                  className={` ${css['form-container_fieldset_radio']} ${css['center-radio']}`}
                >
                  <FormRadioButton id="school_type_public" label={t('school_type_public')} value="PUBLIC" />
                  <FormRadioButton id="school_type_private" label={t('school_type_private')} value="PRIVATE" />
                </FormRadioGroup>
              </div>
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader variant={3} legend={t('form_title_responsible')} className={css['space']} />

              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  type="text"
                  id="responsibleName"
                  name="responsibleName"
                  label={t('label_responsible_name')}
                  placeholder={t('placeholder_responsible_name')}
                  dataRegister={register('responsibleName')}
                  dataErrors={errors['responsibleName']}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  type="text"
                  id="responsibleSurname"
                  name="responsibleSurname"
                  label={t('label_responsible_surname')}
                  placeholder={t('placeholder_responsible_surname')}
                  dataRegister={register('responsibleSurname')}
                  dataErrors={errors['responsibleSurname']}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isTextMask(value);
                  }}
                />

                <FormInput
                  type="text"
                  id="responsibleRg"
                  name="responsibleRg"
                  label={t('label_responsible_rg')}
                  placeholder={t('placeholder_responsible_rg')}
                  dataRegister={register('responsibleRg')}
                  dataErrors={errors['responsibleRg']}
                  minLength={5}
                  maxLength={13}
                />
              </div>

              <div className={css['form-container_fieldset_horizontal']}>
                <FormInput
                  type="text"
                  id="responsibleCpf"
                  name="responsibleCpf"
                  label={t('label_responsible_cpf')}
                  placeholder={t('placeholder_responsible_cpf')}
                  dataRegister={register('responsibleCpf')}
                  dataErrors={errors['responsibleCpf']}
                  minLength={14}
                  maxLength={14}
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = cpfMask(value);
                  }}
                />

                <FormInput
                  type="text"
                  id="responsibleOccupation"
                  name="responsibleOccupation"
                  label={t('label_responsible_occupation')}
                  placeholder={t('placeholder_responsible_occupation')}
                  dataRegister={register('responsibleOccupation')}
                />
              </div>
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FieldsetHeader variant={3} legend={t('form_title_search')} className={css['space']} />

              <p className={css['form-container_fieldset_search']}>{t('label_search_know')}</p>

              <div className={css['form-container_fieldset_checkbox']}>
                <FormCheckbox
                  id="search_school"
                  label={t('label_search_school')}
                  dataRegister={register('search_school')}
                />

                <FormCheckbox
                  id="search_friends"
                  label={t('label_search_friends')}
                  dataRegister={register('search_friends')}
                />
                <FormCheckbox
                  id="search_dealers"
                  label={t('label_search_dealers')}
                  dataRegister={register('search_dealers')}
                />
                <FormCheckbox id="search_site" label={t('label_search_site')} dataRegister={register('search_site')} />
                <FormCheckbox id="search_news" label={t('label_search_news')} dataRegister={register('search_news')} />
                <FormCheckbox
                  id="search_posters"
                  label={t('label_search_posters')}
                  dataRegister={register('search_posters')}
                />
                <FormCheckbox id="search_ad" label={t('label_search_ad')} dataRegister={register('search_ad')} />
                <FormCheckbox
                  id="search_others"
                  label={t('label_search_others')}
                  dataRegister={register('search_others')}
                />
              </div>

              <FormRadioGroup
                id="brand"
                name="brand"
                label={t('label_brand')}
                dataRegister={register('brand')}
                className={css['form-container_fieldset_radio']}
              >
                <FormRadioButton id="brand_toyota" label={t('label_brand_toyota')} value="" />
                <FormRadioButton id="brand_other" label={t('label_brand_other')} value={t('label_brand_other')} />
                <FormRadioButton
                  id="brand_toyota_other"
                  label={t('label_brand_toyota_other')}
                  value={t('label_brand_toyota_other')}
                />
                <FormRadioButton id="brand_no" label={t('label_brand_no')} value={t('label_brand_no')} />
              </FormRadioGroup>
            </fieldset>

            <fieldset className={css['form-container_fieldset']}>
              <FormCheckbox
                id="agreement"
                name="agreement"
                dataRegister={register('agreement')}
                dataErrors={errors['agreement']}
              >
                {t('label_agreement_initial')}
                &nbsp;
                <AnchorLink link={t('link_agreement')} hasArrow={false} linkLabel={t('link_label_agreement')} />
                &nbsp;
                {t('label_agreement_end')}
              </FormCheckbox>
            </fieldset>

            <Button type="submit" className={css['button_submit']}>
              {t('button_submit_form')}
            </Button>
          </>
        )}
      </Section>
    </Wrapper>
  );
}

export default DreamCarForm;
