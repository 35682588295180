/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import IconArrow from 'components/atoms/icon-arrow';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 *
 *  Molecule Viwer360
 *
 *  Desktop sprite: 586x316
 *  Desktop sheet length: 18166
 *
 *  Mobile sprite: 375x202
 *  Mobile sheet length: 11625
 *
 *  Sprites per sheet: 31
 *
 * The Jest errors of this component can be avoided by using
 * 'jest-canvas-mock' but importing this lib inside the viwer-360
 * component will break the storybook visualization.
 *
 */
function Viwer360(props) {
  const t = i18n.useTranslations('components.viwer-360');

  const {
    className = '',
    poseSprites = 31, // The total number of sprites in a spritesheet;
    pixelsToRotate = 30, // How many pixels the displacement of the cursor needs to update the position (rotation "speed");
    spritesheet,
    spritesheetMob,
    ...other
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = React.useState(true); // TODO: Remove the eslint exception after the addition of a loader;
  const [error, setError] = React.useState(false);
  const canvasRef = React.useRef(null);
  const contextRef = React.useRef(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  // PARAMETERS (at the moment those are tied to a hardcode inside the scss)
  // ======================================================================================================================================
  const poseHeightDesk = 316; // The height of a single sprite;
  const poseWidthDesk = 586; // The width of a single sprite;
  const poseHeightMobile = 202; // The height of a single sprite;
  const poseWidthMobile = 375; // The width of a single sprite;

  // VARIABLES
  // ======================================================================================================================================
  let pose = 0; // Reference to the current sprite pose within the sprite sheet;
  let interaction = false; // Weather or not the car is being dragged;
  let originX; // Coordinate from where the current drag movement was based on;

  const url = (isTabletOrMobile ? spritesheetMob : spritesheet) || spritesheet;

  // START UP
  // ======================================================================================================================================
  React.useEffect(() => {
    const carImage = new Image();

    const viwerCanvas = canvasRef.current;
    carImage.src = url;
    viwerCanvas.width = poseWidth();
    viwerCanvas.height = poseHeight();
    contextRef.current = canvasRef.current.getContext('2d');
    viwerCanvas.addEventListener('mousedown', dragStart);
    viwerCanvas.addEventListener('touchstart', dragStart);
    carImage.addEventListener('load', onImageLoad);
    carImage.addEventListener('error', onImageError);
    return () => {
      viwerCanvas.removeEventListener('mousedown', dragStart);
      viwerCanvas.removeEventListener('touchstart', dragStart);
      carImage.removeEventListener('load', onImageLoad);
      carImage.removeEventListener('error', onImageError);
    };
  }, [isTabletOrMobile]);

  // FUNCTIONS
  // ======================================================================================================================================
  const onImageLoad = () => {
    setLoading(false);
    rotateCar(0);
  };

  const onImageError = () => {
    setError(true);
    console.error(error);
  };

  const poseWidth = () => (isTabletOrMobile ? poseWidthMobile : poseWidthDesk);

  const poseHeight = () => (isTabletOrMobile ? poseHeightMobile : poseHeightDesk);

  const nextCar = () => {
    pose >= poseSprites - 1 ? (pose = 0) : pose++;
    let position = pose * poseWidth();
    rotateCar(position);
  };

  const previousCar = () => {
    pose <= 1 ? (pose = poseSprites - 1) : pose--;
    let position = pose * poseWidth();
    rotateCar(position);
  };

  const rotateCar = (Xpos) => {
    const img = new Image();
    const ctx = canvasRef.current.getContext('2d');
    const cnv = canvasRef.current;
    img.onload = function () {
      ctx.clearRect(0, 0, cnv.width, cnv.height);
      ctx.drawImage(img, Xpos, 0, poseWidth(), poseHeight(), 0, 0, cnv.width, cnv.height);
    };
    img.src = url;
  };

  const dragCar = (Xdif) => {
    if (Xdif > 0) {nextCar();}
    if (Xdif < 0) {previousCar();}
  };

  // CONTROLS
  // ======================================================================================================================================
  const dragStart = (e) => {
    interaction = true;
    document.body.classList.add(css['dragging']);
    if (e.type == 'touchstart') {originX = e.touches[0].pageX - canvasRef.current.offsetLeft;}
    if (e.type == 'mousedown') {originX = e.pageX - canvasRef.current.offsetLeft;}
    // TOUCH COMMANDS
    document.body.addEventListener('touchmove', onDraghMove);
    document.body.addEventListener('touchend', dragEnd);
    // MOUSE COMMANDS
    document.body.addEventListener('mousemove', onDraghMove);
    document.body.addEventListener('mouseleave', dragEnd);
    document.body.addEventListener('mouseup', dragEnd);
  };

  const dragEnd = () => {
    interaction = false;
    document.body.classList.remove(css['dragging']);
    // TOUCH COMMANDS
    document.body.removeEventListener('touchmove', onDraghMove);
    document.body.removeEventListener('touchend', dragEnd);
    // MOUSE COMMANDS
    document.body.removeEventListener('mousemove', onDraghMove);
    document.body.removeEventListener('mouseleave', dragEnd);
    document.body.removeEventListener('mouseup', dragEnd);
  };

  const onDraghMove = (e) => {
    let x;
    if (!interaction) {return;}
    if (e.type == 'touchmove') {x = e.touches[0].pageX - canvasRef.current.offsetLeft;}
    if (e.type == 'mousemove') {
      x = e.pageX - canvasRef.current.offsetLeft;
      e.preventDefault();
    }
    const drag = x - originX;
    if (drag > pixelsToRotate || drag < pixelsToRotate * -1) {
      originX = x;
      dragCar(drag);
    }
  };

  // RETURN
  // ======================================================================================================================================
  return (
    <div className={`${css['molecule__viwer-360-container']} ${className}`} {...other}>
      {/**
       * // TODO: Add a loader here;
       * {loading && !error && "Loader placeholder"}
       * {error && "Error placeholder"}
       */}
      <canvas
        className={css['spinner']}
        ref={canvasRef}
        screen={isTabletOrMobile ? 'mobile' : 'desk'}
        data-is-360={true}
      />

      <div className={css['info']}>
        <IconArrow arrowPosition="left" />
        <span className={css['info_content']}>{t('drag_to_turn')} </span>
        <IconArrow arrowPosition="right" />
      </div>
    </div>
  );
}

export default Viwer360;
