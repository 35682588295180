import React from 'react';

/**
 * Atom IconPinMap
 *
 * <!-- TODO: add a description here! -->
 */
function IconHeart(props) {
  const { filled } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <>
        {filled ? (
          <path
            fill="currentColor"
            // eslint-disable-next-line max-len
            d="M12 20.9754L10.975 20.0504C9.20833 18.4337 7.75 17.0379 6.6 15.8629C5.45 14.6879 4.53333 13.6379 3.85 12.7129C3.16667 11.7879 2.6875 10.9504 2.4125 10.2004C2.1375 9.45039 2 8.69206 2 7.92539C2 6.42539 2.50417 5.17122 3.5125 4.16289C4.52083 3.15456 5.76667 2.65039 7.25 2.65039C8.2 2.65039 9.07917 2.87539 9.8875 3.32539C10.6958 3.77539 11.4 4.42539 12 5.27539C12.7 4.37539 13.4417 3.71289 14.225 3.28789C15.0083 2.86289 15.85 2.65039 16.75 2.65039C18.2333 2.65039 19.4792 3.15456 20.4875 4.16289C21.4958 5.17122 22 6.42539 22 7.92539C22 8.69206 21.8625 9.45039 21.5875 10.2004C21.3125 10.9504 20.8333 11.7879 20.15 12.7129C19.4667 13.6379 18.55 14.6879 17.4 15.8629C16.25 17.0379 14.7917 18.4337 13.025 20.0504L12 20.9754Z"
          />
        ) : (
          <path
            fill="currentColor"
            // eslint-disable-next-line max-len
            d="M11.9998 20.9754L10.9748 20.0504C9.21196 18.4317 7.75459 17.0354 6.60266 15.8614C5.45072 14.6874 4.53309 13.6379 3.84976 12.7129C3.16642 11.7879 2.68726 10.9504 2.41226 10.2004C2.13726 9.45039 1.99976 8.69206 1.99976 7.92539C1.99976 6.42281 2.50392 5.168 3.51226 4.16096C4.52059 3.15391 5.76642 2.65039 7.24976 2.65039C8.19976 2.65039 9.07892 2.87539 9.88726 3.32539C10.6956 3.77539 11.3998 4.42539 11.9998 5.27539C12.6998 4.37539 13.4414 3.71289 14.2248 3.28789C15.0081 2.86289 15.8498 2.65039 16.7498 2.65039C18.2331 2.65039 19.4789 3.15391 20.4873 4.16096C21.4956 5.168 21.9998 6.42281 21.9998 7.92539C21.9998 8.69206 21.8623 9.45039 21.5873 10.2004C21.3123 10.9504 20.8331 11.7879 20.1498 12.7129C19.4664 13.6379 18.5488 14.6874 17.3969 15.8614C16.2449 17.0354 14.7876 18.4317 13.0248 20.0504L11.9998 20.9754ZM11.9998 19.0004C13.687 17.4505 15.0755 16.1213 16.1652 15.0129C17.2549 13.9046 18.1206 12.9337 18.7623 12.1004C19.4039 11.2671 19.8539 10.5243 20.1123 9.87202C20.3706 9.21975 20.4998 8.57208 20.4998 7.92902C20.4998 6.8266 20.1498 5.92122 19.4498 5.21289C18.7498 4.50456 17.8516 4.15039 16.7554 4.15039C15.8966 4.15039 15.1019 4.41289 14.371 4.93789C13.6402 5.46289 13.0498 6.20039 12.5998 7.15039H11.3748C10.9414 6.21706 10.3593 5.48372 9.62851 4.95039C8.89766 4.41706 8.10286 4.15039 7.24413 4.15039C6.14788 4.15039 5.24976 4.50456 4.54976 5.21289C3.84976 5.92122 3.49976 6.82802 3.49976 7.93329C3.49976 8.57802 3.62892 9.22956 3.88726 9.88789C4.14559 10.5462 4.59559 11.2962 5.23726 12.1379C5.87892 12.9796 6.74976 13.9504 7.84976 15.0504C8.94976 16.1504 10.3331 17.4671 11.9998 19.0004Z"
          />
        )}
      </>
    </svg>
  );
}

export default IconHeart;
