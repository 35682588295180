import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import { anchor, spacingTopMeta, spacingBottomMeta, bgColorMeta, centerContentMeta, kindOfContent } from 'utils/meta';

class RichTextSection implements ContentBlock {
  label = 'Rich Text';

  component = 'RichTextSection';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    centerContentMeta,
    bgColorMeta,
    spacingTopMeta,
    spacingBottomMeta,
    kindOfContent
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'hidden title attr',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'description',
      help: 'Rich text content',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];

    return {
      textContent: content?.description || null
    };
  }
}

export { RichTextSection };
