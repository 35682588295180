export interface ParcelaResidual {
  anoModelo: string;
  cnpjOrigemNegocio: string;
  cpfCnpjCliente: string;
  itensfinanciaveis?: Item[];
  modeloId: string;
  parcelas?: number[];
  seguroAuto: number;
  spfRemovido: boolean;
  svpRemovido: boolean;
  taxaSubsidio?: string;
  ufEmplacamento?: string;
  valorBem: number;
  valorEntrada: number;
  valorParcelaResidual: number;
  valorsubsidio?: number;
}

export interface Item {
  codigo: string;
  descricao: string;
  valor: number;
}

const parcelaResidual = () => {
  return `query parcelaResidual(
				$anoModelo: String!
				$cnpjOrigemNegocio: String
				$cpfCnpjCliente: String
				$itensFinanciaveis: [Item!]
				$modeloId: String!
				$parcelas: [Int!]
				$seguroAuto: Float!
				$spfRemovido: Boolean!
				$svpRemovido: Boolean!
				$taxaSubsidio: String
				$ufEmplacamento: String
				$valorBem: Float!
				$valorEntrada: Float!
				$valorParcelaResidual: Float!
				$valorSubsidio: Float
			) {
				parcelaResidual(
					parcelaResidual: {
						anoModelo: $anoModelo
						cnpjOrigemNegocio: $cnpjOrigemNegocio
						modeloId: $modeloId
						ufEmplacamento: $ufEmplacamento
						parcelas: $parcelas
						valorBem: $valorBem
						valorEntrada: $valorEntrada
						valorParcelaResidual: $valorParcelaResidual
						valorSubsidio: $valorSubsidio
						taxaSubsidio: $taxaSubsidio
						seguroAuto: $seguroAuto
						spfRemovido: $spfRemovido
						svpRemovido: $svpRemovido
						cpfCnpjCliente: $cpfCnpjCliente
						itensFinanciaveis: $itensFinanciaveis
					}
				)
			}`;
};

const parcelaDesejada = () => `query parcelaDesejada(
	$anoModelo: String!
	$cnpjOrigemNegocio: String
	$modeloId: String!
	$seguroAuto: Float!
	$spfRemovido: Boolean!
	$svpRemovido: Boolean!
	$taxaSubsidio: String
	$ufEmplacamento: String
	$valorBem: Float!
	$valorEntrada: Float!
	$valorParcelaDesejada: Float!
	$itensFinanciaveis: [Item!]
) {
  parcelaDesejada(parcelaDesejada: {
    anoModelo: $anoModelo
    cnpjOrigemNegocio: $cnpjOrigemNegocio
    modeloId: $modeloId
		ufEmplacamento: $ufEmplacamento
    valorBem: $valorBem
    valorEntrada: $valorEntrada
    valorParcelaDesejada: $valorParcelaDesejada
    seguroAuto: $seguroAuto
    spfRemovido: $spfRemovido
    svpRemovido: $svpRemovido
		taxaSubsidio: $taxaSubsidio
		itensFinanciaveis: $itensFinanciaveis
  })
}
`;

const Btb = {
  parcelaResidual,
  parcelaDesejada
};

export default Btb;
