/* eslint-disable no-unused-vars */
import React from 'react';
import Tab from 'components/atoms/tab';
import Slider from 'react-slick';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom CategoryTabs
 *
 * <!-- TODO: add a description here! -->
 */
function CategoryTabs(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.category-tabs');

  const {
    className = '',
    children,
    tabs = [],
    selected = null,
    onChange = {},
    centered = false,
    removeDesktopCarousel = false,
    ...other
  } = props;

  const settings = {
    speed: 500,
    useTransform: false,
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: removeDesktopCarousel ? 9999 : null,
        settings: 'unslick'
      },
      {
        breakpoint: 767, //$bp-phone
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  try {
    const [selectedTab, setSelectedTab] = React.useState(
      selected ? selected : tabs?.length > 0 ? tabs[0]?.id : null
    );

    const handleOnClick = (id) => {
      onChange(id);
      setSelectedTab(id);
    };

    return (
      <div className={`${css['atom__category-tabs-container']} ${className}`} {...other}>
        <div
          className={`${css['tabs-container']} ${centered ? css['centered'] : ''}`}>
          {isEmpty(tabs) ? (
            'Category Tabs component missing Tabs'
          ) : (
            <Slider
              {...settings}
              className={`${css['action-banner-wrapper']} ${className}`}
            >
              {tabs.map((tab, index) => {
                return (
                  <div key={`${tab?.id}-cont`} className={css['slide-container']}>
                    <Tab
                      key={index}
                      id={tab?.id}
                      onClick={handleOnClick}
                      link={tab?.link}
                      selected={tab?.id === selectedTab}>
                      {tab?.label}
                    </Tab>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>

        {children}
      </div>
    );
  } catch (error) {
    console.error('Category Tabs component error: ', error);
    return <div>There was an error in the Category Tabs</div>;
  }
}

export default CategoryTabs;
