import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom FormSelect
 *
 * <!-- TODO: add a description here! -->
 */
function FormSelect(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.form-select');

  const {
    className = '',
    id,
    disabled,
    label,
    placeholder,
    dataRegister,
    dataErrors,
    displayLabel = true,
    displayError = true,
    isAside = false,
    children,
    ...other
  } = props;

  return (
    <div
      className={`${css['atom__form-select-container']} ${css['ff-container']} ${className}`}
      data-is-aside={isAside}
    >
      {label && (
        <label
          htmlFor={id}
          className={`${css['ff-label']} ${disabled ? css['ff-disabled'] : ''} ${displayLabel ? '' : css['absent']}`}
          disabled={disabled ? true : undefined}
        >
          {label}
        </label>
      )}
      <div className={css['field-select-wrapper']}>
        <select
          id={id}
          className={`${displayError ? css['ff-box'] : css['ff-box-clean']} ${css['field-select']} ${
            dataErrors?.type ? css['ff-box-error'] : ''
          }`}
          {...dataRegister}
          {...other}
          disabled={disabled ? true : undefined}
          defaultValue={placeholder ? 'placeholder' : undefined}
        >
          {placeholder && (
            <option value="placeholder" disabled>
              {placeholder}
            </option>
          )}
          {children}
        </select>
      </div>
      {displayError && dataErrors?.type && <span className={css['ff-error-message']}>{dataErrors.message}</span>}
    </div>
  );
}

export default FormSelect;
