import React, { useEffect, useState } from 'react';
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';
import Title from 'components/atoms/title';
import { useMediaQuery } from 'react-responsive';

function Banner(props) {
  const { className = '', bannerData = {}, isGradientType, children, ...other } = props;

  const [imageInfo, setImageInfo] = useState({ url: '', alt: '' });

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  useEffect(() => {
    if (isTabletOrMobile && bannerData?.imageBanner?.mobile) {
      setImageInfo({
        url: bannerData.imageBanner.mobile.url,
        alt: bannerData.imageBanner.mobile.title || ''
      });
    } else if (bannerData?.imageBanner?.desktop) {
      setImageInfo({
        url: bannerData.imageBanner.desktop.url,
        alt: bannerData.imageBanner.desktop.title || ''
      });
    }
  }, []);

  return (
    <Wrapper
      data-linear-gradient-type={isGradientType}
      className={`${css['molecule__banner-container']} ${className}`}
      {...other}
    >
      {imageInfo.url && <img className={css['container-image-banner']} src={imageInfo.url} alt={imageInfo.alt} />}

      {isGradientType && <div className={css['gradient']}></div>}
      <div className={css['container-content']}>
        <div className={css['container-content-header']}>
          {bannerData?.imageLogo?.url && (
            <img
              className={css['container-content-header-logo']}
              src={bannerData?.imageLogo?.url}
              alt={bannerData?.imageLogo?.alt}
            />
          )}
          {bannerData?.title && (
            <Title color="white" level={1} className={css['container-content-header-title']}>
              {bannerData?.title}
            </Title>
          )}
        </div>
        {bannerData?.description && <p className={css['container-content-text']}>{bannerData?.description}</p>}
      </div>
      {children}
    </Wrapper>
  );
}

export default Banner;
