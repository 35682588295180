import React from 'react';

import Section from 'components/utils/section';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';
import Media from 'components/molecules/media';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism ContentMediaSection
 *
 * <!-- TODO: add a description here! -->
 */
function ContentMediaSection(props) {
  const { featuredMedia, mediaList, title, bgColor = 'default', spacingTop = 0, spacingBottom = 0, ...other } = props;

  return (
    <Wrapper
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      bgColor={bgColor}
      className={`${css['organism__content-media-section-container']}`}
      {...other}
    >
      <Section className={`${css['content-media-section']}`}>
        <Title className={`${css['title']}`} variant={1}>
          {title}
        </Title>
        <div className={`${css['content']}`}>
          <ul className={`${css['content__list']}`}>
            {mediaList?.map((media) => (
              <li key={media.id} className={`${css['content__item']}`}>
                <Media url={media.url} alt={media.subtitle} className={`${css['content__item-image']}`} />
                <p className={`${css['content__item-description']}`}>{media.description}</p>
              </li>
            ))}
          </ul>
          {featuredMedia?.url && (
            <Media url={featuredMedia.url} alt={featuredMedia.subtitle} className={`${css['content__picture']}`} />
          )}
        </div>
      </Section>
    </Wrapper>
  );
}

export default ContentMediaSection;
