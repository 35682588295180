import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import { anchor, spacingTopMeta, spacingBottomMeta, bgColorMeta, buttonColorMeta } from 'utils/meta';

class InteractiveVideoContent implements ContentBlock {
  label = 'Interactive Video Content';

  component = 'InteractiveVideoContent';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    bgColorMeta,
    buttonColorMeta,
    {
      name: 'buttonLabel',
      label: 'Button label',
      help: 'Button label',
      type: 'String',
      control: 'input:text'
    },
    spacingTopMeta,
    spacingBottomMeta,
    {
      name: 'containerVideoSize',
      label: 'Containervideo size',
      help: 'The width of the container video',
      type: 'String',
      control: 'select',
      options: ['default', 'small'],
      defaultValue: 'default'
    },
    {
      name: 'showDivider',
      label: 'Show divider',
      help: 'Will show section divider on top',
      type: 'boolean',
      control: 'toggle',
      defaultValue: true
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Title for video sound player',
      help: 'Title for video sound player',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the video sound player',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Video',
      help: 'Select the video',
      type: 'video',
      control: 'upload',
      defaultValue: '',
      props: null
    }
  ];

  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];
    const bgColor = extra?.settings?.bgColor;
    const buttonColor = extra?.settings?.buttonColor;
    const buttonLabel = extra?.settings?.buttonLabel;
    const containerVideoSize = extra?.settings?.containerVideoSize;
    const contentText = content?.description || null;
    const media = content?.media || null;
    const showDivider = extra?.settings?.showDivider;
    const spacingBottom = extra?.settings?.spacingBottom;
    const spacingTop = extra?.settings?.spacingTop;

    return {
      bgColor: bgColor,
      buttonColor: buttonColor,
      buttonLabel: buttonLabel,
      containerVideoSize: containerVideoSize,
      contentText: contentText,
      mediaVideo: media,
      showDivider: showDivider,
      spacingBottom: spacingBottom,
      spacingTop: spacingTop
    };
  }
}

export { InteractiveVideoContent };
