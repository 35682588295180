import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Script from 'next/script';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Title from 'components/atoms/title';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import FormTextArea from 'components/atoms/form-text-area';
import Button from 'components/atoms/button';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Section from 'components/utils/section';
import { empty } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';
import AnchorLink from 'components/atoms/anchor-link';
import ContentDivider from 'components/atoms/content-divider';
import {
  isNumberMask,
  validatePhoneMobileTasa,
  removeSpecialCharacters,
  removeSpecialCharactersAndNumbers
} from 'utils/forms';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule TASAInterestForm
 *
 * TASA interest form
 */
function TASAInterestForm(props) {
  const t = i18n.useTranslations('components.tasa-interest-form');

  const {
    className = '',
    children,
    locale,
    dealers = [],
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    checkFormStatus = () => {},
    ...other
  } = props;

  const validationSchema = yup.object().shape({
    name: yup.string().required(t('message_error_required_name')).min(1, t('message_error_min_char_name')),
    email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
    phone: yup
      .string()
      .test('is-number', t('message_error_invalid_phone'), (value) => {
        if (!value) {
          return true;
        }
        return validatePhoneMobileTasa(value);
      })
      .test('min-8-digits', t('message_error_min_char_phone'), (value) => {
        if (!value) {
          return true;
        }
        if (value?.length < 8) {
          return false;
        } else {
          return true;
        }
      }),
    dni: yup
      .string()
      .required(t('message_error_required_dni'))
      .test('min-8-digits', t('message_error_min_char_dni'), (value) => {
        if (!value) {
          return true;
        }
        if (value?.length < 8) {
          return false;
        } else {
          return true;
        }
      }),
    vinDominio: yup.string().test('min-6-digits', t('message_error_min_char_vinDominio'), (value) => {
      if (!value) {
        return true;
      }
      if (value?.length < 6) {
        return false;
      } else {
        return true;
      }
    }),
    mileage: yup.string(),
    state: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_state'))
      .required(t('message_error_required_state')),
    city: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_city'))
      .required(t('message_error_required_city')),
    dealership: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership'))
      .required(t('message_error_required_dealership')),
    commentary: yup.string().required(t('message_error_required_commentary'))
  });

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const watchedFields = watch([
    'name',
    'email',
    'dni',
    'state',
    'city',
    'dealership',
    'commentary'
  ]);

  const [isStatusComponent, setIsStatusComponent] = useState('default');

  const [, setSelectedDealer] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function validateForm() {
    const isFormEmpty = watchedFields.some((value) => empty(value) || value === 'placeholder');
    isFormEmpty ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
  }

  function loadStates() {
    setListStates([...new Set(dealers?.map((state) => state?.address?.state))].sort());
  }

  function loadCity(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() == selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered?.map((city) => city?.address?.city?.toUpperCase()))].sort();

    setListCities(resultCities);
    setSelectedCity(resultCities[0]);
    loadDealers(selectedState);
  }

  function loadDealers(selectedCity) {
    if (selectedCity) {
      const filtered = dealers?.filter((dealer) => {
        return dealer?.address?.city?.toUpperCase() == selectedCity?.toUpperCase();
      });
      setListDealers(filtered);
      setSelectedDealer(filtered[0]);
    }
  }

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  useEffect(() => {
    loadDealers(selectedCity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setSelectedState(selected);

    if (selected) {
      loadCity(selected);
    }
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setSelectedCity(selected);
    loadDealers(selected);
  }

  function onChangeDealers(e) {
    const selected = e.currentTarget.value;
    setSelectedDealer(selected);
  }

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    data = { ...data, locale, type: 'TYT_ARG' };

    const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');

      return false;
    }

    data.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    const url = publicRuntimeConfig?.FEATURE_SAC_SALESFORCE ? '/api/salesforce/contactCustomerService' : '/api/contact';

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsStatusComponent('success');
          checkFormStatus('success');
          setIsSubmitting(false);
          reset();
          return;
        }
        setIsStatusComponent('error');
        checkFormStatus('error');
      })
      .catch((err) => {
        setIsStatusComponent('error');
        checkFormStatus('error');
        setIsSubmitting(false);
        console.log(err);
      });
  };

  useEffect(() => {
    checkFormStatus(isStatusComponent);
  }, [isStatusComponent]);

  return (
    <Section>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      {isStatusComponent === 'success' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
          contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
          contentBackForm={t('interest_content_back_form')}
          contentBackHome={t('interest_content_back_home')}
        />
      )}
      {isStatusComponent === 'error' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleError ? titleError : t('error_title')}
          contentSuccess={contentError ? contentError : t('error_content')}
          contentBackForm={t('interest_content_back_form')}
          contentBackHome={t('interest_content_back_home')}
        />
      )}
      {isStatusComponent === 'default' && (
        <form
          className={`${css['molecule__tasa-interest-form-container']} ${className}`}
          {...other}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className={css['form-container__section']}>
            <Title className={css['form-container__title']} variant={5}>
              {t('title_section_personal_data')}
            </Title>

            <div className={css['form-container__list-input']}>
              <FormInput
                id="name"
                type="text"
                className={css['form-container__input']}
                label={t('label_name')}
                placeholder={t('placeholder_name')}
                maxLength="80"
                dataRegister={register('name')}
                dataErrors={errors['name']}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharactersAndNumbers(value);
                }}
              />
              <FormInput
                id="email"
                type="email"
                maxLength="80"
                className={css['form-container__input']}
                label={t('label_email')}
                placeholder={t('placeholder_email')}
                dataRegister={register('email')}
                dataErrors={errors['email']}
              />

              <FormInput
                id="phone"
                type="tel"
                className={css['form-container__input']}
                label={t('label_phone')}
                placeholder={t('placeholder_phone')}
                dataRegister={register('phone')}
                dataErrors={errors['phone']}
                maxLength="12"
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = isNumberMask(value);
                }}
              />

              <FormInput
                id="dni"
                type="text"
                maxLength="10"
                className={css['form-container__input']}
                label={t('label_dni')}
                placeholder={t('placeholder_dni')}
                dataRegister={register('dni')}
                dataErrors={errors['dni']}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = isNumberMask(value);
                }}
              />
            </div>
          </div>

          <div className={css['form-container__section']}>
            <div className={css['form-container__list-input']}>
              <FormInput
                id="vinDominio"
                type="text"
                maxLength="17"
                className={css['form-container__input']}
                label={t('label_vinDominio')}
                placeholder={t('placeholder_vinDominio')}
                dataRegister={register('vinDominio')}
                dataErrors={errors['vinDominio']}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = removeSpecialCharacters(value);
                }}
              />

              <FormInput
                id="mileage"
                type="text"
                maxLength="40"
                className={css['form-container__input']}
                label={t('label_mileage')}
                placeholder={t('placeholder_mileage')}
                dataRegister={register('mileage')}
              />
            </div>
          </div>
          <div className={css['form-container__section']}>
            <Title className={css['form-container__title']} variant={5}>
              {t('title_section_dealership')}
            </Title>

            <div className={css['form-container__list-input']}>
              <FormSelect
                id="state"
                className={css['form-container__input']}
                label={t('label_state')}
                placeholder={t('placeholder_state')}
                onFocus={loadStates}
                onClick={onChangeState}
                dataRegister={register('state')}
                dataErrors={errors['state']}
              >
                {listStates?.length > 0 &&
                  listStates?.map((state, index) => {
                    return (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    );
                  })}
              </FormSelect>

              <FormSelect
                id="city"
                className={css['form-container__input']}
                label={t('label_city')}
                placeholder={t('placeholder_city')}
                onClick={onChangeCity}
                dataRegister={register('city')}
                dataErrors={errors['city']}
              >
                {selectedState &&
                  listCities?.length > 0 &&
                  listCities?.map((city, index) => {
                    return (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    );
                  })}
              </FormSelect>

              <FormSelect
                id="dealership"
                className={css['form-container__input']}
                label={t('label_dealership')}
                placeholder={t('placeholder_dealership')}
                onClick={onChangeDealers}
                onBlur={onChangeDealers}
                dataRegister={register('dealership')}
                dataErrors={errors['dealership']}
              >
                {listDealers?.length > 0 &&
                  listDealers?.map((dealer, index) => {
                    return (
                      <option key={index} value={dealer?.name} data-dn={dealer?.dn}>
                        {dealer?.name?.toUpperCase()}
                      </option>
                    );
                  })}
              </FormSelect>
            </div>
          </div>

          <div className={css['form-container__section']}>
            <FormTextArea
              rows="3"
              id="commentary"
              maxLength="500"
              label={t('label_commentary')}
              placeholder={t('placeholder_commentary')}
              dataRegister={register('commentary')}
              dataErrors={errors['commentary']}
              className={css['form-container__input']}
            />
          </div>

          <div className={css['form-container__section']}>
            <ContentDivider />

            <div className={css['disclaimer']}>
              {t('label_agreement_one')} &nbsp;
              <AnchorLink
                className={css['link']}
                link={locale === 'pt-br' ? '/politica-de-privacidade' : '/terminos-y-condiciones'}
                hasArrow={false}
                linkLabel={t('link_label_agreement')}
              />
              &nbsp; {t('label_agreement_two')}
            </div>
          </div>

          <div className={css['form-container__section']}>
            <Button type="submit" disabled={Object.keys(errors)?.length > 0 || isFormIncomplete || isSubmitting}>
              {t('button_submit_form')}
            </Button>
          </div>
        </form>
      )}
    </Section>
  );
}

export default TASAInterestForm;
