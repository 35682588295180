/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
// import i18n from 'utils/i18n';

import Wrapper from 'components/atoms/wrapper';

import Section from 'components/utils/section';

import BuildYourCar from 'components/molecules/build-your-car';
import CarPaymentBanner from 'components/molecules/car-payment-banner';
import AccessoryListCarousel from 'components/molecules/accessory-list-carousel';
import CarContactInfo from 'components/molecules/car-contact-info';
import BuildACarForm from 'components/molecules/build-a-car-form';
import ToyotaCicle from 'components/molecules/toyota-cicle';
import Title from 'components/atoms/title';

import SectionHeader from 'components/molecules/section-header';

import BTB from 'utils/btb/utils';

import { useCarBuilder } from 'providers/car-builder';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism BuildYourCarSection
 *
 * <!-- TODO: add a description here! -->
 */
function BuildYourCarSection(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.build-your-car-section');

  const router = useRouter();

  const { className = '', children, models, model, dealers, ...other } = props;

  const { data: carData, setData: setCarData } = useCarBuilder();

  //PAGE DATA
  const [currentPath, setCurrentPath] = useState(undefined);

  //CAR DATA
  const [selectedModel, setSelectedModel] = useState(null);
  const [accessoriesWithPrice, setAccessoriesWithPrice] = useState(null);

  // BTB
  const [btb, setBtb] = useState(null);
  const [btbFake, setBtbFake] = useState(null);

  // PAGE STATE
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFistLoad] = useState(true);

  const [attractingMedia, setAttractingMedia] = useState(null);

  useEffect(() => {
    getAttractingMedia();
  }, []);

  //When the user selects a vehicle version and a color, we load the BTB instance and populate the context of the application
  useEffect(() => {
    if (selectedModel) {
      loadBTBData();
    }
  }, [selectedModel]);

  //We do things here when we add or remove accessories
  // useEffect(() => {

  //   if (carData?.data?.selectedAccessories) {

  //     let accessoriesFromData = Object.keys(carData?.data?.selectedAccessories || {});

  //     btb.clearAccessories();

  //     accessoriesFromData.forEach(item => {
  //       btb.addAccessory(item)
  //     });

  //     setCarData("data", {
  //       ...carData?.data,
  //       installmentsValue: btb.getInstallments(),
  //       totalValue: btb.getSinglePayment()
  //     });

  //   }

  // }, [carData?.data?.selectedAccessories])

  // When the user changes the installment number
  useEffect(() => {
    if (carData?.data?.installmentsNumber) {
      btb?.setInstallments(carData?.data?.installmentsNumber);

      btbFake?.setInstallments(carData?.data?.installmentsNumber);

      //We need to update the accessories list to get the new installments price
      setAccessoriesWithPrice(updateAccessoriesWithInstallments());

      setCarData('data', {
        ...carData.data,
        installmentsValue: btb?.getInstallments(),
        BTBFullInformation: btb?.toObject()
      });
    }
  }, [carData?.data?.installmentsNumber]);

  // When we change the value of the installment, load the API again
  useEffect(() => {
    if (carData?.data?.desiredInstallment) {
      loadBTBData();
    }
  }, [carData?.data?.desiredInstallment]);

  //When we update the initial installment, load the new data @BTB
  useEffect(() => {
    if (carData?.data?.desiredFirstInstallment) {
      loadBTBData();
    }
  }, [carData?.data?.desiredFirstInstallment]);

  //Load the accessories from the btbFake instance
  useEffect(() => {
    if (btbFake) {
      setAccessoriesWithPrice(updateAccessoriesWithInstallments());
    }
  }, [btbFake]);

  //Load the BTB data with the selected model version
  async function loadBTBData() {
    //We add the loading background
    setIsLoading(true);

    //First we get and sort the accessories to send to BTB
    const itemsFinanciaveis = sortItemsFinanciaveis();

    //We load BTB with the data for the query
    const btb = new BTB({
      fipe: selectedModel?.modelYear?.version?.code, //fipe: selectedModel?.code, //Tirar o último numero do FIPE
      year: selectedModel?.modelYear?.year?.toString(), //year: selectedModel?.year?.toString(),
      price: selectedModel?.price,
      down: carData?.data?.desiredFirstInstallment === undefined ? '60%' : undefined, //If we do not have an user desired first installment, load the default value of 60%
      downValue: carData?.data?.desiredFirstInstallment ? carData?.data?.desiredFirstInstallment : undefined //If the user change the first installment, we try to load it´s values
    });

    //This "fake btb" data is needed just for us to get the accessory prices.
    //Currently, there´s no way to NOT link the acessory prices with the model price, thus making the model prices higher than they should be.
    const btbFake = new BTB({
      fipe: selectedModel?.modelYear?.version?.code,
      year: selectedModel?.modelYear?.year?.toString(),
      price: selectedModel?.price,
      down: carData?.data?.desiredFirstInstallment === undefined ? '60%' : undefined,
      downValue: carData?.data?.desiredFirstInstallment ? carData?.data?.desiredFirstInstallment : undefined,
      accessories: itemsFinanciaveis
    });

    await Promise.all([btb.load(), btbFake.load()]);

    //If the query returns nothing, we alert the user
    if (!btb.toObject()) {
      if (carData?.data?.desiredFirstInstallment > 0) {
        alert('Valor de entrada inválido. Favor, tente outro valor ');
      } else if (carData?.data?.desiredInstallment > 0) {
        alert('Valor de parcelamento inválido. Favor, tente outro valor ');
      } else {
        setCarData('data', {
          totalValue: null,
          firstInstallment: null,
          finalInstallment: null,
          installmentsNumber: '24', //Default number on load
          installmentsValue: null,
          desiredInstallment: null,
          selectedAccessories: { ...(carData?.data?.selectedAccessories || {}) },
          downPerc: null,
          iof: null,
          BTBFullInformation: btb?.toObject()
        });
      }

      setIsLoading(false);

      return false;
    }

    //Set the number of installments to show on load
    btb?.setInstallments(24);
    btbFake?.setInstallments(24);

    //Set the BTB data
    setBtb(btb);
    setBtbFake(btbFake);

    setCarData('data', {
      totalValue: btb?.getSinglePayment(),
      firstInstallment: btb?.getDownPayment(),
      finalInstallment: btb?.getResidualPayment(),
      installmentsNumber: '24', //Default number on load
      installmentsValue: btb?.getInstallments(),
      desiredInstallment: null,
      selectedAccessories: { ...(carData?.data?.selectedAccessories || {}) },
      downPerc: btb?.toObject().downPerc,
      iof: btb?.toObject().installment.taxes.iof,
      BTBFullInformation: btb?.toObject()
    });

    if (model && !firstLoad) {
      router.push(
        `${currentPath}/monte/?version=${selectedModel?.name}${
          router?.query?.state ? `&state=${router?.query?.state}` : ''
        }`,
        undefined,
        { shallow: true }
      );
    } else {
      setFistLoad(false);
    }

    //Remove the loading background
    setIsLoading(false);
  }

  function sortItemsFinanciaveis() {
    let accessories = selectedModel?.accessories;

    if (accessories?.length > 0) {
      let itemsFinanciaveis = [];

      accessories.sort(sortAccessoriesByName).map((accessory, key) => {
        itemsFinanciaveis.push({
          price: accessory?.price?.value,
          name: accessory?.name,
          id: key.toString()
        });
      });

      return itemsFinanciaveis;
    }
  }

  function sortAccessoriesByName(a, b) {
    return a.name > b.name ? 1 : -1;
  }

  function updatePageState(pageState) {
    const qr = router.query.version ? { version: router.query.version } : {};

    if (router.query.state !== pageState) {
      qr.state = pageState;
    }

    router.push(
      {
        pathname: `/${router?.query?.slug.join('/')}`,
        query: qr
      },
      undefined,
      { shallow: true }
    );
  }

  function updateAccessoriesWithInstallments() {
    const ItemsFromBTB = btbFake?.getAccessories().sort(sortAccessoriesByName);
    let accessories = selectedModel?.accessories;
    ItemsFromBTB?.forEach((item, key) => {
      accessories[key] = accessories[key] || {};
      accessories[key].monthlyPrice = item.monthlyPrice;
    });
    return accessories;
  }

  function getAttractingMedia() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });

    params?.attr_m ? setAttractingMedia(params?.attr_m) : null;
  }

  return (
    <div
      className={`${css['organism__build-your-car-section-container']} ${className}`}
      data-is-loading={isLoading}
      {...other}
    >
      <BuildYourCar
        carModelsList={models}
        setSelectedModel={setSelectedModel}
        setCurrentPath={setCurrentPath}
        pageModel={model}
        style={router?.query?.state ? { display: 'none' } : undefined}
      />

      {router?.query?.state === 'simule' && (
        <Wrapper bgColor="gray" spacingTop="0" spacingBottom="3">
          <Section>
            <ToyotaCicle model={selectedModel} currentPath={currentPath} />
          </Section>
        </Wrapper>
      )}

      {
      router?.query?.state !== 'eu-queroavista' &&
      router?.query?.state !== 'eu-quero-ciclo' &&
      selectedModel ? (
        <>
            <Wrapper bgColor="gray" spacing="7">
              <Section hasGrid>
                <h2 className={css['payment-banner-title']}>
                  <b>{selectedModel?.vehicle?.name} </b>
                  {selectedModel?.name}
                </h2>
                <CarPaymentBanner
                  hasIcon
                  hasExtraInfo
                  hasButton
                  hasCTA
                  pageState={router?.query?.state}
                  model={selectedModel}
                  updatePageState={updatePageState}
                />
              </Section>
            </Wrapper>

          {accessoriesWithPrice && accessoriesWithPrice?.length > 0 && (
            <Wrapper bgColor="gray" spacing="6">
              <Section>
                <SectionHeader
                  title="Acessórios"
                  level={2}
                  variant={3}
                  description={{
                    value: 'Adicione acessórios genuínos ao seu Toyota.'
                  }}
                />
                <AccessoryListCarousel data={accessoriesWithPrice} />
              </Section>
            </Wrapper>
          )}
        </>
      ) : (
        <>
          {!selectedModel && (
            <Wrapper bgColor="gray" spacing="6">
              <Section>Selecione o modelo e versão do veículo</Section>
            </Wrapper>
          )}
        </>
      )}

      {(router?.query?.state === 'eu-queroavista' || router?.query?.state === 'eu-quero-ciclo') && selectedModel && (
        <>
          <div className={css['page__model-contact-container']}>
            <Wrapper bgColor="gray" spacingBottom="6">
              <Section className={css['contact-form']} hasGrid>
                <div className={css['contact-form__info']}>
                  <CarContactInfo
                    carModel={selectedModel}
                    updatePageState={updatePageState}
                    pageState={router?.query?.state}
                  />
                </div>
                {dealers && (
                  <div className={css['contact-form__form']}>
                    <BuildACarForm dealers={dealers} carModel={selectedModel} attractingMedia={attractingMedia} />
                  </div>
                )}
              </Section>
            </Wrapper>
          </div>
        </>
      )}

      {selectedModel && !router?.query?.state && (
        <Wrapper className={css['specs-container']} bgColor="gray" spacing="3" spacingBottom="6">
          <Section>
            <Title className={css['specs__title']} variant={'3'}>
              Conheça a versão {selectedModel?.name}
            </Title>
            {selectedModel?.modelYear?.specs?.length > 0 && (
              <>
                <ul className={css['specs-list']}>
                  {selectedModel?.modelYear?.specs?.map((item, index) => {
                    return (
                      <li key={index} className={css['specs-list__item']}>
                        <strong className={css['specs-list__title']}>{item?.contents[0]?.title?.toLowerCase()}</strong>
                        <p className={css['specs-list__description']}>{item?.contents[0]?.description}</p>
                      </li>
                    );
                  })}
                </ul>
              </>
            )}
          </Section>
        </Wrapper>
      )}
    </div>
  );
}

export default BuildYourCarSection;
