import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom IconPinMap
 *
 * <!-- TODO: add a description here! -->
 */
function IconPinMap (props) {

  const {
    size,
  } = props;

  return <svg data-size={size} className={css['icon-pin-map-container']} viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule={`evenodd`} clipRule="evenodd" d="M12.8966 0.982735L12.644 0.940626C11.5555 0.759212 10.4445 0.759212 9.35601 0.940626L9.10336 0.982735C4.42727 1.76208 1 5.80784 1 10.5484C1 13.8707 2.07755 17.1034 4.07092 19.7612L11 29L17.9291 19.7612C19.9224 17.1034 21 13.8707 21 10.5484C21 5.80784 17.5727 1.76208 12.8966 0.982735ZM11 15.6666C13.7614 15.6666 16 13.428 16 10.6666C16 7.9052 13.7614 5.66663 11 5.66663C8.23858 5.66663 6 7.9052 6 10.6666C6 13.428 8.23858 15.6666 11 15.6666Z" fill="currentColor"/>
    <path d="M12.644 0.940626L12.7262 0.447429L12.7262 0.447429L12.644 0.940626ZM12.8966 0.982735L12.8144 1.47593H12.8144L12.8966 0.982735ZM9.35601 0.940626L9.27381 0.447429L9.27381 0.447429L9.35601 0.940626ZM9.10336 0.982735L9.18556 1.47593H9.18556L9.10336 0.982735ZM4.07092 19.7612L3.67092 20.0612H3.67092L4.07092 19.7612ZM11 29L10.6 29.3L11 29.8333L11.4 29.3L11 29ZM17.9291 19.7612L18.3291 20.0612L17.9291 19.7612ZM12.5618 1.43382L12.8144 1.47593L12.9788 0.489538L12.7262 0.447429L12.5618 1.43382ZM9.43821 1.43382C10.4723 1.26148 11.5277 1.26148 12.5618 1.43382L12.7262 0.447429C11.5833 0.256944 10.4167 0.256944 9.27381 0.447429L9.43821 1.43382ZM9.18556 1.47593L9.43821 1.43382L9.27381 0.447429L9.02116 0.489538L9.18556 1.47593ZM1.5 10.5484C1.5 6.05226 4.75057 2.2151 9.18556 1.47593L9.02116 0.489538C4.10398 1.30907 0.5 5.56342 0.5 10.5484H1.5ZM4.47092 19.4612C2.54246 16.8899 1.5 13.7625 1.5 10.5484H0.5C0.5 13.9789 1.61264 17.3168 3.67092 20.0612L4.47092 19.4612ZM11.4 28.7L4.47092 19.4612L3.67092 20.0612L10.6 29.3L11.4 28.7ZM17.5291 19.4612L10.6 28.7L11.4 29.3L18.3291 20.0612L17.5291 19.4612ZM20.5 10.5484C20.5 13.7625 19.4575 16.8899 17.5291 19.4612L18.3291 20.0612C20.3874 17.3168 21.5 13.9789 21.5 10.5484H20.5ZM12.8144 1.47593C17.2494 2.2151 20.5 6.05226 20.5 10.5484H21.5C21.5 5.56342 17.896 1.30907 12.9788 0.489538L12.8144 1.47593ZM15.5 10.6666C15.5 13.1519 13.4853 15.1666 11 15.1666V16.1666C14.0376 16.1666 16.5 13.7042 16.5 10.6666H15.5ZM11 6.16663C13.4853 6.16663 15.5 8.18134 15.5 10.6666H16.5C16.5 7.62906 14.0376 5.16663 11 5.16663V6.16663ZM6.5 10.6666C6.5 8.18134 8.51472 6.16663 11 6.16663V5.16663C7.96243 5.16663 5.5 7.62906 5.5 10.6666H6.5ZM11 15.1666C8.51472 15.1666 6.5 13.1519 6.5 10.6666H5.5C5.5 13.7042 7.96243 16.1666 11 16.1666V15.1666Z" fill={`currentColor`}/>
  </svg>;
}

export default IconPinMap;
