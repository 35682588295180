import AnchorLink from 'components/atoms/anchor-link';
import React from 'react';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule BreadcrumbList
 *
 * Pages breadcrumb
 */
function BreadcrumbList(props) {
  const { className = '', breadcrumbList = [], link, linkLabel, bgColor = 'gray', ...other } = props;

  return (
    <Wrapper spacing={4} bgColor={bgColor} {...other}>
      <Section>
        <ul className={`${css['molecule__breadcrumb-list-container']} ${className}`}>
          {breadcrumbList?.map((item, index) => {
            return (
              <li key={index + item?.link} className={css['breadcrumb-item']}>
                <AnchorLink
                  className={css['breadcrumb-item__link']}
                  linkLabel={item?.linkLabel}
                  link={item?.link}
                  removeUnderline={true}
                />
              </li>
            );
          })}
        </ul>
        <AnchorLink
          className={css['breadcrumb-item--mobile']}
          linkLabel={linkLabel}
          link={link?.slug}
          removeUnderline={true}
          data-arrow-position={'left'}
        />
      </Section>
    </Wrapper>
  );
}

export default BreadcrumbList;
