import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getStatesFromDealers } from 'utils/forms';
import i18n from 'utils/i18n';

import AnchorLink from 'components/atoms/anchor-link';
import FormSelect from 'components/atoms/form-select';
import IconPinMap from 'components/atoms/icon-pin-map';
import Title from 'components/atoms/title';
import MapInformation from 'components/molecules/map-information';
import InfoDealer from 'components/molecules/info-dealer';

import css from './styles.module.scss';

/**
 * Organism MyDealers
 *
 * Find and favorite dealers
 */
function MyDealers(props) {
  const t = i18n.useTranslations('components.my-dealers');

  const { className = '', dealers = [], googleKey, children, locale, ...other } = props;

  const initialFormValues = {
    state: 'placeholder',
    city: 'placeholder'
  };

  const listStates = getStatesFromDealers(dealers);
  const [citiesFromStates, updateCitiesFromStates] = useState([]);
  const [markers, setMarkers] = useState(dealers || []);
  const [favoriteDealers, setFavoriteDealers] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  const [goToCurrentPosition, setGoToCurrentPosition] = useState(null);
  const mapZoom = 1;

  const [locationError, setLocationError] = useState(null);

  const validationSchema = yup
    .object()
    .shape({
      state: yup.string().notOneOf(['placeholder']),
      city: yup.string().notOneOf(['placeholder'])
    })
    .required();

  const { register, setValue, watch } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialFormValues
  });

  const watchCity = watch('city');
  const watchState = watch('state');

  const handleUseMyLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGoToCurrentPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          setLocationError(t('unable_to_access_location'));
          console.log(error);
        }
      );
    } else {
      setLocationError(t('message_geolocation_not_supported'));
    }
  };

  useEffect(async (_) => {
    await fetchFavoriteDealers();
  }, []);

  useEffect(
    async (_) => {
      const updatedMarkers = markers?.map((marker) => ({
        ...marker,
        isFavorite: favoriteDealers.some((favorite) => favorite.id === marker.id)
      }));

      setMarkers(updatedMarkers);
    },
    [favoriteDealers]
  );

  useEffect(() => {
    loadCitiesList(watchState);
  }, [watchState]);

  useEffect(() => {
    if (watchCity && watchCity !== 'placeholder') {
      const dealersFromCity = dealers?.filter(
        (dealer) =>
          dealer?.address?.state.toUpperCase() === watchState?.toUpperCase() && dealer?.address?.city === watchCity
      );
      setMarkers(dealersFromCity);
    }
  }, [watchCity]);

  function loadCitiesList(state) {
    const dealersFromState = dealers?.filter((dealer) => dealer?.address?.state.toUpperCase() === state?.toUpperCase());

    if (state !== 'placeholder') {
      setMarkers(dealersFromState);
    }

    const uniqueCitiesList = Array.from(
      new Set(dealersFromState.map((dealer) => dealer.address.city.toUpperCase()))
    ).sort();

    updateCitiesFromStates(uniqueCitiesList);
  }

  async function fetchFavoriteDealers() {
    try {
      const response = await fetch(`/api/favorite-dealers/get-favorite-dealers`);
      const responseData = (await response.json())?.favoriteDealers || [];
      const favoriteDealersIds = responseData.map(({ id }) => id);
      const updatedFavoriteDealers = dealers.filter(({ id }) => favoriteDealersIds.includes(id));

      if (!response.ok) {
        throw new Error(`Failed to fetch favorite dealers: ${response.status} - ${response.statusText}`);
      }
      const isFavoriteDealer = updatedFavoriteDealers.map((currentDealer) => ({ ...currentDealer, isFavorite: true }));

      if (isFavoriteDealer.length > 0) {
        setValue('state', isFavoriteDealer[0]?.address?.state);
        setValue('city', isFavoriteDealer[0]?.address?.city);
        setMapCenter(isFavoriteDealer[0]?.position);
      }
      setFavoriteDealers(isFavoriteDealer);
    } catch (error) {
      console.log(error);
    }
  }

  async function requestAddFavoriteDealer(dealer) {
    try {
      const url = `/api/favorite-dealers/create-favorite-dealer`;
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dealersIds: [dealer?.id] })
      };
      const response = await fetch(url, opts);
      const data = await response.json();

      if (!response.ok || !data) {
        throw new Error(`Failed to fetch favorite dealers: ${response.status} - ${response.statusText}`);
      }

      setFavoriteDealers([...favoriteDealers, { ...dealer, isFavorite: true }]);
    } catch (error) {
      console.log(error);
    }
  }

  async function requestRemoveFavoriteDealer(dealer) {
    try {
      const url = `/api/favorite-dealers/remove-favorite-dealer`;
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dealersIds: [dealer?.id] })
      };
      const response = await fetch(url, opts);
      const data = await response.json();

      if (!response.ok || !data) {
        throw new Error(`Failed to fetch favorite dealers: ${response.status} - ${response.statusText}`);
      }

      setFavoriteDealers(favoriteDealers.filter((fav) => fav.id !== dealer.id));
    } catch (error) {
      console.log(error);
    }
  }

  function onChangeState(event) {
    setValue('city', 'placeholder');
    setValue('state', event.target.value);
  }

  function onChangeCity(event) {
    const cityValue = event.target.value;
    setValue('city', cityValue.toString());
  }

  async function handleFavoriteChange(dealer) {
    if (!dealer.isFavorite) {
      if (favoriteDealers.length > 0) {
        await requestRemoveFavoriteDealer(favoriteDealers[0]);
      }
      await requestAddFavoriteDealer(dealer);
      setFavoriteDealers([{ ...dealer, isFavorite: true }]);
    } else {
      await requestRemoveFavoriteDealer(dealer);
    }
  }

  function handleInfoDealer(dealer) {
    setMapCenter({ ...dealer?.position, timestamp: Date.now() });
    document.querySelector('[data-id="map"]')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }

  return (
    <div className={`${css['organism__my-dealers-container']} ${className}`} {...other}>
      <Title level={1} variant={2}>
        {t('title')}
      </Title>
      <p className={css['subtitle']}>{t('subtitle')}</p>
      <div className={css['filter-form']}>
        <FormSelect
          displayLabel={false}
          displayError={false}
          id="selectState"
          placeholder={t('placeholder_state')}
          className={css['select-input']}
          dataRegister={register('state')}
          onChange={onChangeState}
        >
          {listStates?.length > 0 ? (
            listStates?.map((state, index) => {
              return (
                <option key={index} value={state}>
                  {state}
                </option>
              );
            })
          ) : (
            <option>{t('placeholder_state')}</option>
          )}
        </FormSelect>
        <FormSelect
          displayLabel={false}
          displayError={false}
          id="selectCity"
          placeholder={t('placeholder_city')}
          className={css['select-input']}
          dataRegister={register('city')}
          onChange={onChangeCity}
        >
          {citiesFromStates?.length > 0 ? (
            citiesFromStates?.map((city, index) => {
              return (
                <option key={index} value={city}>
                  {city}
                </option>
              );
            })
          ) : (
            <option>{t('placeholder_city')}</option>
          )}
        </FormSelect>
      </div>

      <AnchorLink
        size="small"
        hasArrow={false}
        onClick={handleUseMyLocation}
        linkLabel={
          <>
            {t('use_my_location')} <IconPinMap />
          </>
        }
      />
      <span>{locationError}</span>

      <MapInformation
        data-id={'map'}
        className={css['map-container']}
        locale={locale}
        markers={markers}
        selectedCity={watchCity}
        selectedState={watchState}
        selectedZoom={mapZoom}
        selectedMapCenter={mapCenter}
        centerOffset={-0.012}
        goToCurrentPosition={goToCurrentPosition}
        handleFavoriteChange={handleFavoriteChange}
      />

      <Title level={2} variant={2}>
        {t('favorite_dealers')}
      </Title>
      <div className={css['favorite-dealers-list']}>
        {favoriteDealers?.length
          ? favoriteDealers.map((dealer, index) => (
              <InfoDealer
                key={dealer?.id + index}
                className={css['info-dealer']}
                dealer={dealer}
                listServices={dealer?.services}
                locale={locale}
                handleFavoriteChange={handleFavoriteChange}
                handleInfoDealer={() => handleInfoDealer(dealer)}
              />
            ))
          : t('has_no_favorite_dealers')}
      </div>
    </div>
  );
}

export default MyDealers;
