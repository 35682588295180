import Service from 'utils/repo/service';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class ServiceOffers implements ContentBlock {
  label = 'Service Offers';

  component = 'ServiceOffers';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  properties = [];

  async adapter(contents: any, extra: any, dataFetcher: any) {
    let serviceItemCategories = [];
    const locale = extra?.context?.locale || 'pt-BR';

    try {
      const pageId = extra?.context?.page?.id;
      const installmentsNumber = extra?.context?.page?.tenant?.tenantData?.installmentMaintenanceClosePrice;

      if (!pageId) {
        throw new Error('Page ID is missing in context');
      }

      const variables: any = { pageId };
      if (installmentsNumber) {
        variables.installmentsNumber = installmentsNumber;
      }

      const response = await dataFetcher(Service.getServiceItemCategoriesByPage(), variables);
      serviceItemCategories = response?.data?.serviceByPage?.serviceItemCategories || [];
    } catch (error) {
      console.error('Error fetching service item categories:', error);
    }

    return {
      serviceItemCategories,
      locale
    };
  }
}

export { ServiceOffers };
