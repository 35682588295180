import React, { useState } from 'react';
import i18n from 'utils/i18n';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from 'components/atoms/button';
import FormInput from 'components/atoms/form-input';
import Loader from 'components/atoms/loader';
import Section from 'components/utils/section';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Title from 'components/atoms/title';
import Wrapper from 'components/atoms/wrapper';
// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule EmailDreamCarForm
 *
 * <!-- TODO: add a description here! -->
 */

function EmailDreamCarForm(props) {
  const t = i18n.useTranslations('components.email-dream-car-form');

  const {
    className = '',
    contestId,
    titleForm,
    descriptionForm,
    headerSpacing = 7,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    registrationLink,
    registrationLinkLabel,
    ...other
  } = props;
  const [isStatusComponent, setIsStatusComponent] = useState('default');

  const validationSchemaConfig = {
    email: yup
      .string()
      .required(t('message_error_required_email'))
      .email(t('message_error_invalid_email'))
      .max(50, t('message_error_max_char_email'))
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    reset
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    setIsStatusComponent('loading');
    data.contestId = contestId;
    const url = '/api/subscription/resend';
    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    try {
      const response = await fetch(url, opts);

      const isFormSubmittedSuccessfully = response.status === 200;
      if (isFormSubmittedSuccessfully && response.ok) {
        reset();
        setIsStatusComponent('success');
        return;
      } else {
        const errorData = await response.json();
        setIsStatusComponent('error');
        console.error(t('error_title'), errorData.error || 'Unexpected error occurred.');
      }
    } catch (error) {
      console.log(error);
      setIsStatusComponent('error');
    }
  };

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('loading');
    setTimeout(() => {
      setIsStatusComponent('default');
    }, 1000);
  }

  return (
    <Wrapper spacing={headerSpacing} {...other}>
      <Section tag="div" className={`${css['molecule__email-dream-car-form-container']} ${className}`} {...other}>
        {isStatusComponent === 'loading' && (
          <div className={css['container-loader']}>
            <Loader />
          </div>
        )}
        {isStatusComponent === 'success' && (
          <SuccessSubmitForm
            onBackForm={onBackForm}
            extraLink={registrationLink}
            extraLabelLink={registrationLinkLabel}
            titleSuccess={titleSuccess || t('success_title')}
            contentSuccess={contentSuccess || t('success_content')}
            contentBackForm={t('back_form-success')}
            contentBackHome={t('back_home')}
          />
        )}
        {isStatusComponent === 'error' && (
          <SuccessSubmitForm
            onBackForm={onBackForm}
            titleSuccess={titleError || t('error_title')}
            contentSuccess={contentError || t('error_content')}
            contentBackForm={t('back_form-error')}
            contentBackHome={t('back_home')}
          />
        )}
        {isStatusComponent === 'default' && (
          <form className={css['container-form']} onSubmit={handleSubmit(onSubmit, onError)}>
            <Title variant="2" level="4">
              {titleForm || t('title_form')}
            </Title>
            <p className={css['container-form__description']}>{descriptionForm || t('description_form')}</p>
            <div className={css['container-form__input-submit']}>
              <FormInput
                className={css['container-form__input']}
                id="email"
                label={t('label_email')}
                placeholder={t('placeholder_email')}
                dataRegister={register('email')}
                dataErrors={errors['email']}
                type="email"
                displayLabel={true}
                isLongMessage={true}
              />

              <Button className={css['container-form__button']} type="submit" disabled={!formState.isValid}>
                {t('button_submit_form')}
              </Button>
            </div>
          </form>
        )}
      </Section>
    </Wrapper>
  );
}

export default EmailDreamCarForm;
