import Button from 'components/atoms/button';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import Modal from 'components/molecules/modal';
import React, { useState } from 'react';
import i18n from 'utils/i18n';

import { useForm } from 'react-hook-form';
// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism RecallContactModal
 * 
 * <!-- TODO: add a description here! -->
 */
function RecallContactModal (props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.recall_contact_modal');

  const {
    className = "",
    contactModalIsOpen = false,
    setContactModalIsOpen = (() => undefined),
    dealers=[],
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [selectedBox, setSelectedBox] = useState('state-city');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dealerships, setDealerships] = useState([]);
  const [selectedstate, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [dealer, setDealer] = useState({});

  function onError(error) {
    console.log('Error: ', error);
  }

  function loadStates() {
    setStates([...new Set(dealers.map((data) => data?.address?.state?.toUpperCase()))].sort());
  }

  function loadCities() {
    const filteredCites = dealers?.filter((data) => data?.address?.state?.toUpperCase() === selectedstate?.toUpperCase());
    console.log(filteredCites);
    setCities([...new Set(filteredCites.map((data) => data?.address?.city?.toUpperCase()))].sort());
  }

  function loadDealerships() {
    const filteredDealerships = dealers?.filter((data) => (
      data?.address?.state?.toUpperCase() === selectedstate?.toUpperCase() &&
      data?.address?.city?.toUpperCase() === selectedCity?.toUpperCase()
    ));
    setDealerships(filteredDealerships);
  }

  async function handleSendLead(formData) {
    console.log(formData);
    console.log(dealer);
  }

  return <Modal
    modalIsOpen={contactModalIsOpen}
    onClose={() => setContactModalIsOpen(false)}
    className={`${css["recall-contact-modal-container"]} ${className}`}
    textContent={t("modal_text")}
  >
    <form
      onSubmit={(handleSubmit(async (formData) => {
        handleSendLead(formData);
      }, onError))}
      className={css['form-modal-contact-container']}
    >
      <FormInput
        displayLabel={true}
        displayError={false}
        className={css['remove-margin']}
        id="name"
        label={t("name_label")}
        dataRegister={register("name")}
        dataErrors={errors["name"]}
        maxLength="19"
      />

      <FormInput
        displayLabel={true}
        displayError={false}
        className={css['remove-margin']}
        id="phone"
        label={t("phone_label")}
        dataRegister={register("telephone")}
        dataErrors={errors["phone"]}
        maxLength="19"
      />

      <div className={css['is-horizontal']}>
        <div className={css['radio-buttons-container']}>
          <span className={css['ff-label']}>{t("search_for")}</span>
          <div className={css['radio-button-container']}>
            <button
              type="button"
              className={css['radio-button']}
              data-is-selected={selectedBox === "state-city"}
              onClick={() => setSelectedBox("state-city")}
            />
            <label className={css['ff-label']}>{`${t("state")}/${t("city")}`}</label>
          </div>
          <div className={css['radio-button-container']}>
            <button
              type="button"
              className={css['radio-button']}
              data-is-selected={selectedBox === "zipCode"}
              onClick={() => setSelectedBox("zipCode")}
            />
            <label className={css['ff-label']}>{t("zip_code")}</label>
          </div>
        </div>
      </div>

      {
        selectedBox === "zipCode" ?
          <FormInput
            displayLabel={true}
            displayError={false}
            className={css['remove-margin']}
            id="zipCode"
            label={t("zip_code")}
            dataRegister={register("zipCode")}
            dataErrors={errors["zipCode"]}
            maxLength="19"
          />

          :

          selectedBox === "state-city" &&
        <div className={css['is-horizontal']}>
          <FormSelect
            displayLabel={false}
            displayError={false}
            onFocus={loadStates}
            id="stateSelected"
            placeholder={t("state")}
            dataRegister={register("state")}
            className={css['modal-select-container']}
          >
            {
              states?.length > 0 &&
              states?.map((state, index) => {
                return (
                  <option
                    key={index}
                    value={state}
                    onClick={() => setSelectedState(state)}
                  >
                    {state}
                  </option>
                );
              })
            }
          </FormSelect>

          <FormSelect
            displayLabel={false}
            displayError={false}
            onFocus={loadCities}
            id="citySelected"
            placeholder={t("city")}
            dataRegister={register("city")}
            className={css['modal-select-container']}
          >
            {
              cities?.length > 0 &&
              cities?.map((city, index) => {
                return (
                  <option
                    key={index}
                    value={city}
                    onClick={() => setSelectedCity(city)}
                  >
                    {city}
                  </option>
                );
              })
            }
          </FormSelect>
        </div>
      }

      <FormSelect
        displayLabel={false}
        displayError={false}
        onFocus={loadDealerships}
        id="dealershipSelected"
        placeholder={t("dealership")}
      >
        {
          dealerships?.length > 0 &&
          dealerships?.map((dealer, index) => {
            return (
              <option
                key={index}
                onClick={() => setDealer(dealer)}
              >
                {dealer?.companyName}
              </option>
            );
          })
        }
      </FormSelect>

      <Button type="submit">{t("button_label")}</Button>
    </form>
  </Modal>;
}

export default RecallContactModal;
