import { File } from 'components/icons';
import React from 'react';
import Title from 'components/atoms/title';

//import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import IconDownload from '../icon-download';

/**
 * Atom DownloadDocumentItem
 *
 * <!-- TODO: add a description here! -->
 */
function DownloadDocumentItem(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.download-document-item');

  const {
    className = '', //
    title,
    text,
    value,
    linkUrl,
    color,
    showTitle = false,
    ...other
  } = props;

  return (
    <div className={`${css['atom__download-document-item-container']} ${className}`} {...other}>
      {!showTitle && title && (
        <Title level={3} variant={3} className={css['title']}>
          {title}
        </Title>
      )}
      <a className={css['container']} href={linkUrl} target="_blank">
        <div className={css['container-file']}>
          <File className={css['container-content__file']} />
          <div className={css['container-content']}>
            <span className={css['container-content__text']}>{text}</span>
            <span className={css['container-content__value']}>{value}</span>
          </div>
        </div>
        <div className={css['download']}>
          <IconDownload color={color} />
        </div>
      </a>
    </div>
  );
}

export default DownloadDocumentItem;
