import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

import Card from 'components/molecules/card';
import Carousel from 'components/molecules/carousel';

/**
 * Molecule CardsList
 *
 * A list of cards
 */
function CardsList(props) {
  const {
    className = '',
    children,
    cardsData,
    isHorizontal = false,
    variant = null,
    removeCarousel = false,
    isUppercase = true,
    autoPlay,
    muted,
    loop,
    controls,
    ...other
  } = props;

  const settings = {
    speed: 500,
    arrows: false,
    dots: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick'
      },
      {
        breakpoint: removeCarousel ? 9999 : 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: false,
          padding: 0
        }
      }
    ]
  };

  return (
    <>
      <Carousel
        settings={settings}
        className={`${css['cards-list']} ${className} ${variant ? css[variant] : ''} ${
          removeCarousel ? css['no-carousel'] : ''
        }`}
      >
        {cardsData &&
          cardsData.map((card, key) => {
            return (
              <Card
                key={key}
                title={card.title}
                media={card.media}
                content={card.content}
                category={card.category}
                additionalContent={card.additionalContent}
                link={card.link || undefined}
                linkLabel={card.linkLabel}
                links={card.links}
                isExternal={card.isExternal}
                isDownload={card.isDownload}
                isHorizontal={isHorizontal}
                date={card.date}
                isUppercase={isUppercase}
                autoPlay={autoPlay}
                muted={muted}
                loop={loop}
                controls={controls}
              />
            );
          })}
      </Carousel>
    </>
  );
}

export default CardsList;
