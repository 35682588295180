import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';

import {
  anchor,
  spacingTopMeta,
  spacingBottomMeta,
  bgColorMeta,
  buttonColorMeta,
  centerContentMeta,
  kindOfContent,
  autoPlay,
  muted,
  loop,
  controls
} from 'utils/meta';

class CardsList implements ContentBlock {
  label = 'Card List';

  component = 'SectionCard';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    {
      name: 'showTitle',
      label: 'Show section title',
      help: 'Will show the section title in the top of the section',
      type: 'boolean',
      control: 'toggle',
      defaultValue: true
    },
    {
      name: 'isUppercase',
      label: 'Title of the cards is uppercase?',
      help: 'Display the Title of the cards in uppercase',
      type: 'boolean',
      control: 'toggle',
      defaultValue: true,
      props: null
    },
    {
      name: 'isHorizontal',
      label: 'Landscape?',
      help: 'Display the Card in landscape mode',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    centerContentMeta,
    {
      name: 'removeCarousel',
      label: 'Remove the Carousel?',
      help: 'Remove carousel from card list',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    autoPlay,
    muted,
    loop,
    controls,
    {
      name: 'variant',
      label: 'The number of collumns the layout will be show',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'type-2',
        'type-3'
      ]
    },
    spacingTopMeta,
    spacingBottomMeta,
    bgColorMeta,
    {
      name: 'callToActionLabel',
      label: 'Button title',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActionTarget',
      label: 'Button target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    buttonColorMeta,
    kindOfContent

  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the card title',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the card',
      type: 'string',
      control: 'textarea',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link title',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Image',
      help: 'Set an image to the card',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {
      title: extra?.section?.name,
      description: extra?.section?.description,
      settings: extra?.settings,
      buttonColor: extra?.settings?.buttonColor,
      callToAction: extra?.settings?.callToActionLabel
        ? {
            label: extra?.settings?.callToActionLabel,
            target: extra?.settings?.callToActionTarget
          }
        : null,
      cardsData: contents.map((content) => {
        if (!content?.title) {
          throw Error("Content 'title' are required");
        }

        return {
          title: content?.title.trim().length > 0 ? content?.title?.trim() : undefined,
          isUppercase: extra?.settings?.isUppercase || false,
          content: content?.description,
          media: content?.media
            ? {
                type: content?.media?.mediaType?.title,
                url: content?.media?.url,
                alt: content?.media?.description
              }
            : null,
          link: content?.callToActions?.[0]?.target || undefined,
          linkLabel: content?.callToActions?.[0]?.label || undefined,
          links: content?.callToActions.map((data) => {
            return {
              link: data.target,
              linkLabel: data.label
            };
          })
        };
      })
    };
  }
}

export { CardsList };
