import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';

import Button from 'components/atoms/button';
import FieldsetHeader from 'components/molecules/fieldset-header';
import FormSelect from 'components/atoms/form-select';
import FormInput from 'components/atoms/form-input';
import Loader from 'components/atoms/loader';
import Modal from 'components/molecules/modal';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import SuccessSubmitForm from 'components/molecules/success-submit-form';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Script from 'next/script';

import {
  getCitiesThatHaveDealersFromState,
  getDealersFromState,
  getStatesFromDealers,
  isNumberMask,
  removeSpecialCharacters,
  validatePhoneMobileTasa
} from 'utils/forms';
import { models } from './models';

import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

function RecallFormCustom(props) {
  const t = i18n.useTranslations('components.recall-form-custom');

  const {
    className = '',
    dealersByLocation = [],
    locale,
    formTitle,
    formDescription,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    headerSpacing,
    children,
    ...other
  } = props;

  const listStates = getStatesFromDealers(dealersByLocation);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);
  const [viewTasaChassi, setViewTasaChassi] = useState(false);
  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isDisable, setIsDisable] = useState(false);

  const validationSchemaConfig = {
    address: yup.string(),
    name: yup.string().required(t('message_error_required_name')),
    email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
    frame: yup
      .string()
      .required(t('message_error_required_chassis'))
      .test('is-17-digits', t('message_error_invalid_chassis_length'), (value) => {
        return value && value.length === 17;
      }),
    model: yup.string(),
    plaque: yup.string(),
    telephone:yup.string(),
    year: yup.string(),
    secondPhone: yup
      .string()
      .test('test-invalid-mobile-phone', t('error_message_invalid_mobile_phone'), (phone) =>
        validatePhoneMobileTasa(phone)
      ),
    state: yup
      .string()
      .notOneOf(['placeholder', undefined], t('message_error_required_state'))
      .required(t('message_error_required_state')),
    city: yup
      .string()
      .notOneOf(['placeholder', undefined], t('message_error_required_city'))
      .required(t('message_error_required_city')),
    dealership: yup
      .string()
      .notOneOf(['placeholder', undefined], t('message_error_required_dealership'))
      .required(t('message_error_required_dealership'))
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    trigger,
    formState,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      address: '',
      city: 'placeholder',
      dealership: 'placeholder',
      email: '',
      frame: '',
      model: 'placeholder',
      name: '',
      plaque: '',
      secondPhone: '',
      state: 'placeholder',
      telephone: '',
      year: ''
    }
  });

  const watchCity = watch('city');
  const watchState = watch('state');
  const baseClassName = 'form-container_recall_horizontal_field';

  function loadCities(state) {
    const dealersFromState = getDealersFromState(dealersByLocation, state);
    const citiesThatHaveDealers = getCitiesThatHaveDealersFromState(dealersFromState);
    setListCities(citiesThatHaveDealers);
  }

  function loadDealers(city) {
    if (city) {
      const filtered = dealersByLocation?.filter((dealer) => {
        return dealer?.address?.city.toUpperCase() == city.toUpperCase();
      });
      setListDealers(filtered);
    }
  }

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setValue('city', 'placeholder');
    setValue('dealership', 'placeholder');
    setValue('state', selected);
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('city', selected);
  }

  function onChangeDealers(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', selected);
    trigger('dealership');
  }

  useEffect(() => {
    if (!watchState) {
      return false;
    }
    loadCities(watchState);
  }, [watchState]);

  useEffect(() => {
    if (!watchCity) {
      return false;
    }
    loadDealers(watchCity);
  }, [watchCity]);

  function getSelectedDealerData(data) {
    if (!data?.dealership) {
      return null;
    }

    return dealersByLocation?.find((v) => v.name === data.dealership);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  const onSubmit = async (formData) => {
    setIsDisable(true);
    setIsStatusComponent('loading');
    formData.locale = 'es-AR';
    formData.type = 'RECALL';
    const dealer = getSelectedDealerData(formData);

    if (dealer) {
      formData.dealershipEmail = dealer.afterSalesEmail;
    }

    if (formData.model === 'placeholder') {
      formData.model = '';
    }

    const url = '/api/contact';

    const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');

    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');
      setIsDisable(false);
      return false;
    }

    formData.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    };

    try {
      const response = await fetch(url, opts);

      const isFormSubmittedSuccessfully = response.status === 200;

      if (isFormSubmittedSuccessfully) {
        reset();
        setIsStatusComponent('success');
        setIsDisable(false);
        return;
      }

      setIsStatusComponent('error');
      setIsDisable(false);
      console.error(t('error_title'), response.statusText + ' ' + response.status);
    } catch (error) {
      setIsStatusComponent('error');
      setIsDisable(false);
      console.log(error);
    }
  };

  function onError(error) {
    console.log('Error: ', error);
  }

  return (
    <Wrapper
      spacing={headerSpacing}
      className={`${css['molecule__recall-form-custom-container']} ${className}`}
      {...other}
    >
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>
      <Section tag="form" onSubmit={handleSubmit(onSubmit, onError)}>
        {isStatusComponent === 'loading' && (
          <div className={css['container-loader']}>
            <Loader />
          </div>
        )}

        {isStatusComponent === 'success' && (
          <SuccessSubmitForm
            onBackForm={onBackForm}
            titleSuccess={titleSuccess || t('success_title')}
            contentSuccess={contentSuccess || t('success_content')}
            contentBackForm={t('back_form_label')}
            contentBackHome={t('back_home_label')}
          />
        )}

        {isStatusComponent === 'error' && (
          <SuccessSubmitForm
            onBackForm={onBackForm}
            titleSuccess={titleError || t('error_title')}
            contentSuccess={contentError || t('error_content')}
            contentBackForm={t('back_form_label')}
            contentBackHome={t('back_home_label')}
          />
        )}

        {isStatusComponent === 'default' && (
          <div className={css['form-container']}>
            <FieldsetHeader
              className={css['form-container_header']}
              variant="1"
              legend={formTitle || t('form_title')}
              description={formDescription || t('form_description')}
            />
            <div className={css['form-container_recall']}>
              <div className={css['form-container_recall_horizontal']}>
                <FormInput
                  className={css[baseClassName]}
                  id="name"
                  dataRegister={register('name')}
                  dataErrors={errors['name']}
                  label={t('name_label')}
                  placeholder={t('name_placeholder')}
                  maxLength="19"
                />

                <FormInput
                  className={css[baseClassName]}
                  id="phone"
                  dataRegister={register('telephone')}
                  dataErrors={errors['phone']}
                  label={t('phone_label')}
                  placeholder={t('phone_placeholder')}
                  maxLength="19"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isNumberMask(value);
                  }}
                />
              </div>
              <div className={css['form-container_recall_horizontal']}>
                <FormInput
                  className={css[baseClassName]}
                  id="secondPhone"
                  dataRegister={register('secondPhone')}
                  dataErrors={errors['secondPhone']}
                  label={t('phone_alternative_label')}
                  placeholder={t('phone_alternative_placeholder')}
                  maxLength="19"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = isNumberMask(value);
                  }}
                />
                <FormInput
                  className={css[baseClassName]}
                  id="email"
                  dataRegister={register('email')}
                  dataErrors={errors['email']}
                  label={t('email_label')}
                  placeholder={t('email_placeholder')}
                />
              </div>
              <div className={css['form-container_recall_horizontal']}>
                <FormInput
                  className={css[baseClassName]}
                  id="address"
                  dataRegister={register('address')}
                  dataErrors={errors['address']}
                  label={t('address_label')}
                  placeholder={t('address_placeholder')}
                />
                <div className={css[baseClassName]} />
              </div>

              <div className={css['form-container_recall_horizontal']}>
                <FormInput
                  className={css[baseClassName]}
                  id="plaque"
                  dataRegister={register('plaque')}
                  dataErrors={errors['plaque']}
                  label={t('patent_label')}
                  placeholder={t('patent_placeholder')}
                  maxLength="19"
                  masking={(event) => {
                    const value = event.target.value;
                    event.target.value = removeSpecialCharacters(value);
                  }}
                />
                <div className={css['form-container_recall_horizontal_button']}>
                  <FormInput
                    className={css[errors['frame'] ? baseClassName : `${baseClassName}_button`]}
                    id="frame"
                    dataRegister={register('frame')}
                    dataErrors={errors['frame']}
                    label={t('chassis_label')}
                    placeholder={t('chassis_placeholder')}
                    maxLength="17"
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = removeSpecialCharacters(value);
                    }}
                  />

                  <button
                    className={css['modal-activate-button']}
                    type="button"
                    onClick={() => setViewTasaChassi(true)}
                  >
                    {t('anchor_link_label')}
                  </button>
                </div>
              </div>
              <div className={css['form-container_recall_horizontal']}>
                <FormSelect
                  className={css[baseClassName]}
                  id="model"
                  dataRegister={register('model')}
                  label={t('model_label')}
                  placeholder={t('model_placeholder')}
                >
                  {models?.length > 0 &&
                    models?.map((model, index) => {
                      return (
                        <option key={index} value={model?.name ? model?.name : ''}>
                          {model?.name}
                        </option>
                      );
                    })}
                </FormSelect>

                <FormInput
                  className={css[baseClassName]}
                  id="year"
                  dataRegister={register('year')}
                  dataErrors={errors['year']}
                  label={t('year_label')}
                  placeholder={t('year_placeholder')}
                  maxLength="4"
                  minLength="2"
                />
              </div>

              <div className={css['form-container_recall_horizontal']}>
                <FormSelect
                  className={css[baseClassName]}
                  id="state"
                  dataErrors={errors['state']}
                  dataRegister={register('state')}
                  label={t('state_label')}
                  placeholder={t('state_placeholder')}
                  onChange={onChangeState}
                >
                  {listStates.length > 0 &&
                    listStates?.map((state, index) => {
                      return (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      );
                    })}
                </FormSelect>

                <FormSelect
                  className={css[baseClassName]}
                  id="city"
                  dataErrors={errors['city']}
                  dataRegister={register('city')}
                  label={t('city_label')}
                  placeholder={t('city_placeholder')}
                  onChange={onChangeCity}
                >
                  {watchState &&
                    listCities.length > 0 &&
                    listCities?.map((city, index) => {
                      return (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      );
                    })}
                </FormSelect>
              </div>
              <div className={css['form-container_recall_horizontal']}>
                <FormSelect
                  className={css[baseClassName]}
                  id="dealership"
                  dataErrors={errors['dealership']}
                  dataRegister={register('dealership')}
                  label={t('dealership_label')}
                  placeholder={t('dealership_placeholder')}
                  onChange={onChangeDealers}
                >
                  {watchCity &&
                    listDealers.length > 0 &&
                    listDealers?.map((dealer, index) => {
                      return (
                        <option key={`dealer-${index}`} value={dealer.name}>
                          {dealer.name.toUpperCase()}
                        </option>
                      );
                    })}
                </FormSelect>
                <div className={css[baseClassName]} />
              </div>
            </div>

            <Button type="submit" disabled={!formState.isValid || isDisable} className={css['button_submit']}>
              {t('submit_button_label')}
            </Button>

            <Modal
              modalIsOpen={viewTasaChassi}
              onClose={() => setViewTasaChassi(false)}
              className={css['tasa-chassi-modal']}
            >
              <img src={'/images/chassiAR.png'} alt="chassi" />
            </Modal>
          </div>
        )}
      </Section>

      {children}
    </Wrapper>
  );
}

export default RecallFormCustom;
