/* eslint-disable max-len */
import React from 'react';
import Title from 'components/atoms/title';
import Media from 'components/molecules/media';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule SearchResultCard
 *
 * <!-- TODO: add a description here! -->
 */
function SearchResultCard(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.search-result-card');

  const {
    className = "",
    children,
    title,
    link,
    highlighted,
    media,
    target="",
    ...other
  } = props;

  return <div
    className={`${css["molecule__search-result-card-container"]} ${className}`}
    {...other}
  >
    <div className={css['info']}
      data-is-highlighted={highlighted ? true : false}
    >
      <a href={link} target={target} className={css['anchor']}>
        <Title className={css['info__title']}>
          {title}
        </Title>
      </a>
      <p className={css['info__content']}>
        {children}
      </p>
    </div>

    {media?.type === 'img' ?
      <a href={link} className={css['anchor']}>
        <Media
          className={css['media']}
          url={media?.url}
          alt={media?.alt}
          type={media?.type}
        />
      </a>
      : null
    }

  </div>;
}

export default SearchResultCard;
