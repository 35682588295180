import AnchorLink from 'components/atoms/anchor-link';
import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule CarListModels
 *
 * Card that will be used with links to other informations of the same vehicle
 */

function CarListModels(props) {
  const { className = '', logo = null, linksList = null, children, ...other } = props;

  return (
    <>
      <div className={`${css['molecule__car-list-models-container']} ${className}`} {...other}>
        {logo &&
          <div className={css['image-container']}>
            <h1>
              <img src={logo?.url} alt={logo?.alt} />
            </h1>
          </div>
        }
        {linksList &&
          <ul className={css['links-list']}>
            {linksList.map((link, key) => {
              return <li key={key}>
                <AnchorLink
                  color="white"
                  linkLabel={link.label}
                  link={link.url}
                  className={css['links-list__link']}
                />
              </li>;
            })}
          </ul>
        }
      </div>
    </>
  );
}

export default CarListModels;
