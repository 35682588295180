import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

import Dealer from 'utils/repo/dealers';

class FindDealers implements ContentBlock {
  label = 'Dealers list';

  component = 'FindDealers';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Title Dealers list',
      help: 'Enter the title for the Dealers list displayed on the screen',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'bgColor',
      label: 'Container Background color',
      help: 'The container Background color',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'black'
      ]
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const productId = extra?.context?.productId;

    if (!productId) {
      throw new Error(`There is no productId defined.`);
    }

    const dealersByLocation = (await dataFetcher(Dealer.getAllDealers(), { productId: productId }))?.data?.dealers;

    if (!dealersByLocation) {
      throw new Error('No dealers were found');
    }

    const dealers = [];

    dealersByLocation.forEach((item) => {
      if (item?.address?.coordLat && item?.address?.coordLng) {
        dealers.push({
          position: {
            lat: parseFloat(item.address.coordLat),
            lng: item.address.coordLng
          },
          ...item
        });
      }
    });

    const listStates = [...new Set(dealers.map((state) => state.address.state))].sort();

    return {
      dealers: dealers,
      title: extra?.settings?.title || '',
      listStates: listStates,
      locale: extra?.context?.locale || 'pt-BR'
    };
  }
}

export { FindDealers };
