import React from 'react';
import i18n from 'utils/i18n';
import Gallery from 'components/organisms/gallery';

// loading the sass style fot the component
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

/**
 * Organism VehicleGallery
 *
 * <!-- TODO: add a description here! -->
 */
function VehicleGallery(props) {
  const t = i18n.useTranslations('components.vehiclegallery');

  const {
    className = '',
    internalData,
    externalData,
    spacingTop = null,
    spacingBottom = null,
    children,
    ...other
  } = props;

  return (
    <div className={`${css['organism__vehiclegallery-container']} ${className}`} {...other}>
      <Wrapper spacingTop={spacingTop} spacingBottom={spacingBottom} bgColor="gray">
        <Section>
          <ul className={css['gallery-list']} data-grid>
            <li className={css['gallery-list__item']}>
              <Gallery
                title={t('internal-title')}
                alt={t('internal-alt')}
                coverImage={internalData?.[0]?.url}
                galleryData={internalData}
              />
            </li>
            <li>
              <Gallery
                title={t('external-title')}
                alt={t('external-alt')}
                coverImage={externalData?.[0]?.url}
                galleryData={externalData}
              />
            </li>
          </ul>
        </Section>
      </Wrapper>
      {children}
    </div>
  );
}

export default VehicleGallery;
