import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

function Tab(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.tab');

  const {
    className = "",
    selected = false,
    id,
    onClick = null,
    children,
    link = null,
    ...other
  } = props;

  function handleClick() {
    id ? onClick(id) : false;
  }

  const Tag = link ? 'a' : 'button';

  return <Tag
    className={`${css["atom__tab-container"]} ${className} ${selected ? css["selected"] : ""}`}
    onClick={handleClick}
    href={link || undefined}
    {...other}
  >
    {children}
  </Tag>;
}

export default Tab;
