import Wrapper from 'components/atoms/wrapper';
import React from 'react';

import Media from 'components/molecules/media';
import Section from 'components/utils/section';
import Title from 'components/atoms/title';

import css from './styles.module.scss';

/**
 * Organism SimpleBanner
 *
 * A section that will render banner.
 */
function SimpleBanner(props) {
  const { media, description, title, logo, ...other } = props;

  return (
    <Wrapper className={`${css['organism__simple-banner-container']}`} {...other}>
      <div className={css['banner-art']}>
        <Media
          url={media?.url}
          type={media?.mediaType?.title}
          alt={media?.subtitle}
          className={css['banner-art__picture']}
        />
        <Media url={'/images/simple-banner/simple-banner-mask.svg'} alt="" className={css['banner-art__mask']} />
      </div>

      <Section className={css['banner-content']}>
        {!!logo && <Media url={logo?.url} alt={logo?.subtitle} className={css['banner-content__logo']} />}

        <Title level={1} variant={1} className={css['banner-content__title']}>
          {title}
        </Title>

        <p className={css['banner-content__description']}>{description}</p>
      </Section>
    </Wrapper>
  );
}

export default SimpleBanner;
