import React, { useCallback, useEffect, useState } from 'react';
import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Section from 'components/utils/section';
import i18n from 'utils/i18n';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Title from 'components/atoms/title';
import FormInput from 'components/atoms/form-input';
import Button from 'components/atoms/button';
import Wrapper from 'components/atoms/wrapper';
import SectionHeader from 'components/molecules/section-header';
import FormSelect from 'components/atoms/form-select';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Script from 'next/script';
import { empty } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';
import FormCheckbox from 'components/atoms/form-checkbox';
import AnchorLink from 'components/atoms/anchor-link';

const FORM_RECORD_TYPE = '0123i000000Utrc';

/**
 * Organism SaleUsedForm
 *
 * Sale Used Form
 */
function SaleUsedForm(props) {
  const t = i18n.useTranslations('components.sale-used-form');
  const { publicRuntimeConfig } = getConfig();
  const {
    className = '',
    children,
    locale,
    dealers = [],
    headerSpacing,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    description,
    comments,
    ...other
  } = props;

  const validationSchemaConfig = {
    name: yup.string().trim().required(t('message_error_required_personalName')),
    lastName: yup
      .string()
      .trim()
      .required(t('message_error_required_lastName'))
      .min(1, t('message_error_min_char_lastName')),
    email: yup
      .string()
      .trim()
      .required(t('message_error_required_personalEmail'))
      .email(t('message_error_invalid_personalEmail')),
    telephone: yup.string(),
    state: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealershipState'))
      .required(t('message_error_required_dealershipState')),
    city: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealershipCity'))
      .required(t('message_error_required_dealershipCity')),
    dn: yup
      .string()
      .notOneOf(['placeholder'], t('message_error_required_dealership'))
      .typeError(t('message_error_required_dealership'))
      .required(t('message_error_required_dealership')),
    carModel: yup.string(),
    carVersion: yup.string(),
    carYear: yup.string(),
    carMileage: yup.string(),
    carPatents: yup.string(),
    agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      agreement: false
    }
  });

  const watchedFields = watch([
    'name',
    'lastName',
    'email',
    'state',
    'city',
    'dn',
    'agreement'
  ]);

  const watchedAgreement = watch('agreement');

  const [isStatusComponent, setIsStatusComponent] = useState('default');

  // eslint-disable-next-line no-unused-vars
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDealer, setSelectedDealer] = useState(dealers[0]);
  const [addressVisible, setAddressVisible] = useState('');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  useEffect(() => {
    validateForm();
  }, [watchedFields]);

  function validateForm() {
    const isFormEmpty = watchedFields.some((value) => empty(value) || value === 'placeholder');
    (isFormEmpty || (watchedAgreement === false)) ? setIsFormIncomplete(true) : setIsFormIncomplete(false);
}

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state.address.state))].sort());
  }

  function loadCity(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() == selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    if (selectedCity) {
      const filtered = dealers?.filter((dealer) => {
        return dealer?.address?.city.toUpperCase() == selectedCity.toUpperCase();
      });
      setListDealers(filtered);
      setSelectedDealer(filtered[0]);
    }
  }

  useEffect(() => {
    loadDealers(selectedCity);
  }, [selectedCity]);

  useEffect(() => {
    loadStates();
  }, []);

  function onChangeState(e) {
    const selected = e.currentTarget.value;

    if (selected) {
      loadCity(selected);
    }
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setSelectedCity(selected);
    loadDealers(selected);
  }

  function onChangeDealers(event) {
    const index = event.target.value;
    setSelectedDealer(listDealers[index]);
    setAddressVisible(true);
  }

  function onDisplayAddress(address, characters) {
    if (!address || address === '.') {
      return '';
    }

    return address.toLowerCase().concat(characters);
  }

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function generateCommentsByInfoVehicle() {
    return `${t('label_carModel')}: ${getValues('carModel')}
            ${t('label_carVersion')}: ${getValues('carVersion')}
            ${t('label_carYear')}: ${getValues('carYear')}
            ${t('placeholder_carMileage')}: ${getValues('carMileage')}
            ${t('label_carPatents')}: ${getValues('carPatents')}`;
  }

  const resetForm = useCallback(() => {
    const result = {};
    reset(result);
  }, [reset]);

  const onSubmit = async (data, event) => {
    setIsSubmitting(true);
    event.preventDefault();
    data.comments = generateCommentsByInfoVehicle();
    data.locale = locale;

    const captchaPayload = await captchaToken('LEAD', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');

      return false;
    }

    data.captchaPayload = captchaPayload;

    const updatedData = {
      ...data,
      origin: 'Inspeccion Usados',
      recordType: FORM_RECORD_TYPE,
      dn: selectedDealer.dn
    };

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedData)
    };
    const url = '/api/lead';

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsStatusComponent('success');
          setIsSubmitting(false);
          resetForm();
          return;
        }
        setIsStatusComponent('error');
        setIsSubmitting(false);
      })
      .catch((err) => {
        setIsSubmitting(false);
        console.log(err);
      });
  };

  return (
    <>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>
      <Section className={`${css['organism__sale-used-form-container']} ${className}`} {...other} tag="div" >
        {isStatusComponent === 'success' && (
          <SuccessSubmitForm
            className={css['container_sucess_submit']}
            onBackForm={onBackForm}
            titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
            contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
            contentBackForm={t('interest_content_back_form')}
            contentBackHome={t('interest_content_back_home')}
          />
        )}
        {isStatusComponent === 'error' && (
          <SuccessSubmitForm
            className={css['form-container__section']}
            onBackForm={onBackForm}
            titleSuccess={titleError ? titleError : t('error_title')}
            contentSuccess={contentError ? contentError : t('error_content')}
            contentBackForm={t('interest_content_back_form')}
            contentBackHome={t('interest_content_back_home')}
          />
        )}
        {isStatusComponent === 'default' && (
          <>
                      <Wrapper spacing={headerSpacing}>
                      <SectionHeader
                        style={{marginBottom: 0}}
                        title={t('form_title')}
                        description={{
                          value: description ? description : t('description_form'),
                          textColor: 'gray-4'
                        }}
                      />
                    </Wrapper>
                    <Title className={css['form-container__title_first']} variant={5}>
                {t('personal_info_title')}
              </Title>
          <form onSubmit={handleSubmit(onSubmit, onError)} data-grid data-grid-list='3'>

                <FormInput
                  id="name"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_personalName')}
                  placeholder={t('placeholder_personalName')}
                  dataRegister={register('name')}
                  dataErrors={errors['name']}
                />

                <FormInput
                  id="lastName"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_lastName')}
                  placeholder={t('placeholder_lastName')}
                  dataRegister={register('lastName')}
                  maxLength='80'
                  dataErrors={errors['lastName']}
                />

                <FormInput
                  id="email"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_personalEmail')}
                  placeholder={t('placeholder_personalEmail')}
                  dataRegister={register('email')}
                  dataErrors={errors['email']}
                />

                <FormInput
                  id="telephone"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_personalPhone')}
                  placeholder={t('placeholder_personalPhone')}
                  dataRegister={register('telephone')}
                  dataErrors={errors['telephone']}
                />

              <Title className={css['form-container__title']} variant={5}>
                {t('title_search_a_dealership')}
              </Title>

                <FormSelect
                  id="state"
                  className={css['form-container__input']}
                  label={t('label_dealershipState')}
                  placeholder={t('placeholder_dealershipState')}
                  dataRegister={register('state')}
                  dataErrors={errors['state']}
                  onChange={onChangeState}
                >
                  {listStates?.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </FormSelect>

                <FormSelect
                  id="city"
                  className={css['form-container__input']}
                  label={t('label_dealershipCity')}
                  placeholder={t('placeholder_dealershipCity')}
                  onChange={onChangeCity}
                  dataRegister={register('city')}
                  dataErrors={errors['city']}
                >
                  {listCities?.map((city, index) => (
                    <option key={index} value={city}>
                      {city}
                    </option>
                  ))}
                </FormSelect>
<div className={css['form-container__adress']}>
                  <FormSelect
                    id="dn"
                    className={css['form-container__input']}
                    label={t('label_dealership')}
                    placeholder={t('placeholder_dealership')}
                    dataRegister={register('dn')}
                    onChange={onChangeDealers}
                    dataErrors={errors['dn']}
                  >
                    {listDealers?.map((dealer, index) => (
                      <>
                        <option key={index} value={index}>
                          {dealer.name.toUpperCase()}
                        </option>
                      </>
                    ))}
                  </FormSelect>

                  {selectedDealer && addressVisible && locale === 'es-ar' && (
                    <p className={css['form-container__label_address']} data-grid-full-width>
                      {onDisplayAddress(selectedDealer?.address.street, ' ')}
                      {onDisplayAddress(selectedDealer?.address.number, ', ')}
                      {onDisplayAddress(selectedDealer?.address?.neighborhood, ', ')}
                      {onDisplayAddress(selectedDealer?.address.city, ', ')}
                      {onDisplayAddress(selectedDealer?.address.state, ', Argentina.')}
                    </p>
                  )}
</div>

              <Title className={css['form-container__title']} variant={5}>
                {t('title_car_information')}
              </Title>

                <FormInput
                  id="carModel"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_carModel')}
                  placeholder={t('placeholder_carModel')}
                  dataRegister={register('carModel')}
                  dataErrors={errors['carModel']}
                />

                <FormInput
                  id="carVersion"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_carVersion')}
                  placeholder={t('placeholder_carVersion')}
                  dataRegister={register('carVersion')}
                  dataErrors={errors['carVersion']}
                />

                <FormInput
                  id="carYear"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_carYear')}
                  placeholder={t('placeholder_carYear')}
                  dataRegister={register('carYear')}
                  dataErrors={errors['carYear']}
                />

                <FormInput
                  id="carMileage"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_carMileage')}
                  placeholder={t('placeholder_carMileage')}
                  dataRegister={register('carMileage')}
                  dataErrors={errors['carMileage']}
                />

                <FormInput
                  id="carPatents"
                  type="text"
                  className={css['form-container__input']}
                  label={t('label_carPatents')}
                  placeholder={t('placeholder_carPatents')}
                  dataRegister={register('carPatents')}
                  dataErrors={errors['carPatents']}
                />

            <div data-grid-full-width>
              <FormCheckbox
                className={css['form-container__checkbox-agreement']}
                id="agreement"
                dataRegister={register('agreement')}
                dataErrors={errors['agreement']}
              >
                {t('label_agreement')}&nbsp;
                <AnchorLink link={'/venta-usados-legales'} hasArrow={false} linkLabel={t('link_label_agreement')} />
              </FormCheckbox>
            </div>

            <div className={css['form-container__section']} data-grid-full-width>
              <Button type="submit" disabled={Object.keys(errors).length > 0 || isFormIncomplete || isSubmitting}>{t('button_submit_form')}</Button>
            </div>
          </form>
          </>
        )}
      </Section>
    </>
  );
}

export default SaleUsedForm;
