import React from 'react';

import Button from 'components/atoms/button';
import AnchorLink from 'components/atoms/anchor-link';

import ModelLegalText from 'components/molecules/model-legal-text';

// loading the sass style fot the component
import css from './styles.module.scss';

import { useCarBuilder } from 'providers/car-builder';

import { currency } from 'utils/functions';

/**
 * Molecule CarPaymentBanner
 *
 * <!-- TODO: add a description here! -->
 */
function CarPaymentBanner(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.car-payment-banner');

  const {
    className = '',
    hasIcon = false,
    hasExtraInfo = false,
    hasButton = false,
    hasCTA = false,
    updatePageState = null,
    model = null,
    isContactForm = undefined,
    pageState = null,
    btbData = null
  } = props;

  const { data: carData } = useCarBuilder();

  const carPaymentData = btbData || carData?.data?.BTBFullInformation;

  const installmentsNumber = carPaymentData?.installments || null;
  const installmentsValue = currency(carPaymentData?.installment?.total) || null;
  const firstInstallment = currency(carPaymentData?.downPayment) || null;
  const finalInstallment = currency(carPaymentData?.residual) || null;
  const totalValue = currency(carPaymentData?.total) || null;

  const extraInfoClass = isContactForm ? `${css['extra-info']} ${css['extra-info--form']}` : `${css['extra-info']}`;

  return (
    <>
      <div className={`${css['car-payment-banner-container']} ${className}`}>
        <div className={`${css['top-container']} ${css['container-position']}`}>
          {hasIcon && (
            <div className={css['image container']}>
              <img src="/images/logos/logo-ciclo-toyota.png" alt="ciclo toyota" />
            </div>
          )}

          <div data-has-icon={hasIcon} className={`${css['info-container']} ${css['container-position']}`}>
            <ul className={`${css['payment-container']} ${css['container-position']}`}>
              {installmentsNumber && installmentsValue && (
                <li className={`${css['payment-container__item']} ${css['container-position']}`}>
                  <div className={css['item']}>
                    <span className={css['item__label']}>{installmentsNumber} Parcelas de</span>
                    <span data-bold className={css['item__value']}>
                      {installmentsValue}
                    </span>
                  </div>
                </li>
              )}

              {firstInstallment && (
                <li className={`${css['payment-container__item']} ${css['container-position']}`}>
                  <div className={css['item']}>
                    <span className={css['item__label']}>Entrada de</span>
                    <span className={css['item__value']}>{firstInstallment}</span>
                  </div>
                </li>
              )}

              {finalInstallment && (
                <li className={`${css['payment-container__item']} ${css['container-position']}`}>
                  <div className={css['item']}>
                    <span className={css['item__label']}>Parcela final de</span>
                    <span className={css['item__value']}>{finalInstallment}</span>
                  </div>
                </li>
              )}
            </ul>

            {hasExtraInfo && (
              <div className={css['extra-info-container']}>
                <span className={extraInfoClass}>ou seu usado como entrada de um Toyota 0km</span>
              </div>
            )}
          </div>
        </div>

        <div className={`${css['bottom-container']} ${css['container-position']}`}>
          <ul data-has-icon={hasIcon} className={`${css['links-container']} ${css['container-position']}`}>
            <li className={css['links-container__item']}>
              <ModelLegalText
                vehicleName={model?.vehicle?.name}
                modelName={model?.name}
                colorName={model?.selectedColor?.color?.name}
                installmentsValue={installmentsValue}
                btbData={carPaymentData}
              />
            </li>
            <li className={css['links-container__item']}>
              <AnchorLink
                size="small"
                linkLabel="Manual de recompra"
                link="/docs/manual-de-recompra.pdf"
                target="_blank"
              />
            </li>
          </ul>

          {hasButton && (
            <div className={css['button-container']}>
              {pageState === 'simule' ? (
                <Button
                  color="default"
                  onClick={(_) => {
                    if (updatePageState) {
                      updatePageState('eu-quero-ciclo');
                    }
                  }}
                >
                  Eu quero pelo ciclo
                </Button>
              ) : (
                <Button
                  color="light"
                  onClick={(_) => {
                    if (updatePageState) {
                      updatePageState('simule');
                    }
                  }}
                >
                  Simule seu ciclo
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {hasCTA && (
        <div className={css['car-payment-cta-container']}>
          <div className={css['car-payment-header']}>
            <span>Com frete incluso, a partir de</span>
            <strong className={css['car-payment-header__highlight']}>{totalValue}</strong>
            <span className={css['car-payment-header__extra-info']}>Preço base: Brasília¹</span>
          </div>
          <Button
            className={css['car-payment-cta-container__cta']}
            onClick={(_) => {
              if (updatePageState) {
                updatePageState('eu-queroavista');
              }
            }}
          >
            Eu quero
          </Button>
        </div>
      )}
    </>
  );
}

export default CarPaymentBanner;
