import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class MediaBannerAction implements ContentBlock {
  label = 'Single Action Banner';

  component = 'MediaBannerAction';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the banner title',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The text description of the banner',
      type: 'string',
      control: 'textarea',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Banner image',
      help: 'Set the banner image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link label',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];

    return {
      title: content?.title,
      textContent: content?.description || null,
      media: { type: 'img', ...content?.media } || null,
      actionButton: content?.callToActions?.[0]?.target
        ? {
            link: content?.callToActions?.[0]?.target || null,
            label: content?.callToActions?.[0]?.label || null
          }
        : null
    };
  }
}

export { MediaBannerAction };
