import React from 'react';
import Title from 'components/atoms/title';
import Media from 'components/molecules/media';
import Section from 'components/utils/section';

//import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Wrapper from 'components/atoms/wrapper';

/**
 * Molecule MediaBanner
 *
 * <!-- TODO: add a description here! -->
 */

function MediaBanner(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.media-banner');

  const {
    className = '',
    image,
    title,
    content,
    media,
    poster,
    spacingTop = '4',
    spacingBottom = '4',
    bgColor = 'default',
    ...other
  } = props;

  return (
    <Wrapper bgColor={bgColor} spacingTop={spacingTop} spacingBottom={spacingBottom}>
      <Section
        className={`${css['molecule__media-banner-container']} ${className}`}
        {...other}
        tag="div"
        hasGrid={true}
      >
        <div className={css['container-content']}>
          {image && <Media url={image?.url} alt={image?.alt} type={image?.type} />}
          <Title className={css['container-content__title']} level={1} variant={1} isUppercase>
            {title}
          </Title>
          {content && (
            <ReactMarkdown className={css['container-content__content']} rehypePlugins={[rehypeRaw]}>
              {content}
            </ReactMarkdown>
          )}
        </div>
        <div className={css['container-media']}>
          <Media poster={poster} url={media?.url} type={media?.type} />
        </div>
      </Section>
    </Wrapper>
  );
}

export default MediaBanner;
