import React from 'react';
//import i18n from 'utils/i18n';

// loading the sass style fot the component
import ActionBannerItem from 'components/molecules/action-banner-item';
import css from './styles.module.scss';
import ToyotaCicleCars from 'components/organisms/toyota-cicle-cars';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

/**
 * Organism VehicleOpen
 *
 * <!-- TODO: add a description here! -->
 */
function VehicleOpen(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.vehicle-open');

  const {
    className = '',
    logo,
    bannerImage,
    linksList,
    carVersionsData,
    vehicleMedia,
    templateData,
    locale = 'pt-BR',
    children,
    ...other
  } = props;

  const actionBannerImage = {
    desktop: bannerImage?.desktop?.url || '',
    mobile: bannerImage?.mobile?.url || '',
    alt: bannerImage?.desktop?.title || ''
  };

  return (
    <div className={`${css['organism__vehicle-open-container']} ${className}`} {...other}>
      <Wrapper>
        <ActionBannerItem bannerImage={actionBannerImage} logo={logo} linksList={linksList} />
      </Wrapper>
      <Wrapper spacing="7">
        <Section>
          <ToyotaCicleCars
            templateData={templateData}
            carVersionsData={carVersionsData}
            vehicleMedia={vehicleMedia}
            locale={locale}
          />
        </Section>
      </Wrapper>

      {children}
    </div>
  );
}

export default VehicleOpen;
