// import { FormSearch } from 'components/icons';
import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'utils/i18n';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import Button from 'components/atoms/button';
import FormSelect from 'components/atoms/form-select';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';
import css from './styles.module.scss';

/**
 * Molecule ActionBannerWithForm
 *
 * <!-- TODO: add a description here! -->
 */
function ActionBannerWithForm(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.action-banner-with-form');

  const {
    className = '',
    bgImage = '/images/bgLocal.png',
    highlightImage = '/images/hlLocal.png',
    title = t('banner-title'),
    dealers = [],
    description,
    variant,
    spacingTop = 0,
    spacingBottom = 0,
    bgColor = 'default',
    ...other
  } = props;

  const router = useRouter();

  const { register, setValue, watch } = useForm({
    defaultValues: {
      city: 'placeholder',
      state: 'placeholder'
    }
  });

  const watchedState = watch('state');
  const watchedCity = watch('city');
  const [listCities, setListCities] = useState([]);

  const isButtonDisabled = !isValidSelection(watchedState, watchedCity);

  const listStates = useMemo(() => {
    return [...new Set(dealers?.flatMap((dealer) => (dealer?.address?.state ? [dealer.address.state] : [])))].sort();
  }, [dealers]);

  useEffect(() => {
    loadCities();
  }, [watchedState]);

  function loadCities() {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state?.toUpperCase() === watchedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered?.map((city) => city?.address?.city?.toUpperCase()))].sort();
    setListCities(resultCities);
  }

  function isValidSelection(state, city) {
    return !!state && state !== 'placeholder' && !!city && city !== 'placeholder';
  }

  function onChangeState(e) {
    setListCities([]);
    setValue('city', 'placeholder');
    setValue('state', e.currentTarget.value);
  }

  function onChangeCity(e) {
    setValue('city', e.currentTarget.value);
  }

  function onSubmit() {
    const url = t('url');

    if (url && !isButtonDisabled) {
      router?.push(`${url}?state=${watchedState}&city=${watchedCity}`, undefined, {
        shallow: true
      });
    }
  }

  return (
    <>
      <Wrapper data-spacing-top={spacingTop} data-spacing-bottom={spacingBottom} bgColor={bgColor} {...other}>
        <Section
          className={`${css['molecule-action-banner-with-form-container']} ${className}`}
          style={{ backgroundImage: `url('${bgImage}')` }}
          hasGrid={true}
        >
          <picture>
            <source srcSet={highlightImage} media="(min-width: 768px)" />
            <img
              alt=""
              data-variant={variant}
              className={css['banner-highlight-image']}
              src={`data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==`}
            />
          </picture>

          <form className={css['banner-form-wrapper']} data-variant={variant}>
            {title && <h2 className={css['banner-title']}>{title}</h2>}
            {description && <span className={css['banner-description']}>{description}</span>}
            <div className={css['form-container__list-input']}>
              <FormSelect
                className={css['form-container__input']}
                id="state"
                label={t('label_state')}
                placeholder={t('placeholder_state')}
                onChange={onChangeState}
                dataRegister={register('state')}
                displayError={false}
              >
                {listStates?.length > 0 &&
                  listStates?.map((state, index) => {
                    return (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    );
                  })}
              </FormSelect>
              <FormSelect
                className={css['form-container__input']}
                id="city"
                label={t('label_city')}
                placeholder={t('placeholder_city')}
                onChange={onChangeCity}
                dataRegister={register('city')}
                displayError={false}
              >
                {watchedState &&
                  listCities?.length > 0 &&
                  listCities?.map((city, index) => {
                    return (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    );
                  })}
              </FormSelect>
            </div>
            <div className={css['button-container']}>
              <Button className={css['button-submit']} type="submit" disabled={isButtonDisabled} onClick={onSubmit}>
                {t('button_submit_form')}
              </Button>
            </div>
          </form>
        </Section>
      </Wrapper>
    </>
  );
}

export default ActionBannerWithForm;
