import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class IframeSection implements ContentBlock {
  label = 'Iframe';

  component = 'IframeSection';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Page Title',
      help: 'The of embeded page',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'url',
      label: 'Page URL',
      help: 'Full address of page',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    return {
      title: extra?.settings?.title,
      url: extra?.settings?.url,
    };
  }
}

export { IframeSection };
