import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class DownloadDocList implements ContentBlock {
  label = 'Download Doc List';

  component = 'DownloadDocList';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    {
      name: 'showTitle',
      label: 'Show section title',
      help: 'Will show the section title in the top of the section',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'The title of the content',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the item',
      type: 'string',
      control: 'textarea',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link label',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter (contents: any[], extra: any): Promise<object> {

    return {
      title: extra?.section?.name,
      downloadDocList:
        contents.map(content => {

          return {
            title: content?.title,
            text: content?.description,
            linkUrl: content?.callToActions?.[0]?.target || null,
            value: content?.callToActions?.[0]?.label || null,
            color: 'white',
            showTitle: extra?.showTitle?.settings
          };

        })
    };

  }

}

export { DownloadDocList };
