import React, { useState, useEffect } from 'react';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import ThumbHover from 'components/atoms/thumb-hover';
import Title from 'components/atoms/title';
import Loader from 'components/atoms/loader';

// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

import BTB from 'utils/btb/utils';

import { useCarBuilder } from 'providers/car-builder';
import { currency } from 'utils/functions';

/**
 * Molecule CarUpgradeCard
 *
 * <!-- TODO: add a description here! -->
 */
function CarUpgradeCard(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.car-upgrade-card');

  const { className = '', model = null, carThumb = null, currentPath = null } = props;

  const { data: carData } = useCarBuilder();

  // BTB
  const [btb, setBtb] = useState(null);
  const [BTBIsLoading, setBTBisLoading] = useState(false);
  const [installmentUpgradeValue, setInstallmentUpgradeValue] = useState(null);

  useEffect(() => {
    if (!btb) {
      loadBTBData();
    }
  }, []);

  // When the user changes the installment number
  useEffect(() => {
    if (carData?.data?.installmentsValue) {
      btb?.setInstallments(carData?.data?.installmentsNumber);

      setInstallmentUpgradeValue(getInstallmentValue());
    }
  }, [carData?.data?.installmentsValue]);

  // When the user changes the downpayment value
  useEffect(() => {
    if (carData?.data?.desiredFirstInstallment) {
      loadBTBData();
    }
  }, [carData?.data?.desiredFirstInstallment]);

  useEffect(() => {
    if (!btb) {
      return;
    }

    btb?.setInstallments(carData?.data?.installmentsNumber);

    setInstallmentUpgradeValue(getInstallmentValue());

    setBTBisLoading(false);
  }, [btb]);

  function loadBTBData() {
    (async () => {
      const modelUpgrade = model?.upgrade;

      setBTBisLoading(true);

      //We load the upgrade BTB with the data for the query
      const btbData = new BTB({
        fipe: modelUpgrade?.modelYear?.version?.code,
        year: modelUpgrade?.modelYear?.year?.toString(),
        price: modelUpgrade?.modelYear?.colorWithPrice[0]?.price,
        downValue: carData?.data?.desiredFirstInstallment || null, //If we have a desired value from the user, use it
        down: '60%' //Load the default value of 60%
      });

      await btbData.load();

      setBtb(btbData);
    })().catch((error) => console.error(error));
  }

  function getInstallmentValue() {
    return currency(Math.floor(btb?.getInstallments() - carData?.data?.installmentsValue));
  }

  return (
    <div className={`${css['car-upgrade-card-container']} ${className}`}>
      <div className={css['title-container']}>
        <span className={css['title-container__text']}>Leve um</span>
        <Title className={css['title-container__title']} variant={3} level={2}>
          {model?.vehicle?.name} {model?.upgrade?.name}
        </Title>
      </div>

      <div className={css['link-container']}>
        <AnchorLink link={`${currentPath}/monte/?version=${model?.upgrade?.name}`} linkLabel="Monte o seu" />
      </div>

      {carThumb && (
        <div className={css['car-image-container']}>
          <ThumbHover image={carThumb} />
        </div>
      )}

      {BTBIsLoading ? (
        <Loader />
      ) : (
        <div className={css['info-container']}>
          <div className={css['addition-container']}>
            <span className={css['addition-container__label']}>Acréscimo na parcela</span>
            <span className={css['addition-container__value']}>{installmentUpgradeValue}</span>
          </div>
        </div>
      )}
      <div className={css['bottom-container']}>
        <Button className={css['button']} color="light" link={`${currentPath}/monte/?version=${model?.upgrade?.name}`}>
          Fazer upgrade
        </Button>
      </div>
    </div>
  );
}

export default CarUpgradeCard;
