import Dealer from 'utils/repo/dealers';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class DealersList implements ContentBlock {
  label = 'All dealers list';

  component = 'DealersList';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const productId = extra?.context?.productId;

    if (!productId) {
      throw new Error(`There is no productId defined.`);
    }

    const dealers = (await dataFetcher(Dealer.getAllDealers(), { productId: productId }))?.data?.dealers;

    return {
      dealers: dealers || [],
      locale: extra?.context?.locale
    };
  }
}

export { DealersList };
