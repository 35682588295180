import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class SecondaryNavigation implements ContentBlock {
  label = 'Secondary Navigation';

  component = 'SecondaryNavigation';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    {
      name: 'showSectionNavigation',
      label: 'Show section navigation',
      help: 'Will show the section navigation in the top of the section',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    {
      name: 'title',
      label: 'Content navigation',
      help: 'The content of the navigation',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the page title, ex: Sports.',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Active link color(hexadecimal)',
      help: 'Enter the hexadecimal of the button color when active ex #FF0000',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },

    {
      name: 'media',
      label: 'Active url page icon image',
      help: 'Set page icon image with active url',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'mediaMob',
      label: 'Inactive url page icon image',
      help: 'Set page icon image with inactive url',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link label',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {
      title: extra?.settings?.title,
      showSectionNavigation: extra?.settings?.showSectionNavigation,
      links: contents.map((content) => {
        return {
          pageTitle: content?.title,
          activeIcon: content?.media
            ? {
                type: content?.media?.mediaType?.title,
                url: content?.media?.url,
                alt: content?.media?.description
              }
            : null,
          inactiveIcon: content?.mediaMob
            ? {
                type: content?.mediaMob?.mediaType?.title,
                url: content?.mediaMob?.url,
                alt: content?.mediaMob?.description
              }
            : null,
          url: content?.callToActions?.[0]?.target || null,
          label: content?.callToActions?.[0]?.label || null,
          colorActiveLink: content?.description
        };
      })
    };
  }
}

export { SecondaryNavigation };
