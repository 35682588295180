/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import Pill from 'components/atoms/pill';
import Button from 'components/atoms/button';
import Title from 'components/atoms/title';
import Loader from 'components/atoms/loader';

import ModelForm from 'components/molecules/model-form';
import AccesoryCardList from 'components/molecules/accesory-card-list';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism AccessoriesList
 *
 * <!-- TODO: add a description here! -->
 */
function AccessoriesList(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.accessories-list');

  const {
    className = '',
    spacingTop = 6,
    spacingBottom = 6,
    bgColor = 'gray',
    models,
    categories,
    categoriesModels = [],
    disclaimer = null,
    locale,
    ...other
  } = props;

  const getUniqueCategories = [...new Set(categoriesModels.map((category) => category?.name))];
  const isTasa = locale.toLowerCase() === 'es-ar';
  const [modelsList, setModelsList] = useState(models);

  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [selectedTypeFilter, setSelectedTypeFilter] = useState(null);

  const [filteredAccessories, setFilteredAccessories] = useState(null);
  const types = ['INTERNAL', 'EXTERNAL'];

  const [filtersOrderBy, setFiltersOrderBy] = useState('name:asc');
  const [selectedType, setSelectedType] = useState('');

  const [enableForm, setEnableForm] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [categoriesIds, setCategoriesIds] = useState([]);

  const getterOrderBy = (option) => {
    const optionList = option.split(':');
    return {
      type: optionList[0],
      order: optionList[1]
    };
  };

  function updateType(type) {
    type === selectedType ? setSelectedType('') : setSelectedType(type);
  }

  function searchSubmit(evt) {
    evt.preventDefault();
    if (searchInput !== query && enableForm) {
      setQuery(searchInput);
    }
  }

  function handleCategories(categoryId) {
    const verification = categoriesIds?.filter((res) => res !== categoryId);
    if (verification?.length < categoriesIds?.length) {
      setCategoriesIds(verification);
    } else {
      setCategoriesIds([...categoriesIds, categoryId]);
    }
  }

  function sortVehiclesByCategoryAndOrder(vehicles) {
    vehicles?.sort((a, b) => {
      const categoryIndexA = getUniqueCategories.indexOf(a.category.name);
      const categoryIndexB = getUniqueCategories.indexOf(b.category.name);

      if (categoryIndexA !== categoryIndexB) {
        return categoryIndexA - categoryIndexB;
      }
      return a.order - b.order;
    });

    return vehicles;
  }

  useEffect(() => {
    if (isTasa) {
      sortVehiclesByCategoryAndOrder(models);
      const sortedModels = sortVehiclesByCategoryAndOrder([...models]);
      setModelsList(sortedModels);
    }
  }, []);

  useEffect(() => {
    if (selectedOptionId) {
      const orderBy = getterOrderBy(filtersOrderBy);

      const isAllVersions = selectedTypeFilter === 'all';

      const apiEndpoint = isAllVersions
        ? `/api/accessories/by-vehicle/?vehicleId=${selectedOptionId}&type=${selectedType}&categoryIds=${categoriesIds}&name=${query}&orderByType=${orderBy.type}&order=${orderBy.order}`
        : `/api/accessories/?versionId=${selectedOptionId}&type=${selectedType}&categoryIds=${categoriesIds}&name=${query}&orderByType=${orderBy.type}&order=${orderBy.order}`;

      setLoading(true);
      fetch(apiEndpoint)
        .then(async (res) => {
          const result = await res.json();

          if (result.accessoriesByVehicle) {
            setFilteredAccessories(result.accessoriesByVehicle);
          } else if (result.accessoriesByVersion) {
            setFilteredAccessories(result.accessoriesByVersion);
          } else {
            setFilteredAccessories(null);
            console.error('No valid result returned by the API');
          }
        })
        .then(async (res) => {
          setEnableForm(true);
        })
        .catch((error) => {
          console.error(error);
          setFilteredAccessories(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    selectedOptionId,
    filtersOrderBy,
    categoriesIds,
    selectedType,
    selectedTypeFilter,
    query
  ]);

  function handleVersionSelect(selectedFilter) {
    if (selectedFilter) {
      setSelectedTypeFilter(selectedFilter);
    }
  }

  return (
    <Wrapper
      bgColor={bgColor}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      className={`${css['accessories-list-container']} ${className}`}
      {...other}
    >
      <Section className={css['accessory-header']}>
        <Title variant={2} level={1} className={css['accessory-header__title']}>
          {t('title')}
        </Title>
        <div className={css['message-container']}>
          <strong className={css['message-container__message']}>{t('initial-accessories-message')}</strong>
        </div>
      </Section>

      {modelsList?.length > 0 && (
        <Section className={css['model-form-wrapper']} hasGrid>
          <ModelForm
            className={css['accessory-model-form']}
            carModelsList={modelsList}
            setSelectedOptionId={setSelectedOptionId}
            locale={locale}
            onVersionSelect={handleVersionSelect}
          />
          {selectedOptionId && (
            <form className={css['accessory-search-form']} onSubmit={searchSubmit}>
              <input
                className={css['accessory-search-form__input']}
                type="text"
                placeholder={t('accessory-form-placeholder')}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                disabled={enableForm ? undefined : true}
              />
              <Button className={css['accessory-search-form__action']}>Buscar</Button>
            </form>
          )}
        </Section>
      )}
      {selectedOptionId && (
        <>
          <Section className={css['filters-wrapper']}>
            <div className={css['filters-container']}>
              <strong className={css['filters-container__title']}>{t('accessory-list-title-type')}</strong>
              <ul className={css['filter-list']}>
                {types?.map((type, index) => {
                  return (
                    <li className={css['filter-list__item']} key={`${selectedOptionId}` + index}>
                      <Pill
                        label={t(type)}
                        isButton={true}
                        removeDefaultBehaviours={true}
                        isSelected={selectedType === type}
                        onClick={() => updateType(type)}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className={css['filters-container']}>
              <strong className={css['filters-container__title']}>{t('accessory-list-title-category')}</strong>
              <ul className={css['filter-list']}>
                {categories?.map((category, index) => {
                  return (
                    <li className={css['filter-list__item']} key={`${selectedOptionId}` + index}>
                      <Pill label={category.name} isButton={true} onClick={() => handleCategories(category.id)} />
                    </li>
                  );
                })}
              </ul>
            </div>
          </Section>
          <Section className={css['accessory-container']}>
            {loading ? (
              <div className={css['loader-container']}>
                <Loader />
              </div>
            ) : (
              selectedOptionId &&
              (filteredAccessories?.length > 0 ? (
                <>
                  <div className={css['result-pagination']}>
                    <span className={css['result-pagination__product']}>
                      <span>{filteredAccessories?.length}</span>{' '}
                      {filteredAccessories?.length > 1 ? t('result-product').concat('s') : t('result-product')}
                    </span>
                    <div className={css['order']}>
                      <span className={css['result-pagination__text']}>Ordenar por:</span>

                      <select
                        className={css['result-pagination__selected']}
                        value={filtersOrderBy}
                        onChange={(e) => setFiltersOrderBy(e.target.value)}
                      >
                        <optgroup label="Ordernar por: ">
                          <option value="price:asc">{t('selected-lower-value')}</option>
                          <option value="price:desc">{t('selected-highest-value')}</option>
                          <option value="name:asc">{t('selected-az-value')}</option>
                          <option value="name:desc">{t('selected-za-value')}</option>
                        </optgroup>
                      </select>
                    </div>
                  </div>

                  <AccesoryCardList
                    className={css['result-pagination__accessory-card-list']}
                    acessoryListData={filteredAccessories}
                    locale={locale}
                  />
                </>
              ) : (
                <div className={css['message-container']}>
                  <strong className={css['message-container__message']}>{t('no-result-accessories')}</strong>
                </div>
              ))
            )}
          </Section>
        </>
      )}

      {disclaimer && (
        <Section className={css['section-disclaimer']}>
          {/* eslint-disable-next-line */}
          <ReactMarkdown rehypePlugins={[rehypeRaw]} children={disclaimer} />
        </Section>
      )}
    </Wrapper>
  );
}

export default AccessoriesList;
