export const models = [
  { name: 'C - HR' },
  { name: 'Camry' },
  { name: 'Corolla' },
  { name: 'Corolla Cross' },
  { name: 'Corolla Cross GRS' },
  { name: 'Corolla Cross Hybrid' },
  { name: 'Corolla GR-Sport' },
  { name: 'Corolla Hybrid' },
  { name: 'Etios Aibo' },
  { name: 'Etios Hatchback' },
  { name: 'Etios Sedán' },
  { name: 'GR Yaris' },
  { name: 'Hiace Commuter' },
  { name: 'Hiace Furgón' },
  { name: 'Hiace Wagon' },
  { name: 'Hilux DX/SR' },
  { name: 'Hilux GR-Sport' },
  { name: 'Hilux SRV/SRX' },
  { name: 'Innova' },
  { name: 'Land Cruiser 300 GR-S' },
  { name: 'Land Cruiser 300 VX' },
  { name: 'Land Cruiser Prado' },
  { name: 'Nueva T - Bike' },
  { name: 'Prius' },
  { name: 'RAV4' },
  { name: 'SW4' },
  { name: 'SW4 DIAMOND' },
  { name: 'SW4 GR-Sport' },
  { name: 'Toyota 86' },
  { name: 'Yaris Hatchback' },
  { name: 'Yaris Sedán' }
];
