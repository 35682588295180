import SvgIconAvailable from '../../icons/IconAvailable';
import SvgIconNotAvailable from '../../icons/IconNotAvailable';
import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom ItemAvailability
 *
 * Verify context present attributes
 */
function ItemAvailability(props) {
  const { className = '', children, isAvailable = true, title = '', ...other } = props;

  return (
    <>
      <div
        className={`${css['atom__item-availability-container']} ${className}`}
        data-is-available={isAvailable ? undefined : 'false'}
        {...other}>
        <strong className={css['item-availability-title']}>{title}</strong>

        {isAvailable ? <SvgIconAvailable /> : <SvgIconNotAvailable />}
      </div>
    </>
  );
}

export default ItemAvailability;
