import React from 'react';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';


// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom ContentDivider
 *
 * Generic content divider
 */
function ContentDivider(props) {
  const { className = '', children, size = null, spacing = "0", bgColor = null, fullWidth = true, ...other } = props;

  return (
    <>
      <Wrapper spacing={spacing} bgColor={bgColor}>
        {fullWidth ?
          <hr className={`${css['atom__content-divider-container']} ${className}`} {...other} data-size={size} />
          :
          <Section>
            <hr className={`${css['atom__content-divider-container']} ${className}`} {...other} data-size={size} />
          </Section>
        }
      </Wrapper>
    </>
  );
}

export default ContentDivider;
