import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import {
  anchor,
  spacingTopMeta,
  spacingBottomMeta,
  bgColorMeta,
  kindOfContent,
  autoPlay,
  muted,
  loop,
  controls
} from 'utils/meta';

class BannerListSection implements ContentBlock {
  label = 'Banner list Section';

  component = 'BannerListSection';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    {
      name: 'showTitle',
      label: 'Show section title',
      help: 'Will show the section title in the top of the section',
      type: 'boolean',
      control: 'toggle',
      defaultValue: false
    },
    autoPlay,
    muted,
    loop,
    controls,
    {
      name: 'contentLayoutType',
      label: 'The layout type of the text content',
      help: '',
      type: 'String',
      control: 'select',
      options: ['default', 'type-2']
    },
    {
      name: 'layoutType',
      label: 'The layout type',
      help: 'Different layouts for the list',
      type: 'String',
      control: 'select',
      options: [
        'default',
        '2',
        '4'
      ]
    },
    spacingTopMeta,
    spacingBottomMeta,
    bgColorMeta,
    kindOfContent

  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the banner title',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The text description of the banner',
      type: 'string',
      control: 'editor',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Banner image',
      help: 'Set the banner image',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link label',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    return {
      sectionTitle: extra?.section?.name,
      showTitle: extra?.settings?.showTitle,
      bgColor: extra?.settings?.bgColor,
      layoutType: extra?.settings?.layoutType,

      banners: contents.map((content) => {
        return {
          title: content?.title,
          description: content?.description,
          media: content?.media
            ? {
                type: content?.media?.mediaType?.title,
                url: content?.media?.url,
                alt: content?.media?.description
              }
            : null,
          contentLayoutType: extra?.settings?.contentLayoutType,
          cta: content?.callToActions?.[0]?.target
            ? {
                url: content?.callToActions?.[0]?.target || null,
                text: content?.callToActions?.[0]?.label || null
              }
            : null
        };
      })
    };
  }
}

export { BannerListSection };
