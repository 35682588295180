import React from 'react';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import Button from 'components/atoms/button';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism ButtonsSection
 *
 * <!-- TODO: add a description here! -->
 */
function ButtonsSection(props) {

  const {
    bgColor = null,
    spacingTop = 4,
    spacingBottom = 4,
    buttonsList = [],
    buttonColor,
    buttonSize,
    listAlignment = 'left',
    ...other
  } = props;

  return <>
    <Wrapper
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
      data-bg-color={bgColor}
      data-list-alignment={listAlignment}
      {...other}
    >
      <Section>
        <div className={css['button-list']}>
          {buttonsList.length > 0 &&
            buttonsList.map((button, index) => {
              return (
                <Button
                  key={index}
                  className={css['button-list__item']}
                  data-color={buttonColor}
                  data-size={buttonSize}
                  link={button.link}>
                  {button.linkLabel}
                </Button>
              );
            })
          }
        </div>
      </Section>
    </Wrapper>
  </>;
}


export default ButtonsSection;
