const findByPage = () => {
  return `
    query accessoryByPage($pageId: String!) {
      accessoryByPage(pageId: $pageId) {
        id
        name
        summary
        descriptions
        partNumber
        link
        type
        status
        modelYears(available: true) {
          id
          year
          katashiki
          suffixCode
          defaultInternalColorCode
          vehicleColors {
            price
            color{
              code
              name
            }
          }
          color {
            name
            code
          }
          version {
            id
            name
            vehicle {
              id
              name
            }
          }
        }
        price {
          endValidity
          startValidity
          value
          installment {
            quantity
            value
          }
        }
        category {
          name
        }
        media {
          url
          title
        }
        downloadMedias {
          url,
          title,
          description
        }
        versions(current: true) {
          name
          accessories{
            id
          }
          vehicle {
            id
          }
        }
      }
    }`;
};

const getVehicleInfoForAccessory = () => {
  return `
    query getVehicleInfoForAccessory($id: String!) {
      vehicle(id: $id) {
        name
        versions {
          name
        }
        mainVersion {
          name
          modelYear {
            medias {
              tag

              media {
                url
                title
              }
            }
          }
          accessories {
            id
            name
            summary
            descriptions
            partNumber
            link
            type
            status
            price {
              endValidity
              startValidity
              value
              installment {
                quantity
                value
              }
            }
            page {
              title
              fullpath
            },
            category {
              name
            }
            media {
              url
              title
            }
          }
        }
      }
    }`;
};

const getCategoriesAccessory = () => {
  return `
  query  accessoryCategories($locale: String!){
    accessoryCategories(locale:  $locale) {
      id
      name
    }
  }
`;
};

const Accessory = {
  findByPage,
  getVehicleInfoForAccessory,
  getCategoriesAccessory
};

export default Accessory;
