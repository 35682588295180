const findOneNews = () => {
  return `
  query ($newsId: String!) {
    findOneNews (newsId: $newsId){
      id
      title
      description
      abstract
      image {
        url
      }
      highlightMedia {
        url
        title
      }
      releaseDate
      newsCategories {
       name
      }
    }
  }`;
};

const newsByPage = () => {
  return `
  query ($pageId: String!){
    newsByPage (pageId: $pageId ){
      title
      abstract
      releaseDate
      newsCategories {
       name
      }
    }
  }`;
};

const getNewsTotal = async () => {
  return `
  query ($productId: String!) {
    findNews (productId: $productId){
      total
  }
  }`;
};

const findNews = async () => {
  return `
  query ($productId: String!, $take: Float, $skip: Float) {
    findNews (productId: $productId, take: $take, skip: $skip){
      news {
        image {
          url
        }
        highlightMedia {
          url
        }
        title
        releaseDate
        newsCategories {
          name
        }
        description
        page {
          fullpath
        }
      }
  }
  }`;
};

const findNewsCategory = async () => {
  return `
  query ($productId: String) {
    findNewsCategory (productId: $productId){
    name
    id
  }
  }`;
};

const searchNewsByFilters = () => {
  return `
  query (
    $productId: String!,
    $token: String,
    $newsCategoriesIds: [String!],
    $releaseDateOrder: String,
    $skip: Int,
    $take: Int
  ){
    searchNewsByFilters(
      productId : $productId,
      filter : {
      token : $token,
      newsCategoriesIds : $newsCategoriesIds
      },
      releaseDateOrder : $releaseDateOrder,
      pagination : {
        take : $take,
        skip: $skip
      }
    ) {
      news {
        image {
          url
        }
        highlightMedia {
          url
        }
        title
        releaseDate
        newsCategories {
          name
        }
        description
        page {
          fullpath
        }
      }
      total
    }
  }
  `;
};

const newsByIds = () => {
  return `
  query (
    $newsIds: [String!]!
  ){
    newsByIds(
      newsIds: $newsIds
    ) {
      id
      title
      description
      releaseDate
      abstract
      highlightMedia {
        url
      }
      image{
        url
      }
      newsCategories {
        name
      }
      page {
        id
        title
        slug
        fullpath
      }
    }
  }
  `;
};

const News = {
  findOneNews,
  newsByPage,
  getNewsTotal,
  findNews,
  findNewsCategory,
  searchNewsByFilters,
  newsByIds
};

export default News;
