/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';
import AccessoryForm from 'components/molecules/accessory-form';
import ContentDivider from 'components/atoms/content-divider';
import SectionHeader from 'components/molecules/section-header';
import ItemCompatibility from 'components/molecules/item-compatibility';
import AccessoryListCarousel from 'components/molecules/accessory-list-carousel';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';
import RichTextSection from 'components/organisms/rich-text-section';

// eslint-disable-next-line max-len

/**
 * Organism AccessorySingle
 *
 * Display data from single Accessory
 */
function AccessorySingle(props) {
  const t = i18n.useTranslations('components.accessory-single');

  const disclaimerDefault = t("disclaimer-text");

  const {
    className = "",
    children,
    accessoryData,
    accessoryList = [],
    dealers,
    listAvailable,
    disclaimer = disclaimerDefault,
    locale = "pt-BR",
    ...other
  } = props;

  const [otherAccessories, setOtherAccessories] = useState([]);

  function formatOfferDate(timeStamp) {

    if (timeStamp === null || !timeStamp) {return '---';}

    //If we already have a date, eg: "01/12/2022", just return it.
    if (timeStamp.length === 10) {return timeStamp;}

    let date = new Date(timeStamp);

    if (isNaN(date)) {return '---';}

    //We need to return the date WITHOUT any timezone
    return date.toLocaleDateString('pt-BR', { timeZone: 'GMT' });

  }

  const tasaLegalParagraph = locale === 'es-AR' ?
    ` \\* No especificado. Origen: No especificado. Oferta válida en los concesionario oficiales Toyota de la República Argentina desde el ${formatOfferDate(accessoryData?.price?.startValidity)} hasta el ${formatOfferDate(accessoryData?.price?.endValidity)}. Ninguno de los valores incluye mano de obra`
    :
    null
    ;


  useEffect(() => {
    const res = accessoryList?.filter((item) => item?.name !== accessoryData?.name);
    setOtherAccessories(res);
  }, []);

  function getDescriptionList(items) {
    let descriptionList = '';

    items.forEach(item => descriptionList += `* ${item} \n `);

    return descriptionList;
  }

  function reduceItemCompatibilityList(list) {
    const currentVersionName = accessoryData?.model?.versionName;
    const currentAccessoryId = accessoryData?.id;

    return list?.filter(
      (item, index) =>
        index < 4 &&
        item?.name !== currentVersionName &&
        item?.accessories?.some((accessory) => accessory?.id === currentAccessoryId)
    );
  }

  return <div
    className={`${css["organism__accessory-single-container"]} ${className}`}
    {...other}
  >
    <Wrapper bgColor="gray">
      <AccessoryForm locale={locale} accessory={accessoryData} dealers={dealers} />
    </Wrapper>

    {
      tasaLegalParagraph &&
      <Wrapper spacing="7" spacingBottom="0" bgColor="gray">
        <RichTextSection className={css['tasa-legal-text']} textContent={tasaLegalParagraph} />
      </Wrapper>
    }

    {accessoryData?.descriptions && accessoryData?.descriptions?.length > 0 &&
      <Wrapper bgColor="gray" spacingTop="6" spacingBottom="0">
        <Section tag="div">
          <SectionHeader title={t("post-content-title")} level={2} variant={2} />
        </Section>
        <RichTextSection textContent={getDescriptionList(accessoryData?.descriptions)} />
      </Wrapper>
    }

    <Wrapper bgColor="gray" spacing="4">
      <Section>
        <SectionHeader title={t("item-compatibility-title")} level={2} variant={2} />
        <ItemCompatibility listAvailable={reduceItemCompatibilityList(accessoryData?.versions)} acessory={accessoryData} />
      </Section>
    </Wrapper>

    <Wrapper bgColor="gray" spacing="7">
      <Section>
        <SectionHeader title={t("accessory-list-carousel-title")} level={2} variant={2} />
        <AccessoryListCarousel locale={locale} data={otherAccessories} />
      </Section>
    </Wrapper>

    <ContentDivider size="tiny" fullWidth={false} bgColor="gray" />

    <RichTextSection bgColor="gray" spacingTop="4" spacingBottom="4" textContent={disclaimer} />

    {children}
  </div>;
}

export default AccessorySingle;
