import React from 'react';
// import i18n from 'utils/i18n';

import ToyotaCicleCars from 'components/organisms/toyota-cicle-cars';

/**
 * Organism VehicleVerticalTemplateBase
 *
 * The base components of the vertical vehicle template
 */
function VehicleVerticalTemplateBase(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.vehicle-vertical-template-base');

  const { carVersionsData, vehicleMedia, locale, onlyDisplayBasePrice = true, templateData } = props;

  return (
    <div>
      <ToyotaCicleCars
        onlyDisplayBasePrice={onlyDisplayBasePrice}
        carVersionsData={carVersionsData}
        vehicleMedia={vehicleMedia}
        locale={locale}
        variant={'vertical'}
        showCardList={false}
        showSpecsSection={true}
        showToyotaCicleBanner={false}
        templateData={templateData}
      />
    </div>
  );
}

export default VehicleVerticalTemplateBase;
