import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class Notification implements ContentBlock {
  label = 'Notification';

  component = 'Notification';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'titleNotification',
      label: 'Notification title',
      help: 'The title of the notification',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentNotification',
      label: 'Notification Content Text',
      help: 'This is the content that will be displayed in the notification.',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'typeNotification',
      label: 'Notification Type',
      help: 'Select the type of notification you want to display.',
      type: 'String',
      control: 'select',
      defaultValue: 'default',
      options: [
        'default',
        'error',
        'success'
      ]
    },
    {
      name: 'enableAutoClose',
      label: 'Enable Auto Close',
      help: 'Enable auto closing the notification after a certain time.',
      type: 'Boolean',
      control: 'input:checkbox',
      defaultValue: false
    },
    {
      name: 'autoCloseTime',
      label: 'Auto Close Time (ms)',
      help: 'Time in milliseconds after which the notification will be automatically closed.',
      type: 'Number',
      control: 'input:number',
      defaultValue: 10000
    }
  ];

  properties = [];

  async adapter(extra: any): Promise<object> {
    return {
      spacingBottom: extra?.settings?.spacingBottom,
      spacingTop: extra?.settings?.spacingTop,
      titleNotification: extra?.settings?.titleNotification,
      contentNotification: extra?.settings?.contentNotification,
      typeNotification: extra?.settings?.typeNotification,
      enableAutoClose: extra?.settings?.enableAutoClose,
      autoCloseTime: extra?.settings?.autoCloseTime
    };
  }
}

export { Notification };
