import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import Dealers from 'utils/repo/dealers';
import { anchor } from 'utils/meta';

class SaleUsedForm implements ContentBlock {
  label = 'Sale Used Form';

  component = 'SaleUsedForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Title form',
      help: 'The title of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'description',
      label: 'Description',
      help: 'Is the description of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'attractingMedia',
      label: 'Attracting Media',
      help: 'Indentify the form',
      type: 'String',
      control: 'input:text',
      defaultValue: 'SITE MONTADORA'
    },
    {
      name: 'titleSuccess',
      label: 'Title success',
      help: 'The title used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentSuccess',
      label: 'Content success',
      help: 'Descriptive text used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleError',
      label: 'Title error',
      help: 'The title used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentError',
      label: 'Content error',
      help: 'Descriptive text used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'headerSpacing',
      label: 'Header spacing',
      help: 'Is the spacing between the header with title and description of the form',
      type: 'Number',
      control: 'input:number',
      defaultValue: '0'
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;

    if (!extra?.context?.productId) {
      throw new Error(`There is no productId defined.`);
    }

    const dealersByLocation =
      (await dataFetcher(await Dealers?.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    return {
      locale: String(locale).toLowerCase(),
      dealers: dealersByLocation,
      title: extra?.settings?.title,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      headerSpacing: extra?.settings?.headerSpacing || '0',
      attractingMedia: extra?.settings?.attractingMedia || ''
    };
  }
}

export { SaleUsedForm };
