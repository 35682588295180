import React, {useState} from 'react';

import Modal from 'components/molecules/modal';
import AnchorLink from 'components/atoms/anchor-link';

import { currency } from 'utils/functions';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ModelLegalText
 *
 * <!-- TODO: add a description here! -->
 */
function ModelLegalText (props) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }
  const {
    vehicleName = null,
    modelName = null,
    colorName = null,
    installmentsValue = null,
    btbData = null,

  } = props;

  const installmentsNumber = btbData?.installments || '';
  const downPayment = currency(btbData?.downPayment) || null;
  const totalValue = currency(btbData?.total) || null;
  const residual = currency(btbData?.residual) || null;
  const downPerc = btbData?.downPerc || '';
  const iof = currency(btbData?.installment?.taxes?.iof) || null;
  const serviceBasket = currency(btbData?.serviceBasket) || null;
  const registrationFee = currency(btbData?.registrationFee) || null;
  const monthTax = btbData?.installment?.taxes?.month || '';
  const anualTax = btbData?.installment?.taxes?.anualTax || '';
  const anualCET = btbData?.installment?.taxes?.anualCet || '';
  const totalFinanced = currency(btbData?.totalFinanced) || null;
  const totalWithTaxes =  currency(btbData?.totalWithTaxes) || null;

  return <>
    <AnchorLink
      size="small"
      linkLabel="Confira o texto legal"
      onClick={(_) => toggleModal()}
    />

    {isModalOpen &&
        <Modal
          modalClassName={css['modal']}
          title="Informações Legais:"
          textContent={`${vehicleName} ${modelName} 0 Km de cor ${colorName} à vista ${totalValue}
          ou financiado com o Banco Toyota no programa Ciclo Toyota nas seguintes condições:
          CDC (Crédito Direto ao Consumidor), pessoa física, com entrada de ${downPayment} (${downPerc}%) e ${installmentsNumber} prestações fixas de ${installmentsValue},
          mais 1 prestação residual no valor de ${residual}, com vencimento na mesma data da última prestação fixa do financiamento.
          Primeira prestação fixa com vencimento para 30 dias do fechamento do financiamento.
          Taxa de juros pré-fixada de ${monthTax}% ao mês,
          equivalente a ${anualTax}% ao ano + IOF (Imposto sobre Operações Financeiras) no valor de ${iof}
          + Registro de contrato base Brasília no valor de ${registrationFee}
          + Cesta de Serviços sugerida base Brasília de no valor de ${serviceBasket}
          + Tarifa de Confecção de Cadastro para início de Relacionamento no valor de R$ 550,00 inclusos no Total Financiado correspondente a ${totalFinanced}
          Valor Total à prazo de ${totalWithTaxes}.
          Custo Efetivo Total (CET) de ${anualCET}% ao ano.
          O atraso no pagamento acarretará na incidência de multa e juros de mora na forma das cláusulas de financiamento conforme disponível no site www.bancotoyota.com.br.
          Liquidação antecipada não onerosa aplicável. Crédito sujeito à análise e aprovação.
          O CET apresentado é aplicável ao exemplo sugerido. Para os demais Estados consulte valores de Registro de Contrato e Cesta de Serviços e efetue nova simulação na concessionária Toyota. Campanha vinculada à validade do Programa Ciclo Toyota. Condições sujeitas à alteração sem prévio aviso. A alteração do modelo do veículo ou de qualquer das condições do financiamento acarretará novo cálculo do CET. SAC Banco Toyota 0800 016 4155 ou envie um e-mail para sac@bancotoyota.com.br. Ouvidoria Banco Toyota (somente após atendimento pelo SAC): 0800-772-5877. Pelo programa Ciclo Toyota, ao término do financiamento, a Toyota poderá realizar a recompra do veículo Toyota por 80% do valor da tabela FIPE: o valor poderá quitar a parcela residual e poderá ser utilizado como entrada para um Toyota 0Km.`}
          onClose={toggleModal}
        />
    }

  </>;
}

export default ModelLegalText;
