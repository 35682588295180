import ItemAvailability from 'components/atoms/item-availability';
import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ItemAvailabilityList
 *
 * Availability list of items that check the attributes present in the context
 */
function ItemAvailabilityList(props) {
  const { className = '', listAvailable = [], children, ...other } = props;

  return (
    <>
      <ul
        className={`${css['molecule__item-availability-list-container']} ${className}`}
        {...other}>
        {listAvailable.length > 0 &&
          listAvailable?.map((item, index) => {
            return (
              <li key={item.name}>
                <ItemAvailability title={item.name} />
              </li>
            );
          })}
      </ul>
    </>
  );
}

export default ItemAvailabilityList;
