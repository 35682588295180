import React from 'react';
//import i18n from 'utils/i18n';
import ActionBannerSearchForm from '../action-banner-search-form';
import { IconClose } from 'components/icons';
import Section from 'components/utils/section';
import Title from 'components/atoms/title';

// loading the sass style fot the component
import css from './styles.module.scss';


/**
 * Molecule ActionSearchFormHeader
 *
 * <!-- TODO: add a description here! -->
 */
function ActionSearchFormHeader(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.action-search-form-header');

  const {
    className = '',
    headerTitle,
    sizeTitle,
    headerContent,
    labelSearch,
    placeholderSearch,
    clearFilter = true,
    direction = 'column',
    onSubmit,
    onChange,
    ...other
  } = props;

  return (
    <Section
      className={`${css['molecule__action-search-form-header-container']} ${className}`}
      data-flex-direction={direction}
      hasGrid={true}
      {...other}>
      <div className={css['container-header']}>
        <Title variant={sizeTitle} className={css['container-header__title']} data-font-regular>
          {headerTitle}
        </Title>
        <p className={css['container-header__content']}>{headerContent}</p>
      </div>

      <form onSubmit={onSubmit} className={css['container-form']}>
        <ActionBannerSearchForm
          displayLabel={false}
          label={labelSearch}
          id="acessory-search"
          placeholder={placeholderSearch}
          className={css['input-search']}
          onChange={onChange}
        />

        {clearFilter && (
          <button
            className={css['button-clear-filter']}
            onClick={(_) => console.log('clean the search filter')}>
            Limpar filtro
            <IconClose className={css['icon-close']} />
          </button>
        )}
      </form>
    </Section>
  );
}

export default ActionSearchFormHeader;
