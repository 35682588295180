import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import InfoDealer from 'components/molecules/info-dealer';
import Slider from 'react-slick';
import getConfig from 'next/config';
import { useMediaQuery } from 'react-responsive';

import 'slick-carousel/slick/slick.css';
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

function MapInformation(props) {
  const google = typeof window === 'object' ? window.google : null;

  const {
    className = '',
    selectedCity,
    selectedState,
    selectedMapCenter,
    selectedCep,
    goToCurrentPosition,
    enableScheduleService,
    markers,
    locale,
    handleFavoriteChange,
    selectedZoom = 8,
    centerOffset = 0,
    onConfirmAppointment = null,
    selectedDealerId = null,
    selectedDealerIdContext = null,
    serviceAppointmentContentData = null,
    disabledDealers = [],
    typeInfo,
    ...other
  } = props;

  const sliderRef = useRef(null);
  const infoDealerRef = useRef(null);
  const containerEl = React.useRef(null);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const isAppointment = typeInfo === 'appointment';
  const zoomForSelectedMark = 15;
  const zoomForCurrentLocation = 12;
  const zoomDefault = 4;
  const insignificantNumForDiff = 0.00001;
  const googleKey = publicRuntimeConfig?.G_MAPS_KEY;

  const country = {
    'pt-br': { lat: -15.7801, lng: -47.9292 },
    'es-ar': { lat: -34.60548110292948, lng: -58.39672471921689 },
    'es-ve': { lat: 10.4683918, lng: -66.8903658 }
  };

  const countryCenter = country[locale?.toLowerCase()];

  const sliderSettings = {
    speed: 500,
    useTransform: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    padding: 0,
    adaptiveHeight: true
  };

  const [mapZoom, setMapZoom] = useState(1);
  const [isInfoWindowOptionsActive, setIsInfoWindowOptionsActive] = useState(true);
  const [isShowWindowAndCard, setIsShowWindowAndCard] = useState(false);
  const [allMarkersLoaded, setAllMarkersLoaded] = useState(false);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState(null);
  const [mapObj, setMapObj] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [mapCenter, setMapCenter] = useState(countryCenter);

  if (!googleKey) {
    console.log('Google token is not set');
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleKey
  });

  useEffect(() => {
    if (isLoaded && markers?.length > 0) {
      setFitBounds();
      setAllMarkersLoaded(true);
    }
  }, []);

  useLayoutEffect(() => {
    const infoDealerElement = infoDealerRef.current;

    if (infoDealerElement) {
      const infoDealerHeight = infoDealerElement.offsetHeight;
      const arrowHeight = 20;
      const arrowPosition = (infoDealerHeight - arrowHeight) / 2;
      const prevArrow = document.querySelector('.slick-arrow');
      const nextArrow = document.querySelector('.slick-next');

      if (prevArrow) {
        prevArrow.style.top = `${arrowPosition}px`;
        prevArrow.style.left = isAppointment && !isMobile ? '32px' : '16px';
      }

      if (nextArrow) {
        nextArrow.style.top = `${arrowPosition}px`;
        nextArrow.style.right = isAppointment && !isMobile ? '32px' : '16px';
      }
    }
  }, [infoDealerRef.current]);

  useEffect(() => {
    if (selectedDealerIdContext && markers?.length > 0) {
      const index = markers.findIndex(({ id }) => id === selectedDealerIdContext);
      if (index !== -1) {
        setActiveMarkerIndex(index);
        adjustMapView(markers[index]);
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(index);
        }
      }
    }
  }, [selectedDealerIdContext, markers]);

  useEffect(() => setFitBounds(), [markers]);

  useEffect(() => {
    if (selectedMapCenter) {
      const index = markers?.findIndex(
        ({ position }) => position.lat === selectedMapCenter.lat && position.lng === selectedMapCenter.lng
      );

      if (index != -1) {
        setActiveMarkerIndex(index);
        adjustMapView(markers[index]);
        sliderRef?.current?.slickGoTo(index);
      }
    }
  }, [selectedMapCenter]);

  useEffect(() => {
    if (selectedZoom) {
      updateMapZoom(selectedZoom);
    }
  }, [selectedZoom]);

  useEffect(() => {
    if (selectedState !== 'placeholder' && selectedState !== null && selectedState !== undefined) {
      setIsShowWindowAndCard(true);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedState || selectedCity) {
      setActiveMarkerIndex(0);
    }
  }, [selectedCity, selectedState]);

  useEffect(() => {
    if (goToCurrentPosition) {
      setIsInfoWindowOptionsActive(false);
      setActiveMarkerIndex(null);

      navigator?.geolocation?.getCurrentPosition((position) => {
        updateMapZoom(zoomForCurrentLocation);
        updateMapCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      });
    }
  }, [goToCurrentPosition]);

  useEffect(() => {
    if (isLoaded && markers?.length > 0) {
      setFitBounds();
      setAllMarkersLoaded(true);
    }
  }, []);

  const setFitBounds = () => {
    if (allMarkersLoaded && markers?.length && google?.maps && mapObj) {
      const newBounds = new google.maps.LatLngBounds();
      markers?.forEach((mark) => newBounds.extend(new google.maps.LatLng(mark.position.lat, mark.position.lng)));

      if (JSON.stringify(newBounds) !== JSON.stringify(bounds)) {
        setBounds(newBounds);
        mapObj.fitBounds(newBounds);
        updateMapZoom(mapObj.zoom > zoomForSelectedMark ? zoomForSelectedMark : mapObj.zoom);
      }
    } else {
      updateMapZoom(zoomDefault);
    }
  };

  const handleActiveMarker = (index) => {
    setIsInfoWindowOptionsActive(false);
    setIsShowWindowAndCard(true);
    setActiveMarkerIndex(index);
    sliderRef.current.slickGoTo(index);
  };

  const onClearActiveMarker = () => {
    setActiveMarkerIndex(null);
  };

  const onInfoWindowClose = () => {
    if (activeMarkerIndex !== null) {
      adjustMapView(markers[activeMarkerIndex]);
    }
  };

  const handleOnLoad = (map) => {
    setMapObj(map);
    setFitBounds();
  };

  const beforeChange = (oldIndex, newIndex) => {
    setIsInfoWindowOptionsActive(false);
    setActiveMarkerIndex(newIndex);
    adjustMapView(markers[newIndex]);
  };

  const adjustMapView = (selectedMark) => {
    if (selectedMark?.position) {
      updateMapZoom(zoomForSelectedMark);
      updateMapCenter({ ...selectedMark.position });
    }
  };

  const updateMapZoom = (newMapZoom) => {
    if (mapZoom === newMapZoom) {
      newMapZoom += insignificantNumForDiff;
    }

    setMapZoom(newMapZoom);
  };

  const updateMapCenter = (newCenterPosition) => {
    newCenterPosition.lng += isTabletOrMobile ? 0 : centerOffset;

    if (mapCenter.lng === newCenterPosition.lng) {
      newCenterPosition.lng += insignificantNumForDiff;
    }

    setMapCenter(newCenterPosition);
  };

  return (
    <div className={`${css['molecule__map-information-container']} ${className}`} {...other}>
      <div className={css['container-google']} ref={containerEl}>
        {isLoaded && (
          <GoogleMap
            center={mapCenter}
            zoom={mapZoom}
            onLoad={handleOnLoad}
            onClick={onClearActiveMarker}
            options={{
              gestureHandling: 'cooperative'
            }}
            mapContainerStyle={{ width: '100%', height: '100%' }}
          >
            {markers?.length > 0 &&
              markers?.map((marker, index) => {
                return (
                  <Marker key={marker.id + index} position={marker.position} onClick={() => handleActiveMarker(index)}>
                    {activeMarkerIndex === index && isShowWindowAndCard && (
                      <InfoWindow
                        options={
                          isInfoWindowOptionsActive &&
                          selectedCity && { pixelOffset: new window.google.maps.Size(0, -40) }
                        }
                        position={marker.position}
                        onCloseClick={() => {
                          onClearActiveMarker();
                          onInfoWindowClose();
                        }}
                      >
                        <div>{marker.name}</div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })}
          </GoogleMap>
        )}
      </div>

      {isShowWindowAndCard && (selectedCity || activeMarkerIndex !== null) && (
        <div className={css['carousel-info']} data-type-info={typeInfo}>
          <Slider
            ref={sliderRef}
            settings={sliderSettings}
            beforeChange={beforeChange}
            className={`${css['action-banner-wrapper']} ${className}`}
            {...other}
          >
            {markers?.map((mark, key) => {
              const isDisabled = disabledDealers.includes(mark.id);
              return (
                <div id={mark.id} key={key} className={css['info-slide-container']} ref={infoDealerRef}>
                  <InfoDealer
                    className={css['info']}
                    dealer={mark}
                    listServices={mark?.services}
                    locale={locale}
                    enableScheduleService={enableScheduleService}
                    handleInfoDealer={() => adjustMapView(mark)}
                    handleFavoriteChange={handleFavoriteChange}
                    onConfirmAppointment={onConfirmAppointment}
                    selectedDealerId={selectedDealerId}
                    serviceAppointmentContentData={serviceAppointmentContentData}
                    typeInfo={typeInfo}
                    isDisabledDealer={isDisabled}
                  />
                </div>
              );
            })}
          </Slider>
        </div>
      )}
    </div>
  );
}

export default MapInformation;
