import React, { useEffect, useState } from 'react';

// loading the sass style fot the component
import Modal from 'components/molecules/modal';
import FormSelect from 'components/atoms/form-select';
import Button from 'components/atoms/button';

import i18n from 'utils/i18n';
import { useForm } from 'react-hook-form';

import { getStatesFromDealers, getDealersFromState, getCitiesThatHaveDealersFromState } from 'utils/forms';

import css from './styles.module.scss';

/**
 * Molecule RegionFinder
 *
 * Find user's region by geolocation or by user input
 */
function RegionFinder(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.region-finder');

  const {
    className = "",
    onSelectRegion,
    dialogTitle = null,
    dealers = null,
    dialogText = null,
    ...other
  } = props;

  const { register, handleSubmit, setValue } = useForm({
    city: "placeholder",
    state: "placeholder"
  });

  const states = getStatesFromDealers(dealers);

  const [region, setRegion] = useState(null);

  const [selectedState, setSelectedState] = useState(null);

  const [cities, setCities] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    if (!region) {return false;}

    toggleRegionModal();

    //Changing the region pass the region data to the offers panel
    onSelectRegion && onSelectRegion({ target: region });

  }, [region]);

  useEffect(() => {

    if (!selectedState) {return null;}

    const dealersFromState = getDealersFromState(dealers, selectedState);

    const citiesThatHaveDealers = getCitiesThatHaveDealersFromState(dealersFromState);

    setCities(citiesThatHaveDealers);
    setValue('city', "placeholder");

  }, [selectedState]);

  function onStateChanged(event) {

    setSelectedState(event.target.value);

  }

  function onCityChanged(event) {

    setSelectedCity(event.target.value);

  }

  function toggleRegionModal() {

    setIsModalOpen(!isModalOpen);

  }

  function onSubmit() {

    setRegion({
      state: selectedState,
      city: selectedCity
    });

  }

  return <div
    className={`${css["molecule__region-finder-container"]} ${className}`}
    {...other}
  >
    {
      isModalOpen && states &&
      <Modal
        showCloseButton={false}
        modalClassName={css['offers-region-modal']}
        title={dialogTitle || t('modal-title')}
      >
        <p className={css['offers-region-modal__description']}>
          {dialogText || t('modal-text')}
        </p>
        <form
          className={css['offers-region-form']}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={css['offers-region-form__selects-container']}>
            <FormSelect
              className={css['offers-region-form__select']}
              displayLabel={true}
              displayError={false}
              id='state'
              label={t('modal-state')}
              placeholder={t('modal-select')}
              onChange={onStateChanged}
              dataRegister={register('state')}
            >
              {states.map((state, index) => {
                return (
                  <option
                    value={state}
                    key={index}
                  >
                    {state}
                  </option>
                );
              })}
            </FormSelect>
            <FormSelect
              className={css['offers-region-form__select']}
              displayLabel={true}
              displayError={false}
              id='city'
              label={t('modal-city')}
              placeholder={t('modal-select')}
              onChange={onCityChanged}
              dataRegister={register('city')}
            >
              {cities?.map((city, index) => {
                return (
                  <option
                    value={city}
                    key={index}
                  >
                    {city}
                  </option>
                );
              })}
            </FormSelect>
          </div>
          <div className={css['offers-region-form__action-container']}>
            <Button disabled={!selectedCity}>
              Alterar localização
            </Button>
          </div>
        </form>

      </Modal>
    }
  </div>;
}

export default RegionFinder;
