import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import { IconCheck, IconError, IconClose } from 'components/icons';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';

import css from './styles.module.scss';

function Notification(props) {
  const t = i18n.useTranslations('components.notification');
  const {
    children,
    className = '',
    contentNotification,
    spacing,
    titleNotification,
    typeNotification,
    handleClose,
    isOpen: parentIsOpen = true,
    setIsOpen: parentSetIsOpen,
    enableAutoClose = false,
    autoCloseTime = 10000,
    fullScreen = false,
    ...other
  } = props;

  const [isOpen, setIsOpen] = useState(parentIsOpen);
  const [remainingTime, setRemainingTime] = useState(autoCloseTime);

  const updateIsOpen = (newIsOpen) => {
    setIsOpen(newIsOpen);
    if (parentSetIsOpen) {
      parentSetIsOpen(newIsOpen);
    }
  };

  useEffect(() => {
    let timer;

    if (enableAutoClose && isOpen) {
      setRemainingTime(autoCloseTime);

      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 100) {
            clearInterval(timer);
            updateIsOpen(false);
            return 0;
          }
          return prevTime - autoCloseTime * 0.2;
        });
      }, autoCloseTime * 0.2);
    }

    return () => {
      clearInterval(timer);
    };
  }, [
    enableAutoClose,
    isOpen,
    autoCloseTime
  ]);

  const iconMappings = {
    error: {
      icon: <IconError />,
      title: t('title_icon_error')
    },
    success: {
      icon: <IconCheck />,
      title: t('title_icon_success')
    }
  };

  const iconNotification = iconMappings[typeNotification] || null;

  return (
    <Wrapper spacing={!isOpen ? 0 : spacing} {...other}>
      <Section fullScreen={fullScreen}>
        <div
          className={`${css['molecule__notification-container']} ${className}`}
          data-type={typeNotification}
          data-open={isOpen}
          data-fade-out={!isOpen}
        >
          {enableAutoClose && isOpen && (
            <div className={css['auto-close-timer']}>
              <div
                className={css['auto-close-timer-bar']}
                style={{ width: `${(remainingTime / autoCloseTime) * 100}%` }}
              />
            </div>
          )}
          <button
            title={t('title_icon_close')}
            className={css['closebtn']}
            onClick={handleClose ? handleClose : () => updateIsOpen(false)}
          >
            <IconClose />
          </button>

          <div className={css['container-notification-header']}>
            <span title={iconNotification?.title}>{iconNotification?.icon}</span>
            <span className={css['container-notification-header-title']}>{titleNotification}</span>
          </div>

          <span className={css['container-notification-content']}>{contentNotification}</span>
        </div>

        {children}
      </Section>
    </Wrapper>
  );
}

export default Notification;
