import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class Disclaimer implements ContentBlock {
  label = 'Disclaimer';

  component = 'Disclaimer';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'bgColor',
      label: 'Wrapper backgroud color',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'black'
      ]
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'The title of the content',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Content',
      help: 'The content of the item',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Image',
      help: '',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];

    return {
      editorContent: content?.description,
      photoList: [
        {
          type: content?.media?.mediaType?.title,
          url: content?.media?.url,
          alt: content?.media?.description
        }
      ]
    };
  }
}

export { Disclaimer };
