export const anchor = {
  name: 'anchor',
  label: 'Navigation Anchor',
  help: 'Enter the navigation for anchor.',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const spacingTopMeta = {
  name: 'spacingTop',
  label: 'The padding space at the top of the section',
  help: '',
  type: 'String',
  control: 'select',
  options: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ]
};

export const spacingBottomMeta = {
  name: 'spacingBottom',
  label: 'The padding space at the bottom of the section ',
  help: '',
  type: 'String',
  control: 'select',
  options: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ]
};

export const spacingTopBottom = {
  name: 'spacingTopBottom',
  label: 'The padding space at the top and bottom of the section ',
  help: '',
  type: 'String',
  control: 'select',
  options: [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7'
  ]
};

export const bgColorMeta = {
  name: 'bgColor',
  label: 'Wrapper backgroud color',
  help: '',
  type: 'String',
  control: 'select',
  options: [
    'default',
    'white',
    'black',
    'gray',
    'gray-2',
    'gray-3',
    'gray-4',
    'gray-5',
    'gray-6',
    'gray-7',
    'blue-1',
    'blue-2'
  ]
};

export const buttonColorMeta = {
  name: 'buttonColor',
  label: 'Select header button color',
  help: 'Set the header button color',
  type: 'string',
  control: 'input:text',
  defaultValue: '',
  props: null,
  options: [
    {
      key: 'light',
      value: 'light',
      text: 'White'
    },
    {
      key: 'light',
      value: 'dark',
      text: 'Dark'
    },
    {
      key: 'ghost',
      value: 'ghost',
      text: 'Transparent with white font'
    },
    {
      key: 'ghost-2',
      value: 'ghost-2',
      text: 'Transparent with black font'
    },
    {
      key: 'ghost-3',
      value: 'ghost-3',
      text: 'Grey with white font'
    },
    {
      key: 'ghost-4',
      value: 'ghost-4',
      text: 'Light blue with white font'
    },
    {
      key: 'ghost-5',
      value: 'ghost-5',
      text: 'Dark blue with white font'
    }
  ]
};

export const centerContentMeta = {
  name: 'centerContent',
  label: 'Center the content of the section?',
  help: 'Center all elements inside the section',
  type: 'boolean',
  control: 'toggle',
  defaultValue: false
};

/**
 * FORM
 */
export const formTitle = {
  name: 'title',
  label: 'Title form',
  help: 'The title of the form',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formDescription = {
  name: 'description',
  label: 'Description',
  help: 'Is the description of the form',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formAttractingMedia = {
  name: 'attractingMedia',
  label: 'Attracting Media',
  help: 'Indentify the form',
  type: 'String',
  control: 'input:text',
  defaultValue: 'SITE MONTADORA'
};

export const formTitleSuccess = {
  name: 'titleSuccess',
  label: 'Title success',
  help: 'The title used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formContentSuccess = {
  name: 'contentSuccess',
  label: 'Content success',
  help: 'Descriptive text used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formLinkSuccess = {
  name: 'linkSuccess',
  label: 'Link success',
  help: 'The link used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formLinkTitleSuccess = {
  name: 'linkTitleSuccess',
  label: 'Link title success',
  help: 'The title of the link used in the sent form success response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formTitleError = {
  name: 'titleError',
  label: 'Title error',
  help: 'The title used in the sent form error response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formContentError = {
  name: 'contentError',
  label: 'Content error',
  help: 'Descriptive text used in the sent form error response modal',
  type: 'String',
  control: 'input:text',
  defaultValue: ''
};

export const formHeaderSpacing = {
  name: 'headerSpacing',
  label: 'Header spacing',
  help: 'Is the spacing between the header with title and description of the form',
  type: 'Number',
  control: 'input:number',
  defaultValue: '0'
};

export const kindOfContent = {
  name: 'kindOfContent',
  label: 'Kind of Content',
  help: 'A field to identify the section to which each return belongs',
  type: 'text',
  control: 'input:text',
  defaultValue: ''
};

export const autoPlay = {
  name: 'autoPlay',
  label: 'Video autoplay (Use together with the "Video muted" option to work)',
  help: 'This option enables video to be played automatically when the page is loaded.',
  type: 'boolean',
  control: 'toggle',
  defaultValue: true
};

export const muted = {
  name: 'muted',
  label: 'Video muted',
  help: 'This option makes the video have no sound.',
  type: 'boolean',
  control: 'toggle',
  defaultValue: true
};

export const loop = {
  name: 'loop',
  label: 'Video loop',
  help: 'This option makes the video always play.',
  type: 'boolean',
  control: 'toggle',
  defaultValue: false
};

export const controls = {
  name: 'controls',
  label: 'User video controls',
  help: 'This option displays the video controls.',
  type: 'boolean',
  control: 'toggle',
  defaultValue: false
};
