import React from 'react';
import i18n from 'utils/i18n';

import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

// loading the sass style fot the component
import css from './styles.module.scss';
import SectionHeader from 'components/molecules/section-header';
import Media from 'components/molecules/media';
import CardsList from 'components/molecules/cards-list';

/**
 * Organism AboutToyotaCycle
 *
 * <!-- TODO: add a description here! -->
 */
function AboutToyotaCycle(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.about-toyota-cycle');

  const { className = '', children, offerType = 'CICLE_TOYOTA', ...other } = props;

  const cicleCardsData = [
    {
      media: {
        url: '/images/toyota-cicle/cicle-cards-1.png',
        alt: '',
        type: 'img'
      },
      title: t(`card-1-title--${offerType}`),
      content: t(`card-1-description--${offerType}`)
    },
    {
      media: {
        url: '/images/toyota-cicle/cicle-cards-2.png',
        alt: '',
        type: 'img'
      },
      title: t(`card-2-title--${offerType}`),
      content: t(`card-2-description--${offerType}`)
    },
    {
      media: {
        url: '/images/toyota-cicle/cicle-cards-3.png',
        alt: '',
        type: 'img'
      },
      title: t(`card-3-title--${offerType}`),
      content: t(`card-3-description--${offerType}`)
    }
  ];

  return (
    <Wrapper
      className={`${css['organism__about-toyota-cycle-container']} ${className}`}
      {...other}
      spacingTop={6}
      spacingBottom={6}>
      <Section>
        <SectionHeader
          title={t(`header-title--${offerType}`)}
          image={{
            src: `/images/logos/logo-${offerType}.svg`,
            alt: offerType
          }}
          description={{
            value: t(`header-description--${offerType}`)
          }}
        />
        <Media
          type="embed"
          url={t(`header-video--${offerType}`)}
          className={css['video-container']}
        />
        <CardsList cardsData={cicleCardsData} />
      </Section>
    </Wrapper>
  );
}

export default AboutToyotaCycle;
