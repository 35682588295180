import React from 'react';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Tooltip
 *
 * Show more information when interacting
 */
function Tooltip(props) {
  const {
    className = '',
    size,
    textSize,
    children,
    type = 'text',
    label = '',
    isvisible = undefined,
    ...other
  } = props;

  React.useEffect(() => {
    const elements = document.querySelectorAll('#tooltip');

    const handlePosition = (element) => {
      const rect = element.getBoundingClientRect();

      const top = rect.top;
      const left = rect.left;
      const bottom = window.innerHeight - rect.bottom;
      const right = window.innerWidth - rect.right;

      if (right >= left && bottom >= top) {
        element.childNodes[1].style.cssText +=
          'left: 0; top: 100%; margin-top: 7px; width: ' +
          right +
          'px; right: auto; bottom: auto; margin-bottom: auto;';
        element.childNodes[2].style.cssText +=
          'left: 50%; top: 100%; transform: translateX(-50%); right: auto; bottom: auto;';
      } else if (right < left && bottom > top) {
        element.childNodes[1].style.cssText +=
          'right: 0; top: 100%; margin-top: 7px; width: ' + left + 'px; left: auto; bottom: auto; margin-bottom: auto;';
        element.childNodes[2].style.cssText +=
          'right: 50%; top: 100%; transform: translateX(50%); left: auto; bottom: auto';
      } else if (right > left && bottom < top) {
        element.childNodes[1].style.cssText +=
          'left: 0; bottom: 100%; margin-bottom: 7px; width: ' + right + 'px; right: auto; top: auto; margin-top: auto';
        element.childNodes[2].style.cssText +=
          'left: 50%; bottom: 100%; transform: translateX(-50%) rotate(180deg); right: auto; top: auto;';
      } else if (right <= left && bottom <= top) {
        element.childNodes[1].style.cssText +=
          'right: 0; bottom: 100%; margin-bottom: 7px; width: ' + left + 'px; left: auto; top: auto; margin-top: auto;';
        element.childNodes[2].style.cssText +=
          'right: 50%; bottom: 100%; transform: translateX(50%) rotate(180deg); left: auto; top: auto;';
      }
    };

    elements.forEach((element) => {
      element.addEventListener('mouseover', () => {
        handlePosition(element);
      });
      element.addEventListener('focus', () => {
        handlePosition(element);
      });
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener('mouseover', () => {
          handlePosition(element);
        });
        element.removeEventListener('focus', () => {
          handlePosition(element);
        });
      });
    };
  }, []);

  return (
    <div
      id="tooltip"
      className={`${css['atom__tooltip-container']} ${className}`}
      data-size={size}
      data-is-type-img={type === 'img'}
      {...other}
    >
      {type === 'img' ? (
        <span className={css['label']}>{label}</span>
      ) : (
        <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" data-icon="1" className={css['icon']} {...props}>
          <path d="M7 6.251h1.5v4.995H9v1H7.5V7.251H7v-1ZM8.624 4.878a.624.624 0 1 1-1.248 0 .624.624 0 0 1 1.248 0Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.007 8a7.993 7.993 0 1 1 15.986 0A7.993 7.993 0 0 1 .007 8ZM8 1.007a6.993 6.993 0 1 0 0 13.986A6.993 6.993 0 0 0 8 1.007Z"
          />
        </svg>
      )}
      {type === 'img' ? (
        <div className={css['children-container']} data-is-type-img={type === 'img'}>
          {children}
        </div>
      ) : (
        <span
          className={css['children-container']}
          data-tooltip-size={textSize}
          data-visible={isvisible ? String(isvisible) : undefined}
        >
          {children}
        </span>
      )}
      <span className={css['text-detail']} />
    </div>
  );
}

export default Tooltip;
