import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class RecallBannerForm implements ContentBlock {
  label = 'Recall Banner Search Form';

  component = 'RecallBannerForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    return {};
  }
}

export { RecallBannerForm };
