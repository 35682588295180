import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor, bgColorMeta, spacingTopMeta, spacingBottomMeta } from 'utils/meta';

class ContentMediaSection implements ContentBlock {
  label = 'Content Media Section';

  component = 'ContentMediaSection';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = true;

  settings = [
    anchor,
    bgColorMeta,
    spacingTopMeta,
    spacingBottomMeta
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'Set the section title',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'media',
      label: 'Media related to content',
      help: 'Set the media related to content',
      type: 'media',
      control: 'upload',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    const { title } = contents[0];
    const mediaList = contents.map(({ media }) => media);
    const featuredMedia = mediaList.shift();

    return {
      featuredMedia,
      mediaList,
      title
    };
  }
}

export { ContentMediaSection };
