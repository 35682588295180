import React from 'react';
import Title from 'components/atoms/title';
import AnchorLink from 'components/atoms/anchor-link';
import Wrapper from 'components/atoms/wrapper';
import ThumbHover from 'components/atoms/thumb-hover';
import OffersForm from 'components/molecules/offers-form';
import Viwer360 from 'components/molecules/viwer-360';
import Section from 'components/utils/section';

import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism OfferPanel
 *
 * Show offer details and contact form
 */

function OfferPanel(props) {
  //const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.offer-panel');

  const {
    className = '',
    offer,
    sprites = null,
    images = [],
    locale = 'pt-br',
    currency = 'BRL',
    dealers,
    ...other
  } = props;

  const vehicle = offer?.vehicleColor?.modelYear;

  const offerCategory = offer?.category || null;

  const isToyotaBank = offer?.paymentType === 'CICLE_TOYOTA' || offer?.paymentType === 'CICLE_ONE';

  const sealsList = isToyotaBank && getSeals(offer?.paymentType);

  function formatTax() {
    return `${new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(offer.tax)}%`;
  }

  function formatCurrency(value) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
    }).format(value);
  }

  // function transformOfferDate(offerDate) {
  //   return new Date(offerDate).toLocaleDateString('pt-BR', { timeZone: 'GMT' })
  // }

  function getSeals(paymentType) {

    return [
      {
        url: `/images/logos/logo-${paymentType}.svg`,
        alt: paymentType
      },
      {
        url: '/images/logos/logo-recompra-garantida.png',
        alt: 'Recompra Garantida'
      }
    ];

  }

  return (
    <div className={`${css['organism__offer-panel-container']} ${className}`} {...other}>

      <Wrapper className={css['organism_offer-wrapper']}>
        <Section hasGrid style={{ padding: 0 }}>
          <div className={css['vehicle-info-container']}>

            <div className={css['vehicle-info']}>
              <Title level={1} variant={2} className={css['title']}>
                Tenha seu <b>{`${vehicle.version.vehicle.name} ${vehicle.version.name}`}</b>
              </Title>

              <strong className={css['offer-deadline']}>
                {/* {`${t('deadline')} ${format(new Date(offer.deadline), 'dd/MM/yyyy')}`} */}
              </strong>

              {offer.description.length > 0 &&
                <ul className={css['description-list']}>
                  {offer.description?.split('\n').map((item, index) => {
                    return item ? (
                      <li
                        key={index}
                        className={css['description-list__item']}>
                        {item}
                      </li>
                    ) : null;
                  })}
                </ul>
              }

              {offerCategory &&
                <span
                  style={{ backgroundColor: offerCategory.color }}
                  className={css['category-container']}>
                  {offerCategory.name}
                </span>
              }

              {sealsList?.length > 0 && (
                <ul className={css['seals-list']}>
                  {sealsList?.map((seal, index) => {
                    return (
                      <li key={index}>
                        <img src={seal?.url} alt={seal?.alt} />
                      </li>
                    );
                  })}
                </ul>
              )}

              {offer.vehicleColor.modelYear.disabled360 ?
                (
                  images?.length > 0 &&
                  <div className={css['organism__offer-panel-display-view']}>
                    <ThumbHover image={images} />
                  </div>
                )
                :
                (
                  sprites &&
                  <div className={css['vehicle-360-container']}>
                    <Viwer360 spritesheet={sprites.desk} spritesheetMob={sprites.mob} />
                  </div>
                )
              }

              <div className={css['extra-info-container']}>

                {offer.cashPayment > 0 &&
                  <div className={css['extra-info-list']}>
                    <div className={css['extra-info-list__item']}>
                      <span className={css['extra-info-list__label']}>{t('cash-payment')}</span>
                      <strong className={css['extra-info-list__value']}>
                        {formatCurrency(offer.cashPayment)}
                      </strong>
                    </div>
                  </div>
                }

                {offer.entrancePayment > 0 && (
                  <ul className={css['info-list']}>
                    <li className={css['info-list__item']}>
                      <span className={css['info-list__label']}>{t('entrance')}</span>
                      <strong className={css['info-list__value']}>
                        {formatCurrency(offer.entrancePayment)}
                      </strong>
                    </li>

                    {offer.installments > 0
                      ?
                      <li className={css['info-list__item']}>
                        <span className={css['info-list__label']}>
                          {`${offer.installments} ${t('installments')}`}
                        </span>
                        <strong className={`${css['info-list__value']} ${css['info-list__value--tax']}`}>
                          <span>{formatCurrency(offer.installmentsValue.toString())}</span>
                          <small>
                            {t('tax')}: {formatTax()}
                          </small>
                        </strong>
                      </li>
                      :
                      <li className={css['info-list__item']}><span>Sem parcelas mensais</span></li>
                    }
                  </ul>
                )}

                {offer?.residual !== null && (
                  <div className={css['info-list-residual']}>
                    {isToyotaBank && <span className={css['info-list__label']}>{t('informative-text')}</span>}
                    <strong className={`${css['info-list__value']} ${css['info-list__value--tax']}`}>
                      <span>{formatCurrency(offer.residual)}</span>
                    </strong>
                    <AnchorLink
                      color="primary"
                      linkLabel={t(`about-anchor--${offer?.paymentType}`)}
                      link='#about-cicle'
                    />
                  </div>
                )}

                {offer?.beforePrice > 0 && offer?.afterPrice > 0 &&
                  <ul className={css['info-list']}>
                    <li className={css['info-list__item']}>
                      <span className={css['info-list__label']}>{t('from')}</span>
                      <strong className={css['info-list__value']}>
                        {formatCurrency(offer.beforePrice)}
                      </strong>
                    </li>
                    <li className={css['info-list__item']}>
                      <span className={css['info-list__label']}>{t('to')}</span>
                      <strong className={css['info-list__value']}>
                        {formatCurrency(offer.afterPrice)}
                      </strong>
                    </li>
                  </ul>
                }

                <ul className={css['links-list-container']}>
                  <li className={css['links-list-container__item']}>
                    <AnchorLink
                      size={'small'}
                      linkLabel={t('conditions')}
                      link='#offer-conditions'
                    />
                  </li>
                  {isToyotaBank && (
                    <li className={css['links-list-container__item']}>
                      <AnchorLink
                        size={'small'}
                        linkLabel={t('repurchase-manual')}
                        link="/docs/manual-de-recompra.pdf"
                        isDownload={true}
                        target="_blank"
                      />
                    </li>
                  )}
                </ul>

              </div>

            </div>

          </div>

          <div className={css['vehicle-form-container']}>
            <OffersForm
              className={css['vehicle-form']}
              offer={offer}
              dealers={dealers}
            />
          </div>

        </Section>

      </Wrapper>
    </div >
  );
}

export default OfferPanel;
