import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Loader
 *
 * A basic inline loader
 */
function Loader (props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.loader');

  const {
    className = "",
    children,
    ...other
  } = props;

  return <div
    className={`${css["atom__loader-container"]} ${className}`}
    {...other}
  >
    <br />
    <div className={css['loader']} />
  </div>;
}

export default Loader;
