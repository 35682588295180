/* eslint-disable */
import React, { useState } from 'react';

import Tooltip from 'components/atoms/tooltip';
import FormSelect from 'components/atoms/form-select';
// import ThumbHover from 'components/atoms/thumb-hover';
import Title from 'components/atoms/title';
import AnchorLink from 'components/atoms/anchor-link';
import CarUpgradeCard from 'components/molecules/car-upgrade-card';

import { useCarBuilder } from 'providers/car-builder';

import { moneyMask } from 'utils/validations';

// loading the sass style fot the component
import css from './styles.module.scss';
import IconArrow from 'components/atoms/icon-arrow';

/**
 * Molecule ToyotaCicle
 *
 * The Toyota Cicle form
 */
function ToyotaCicle(props) {
  const {
    className = '',
    children,
    minimunNumberOfInstallments = 12,
    maximunNumberOfInstallments = 48,
    model = null,
    currentPath = null,
    ...other
  } = props;

  const { data: carData, setData: setCarData } = useCarBuilder();

  const [firstInstallmentInput, setFirstInstallmentInput] = useState('');
  const [installmentsValueInput, setInstallmentsValueInput] = useState(null);

  //Change the initial installment value. DO SOME VERIFICATIONS BEFORE ACCESSING BTB
  function changeInitialInstallment(evt) {
    evt.preventDefault();

    setFirstInstallmentInput(null);

    setCarData('data', {
      ...carData.data,
      desiredFirstInstallment: translateValueToBTB(firstInstallmentInput)
    });
  }

  //Change the value of the installment for BTB verification
  function changeInstallmentValue(evt) {
    evt.preventDefault();

    return false;

    if (installmentsValueInput) {
      setCarData('data', {
        ...carData.data,
        desiredInstallment: translateValueToBTB(installmentsValueInput)
      });

      setInstallmentsValueInput(null);
    }
  }

  //Get number of installments definided by code
  function getInstallmentsOptions() {
    let installmentsOptions = [];

    for (let i = minimunNumberOfInstallments; i <= maximunNumberOfInstallments; i++) {
      installmentsOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return installmentsOptions;
  }

  //Update the value of the installment based on it´s number of months to pay
  function updateInstallmentsNumber(evt) {
    const installmentsNumber = evt.target.value;

    setCarData('data', {
      ...carData.data,
      installmentsNumber: installmentsNumber
    });
  }

  //Transform the value of the user input STRING to the INT expected by BTB
  function translateValueToBTB(value) {
    if (typeof value == 'string') {
      value = (Math.round(parseFloat(value.replaceAll('.', '').replaceAll(',', '.')) * 100) / 100) * 10;
    }

    return value;
  }

  //This function is a strong candidate to the "Utils" toolbox
  function translateValueToCurrency(inputValue) {
    if (!inputValue) {
      return false;
    }

    if (typeof inputValue === 'string') {
      inputValue = parseInt(inputValue);
    }

    const currencyFractionDigits = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).resolvedOptions().maximumFractionDigits;

    const value = inputValue.toLocaleString('pt-BR', {
      minimumFractionDigits: currencyFractionDigits
    });

    return value;
  }

  //There´s no display image for every car color for the thumb hover
  function getCarImages(model) {
    let mediasURL = [];

    model?.modelYear?.medias
      .filter((media) => media.tag?.startsWith('display'))
      .map((media) => mediasURL.push(media?.media));

    return mediasURL;
  }

  return (
    <div className={`${css['molecule__toyota-cicle-container']} ${className}`} {...other}>
      <div className={css['info-container']}>
        <div className={css['info-container__title']}>
          <Title level={1} variant={2}>
            Simule o seu ciclo
          </Title>
          <Title level={2} variant={2}>
            {model?.vehicle?.name} {model?.name}
          </Title>
        </div>

        <p className={css['info-container__description']}>Aqui você configura o Ciclo Toyota do seu jeito.</p>

        <AnchorLink link="/compra/ciclo-toyota" linkLabel="Como funciona" hasArrow={false} size="small" />
      </div>

      <div className={css['section-container']}>
        <div className={css['section-container__toyota-cicle']}>
          <div className={css['form']}>
            <div className={css['form__box-simulation']}>
              <div className={css['form__box-simulation__header']}>
                <span className={css['form__box-simulation__header__title']}>
                  <strong className={css['emphasis']}>1</strong>
                  Entrada
                  <Tooltip>
                    Você pode usar de 30% a 80% do valor total do carro ou seu usado de qualquer marca como entrada no
                    pagamento de seu novo Toyota.
                  </Tooltip>
                </span>
              </div>

              <form
                className={css['toyota-cicle-input-container']}
                onSubmit={(evt) => {
                  changeInitialInstallment(evt);
                }}
              >
                <span className={css['simulation-input-label']}>R$</span>
                <div className={css['simulation-input-container']}>
                  <input
                    type="text"
                    className={css['simulation-input-container__input']}
                    placeholder={
                      carData?.data?.firstInstallment
                        ? translateValueToCurrency(carData?.data?.firstInstallment)
                        : undefined
                    }
                    value={firstInstallmentInput ? moneyMask(firstInstallmentInput) : ''}
                    onChange={(e) => setFirstInstallmentInput(e.target.value)}
                    maxLength="10"
                    required
                  />
                  <button className={css['simulation-input-container__button']} type="submit">
                    <IconArrow arrowPosition="right" />
                  </button>
                </div>
              </form>
            </div>

            <div className={css['form__box-simulation']}>
              <div className={css['form__box-simulation__header']}>
                <span className={css['form__box-simulation__header__title']}>
                  <strong className={css['emphasis']}>2</strong>
                  Parcelas
                  <Tooltip>
                    Escolha a parcela que cabe no seu bolso ou o tempo de financiamento desejado, entre 12 e 36 meses.
                    No Ciclo Toyota, você paga até 40% menos no mês do que nos financiamentos tradicionais.
                  </Tooltip>
                </span>

                <FormSelect
                  id="quantity"
                  label="vezes"
                  className={css['form__box-simulation__quantity']}
                  onChange={updateInstallmentsNumber}
                  value={carData?.data?.installmentsNumber}
                >
                  {getInstallmentsOptions()}
                </FormSelect>
              </div>

              <form
                className={css['toyota-cicle-input-container']}
                onSubmit={(evt) => {
                  changeInstallmentValue(evt);
                }}
              >
                <span className={css['simulation-input-label']}>R$</span>
                <div className={css['simulation-input-container']}>
                  <input
                    type="text"
                    className={css['simulation-input-container__input']}
                    placeholder={
                      carData?.data?.installmentsValue
                        ? translateValueToCurrency(carData?.data?.installmentsValue)
                        : undefined
                    }
                    value={installmentsValueInput ? moneyMask(installmentsValueInput) : ''}
                    onChange={(e) => setInstallmentsValueInput(e.target.value)}
                    maxLength="10"
                    required
                    disabled
                  />
                  {/* <button
                    className={css['simulation-input-container__button']}
                    type="submit"
                    disabled
                  >
                    <IconArrow arrowPosition="right" />
                  </button> */}
                </div>
              </form>
            </div>

            <div className={css['form__box-simulation']}>
              <div className={css['form__box-simulation__header']}>
                <span className={css['form__box-simulation__header__title']}>
                  <strong className={css['emphasis']}>3</strong>
                  Parcela final ou seu usado como entrada de um Toyota 0km
                  <Tooltip>
                    A parcela final representa de 20% a 50% do valor do carro para planos de 12 a 24 vezes e de 20% a
                    40% para planos de 25 a 36 vezes. No término do financiamento, você pode contar com a Recompra
                    Garantida para quitar seu plano e ainda utiliza o saldo para entrada de um novo Toyota.
                  </Tooltip>
                </span>
              </div>
              <div className={css['toyota-cicle-input-container']}>
                <span className={css['simulation-input-label']}>R$</span>
                <div className={css['simulation-input-container']}>
                  <input
                    type="text"
                    className={css['simulation-input-container__input']}
                    value={translateValueToCurrency(carData?.data?.finalInstallment) || ''}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          {/*
          <div className={css['image-container']}>
            <ThumbHover image={getCarImages(model)} className={css['image']} />
          </div> */}
        </div>

        {model?.upgrade && (
          <>
            <div className={css['section-container__car-upgrade']}>
              <CarUpgradeCard model={model} carThumb={getCarImages(model?.upgrade)} currentPath={currentPath} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ToyotaCicle;
