import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import ThumbHover from 'components/atoms/thumb-hover';
import Title from 'components/atoms/title';
import React, { useState } from 'react';
import i18n from 'utils/i18n';
import Modal from 'components/molecules/modal';

// loading the sass style fot the component
import css from './styles.module.scss';
import OfferConditionsCard from 'components/molecules/offer-conditions-card';

/**
 * Molecule OfferDetails
 *
 * Shows offer details
 */
function OfferDetails(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.offer-details');

  const {
    className = '',
    cardType,
    offer,
    region,
    locale = 'pt-br',
    currency = 'BRL'
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const vehicle = offer?.vehicleColor?.modelYear;

  const offerCategory = offer?.category || null;

  const thumbHoverImages = getVehicleMedias();

  const modelYear =  vehicle.year || getVehicleYearFromKatashiki();

  const isToyotaBank = offer?.paymentType === 'CICLE_TOYOTA' || offer?.paymentType === 'CICLE_ONE';

  const sealsList = isToyotaBank && getSeals(offer?.paymentType);

  function getSeals(paymentType) {
    return [
      {
        url: `/images/logos/logo-${paymentType}.svg`,
        alt: paymentType
      },
      {
        url: '/images/logos/logo-recompra-garantida.png',
        alt: 'Recompra Garantida'
      }
    ];
  }

  function formatTax() {
    return `${new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(offer?.tax)}%`;
  }

  function formatCurrency(value) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency
    }).format(value);
  }

  function getVehicleMedias() {
    const thumbHoverImages = [];

    vehicle?.medias?.map((media) => {
      const mediaData = {
        url: media.media.url,
        alt: media.media.title,
        type: media.media.mediaType.title
      };
      if (media.tag === 'display:regular') {
        thumbHoverImages[0] = mediaData;
      } else if (media.tag === 'display:hover') {
        thumbHoverImages[1] = mediaData;
      }
    });

    return thumbHoverImages;
  }

  // To get the year of the vehicle model, we need to retrieve this information from the "katashiki" value.
  // Normaly it´s from it´s last four numbers eg: MXGA10L-GHXEHG-H32223
  function getVehicleYearFromKatashiki() {
    const katashiki = vehicle?.katashiki;

    if (!katashiki) {return false;}

    return `${katashiki.substr(-4, 2)}/${katashiki.substr(-2)}`;
  }

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  function transformOfferDate(offerDate) {
    return new Date(offerDate).toLocaleDateString('pt-BR', { timeZone: 'GMT' });
  }

  return (
    <li className={`${css['offer-details-container']} ${className}`}>
      <div className={css['thumb-hover-container']}>
        {offerCategory && (
          <span
            style={{ backgroundColor: offerCategory.color }}
            className={css['category-container']}>
            {offerCategory.name}
          </span>
        )}
        <a href={`${offer?.page?.fullpath}/?estado=${region?.state}&cidade=${region?.city}`}>
          <ThumbHover image={thumbHoverImages} className={css['thumb-hover']} />
        </a>
        {sealsList?.length > 0 && (
          <ul className={css['seal-container']}>
            {sealsList?.map((seal, index) => {
              return (
                <li key={index} className={css['seal-container__item']}>
                  <img src={seal?.url} alt={seal?.alt} />
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className={css['offer-details-content']}>
        <Title data-uppercase level={3} variant={3}>
          {vehicle &&
            vehicle.version &&
            vehicle.version?.vehicle &&
            `${vehicle?.version?.vehicle?.name} ${vehicle.version?.name}`}
          {modelYear && (
            <span className={css['organism_offer-year']}> {modelYear}</span>
          )}
        </Title>

        <ul className={css['header-list']}>
          {offer?.description?.split('\n').map((item, index) => {
            return item ? (
              <li key={index} className={css['header-list__item']}>
                {item}
              </li>
            ) : null;
          })}
        </ul>

        <ul className={css['info-list']} data-card-type={cardType}>
          {offer?.cashPayment > 0 && (
            <li className={css['info-list__item']}>
              <span className={css['info-list__label']}>
                {t('cash-payment')}
              </span>
              <strong className={css['info-list__value']}>
                {formatCurrency(offer?.cashPayment)}
              </strong>
            </li>
          )}

          {offer?.entrancePayment > 0 && (
            <li className={css['info-list__item']}>
              <span className={css['info-list__label']}>{t('entrance')}</span>
              <strong className={css['info-list__value']}>
                {formatCurrency(offer?.entrancePayment)}
              </strong>
            </li>
          )}

          {offer?.installments > 0 &&
            offer?.installmentsValue > 0 &&
            offer?.tax !== null && (
            <li className={css['info-list__item']}>
              <span className={css['info-list__label']}>{`${
                offer.installments
              } ${t('installments')}`}</span>
              <strong
                className={`${css['info-list__value']} ${css['info-list__value-installment']}`}>
                {formatCurrency(offer.installmentsValue)}
              </strong>
              <small className={css['info-list__footnote']}>
                {t('tax')}: {formatTax()}
              </small>
            </li>
          )}
          {offer?.installments === 0 && (
            <li className={css['info-list__item']}>
              <span className={css['info-list__no-installments']}>
                {t('no-installments')}
              </span>
            </li>
          )}

          {offer?.paymentType === 'CICLE_ONE' && (
            <span>{t('no-installments')}</span>
          )}

          {offer?.residual !== null && (
            <li className={css['info-list__item']}>
              <span className={css['info-list__label']}>{t('residual')}</span>
              <strong className={css['info-list__value']}>
                {formatCurrency(offer.residual)}
              </strong>
              {offer?.tax !== null && offer?.paymentType === 'CICLE_ONE' && (
                <small className={css['info-list__footnote']}>
                  {t('tax')}: {formatTax()}
                </small>
              )}
            </li>
          )}

          {offer?.beforePrice > 0 && (
            <li className={css['info-list__item']}>
              <span className={css['info-list__label']}>De:</span>
              <strong className={css['info-list__value']}>
                {formatCurrency(offer.beforePrice)}
              </strong>
            </li>
          )}

          {offer?.afterPrice > 0 && (
            <li className={css['info-list__item']}>
              <span className={css['info-list__label']}>Para:</span>
              <strong className={css['info-list__value']}>
                {formatCurrency(offer.afterPrice)}
              </strong>
            </li>
          )}
        </ul>

        <div className={css['offer-details-content__footer']}>
          <p className={css['footer-extra-info']}>
            {`${t('deadline')} ${transformOfferDate(offer?.deadline)}`}
          </p>
          <ul className={css['buttons-container']}>
            {/* <li className={css['buttons-container__item']}>
            <Button link="https://google.com" color={"light"}>
              { t('check-location') } <IconPinMap />
            </Button>
          </li> */}
            <li className={css['buttons-container__item']}>
              <Button
                link={`${offer?.page?.fullpath}/?estado=${region?.state}&cidade=${region?.city}`}>
                {t('interested')}
              </Button>
            </li>
          </ul>

          <ul className={css['links-list-container']}>
            {offer?.conditions && (
              <li className={css['links-list-container__item']}>
                <AnchorLink
                  size={'small'}
                  linkLabel={t('conditions')}
                  className={css['atom__anchor']}
                  onClick={toggleModal}
                />
              </li>
            )}
            {isToyotaBank && (
              <li className={css['links-list-container__item']}>
                <AnchorLink
                  size={'small'}
                  linkLabel={t('repurchase-manual')}
                  className={css['atom__anchor']}
                  link="/docs/manual-de-recompra.pdf"
                  isDownload={true}
                  target="_blank"
                />
              </li>
            )}
          </ul>
        </div>

        {isModalOpen && (
          <Modal
            modalIsOpen={isModalOpen}
            onClose={toggleModal}
            modalClassName={css['conditions-modal']}
            title={t('conditions-title')}>
            <OfferConditionsCard
              conditions={offer?.conditions}
              showPrintButton={false}
            />
          </Modal>
        )}
      </div>
    </li>
  );
}

export default OfferDetails;
