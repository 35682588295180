/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';
//import i18n from 'utils/i18n';

import Button from 'components/atoms/button';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';
import css from './styles.module.scss';
import { IconPause, IconPlay } from 'components/icons';

/**
 * Molecule InteractiveVideoContent
 *
 * This component represents a video sound player. It renders a video player
 * with play/pause functionality and a descriptive paragraph. The user can play
 * or pause the video by clicking on a button. The component supports customization
 * through optional props like className and bgColor.
 */
function InteractiveVideoContent(props) {
  //const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.interactive-video-content');

  const {
    className = '',
    bgColor,
    buttonColor,
    contentText,
    buttonLabel,
    mediaVideo,
    showDivider,
    spacingBottom,
    spacingTop,
    containerVideoSize = null,
    ...other
  } = props;

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    const video = videoRef.current;

    isPlaying ? video.pause() : video.play();

    setIsPlaying(!isPlaying);
  };

  return (
    <Wrapper
      className={`${css['molecule__interactive-video-content-container']} ${className}`}
      bgColor={bgColor}
      spacingTop={spacingTop}
      spacingBottom={spacingBottom}
      data-size-video={containerVideoSize}
      {...other}
    >
      <Section className={css['section-container']}>
        {showDivider && <div className={css['divider']} />}
        {mediaVideo && (
          <>
            <div className={css['video']}>
              <video
                onEnded={() => setIsPlaying(!isPlaying)}
                ref={videoRef}
                src={mediaVideo?.url}
                width="100%"
                height="100%"
                title={mediaVideo?.title}
              >
                <source src={mediaVideo?.url} type={mediaVideo?.mediaType?.title} />
              </video>
            </div>

            {contentText && <p className={css['content']}>{contentText}</p>}

            <Button className={css['button-play-pause']} color={buttonColor} onClick={handlePlayPause}>
              {buttonLabel}
              {isPlaying ? <IconPause /> : <IconPlay />}
            </Button>
          </>
        )}
      </Section>
    </Wrapper>
  );
}

export default InteractiveVideoContent;
