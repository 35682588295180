import React from 'react';
// Slider
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule Carousel
 *
 * The carousel can receive a lot of parameters
 * to search and understand more visit the component docs: https://react-slick.neostack.com/docs/example/simple-slider
 */
function Carousel(props) {
  const { className = '', children, carouselRef, settings } = props;

  return (
    <Slider {...settings} ref={carouselRef} className={`${css['carousel-container']} ${className}`}>
      {children}
    </Slider>
  );
}

export default Carousel;
