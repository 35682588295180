import React from 'react';
import TabCar from 'components/molecules/tab-car';
import Slider from "react-slick";
// import i18n from 'utils/i18n';
import "slick-carousel/slick/slick.css";

// loading the sass style fot the component
import css from './styles.module.scss';

function TabCarCarousel (props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.tab-car-carousel');

  const {
    className = "",
    content = [],
    children,
    locale,
    ...other
  } = props;

  let carouselSettings = {
    speed: 500,
    useTransform: false,
    // arrows: false,
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    padding: 0,
    responsive: [
      {
        breakpoint: 1080, //$bp-tablet
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 600, //$bp-phone
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  // try {
  //   React.useState(content ? content : tabs[0].id);


  // } catch (error) {
  //   console.error("Category Tabs component error: ", error);
  //   return <div>There was an error in the Category Tabs</div>;
  // }

  return <div
    className={`${css["molecule__tab-car-carousel-container"]} ${className}`}
    {...other}
  >
    <div className={css['slider-wrapper']}>
      <Slider {...carouselSettings} className={`${css["action-banner-wrapper"]} ${className}`} {...other}>
        {content?.map(item => {
          return <div key={`${item.carlabel}-cont`} className={css['slide-container']}>
            <TabCar {...item} locale = {locale} />
          </div>;
        })}
      </Slider>
      <div className={css['back-line']}></div>
    </div>
    {children}
  </div>;
}

export default TabCarCarousel;
