import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class TabsListButtons implements ContentBlock {
  label = 'Buttons tabs';

  component = 'TabsListButtons';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'bgColor',
      label: 'Wrapper backgroud color',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'black'
      ],
      defaultValue: 'default'
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ],
      defaultValue: '2'
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ],
      defaultValue: '2'
    },
    {
      name: 'listAlignment',
      label: 'Alignment of the list of buttons',
      help: '',
      type: 'String',
      control: 'select',
      options: ['center', 'left'],
      defaultValue: 'center'
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'The title of the content',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].label',
      label: 'Link title',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'callToActions[].target',
      label: 'Link target',
      help: '',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    }
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async adapter(contents: any[], extra: any): Promise<object> {
    const content = contents[0];

    if (!content?.title) {
      throw Error("Content 'title' are required");
    }

    const buttonsList = [];
    const pagePath = extra?.context?.page?.fullpath;

    content?.callToActions?.map((action) => {
      buttonsList.push({
        link: action.target || undefined,
        linkLabel: action.label || undefined
      });
    });

    return { buttonsList, pagePath };
  }
}

export { TabsListButtons };
