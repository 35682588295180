/* eslint-disable @typescript-eslint/no-explicit-any */
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import {
  anchor,
  formTitle,
  formDescription,
  formTitleSuccess,
  formContentSuccess,
  formTitleError,
  formContentError,
  formHeaderSpacing
} from 'utils/meta';

import Dealers from 'utils/repo/dealers';

class RecallFormCustom implements ContentBlock {
  label = 'Recall Form Custom';

  component = 'RecallFormCustom';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    formTitle,
    formDescription,
    formTitleSuccess,
    formContentSuccess,
    formTitleError,
    formContentError,
    formHeaderSpacing
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const settings = extra?.settings;
    const locale = String(extra?.context?.locale).toLowerCase();
    const productId = extra?.context?.productId;

    if (!extra?.context?.productId) {
      throw new Error(`There is no productId defined.`);
    }

    const dealersByLocation =
      (await dataFetcher(Dealers.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    return {
      locale: locale,
      dealersByLocation: dealersByLocation,
      formTitle: settings?.title,
      formDescription: settings?.description,
      titleSuccess: settings?.titleSuccess,
      contentSuccess: settings?.contentSuccess,
      titleError: settings?.titleError,
      contentError: settings?.contentError,
      headerSpacing: settings?.headerSpacing || '0'
    };
  }
}

export { RecallFormCustom };
