import React from 'react';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

import Title from 'components/atoms/title';
import ExpandedContent from 'components/molecules/expanded-content';

/**
 * Molecule ServiceDropdownCard
 *
 * The sidebar content of the services page
 */
function ServiceDropdownCard (props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.service-dropdown-card');

  const {
    className = "",
    children,
    current,
    services = [],
    ...other
  } = props;

  return <div
    className={`${css["service-dropdown-card-container"]} ${className}`}
    {...other}
  >
    <div className={css['header']}>
      <div className={css['header-icon']}>
        {
          current?.content?.icon && <img alt={current?.content?.icon?.title || ""} src={current?.content?.icon?.url} />
        }
      </div>
      <Title level={2} variant={3} className={css['header-title']}>{current?.title}</Title>
    </div>
    <div className={css['content']}>
      <ExpandedContent
        label={t("expanded-content-label")}
      >
        <ul className={css['services-list']}>
          {
            services.map((service, idx) => {
              return <li key={idx} className={css['services-list__item']}>
                <a href={service.page.fullpath} className={css['services-list__anchor']}>
                  <div className={css['services-list__icon']}>
                    {
                      service?.content?.icon &&
                      <img className={css['services-list__icon-image']} alt={current?.content?.icon?.title || ""} src={service?.content?.icon?.url} />
                    }
                  </div>
                  {service?.title}
                </a>
              </li>;
            })
          }
        </ul>
      </ExpandedContent>
    </div>
  </div>;
}

export default ServiceDropdownCard;
