import React, { useState } from 'react';
import { IconClose } from 'components/icons';
//import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Pill
 *
 * <!-- TODO: add a description here! -->
 */
function Pill(props) {
  // eslint-disable-next-line no-unused-vars
  //const gt = i18n.useTranslations('global');
  // eslint-disable-next-line no-unused-vars
  //const t = i18n.useTranslations('components.pill');

  const {
    className = '',
    label,
    size = null,
    disabled = false,
    isButton = true,
    isSelected = false,
    hasCloseIcon = false,
    pillId,
    isSwitch,
    handlePillType = null,
    setType,
    type,
    removeDefaultBehaviours = false,
    onClick: extraOnClick = null,
    ...other
  } = props;

  const [isActive, setIsActive] = useState(false);

  function handleSelected() {
    if (isSwitch) {
      handlePillType(pillId);
    } else {
      setIsActive(!isActive);
    }
  }

  return (
    <>
      {isButton ? (
        <button
          className={`${css['atom__pill-container']} ${className}`}
          data-size={size}
          data-active={isActive}
          data-is-selected={isSelected}
          disabled={disabled ? true : undefined}
          onClick={(_) => {
            if (!removeDefaultBehaviours) {
              handleSelected();
            }
            if (extraOnClick) {
              extraOnClick();
            }
          }}
          {...other}
        >
          {label}
          {hasCloseIcon ? <IconClose className={css['pill-container__icon']} /> : null }
        </button>
      ) : (
        <span
          className={`${css['atom__pill-container']} ${className}`}
          data-size={size}
          data-is-selected={isSelected}
          {...other}>
          {label}
        </span>
      )}
    </>
  );
}

export default Pill;
