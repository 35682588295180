import React from 'react';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Atom Input
 *
 * <!-- TODO: add a description here! -->
 */
function Input (props) {
  const {
    className = "",
    children,
    icon,
    ...others
  } = props;

  return(
    <div
      className={`${css["input-container"]} ${className}`}
    >
      <input
        className={css['input']}
        {...others}
      />
      {/* {icon} */}
    </div>
  );
}

export default Input;
