import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

import Accessory from 'utils/repo/accessory';
import VehicleQuery from 'utils/repo/vehicle';

class AccessoriesList implements ContentBlock {
  label = 'Car Accessories List';

  component = 'AccessoriesList';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'bgColor',
      label: 'Cor de fundo',
      help: 'Cor de fundo para a sessão',
      type: 'String',
      control: 'select',
      options: [
        'default',
        'gray',
        'gray-2',
        'gray-3',
        'gray-4',
        'gray-5',
        'gray-6',
        'black'
      ]
    },
    {
      name: 'spacingTop',
      label: 'The padding space at the top of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    },
    {
      name: 'spacingBottom',
      label: 'The padding space at the bottom of the section ',
      help: '',
      type: 'String',
      control: 'select',
      options: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7'
      ]
    }
  ];

  properties = [
    {
      name: 'title',
      label: 'Title',
      help: 'hidden title attr',
      type: 'string',
      control: 'input:text',
      defaultValue: '',
      props: null
    },
    {
      name: 'description',
      label: 'Disclaimer',
      help: 'Rich text content',
      type: 'String',
      control: 'editor',
      defaultValue: '',
      props: null
    }
  ];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const content = contents[0];

    const models = (await dataFetcher(VehicleQuery.getAllVehicles(), { locale: extra?.context?.locale }))?.data
      ?.vehicles;

    const filteredModels = models?.reduce((acc, model) => {
      model.versions = model.versions?.filter((version) => version.status);
      return [...acc, model];
    }, []);

    const categoriesModels = (
      await dataFetcher(VehicleQuery.getVehiclesByCategory(), { locale: extra?.context?.locale })
    )?.data?.vehicleCategories;

    const categories = (await dataFetcher(Accessory.getCategoriesAccessory(), { locale: extra?.context?.locale }))?.data
      ?.accessoryCategories;
    return {
      models: filteredModels,
      categoriesModels: categoriesModels,
      categories: categories,
      disclaimer: content.description || null,
      locale: extra?.context?.locale || 'pt-BR'
    };
  }
}

export { AccessoriesList };
