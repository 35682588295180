import React from 'react';
import css from './styles.module.scss';
import Wrapper from 'components/atoms/wrapper';
import CardsList from 'components/molecules/cards-list';
import Section from 'components/utils/section';
import i18n from 'utils/i18n';
import Card from 'components/molecules/card';

/**
 * Organism NewsBanner
 *
 * This component will be used to list news
 */
function NewsBanner(props) {
  const { className = '', spacingTop = 0, spacingBottom = 0, newsData = [], ...other } = props;
  const t = i18n.useTranslations('components.news-banner');

  const transformNewsItem = (newsItem, highlight) => ({
    title: newsItem.title,
    content: newsItem.description,
    media: {
      url: highlight ? newsItem.highlightMedia?.url : newsItem.image?.url,
      alt: newsItem.title
    },
    date: new Date(newsItem.releaseDate).toLocaleDateString('pt-BR'),
    category: newsItem.newsCategories?.[0]?.name,
    link: newsItem.page?.fullpath,
    linkLabel: t('continue_reading')
  });

  const cardHighlightData = newsData?.length > 0 ? { ...transformNewsItem(newsData[0], true) } : null;
  const cardData =
    newsData?.length > 1 ? newsData.slice(1, 4).map((newsItem) => transformNewsItem(newsItem, false)) : [];

  return (
    <Wrapper
      className={`${css['organism__news-banner-container']} ${className}`}
      {...other}
      data-spacing-top={spacingTop}
      data-spacing-bottom={spacingBottom}
    >
      <Section className={css['section-container']}>
        <Card
          className={css['card-highlight']}
          isUppercase={false}
          showReadMore={true}
          {...cardHighlightData}
          isHorizontal={true}
        />
        <CardsList cardsData={cardData} />
      </Section>
    </Wrapper>
  );
}

export default NewsBanner;
