import React, { useState, useEffect, useCallback } from 'react';
//import i18n from 'utils/i18n';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { dateMask, validNumber } from 'utils/validations';
import { empty } from 'utils/functions';
import { areaCodeMask } from 'utils/forms';

import { captchaToken } from 'utils/captcha/token';
import getConfig from 'next/config';
import Script from 'next/script';

import Title from 'components/atoms/title';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import FormTextArea from 'components/atoms/form-text-area';
import Button from 'components/atoms/button';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Section from 'components/utils/section';

import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule RepentanceForm
 *
 * Repentance form
 */
function RepentanceForm(props) {
  // const gt = i18n.useTranslations('global');
  //const t = i18n.useTranslations('components.repentance-form');

  const {
    className = '',
    locale,
    dealers = [],
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    children,
    ...other
  } = props;

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required('El campo de nombre no puede estar vacío.')
      .min(4, 'El nombre debe contener al menos 4 caracteres.'),
    email: yup
      .string()
      .required('El campo de email no puede estar vacío.')
      .email('Este no es un correo electrónico válido.'),
    cod_area: yup.string().min(2, 'El Cód. Área debe contener mínimo 2 números.'),
    phone: yup
      .string()
      .min(8, 'El teléfono ingresado no es válido')
      .max(8, 'El teléfono ingresado no es válido.')
      .required('El campo de telefono no puede estar vacío.'),
    state: yup
      .string()
      .required('El campo de provincia no puede estar vacío.')
      .notOneOf(['placeholder', undefined], 'Selecciona una provincia.'),
    city: yup
      .string()
      .required('El campo de ciudad no puede estar vacío.')
      .notOneOf(['placeholder', undefined], 'seleccione una ciudad.'),
    dealership: yup
      .string()
      .required('El campo de concesionario no puede estar vacío.')
      .notOneOf(['placeholder', undefined], 'seleccione un concesionario.')
  });

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isStatusComponent, setIsStatusComponent] = useState('default');

  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDealer, setSelectedDealer] = useState('');
  const [listStates, setListStates] = useState([]);
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const [data, setData] = useState({});

  const [dataFields, setDataFields] = useState({
    name: '',
    email: '',
    phone: '',
    state: '',
    city: ''
  });

  function loadStates() {
    setListStates([...new Set(dealers.map((state) => state.address.state))].sort());
  }

  function loadCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() == selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();
    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    if (selectedCity) {
      const filtered = dealers?.filter((dealer) => {
        return dealer?.address?.city.toUpperCase() == selectedCity.toUpperCase();
      });
      setListDealers(filtered);
    }
  }

  useEffect(() => {
    loadDealers(selectedCity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  useEffect(() => {
    loadCities(selectedState);
  }, [selectedState]);

  function onChangeState(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setValue('city', 'placeholder');
    setListDealers([]);
    setSelectedState(selected);
    setValue('state', selected);
  }

  function onChangeCity(e) {
    const selected = e.currentTarget.value;
    setValue('dealership', 'placeholder');
    setSelectedCity(selected);
    setValue('city', selected);
  }

  function onChangeDealers(e) {
    const selected = e.currentTarget.value;
    setSelectedDealer(selected);
    setValue('dealership', selected);
  }

  function validateForm() {
    setDataFields({
      name: getValues('name'),
      email: getValues('email'),
      phone: getValues('phone'),
      state: selectedState,
      city: selectedCity,
      dealer: selectedDealer
    });

    const isFormEmpty = Object.values(dataFields).some((x) => empty(x));

    !isFormEmpty ? setIsDisable(false) : setIsDisable(true);
    isFormEmpty ? setIsFormIncomplete(true) : setIsFormIncomplete(false);

    setData({
      name: dataFields?.name,
      surname: getValues('surname'),
      email: dataFields?.email,
      phone: dataFields?.phone,
      state: selectedState,
      city: selectedCity,
      dealership: selectedDealer,
      dni: getValues('dni'),
      contract: getValues('contract'),
      codArea: getValues('cod_area'),
      product: getValues('product'),
      closeDate: getValues('close_date'),
      comment: getValues('comment')
    });
  }

  function onError(error) {
    console.log('Error: ', error);
  }

  function onBackForm() {
    setIsStatusComponent('default');
  }

  const resetForm = useCallback(() => {
    const result = {};
    reset(result);
  }, [reset]);

  const sendRequest = async (url, data) => {
    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };

    return await fetch(url, opts);
  };

  const handleResponse = (success) => {
    if (success) {
      setIsStatusComponent('success');
      resetForm(reset);
      return;
    }
    setIsStatusComponent('error');
    return;
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      data.locale = locale;
      data.type = 'REGRET';

      const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
      if (!captchaPayload) {
        console.debug('Falhou verificação do recaptcha');
        handleResponse(false);
        return;
      }

      data.captchaPayload = captchaPayload;
      const url = '/api/contact';

      const response = await sendRequest(url, data);
      handleResponse(response.ok && response.status === 200);
    } catch (error) {
      handleResponse(false);
      console.error('Erro durante o envio do formulário:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Section>
      <Script
        src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
      ></Script>

      {isStatusComponent === 'success' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleSuccess ? titleSuccess : '¡Formulario enviado con éxito!'}
          contentSuccess={contentSuccess ? contentSuccess : 'Se le responderá lo antes posible.'}
          contentBackForm="Enviar otro formulario"
          contentBackHome="Ir a la página incial"
        />
      )}
      {isStatusComponent === 'error' && (
        <SuccessSubmitForm
          onBackForm={onBackForm}
          titleSuccess={titleError ? titleError : '¡Error al enviar el formulario!'}
          contentSuccess={contentError ? contentError : 'Tenta novamente.'}
          contentBackForm="Enviar otro formulario"
          contentBackHome="Ir a la página incial"
        />
      )}
      {isStatusComponent === 'default' && (
        <form
          className={`${css['molecule__repentance-form-container']} ${className}`}
          {...other}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <Title level={2} variant={2} className={css['title']}>
            Formulario arrepentimiento
          </Title>

          <div className={css['fields-container']}>
            <Title level={3} variant={5} className={css['description']}>
              Datos personales
            </Title>

            <div className={css['row']}>
              <FormInput
                id="name"
                label="Nombre*"
                dataRegister={register('name')}
                dataErrors={errors['name']}
                onClick={validateForm}
                type="text"
                placeholder="Nombre"
                className={css['field']}
              />

              <FormInput
                id="surname"
                label="Apellido"
                dataRegister={register('surname')}
                dataErrors={errors['surname']}
                onBlur={validateForm}
                type="text"
                placeholder="Apellido"
                className={css['field']}
              />

              <FormInput
                id="dni"
                label="DNI"
                dataRegister={register('dni')}
                dataErrors={errors['dni']}
                onBlur={validateForm}
                maxLength="8"
                minLength="8"
                placeholder="DNI"
                type="text"
                className={css['field_dni']}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = validNumber(value);
                }}
              />
            </div>

            <div className={css['row']}>
              <FormInput
                id="email"
                label="Email*"
                dataRegister={register('email')}
                dataErrors={errors['email']}
                onBlur={validateForm}
                type="text"
                placeholder="Ejemplo@mail.com"
                className={css['field']}
              />

              <FormInput
                id="cod_area"
                label="Cód. Área*"
                dataRegister={register('cod_area')}
                dataErrors={errors['cod_area']}
                onBlur={validateForm}
                type="text"
                minLength="2"
                placeholder="Ej. 11"
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' || event.key === 'Delete') {
                    const value = event.target.value;
                    const unmaskedValue = value.replace(/\D/g, '');
                    event.target.value = unmaskedValue;
                  }
                }}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = areaCodeMask(value);
                }}
                className={css['field']}
              />

              <FormInput
                id="phone"
                label="Teléfono*"
                dataRegister={register('phone')}
                dataErrors={errors['phone']}
                onBlur={validateForm}
                onClick={validateForm}
                type="tel"
                maxLength="8"
                placeholder="Ej: 12345678"
                className={css['field']}
              />
            </div>
          </div>

          <div className={css['fields-container']}>
            <div className={css['row']}>
              <FormSelect
                id="state"
                label="Provincia*"
                placeholder="Provincia"
                onBlur={validateForm}
                onFocus={loadStates}
                onChange={onChangeState}
                dataRegister={register('state')}
                dataErrors={errors['state']}
                className={css['field']}
              >
                {listStates.length > 0 &&
                  listStates?.map((state, index) => {
                    return (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    );
                  })}
              </FormSelect>

              <FormSelect
                id="city"
                label="Ciudad*"
                placeholder="Ciudad"
                onBlur={validateForm}
                onChange={onChangeCity}
                dataRegister={register('city')}
                dataErrors={errors['city']}
                className={css['field']}
              >
                {selectedState &&
                  listCities.length > 0 &&
                  listCities?.map((city, index) => {
                    return (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    );
                  })}
              </FormSelect>

              <FormSelect
                id="dealership"
                label="Concesionario*"
                placeholder="Concesionario"
                onBlur={validateForm}
                onChange={onChangeDealers}
                dataRegister={register('dealership')}
                dataErrors={errors['dealership']}
                className={css['field']}
              >
                {selectedCity &&
                  listDealers.length > 0 &&
                  listDealers?.map((dealer, index) => {
                    return (
                      <option key={index} value={dealer.name}>
                        {dealer.name.toUpperCase()}
                      </option>
                    );
                  })}
              </FormSelect>
            </div>

            <div className={css['row']}>
              <FormInput
                id="product"
                label="Producto adquirido"
                placeholder="Producto"
                dataRegister={register('product')}
                dataErrors={errors['product']}
                onBlur={validateForm}
                type="text"
                className={css['field']}
              />

              <FormInput
                id="contract"
                label="Número de contrato"
                dataRegister={register('contract')}
                dataErrors={errors['contract']}
                onBlur={validateForm}
                type="tel"
                placeholder="0000000000"
                className={css['field']}
              />

              <FormInput
                id="close_date"
                label="Fecha de suscripción"
                dataRegister={register('close_date')}
                dataErrors={errors['close_date']}
                onBlur={validateForm}
                type="text"
                placeholder="00/00/0000"
                className={css['field']}
                masking={(event) => {
                  const value = event.target.value;
                  event.target.value = dateMask(value);
                }}
              />
            </div>

            <FormTextArea
              displayLabel={false}
              id="comment"
              placeholder="Comentario"
              dataRegister={register('comment')}
              dataErrors={errors['comment']}
              onBlur={validateForm}
              rows="3"
              className={css['field']}
            />
          </div>

          <p disabled={isDisable} className={css['disclaimer']}>
            Tu formulario será enviado al concesionario seleccionado previamente. Al clickear el botón ENVIAR, estarás
            certificando que eres mayor de 18 años y que has leído y estás de acuerdo con los Términos & Condiciones.
          </p>

          <Button disabled={isFormIncomplete || isSubmitting} onClick={validateForm}>
            Enviar
          </Button>
        </form>
      )}
    </Section>
  );
}

export default RepentanceForm;
